import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

export const ITEMS_UPDATE_LIVE_START = "ITEMS_UPDATE_LIVE_START";
export const ITEMS_UPDATE_LIVE_SUCCESS = "ITEMS_UPDATE_LIVE_SUCCESS";
export const ITEMS_UPDATE_LIVE_FAIL = "ITEMS_UPDATE_LIVE_FAIL";
export const COLLECTION_ITEM_UPDATE_START = "COLLECTION_ITEM_UPDATE_START";
export const COLLECTION_ITEM_UPDATE_SUCCESS = "COLLECTION_ITEM_UPDATE_SUCCESS";
export const COLLECTION_ITEM_UPDATE_FAIL = "COLLECTION_ITEM_UPDATE_FAIL";
export const FETCH_WEBFLOW_DATA_START = "FETCH_WEBFLOW_DATA_START";
export const FETCH_WEBFLOW_DATA_SUCCESS = "FETCH_WEBFLOW_DATA_SUCCESS";
export const FETCH_WEBFLOW_DATA_FAIL = "FETCH_WEBFLOW_DATA_FAIL";

export const retrieveWebflowData = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: FETCH_WEBFLOW_DATA_START,
      });

      const websitesData = await api.websites.retrieveWebflowData();
      const webflowWebsites = websitesData.webflowWebsites;

      dispatch({
        type: FETCH_WEBFLOW_DATA_SUCCESS,
        payload: {
          webflowWebsites,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: FETCH_WEBFLOW_DATA_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const updateCollectionItemLive = (
  collectionId: string,
  itemId: string,
  collectionItemFields: any,
  state: any
) => {
  return async (dispatch: Function) => {
    dispatch({
      type: ITEMS_UPDATE_LIVE_START,
      payload: {},
    });

    const items = await api.websites.updateCollectionItemLive(
      collectionId,
      itemId,
      collectionItemFields,
      state
    );
    dispatch({
      type: ITEMS_UPDATE_LIVE_SUCCESS,
      payload: {},
    });
  };
};

export const updateCollectionItem = (
  state: any,
  siteId: string,
  domains: string[]
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: COLLECTION_ITEM_UPDATE_START,
      });

      await api.websites.updateCollectionItem(state);

      // If a custom domain is not set, i.e. the site is still on staging only, webflow will return and empty array.
      // You can't publish to a webflow staging domain via API... publishing must be done manually on webflow in this case.
      if (domains.length > 0) {
        await api.websites.publishSite(siteId, state, domains);
      }

      const _items = await api.websites.retrieveWebflowData();

      const updatedWebsites = _items.webflowWebsites;

      dispatch({
        type: COLLECTION_ITEM_UPDATE_SUCCESS,
        payload: { updatedWebsites },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: COLLECTION_ITEM_UPDATE_FAIL,
        payload: error.response.data,
      });
      return false;
    }
  };
};
