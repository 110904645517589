import {
  DripInterface,
  ReduxAction,
  voidDrip,
} from "../../common-interfaces/interfaces";
import { isDripWithActiveDripsRunning } from "../../common-utils/utils";

import { LOGOUT } from "../../user/redux/user-actions";
import {
  DRIP_CREATED,
  DRIP_FORM_UPDATED,
  DRIPS_DELETED,
  DRIPS_FETCH_SUCCESS,
  INITIALIZE_DRIP_FORM,
  TICK_MULTIPLE_DRIPS,
  TICK_SINGLE_DRIP,
  DRIPS_SHARE_START,
  DRIPS_SHARE_SUCCESS,
  DRIPS_SHARE_FAIL,
} from "./drips-actions";

interface DripsState {
  isInitializedDrips: boolean;
  drips: DripInterface[];
  tickedDripsIds: (number | undefined)[];
  form: DripInterface;
}

const initialDripState = {
  name: "",
  steps: [],
};

const initialState: DripsState = {
  isInitializedDrips: false,
  form: initialDripState,
  drips: [],
  tickedDripsIds: [],
};

export const dripsReducer = (
  state: DripsState = initialState,
  action: ReduxAction
): DripsState => {
  const { payload, type } = action;
  switch (type) {
    case DRIPS_SHARE_SUCCESS:
      const newDrips = payload.newDrips;
      // Replacing multiple objects in array
      // https://stackoverflow.com/a/37585362
      let __drips = state.drips
        .filter(
          (drip: any) => newDrips.findIndex((d: any) => d.id === drip.id) === -1
        )
        .concat(newDrips);

      return {
        ...state,
        drips: __drips.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
        tickedDripsIds: [],
      };

      return {
        ...state,
      };

    case INITIALIZE_DRIP_FORM:
      return {
        ...state,
        form: payload.drip ? payload.drip : initialState.form,
      };

    case DRIP_FORM_UPDATED:
      return {
        ...state,
        form: payload.form,
      };

    case DRIP_CREATED:
      let newDrip = payload.drip;
      if (!newDrip.activeDrips) newDrip.activeDrips = [];
      return {
        ...state,
        drips: state.drips.concat(newDrip),
        form: initialState.form,
      };

    case DRIPS_DELETED:
      const { dripIds } = payload;
      return {
        ...state,
        drips: state.drips.filter((drip) => !dripIds.includes(drip.id)),
        tickedDripsIds: [],
      };

    case TICK_MULTIPLE_DRIPS:
      //drips that have ActiveDrips in "playing" or "paused" mode
      //can't be selected for deletion
      let allTickedDripIds = payload.dripIds;
      let allTickedDrips = state.drips.filter((drip: any) =>
        allTickedDripIds.includes(drip.id)
      );

      const realTickableDripIds = allTickedDrips
        .filter(
          (drip: any) => drip.id !== 0 && !isDripWithActiveDripsRunning(drip)
        )
        .map((drip) => drip.id);

      return {
        ...state,
        tickedDripsIds: realTickableDripIds,
      };

    case TICK_SINGLE_DRIP:
      const { dripId } = payload;
      const { tickedDripsIds } = state;
      const indexOfDrip = tickedDripsIds.indexOf(dripId);

      let newTickedDripsIds;
      if (indexOfDrip > -1) {
        newTickedDripsIds = tickedDripsIds.slice(0); //makes a deep copy of array
        newTickedDripsIds.splice(indexOfDrip, 1);
      } else {
        newTickedDripsIds = tickedDripsIds.concat(dripId);
      }
      return {
        ...state,
        tickedDripsIds: newTickedDripsIds,
      };

    case DRIPS_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedDrips: true,
        drips: [voidDrip].concat(
          payload.drips.sort((a: any, b: any) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        ),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
