import { ReduxAction } from "../../common-interfaces/interfaces";
import { isDoNotCallPhone } from "../../common-utils/utils-import-export";
import { isSetsEqual } from "../../common-utils/utils-sets";

import {
  CALLLOGS_FETCH_SUCCESS,
  DIALER_SET_LIST,
  DIALER_SET_PLAYING,
  DIALER_SET_PAUSED,
  DIALER_SET_STOPPED,
  DIALER_SET_SELECTED_DIALLEAD_FROM_LIST,
  DIALER_PREPARE_NEXT_CALL,
  SET_INBOUNDCALLSID_FOR_OUTGOING_CALL,
  SET_LEAD_IN_OUTGOING_CALL,
  SET_RECORDED_MESSAGE_ENABLED,
  SET_FORWARD_CALL_TO_USERNUMBER_ENABLED,
  INCOMING_CALL_SET_STATUS,
} from "./dialer-actions";
import { LOGOUT } from "../../user/redux/user-actions";
import {
  LISTS_DELETE_SUCCESS,
  LISTS_FETCH_SUCCESS,
} from "../../lists/redux/lists-actions";
import { LEAD_UPDATE_DNC_SUCCESS } from "../../leads/redux/leads-actions";

import { differenceInMilliseconds } from "date-fns";

const sortDialingLeads = (dialLeads: any[]) => {
  return dialLeads.sort((a: any, b: any) => {
    //leads without phone numbers go to the bottom
    if (!a.phoneNumber && b.phoneNumber) return 1;
    if (a.phoneNumber && !b.phoneNumber) return -1;
    //leads without consent on phone number go to the bottom just above the previous
    if (isDoNotCallPhone(a) && !isDoNotCallPhone(b)) return 1;
    if (!isDoNotCallPhone(a) && isDoNotCallPhone(b)) return -1;
    //the not yet dialed go before old dialed
    if (a.lastDialedAt === null && b.lastDialedAt !== null) return -1;
    if (a.lastDialedAt !== null && b.lastDialedAt === null) return 1;
    //oldest dialed go first
    if (a.lastDialedAt !== b.lastDialedAt)
      return a.lastDialedAt > b.lastDialedAt ? 1 : -1;
    //alphabetical sort is applied  if they are both without phoneNumber or both undialed or both have same dial timestamp
    return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());
  });
};

interface DialerState {
  // incoming calls
  phoneNumberOfIncomingPhoneCall: string;
  inboundCallSidForIncomingPhoneCall: string;
  // outgoing calls
  leadInOutgoingCall: any;
  inboundCallSidForOutgoingPhoneCall: string;
  isRecordedMessageEnabled: boolean;
  // common & autodialer
  isForwardCallEnabled: boolean;
  dialList: any;
  dialLeads: any[];
  autodialerStatus: string;
  hasFinishedDialingList: boolean;
  previousDialLeadCalled: any;
  currentDialLeadSelected: any;
  nextDialLeadToCall: any;
  // callLogs
  latestCallLogsInitializedAt: Date | null;
  latestCallLogsUpdateAt: Date | null;
  callLogs: any[];
}

const initialState: DialerState = {
  // incoming calls
  phoneNumberOfIncomingPhoneCall: "",
  inboundCallSidForIncomingPhoneCall: "",
  // outgoing calls
  leadInOutgoingCall: null,
  inboundCallSidForOutgoingPhoneCall: "",
  isRecordedMessageEnabled: false,
  // common & autodialer
  isForwardCallEnabled: false,
  dialList: {},
  dialLeads: [],
  autodialerStatus: "stopped",
  hasFinishedDialingList: false,
  previousDialLeadCalled: null,
  currentDialLeadSelected: null,
  nextDialLeadToCall: null,
  // callLogs
  latestCallLogsInitializedAt: null,
  latestCallLogsUpdateAt: null,
  callLogs: [],
};

export const dialerReducer = (
  state: DialerState = initialState,
  action: ReduxAction
): DialerState => {
  const { payload, type } = action;
  switch (type) {
    case CALLLOGS_FETCH_SUCCESS:
      const newCallLogs = payload.callLogs;

      let _callLogs = [] as any[];

      if (state.callLogs.length === 0) {
        _callLogs = newCallLogs;
      } else {
        // Replacing multiple objects in array
        // https://stackoverflow.com/a/37585362
        _callLogs = state.callLogs
          .filter(
            (callLog: any) =>
              newCallLogs.findIndex((cl: any) => cl.id === callLog.id) === -1
          )
          .concat(newCallLogs);
      }

      return {
        ...state,
        callLogs: _callLogs.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(b.createdAt), new Date(a.createdAt))
        ),
        latestCallLogsInitializedAt: new Date(),
        latestCallLogsUpdateAt: new Date(),
      };

    case INCOMING_CALL_SET_STATUS:
      const __isForwardCallEnabled = payload.inboundCallSidForIncomingPhoneCall
        ? true
        : false;

      return {
        ...state,
        phoneNumberOfIncomingPhoneCall: payload.phoneNumberOfIncomingPhoneCall,
        inboundCallSidForIncomingPhoneCall:
          payload.inboundCallSidForIncomingPhoneCall,
        isForwardCallEnabled: __isForwardCallEnabled,
      };

    case DIALER_SET_LIST:
      const { list, leads } = payload;
      let _dialLeads = [] as any;
      let __currentDialLeadSelected;

      if (list.id !== 0 && list.leadIds.length > 0) {
        _dialLeads = leads.filter((lead: any) =>
          list.leadIds.includes(lead.id)
        );

        _dialLeads = sortDialingLeads(_dialLeads);

        __currentDialLeadSelected =
          _dialLeads
            .filter(
              (dialLead: any) =>
                dialLead.phoneNumber && !isDoNotCallPhone(dialLead)
            )
            .find((dialLead: any) => dialLead.lastDialedAt === null) ||
          _dialLeads.filter(
            (dialLead: any) =>
              dialLead.phoneNumber && !isDoNotCallPhone(dialLead)
          )[0] ||
          null;
      } else {
        __currentDialLeadSelected = null;
      }

      return {
        ...state,
        dialList: list,
        dialLeads: _dialLeads,
        currentDialLeadSelected: __currentDialLeadSelected,
        nextDialLeadToCall: __currentDialLeadSelected,
        hasFinishedDialingList: false,
        autodialerStatus: "stopped",
      };

    case DIALER_SET_PLAYING:
      return {
        ...state,
        autodialerStatus: "playing",
      };

    case DIALER_SET_PAUSED:
      return {
        ...state,
        autodialerStatus: "paused",
      };

    case DIALER_SET_STOPPED:
      const ___currentDialLeadSelected =
        state.dialLeads
          .filter(
            (dialLead: any) =>
              dialLead.phoneNumber && !isDoNotCallPhone(dialLead)
          )
          .find((dialLead: any) => dialLead.lastDialedAt === null) ||
        state.dialLeads.filter(
          (dialLead: any) => dialLead.phoneNumber && !isDoNotCallPhone(dialLead)
        )[0] ||
        null;

      return {
        ...state,
        currentDialLeadSelected: ___currentDialLeadSelected,
        nextDialLeadToCall: ___currentDialLeadSelected,
        hasFinishedDialingList: false,
        autodialerStatus: "stopped",
      };

    case SET_INBOUNDCALLSID_FOR_OUTGOING_CALL:
      return {
        ...state,
        inboundCallSidForOutgoingPhoneCall: payload.inboundCallSid,
      };

    case SET_LEAD_IN_OUTGOING_CALL:
      let _previousDialLeadCalled = null;
      if (state.leadInOutgoingCall !== null) {
        _previousDialLeadCalled = state.leadInOutgoingCall;
      } else {
        _previousDialLeadCalled = state.previousDialLeadCalled;
      }

      const _inboundCallSidForOutgoingPhoneCall =
        payload.leadInOutgoingCall === null
          ? ""
          : state.inboundCallSidForOutgoingPhoneCall;

      const _isRecordedMessageEnabled =
        payload.leadInOutgoingCall === null
          ? false
          : state.isRecordedMessageEnabled;

      const _isForwardCallEnabled =
        payload.leadInOutgoingCall === null
          ? false
          : state.isRecordedMessageEnabled;

      //updates local copy of 'lastDialedAt'
      let __dialLeads = state.dialLeads;
      if (payload.leadInOutgoingCall !== null) {
        const leadInOutgoingCallIndex = __dialLeads.findIndex(
          (dialLead) => dialLead.id === payload.leadInOutgoingCall.id
        );
        //if it's dialing using a list then the following condition applies.
        //if it's a single call then it's skipped
        if (leadInOutgoingCallIndex >= 0) {
          __dialLeads[leadInOutgoingCallIndex].lastDialedAt = new Date();
        }
      }

      return {
        ...state,
        leadInOutgoingCall: payload.leadInOutgoingCall,
        inboundCallSidForOutgoingPhoneCall: _inboundCallSidForOutgoingPhoneCall,
        dialLeads: __dialLeads,
        previousDialLeadCalled: _previousDialLeadCalled,
        isRecordedMessageEnabled: _isRecordedMessageEnabled,
        isForwardCallEnabled: _isForwardCallEnabled,
      };

    case SET_RECORDED_MESSAGE_ENABLED:
      return {
        ...state,
        isRecordedMessageEnabled: true,
      };

    case SET_FORWARD_CALL_TO_USERNUMBER_ENABLED:
      return {
        ...state,
        isForwardCallEnabled: true,
      };

    case DIALER_SET_SELECTED_DIALLEAD_FROM_LIST:
      //in this case next lead to dial is same as selected lead
      return {
        ...state,
        currentDialLeadSelected: payload.selectedDialLeadFromList,
        nextDialLeadToCall: payload.selectedDialLeadFromList,
      };

    case DIALER_PREPARE_NEXT_CALL:
      let _currentDialLeadSelected = payload.currentDialLeadSelected;

      const _currentIndex = state.dialLeads.findIndex(
        (dialLead: any) => dialLead.id === _currentDialLeadSelected.id
      );

      const _nextIndex =
        _currentIndex >= 0
          ? state.dialLeads.findIndex(
              (dialLead: any, i: number) =>
                dialLead.phoneNumber &&
                !isDoNotCallPhone(dialLead) &&
                i > _currentIndex
            )
          : -1;

      let _nextDialLeadToCall =
        _nextIndex >= 0 ? state.dialLeads[_nextIndex] : null;

      const _hasFinishedDialingList = _nextIndex === -1;

      return {
        ...state,
        currentDialLeadSelected: _currentDialLeadSelected,
        nextDialLeadToCall: _nextDialLeadToCall,
        hasFinishedDialingList: _hasFinishedDialingList,
      };

    case LISTS_FETCH_SUCCESS:
      const { lists } = payload;
      //if a lead has been added or removed from the list the dialer is restarted
      if (state.dialList && state.dialList.id) {
        const listToCompareForChange =
          lists.find((list: any) => list.id === state.dialList.id) || null;
        if (
          !listToCompareForChange ||
          !isSetsEqual(
            new Set<number>(listToCompareForChange.leadIds),
            new Set<number>(state.dialList.leadIds)
          )
        ) {
          // the 'dialList' has been revoked, but there could still be a 'leadInOutgoingCall'
          // so the status of the call must be changed without destroying it
          return {
            ...state,
            dialList: {},
            dialLeads: [],
            autodialerStatus: "stopped",
            currentDialLeadSelected: null,
            nextDialLeadToCall: null,
          };
        }
      }
      return { ...state };

    case LISTS_DELETE_SUCCESS:
      const { listIds } = payload;
      // if the dialList has been deleted the dialer is restarted
      if (
        state.dialList &&
        state.dialList.id &&
        listIds.includes(state.dialList.id)
      ) {
        // the 'dialList' has been revoked, but there could still be a 'leadInOutgoingCall'
        // so the status of the call must be changed without destroying it
        return {
          ...state,
          dialList: {},
          dialLeads: [],
          autodialerStatus: "stopped",
          currentDialLeadSelected: null,
          nextDialLeadToCall: null,
        };
      }
      return { ...state };

    case LEAD_UPDATE_DNC_SUCCESS:
      //TO DO: implement  what happens if DNC is changed during a call
      return { ...state };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
