import { routePaths, setPathId } from "../../routing/utils-routing-paths";
import { push } from "connected-react-router";
import { SortDirectionType } from "react-virtualized";
import api from "../../api/api";
import httpService from "../../api/http-service";
import { chunkArray } from "../../common-utils/utils";

import config from "../../config";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

export const FETCH_APP_STATES_SUCCESS = "FETCH_APP_STATES_SUCCESS";
export const USER_IMPERSONATE = "USER_IMPERSONATE";
export const USER_STOP_IMPERSONATING = "USER_STOP_IMPERSONATING";
//
export const SET_USERS_SORTING = "SET_USERS_SORTING";
export const SET_USERS_FILTERS = "SET_USERS_FILTERS";
export const RESET_USERS_FILTERS = "RESET_USERS_FILTERS";
export const SET_ZIPCODES_FILTERS = "SET_ZIPCODES_FILTERS";
export const RESET_ZIPCODES_FILTERS = "RESET_ZIPCODES_FILTERS";
export const ADMIN_FETCH_USERS_START = "ADMIN_FETCH_USERS_START";
export const ADMIN_FETCH_USERS_SUCCESS = "ADMIN_FETCH_USERS_SUCCESS";
export const ADMIN_FETCH_USERS_FAIL = "ADMIN_FETCH_USERS_FAIL";
export const ADMIN_REGISTER_USER_START = "ADMIN_REGISTER_USER_START";
export const ADMIN_REGISTER_USER_SUCCESS = "ADMIN_REGISTER_USER_SUCCESS";
export const ADMIN_REGISTER_USER_FAIL = "ADMIN_REGISTER_USER_FAIL";
export const ADMIN_COLELEADS_IMPORT_START = "ADMIN_COLELEADS_IMPORT_START";
export const ADMIN_COLELEADS_IMPORT_SUCCESS = "ADMIN_COLELEADS_IMPORT_SUCCESS";
export const ADMIN_COLELEADS_IMPORT_FAIL = "ADMIN_COLELEADS_IMPORT_FAIL";
export const ADMIN_EDIT_USER_START = "ADMIN_EDIT_USER_START";
export const ADMIN_EDIT_USER_SUCCESS = "ADMIN_EDIT_USER_SUCCESS";
export const ADMIN_EDIT_USER_FAIL = "ADMIN_EDIT_USER_FAIL";
export const ADMIN_ZIPCODES_FETCH_START = "ADMIN_ZIPCODES_FETCH_START";
export const ADMIN_ZIPCODES_FETCH_SUCCESS = "ADMIN_ZIPCODES_FETCH_SUCCESS";
export const ADMIN_ZIPCODES_FETCH_FAIL = "ADMIN_ZIPCODES_FETCH_FAIL";
export const ADMIN_UPDATE_ZIPCODE_START = "ADMIN_UPDATE_ZIPCODE_START";
export const ADMIN_UPDATE_ZIPCODE_SUCCESS = "ADMIN_UPDATE_ZIPCODE_SUCCESS";
export const ADMIN_UPDATE_ZIPCODE_FAIL = "ADMIN_UPDATE_ZIPCODE_FAIL";
export const ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START =
  "ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START";
export const ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS =
  "ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS";
export const ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL =
  "ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL";
export const ADMIN_CLONE_DRIPS_START = "ADMIN_CLONE_DRIPS_START";
export const ADMIN_CLONE_DRIPS_SUCCESS = "ADMIN_CLONE_DRIPS_SUCCESS";
export const ADMIN_CLONE_DRIPS_FAIL = "ADMIN_CLONE_DRIPS_FAIL";
export const ADMIN_CLONE_TEMPLATES_START = "ADMIN_CLONE_DRIPS_START";
export const ADMIN_CLONE_TEMPLATES_SUCCESS = "ADMIN_CLONE_DRIPS_SUCCESS";
export const ADMIN_CLONE_TEMPLATES_FAIL = "ADMIN_CLONE_DRIPS_FAIL";
export const ADMIN_RELOCATIONS_FETCH_START = "ADMIN_RELOCATIONS_FETCH_START";
export const ADMIN_RELOCATIONS_FETCH_SUCCESS =
  "ADMIN_RELOCATIONS_FETCH_SUCCESS";
export const ADMIN_RELOCATIONS_FETCH_FAIL = "ADMIN_RELOCATIONS_FETCH_FAIL";
export const ADMIN_TRANSACTIONS_FETCH_START = "ADMIN_TRANSACTIONS_FETCH_START";
export const ADMIN_TRANSACTIONS_FETCH_SUCCESS =
  "ADMIN_TRANSACTIONS_FETCH_SUCCESS";
export const ADMIN_TRANSACTIONS_FETCH_FAIL = "ADMIN_TRANSACTIONS_FETCH_FAIL";
export const ADMIN_TRANSACTION_UPDATE_START = "ADMIN_TRANSACTION_UPDATE_START";
export const ADMIN_TRANSACTION_UPDATE_SUCCESS =
  "ADMIN_TRANSACTION_UPDATE_SUCCESS";
export const ADMIN_TRANSACTION_UPDATE_FAIL = "ADMIN_TRANSACTION_UPDATE_FAIL";

let adminAxiosInterceptor: any;

const ejectAdminAxiosInterceptor = () => {
  httpService.axios.interceptors.request.eject(adminAxiosInterceptor);
  adminAxiosInterceptor = null;
};

export const updateTransactionForAdmin = (transactionForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_TRANSACTION_UPDATE_START,
      });
      const { updatedTransaction } = await api.admin.updateTransactionForAdmin(
        transactionForm
      );

      dispatch({
        type: ADMIN_TRANSACTION_UPDATE_SUCCESS,
        payload: { updatedTransaction },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_TRANSACTION_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const fetchTransactionsForAdmin = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_TRANSACTIONS_FETCH_START,
      });

      const { transactions } = await api.admin.fetchTransactionsForAdmin();

      dispatch({
        type: ADMIN_TRANSACTIONS_FETCH_SUCCESS,
        payload: { transactions },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_TRANSACTIONS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const cloneDrips = (operation: string, dripIds: number[]) => {
  if (!dripIds || !dripIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_CLONE_DRIPS_START,
      });

      await api.admin.cloneDrips(operation, dripIds);

      dispatch({
        type: ADMIN_CLONE_DRIPS_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_CLONE_DRIPS_FAIL,
      });
      return false;
    }
  };
};

export const cloneTemplates = (operation: string, templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_CLONE_TEMPLATES_START,
      });

      await api.admin.cloneTemplates(operation, templateIds);

      dispatch({
        type: ADMIN_CLONE_TEMPLATES_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_CLONE_TEMPLATES_FAIL,
      });
      return false;
    }
  };
};

export const setZipCodesFilters = (filterDataKey: string, filterValue: any) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_ZIPCODES_FILTERS,
      payload: { filterDataKey, filterValue },
    });
  };
};

export const resetZipCodesFilters = () => {
  return (dispatch: Function) => {
    dispatch({
      type: RESET_ZIPCODES_FILTERS,
    });
  };
};

export const setUsersSorting = (
  sortBy: string,
  sortDirection: SortDirectionType
) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_USERS_SORTING,
      payload: { sortBy, sortDirection },
    });
  };
};

export const setUsersFilters = (filterDataKey: string, filterValue: any) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: { filterDataKey, filterValue },
    });
  };
};

export const resetUsersFilters = () => {
  return (dispatch: Function) => {
    dispatch({
      type: RESET_USERS_FILTERS,
    });
  };
};

export const updateZipCodeForAdmin = (
  zip: number,
  maxAvailableLicenses: number,
  licenseeId: number,
  freeLicenseesToRemove: number[]
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_ZIPCODE_START,
      });
      const res = await api.admin.updateZipCodeForAdmin(
        zip,
        maxAvailableLicenses,
        licenseeId,
        freeLicenseesToRemove
      );

      const updatedZipCode = res.updatedZipCode;

      dispatch({
        type: ADMIN_UPDATE_ZIPCODE_SUCCESS,
        payload: { updatedZipCode },
      });
    } catch (error: any) {
      dispatch({
        type: ADMIN_UPDATE_ZIPCODE_FAIL,
      });
    }
  };
};

export const fetchZipCodesForAdmin = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_ZIPCODES_FETCH_START,
      });
      const res = await api.admin.fetchZipCodesForAdmin();
      const zipCodes = res.zipCodes;

      dispatch({
        type: ADMIN_ZIPCODES_FETCH_SUCCESS,
        payload: { zipCodes },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_ZIPCODES_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const fetchDirectMailCampaignsForAdmin = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START,
      });
      const directMailCampaigns = await api.admin.fetchDirectMailCampaigns();
      dispatch({
        type: ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS,
        payload: { directMailCampaigns },
      });
    } catch (error: any) {
      dispatch({
        type: ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL,
      });
    }
  };
};

export const updateUserForAdmin = (updatedUserForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: ADMIN_EDIT_USER_START, payload: {} });
      await api.admin.updateUserForAdmin(updatedUserForm);
      await fetchUsersForAdmin()(dispatch);
      dispatch({ type: ADMIN_EDIT_USER_SUCCESS, payload: {} });
      return true;
    } catch (error: any) {
      dispatch({ type: ADMIN_EDIT_USER_FAIL, payload: {} });
      return false;
    }
  };
};

export const impersonateUser = (user: any) => {
  return async (dispatch: Function) => {
    if (adminAxiosInterceptor) {
      ejectAdminAxiosInterceptor();
    }
    adminAxiosInterceptor = httpService.axios.interceptors.request.use(
      (config: any) => {
        config.headers = {
          ...config.headers,
          "x-admin-impersonate-user-id": user.id,
        };
        return config;
      }
    );
    dispatch({
      type: USER_IMPERSONATE,
      payload: {
        user,
      },
    });
  };
};

export const stopImpersonatingUser = () => {
  return async (dispatch: Function) => {
    ejectAdminAxiosInterceptor();
    dispatch({
      type: USER_STOP_IMPERSONATING,
      payload: {},
    });
  };
};

export const setAppStateWorkerProcessLock = (isLockWorkerProcess: boolean) => {
  return async (dispatch: Function) => {
    await api.admin.setAppStateWorkerProcessLock(isLockWorkerProcess);
    await fetchAppStatesForAdmin()(dispatch);
  };
};

export const fetchAppStatesForAdmin = () => {
  return async (dispatch: Function) => {
    const { appStates, numberOfLockedDrips } = await api.admin.fetchAppStates();

    dispatch({
      type: FETCH_APP_STATES_SUCCESS,
      payload: { appStates, numberOfLockedDrips },
    });
  };
};

export const fetchUsersForAdmin = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_FETCH_USERS_START,
      });
      const users = await api.admin.fetchUsersForAdmin();
      dispatch({
        type: ADMIN_FETCH_USERS_SUCCESS,
        payload: { users },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_FETCH_USERS_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const importColeLeads = (
  coleLeads: any[],
  releasedAt: Date,
  listName: string,
  recipientUserIds: number[]
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_COLELEADS_IMPORT_START,
      });

      const coleLeadsChunks = chunkArray(
        coleLeads,
        config.globalVariables.importLeadsChunkSize
      );
      for (const coleLeadsChunk of coleLeadsChunks) {
        const res = await api.admin.importColeLeads(
          coleLeadsChunk,
          releasedAt,
          listName,
          recipientUserIds
        );
        console.log(res);
      }

      dispatch({
        type: ADMIN_COLELEADS_IMPORT_SUCCESS,
      });
      dispatch(push(routePaths.admin.root));
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_COLELEADS_IMPORT_FAIL,
      });
      return false;
    }
  };
};

export const submitRegisterFormForAdmin = (
  emailAddress: string,
  password: string,
  firstName: string,
  lastName: string,
  appPhoneNumber: string,
  appEmailUsername: string,
  cloudCmaApiKey: string,
  phoneNumber: string,
  companyName: string,
  streetAddress: string,
  city: string,
  state: string,
  zipCode: string,
  license: string,
  hasPaidAccountSetup: boolean,
  hasPaidTrackingWebsiteSetup: boolean
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ADMIN_REGISTER_USER_START,
      });

      const user = await api.admin.registerUser(
        emailAddress,
        password,
        firstName,
        lastName,
        appPhoneNumber,
        appEmailUsername,
        cloudCmaApiKey,
        phoneNumber,
        companyName,
        streetAddress,
        city,
        state,
        zipCode,
        license,
        hasPaidAccountSetup,
        hasPaidTrackingWebsiteSetup
      );

      dispatch({
        type: ADMIN_REGISTER_USER_SUCCESS,
      });

      navigateToAdminUsersManagerPage()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: ADMIN_REGISTER_USER_FAIL,
      });

      return false;
    }
  };
};

export const runDangerousScripts = () => {
  return async (dispatch: Function) => {
    try {
      const res = await api.admin.runDangerousScripts();
      // console.log(res);
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const navigateToAdminUsersManagerPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.users));

export const navigateToAdminRegisterUserPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.register));

export const navigateToAdminViewUserPage =
  (userId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.usersView, userId)));
