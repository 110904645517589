import { connectRouter } from "connected-react-router";

import { History } from "history";
import { combineReducers } from "redux";

import { adminReducer } from "../admin/redux/admin-reducer";
import { apiStatusReducer } from "../api/api-status-reducer";
import { campaignsReducer } from "../campaigns/redux/campaigns-reducer";
import { dialerReducer } from "../dialer/redux/dialer-reducer";
import { dripsReducer } from "../drips/redux/drips-reducer";
import { inboxEmailsReducer } from "../inbox-email/redux/inbox-reducer";
import { inboxSmsReducer } from "../inbox-sms/redux/sms-inbox-reducer";
import { landingsReducer } from "../landings/redux/landings-reducer";
import { leadsReducer } from "../leads/redux/leads-reducer";
import { listsReducer } from "../lists/redux/lists-reducer";
import { paymentsReducer } from "../payments/redux/payments-reducer";
import { superUserReducer } from "../superuser/redux/superuser-reducer";
import { tagsReducer } from "../tags/redux/tags-reducer";
import { templatesReducer } from "../templates/redux/templates-reducer";
import { userReducer } from "../user/redux/user-reducer";
import { websitesReducer } from "../websites/redux/websites-reducer";
import { zipCodesReducer } from "../zipcodes/redux/zipcodes-reducer";

export const createRootReducer = (history: History) =>
  combineReducers({
    admin: adminReducer,
    apiStatus: apiStatusReducer,
    campaigns: campaignsReducer,
    dialer: dialerReducer,
    drips: dripsReducer,
    inboxEmails: inboxEmailsReducer,
    inboxSms: inboxSmsReducer,
    landings: landingsReducer,
    leads: leadsReducer,
    lists: listsReducer,
    payments: paymentsReducer,
    router: connectRouter(history),
    superuser: superUserReducer,
    tags: tagsReducer,
    templates: templatesReducer,
    user: userReducer,
    websites: websitesReducer,
    zipcodes: zipCodesReducer,
  });
