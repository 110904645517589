import { push } from "connected-react-router";
import { SortDirectionType } from "react-virtualized";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

import config from "../../config";

import api from "../../api/api";
import { PollingIntervalTypes } from "../../common-interfaces/interfaces";
import { chunkArray } from "../../common-utils/utils";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";
import { fetchLists } from "../../lists/redux/lists-actions";
import { fetchDrips } from "../../drips/redux/drips-actions";

import { TAGS_FOR_USER_CREATE_SUCCESS } from "../../tags/redux/tags-actions";

// these are the actions to be refactored
export const CREATE_BULK_LEADS_FINISHED = "CREATE_BULK_LEADS_FINISHED";
export const RESET_LEADS_FILTERS = "RESET_LEADS_FILTERS";
export const LEAD_VIEW_TAB_CHANGED = "LEAD_VIEW_TAB_CHANGED";

// these are the actions kept tidy
export const TICK_SINGLE_LEAD = "TICK_SINGLE_LEAD";
export const TICK_MULTIPLE_LEADS = "TICK_MULTIPLE_LEADS";
export const SET_LEADS_SORTING = "SET_LEADS_SORTING";
export const SET_LEADS_FILTERS = "SET_LEADS_FILTERS";
export const NOTE_CREATE_START = "NOTE_CREATE_START";
export const NOTE_CREATE_SUCCESS = "NOTE_CREATE_SUCCESS";
export const NOTE_CREATE_FAIL = "NOTE_CREATE_FAIL";
export const NOTE_UPDATE_START = "NOTE_UPDATE_START";
export const NOTE_UPDATE_SUCCESS = "NOTE_UPDATE_SUCCESS";
export const NOTE_UPDATE_FAILED = "NOTE_UPDATE_FAILED";
export const LEAD_ACTIVITYFEED_FETCH_START = "LEAD_ACTIVITYFEED_FETCH_START";
export const LEAD_ACTIVITYFEED_FETCH_SUCCESS =
  "LEAD_ACTIVITYFEED_FETCH_SUCCESS";
export const LEAD_ACTIVITYFEED_FETCH_FAIL = "LEAD_ACTIVITYFEED_FETCH_FAIL";
export const LEADS_ALL_FETCH_START = "LEADS_ALL_FETCH_START";
export const LEADS_ALL_FETCH_PROGRESS = "LEADS_ALL_FETCH_PROGRESS";
export const LEADS_ALL_FETCH_SUCCESS = "LEADS_ALL_FETCH_SUCCESS";
export const LEADS_ALL_FETCH_FAIL = "LEADS_ALL_FETCH_FAIL";
export const LEADS_RECENT_FETCH_START = "LEADS_RECENT_FETCH_START";
export const LEADS_RECENT_FETCH_SUCCESS = "LEADS_RECENT_FETCH_SUCCESS";
export const LEADS_RECENT_FETCH_FAIL = "LEADS_RECENT_FETCH_FAIL";
export const LEAD_UPDATE_CLOUDCMAREPORT_START =
  "LEAD_UPDATE_CLOUDCMAREPORT_START";
export const LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS =
  "LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS";
export const LEAD_UPDATE_CLOUDCMAREPORT_FAIL =
  "LEAD_UPDATE_CLOUDCMAREPORT_FAIL";
export const LEAD_UPDATE_ENHANCEDINFO_START = "LEAD_UPDATE_ENHANCEDINFO_START";
export const LEAD_UPDATE_ENHANCEDINFO_SUCCESS =
  "LEAD_UPDATE_ENHANCEDINFO_SUCCESS";
export const LEAD_UPDATE_ENHANCEDINFO_FAIL = "LEAD_UPDATE_ENHANCEDINFO_FAIL";
export const LEAD_CREATE_START = "LEAD_CREATE_START";
export const LEAD_CREATE_SUCCESS = "LEAD_CREATE_SUCCESS";
export const LEAD_CREATE_FAIL = "LEAD_CREATE_FAIL";
export const LEADS_DELETE_START = "LEADS_DELETE_START";
export const LEADS_DELETE_SUCCESS = "LEADS_DELETE_SUCCESS";
export const LEADS_DELETE_FAIL = "LEADS_DELETE_FAIL";
export const LEAD_UPDATE_START = "LEAD_UPDATE_START";
export const LEAD_UPDATE_SUCCESS = "LEAD_UPDATE_SUCCESS";
export const LEAD_UPDATE_FAIL = "LEAD_UPDATE_FAIL";
export const LEAD_UPDATE_DNC_START = "LEAD_UPDATE_DNC_START";
export const LEAD_UPDATE_DNC_SUCCESS = "LEAD_UPDATE_DNC_SUCCESS";
export const LEAD_UPDATE_DNC_FAIL = "LEAD_UPDATE_DNC_FAIL";
export const LEAD_UPDATE_EMAILS_START = "LEAD_UPDATE_EMAILS_START";
export const LEAD_UPDATE_EMAILS_SUCCESS = "LEAD_UPDATE_EMAILS_SUCCESS";
export const LEAD_UPDATE_EMAILS_FAIL = "LEAD_UPDATE_EMAILS_FAIL";
export const LEAD_UPDATE_PHONE_NUMBERS_START =
  "LEAD_UPDATE_PHONE_NUMBERS_START";
export const LEAD_UPDATE_PHONE_NUMBERS_SUCCESS =
  "LEAD_UPDATE_PHONE_NUMBERS_SUCCESS";
export const LEAD_UPDATE_PHONE_NUMBERS_FAIL = "LEAD_UPDATE_PHONE_NUMBERS_FAIL";
export const ACTIVEDRIPS_CREATE_START = "ACTIVEDRIPS_CREATE_START";
export const ACTIVEDRIPS_CREATE_SUCCESS = "ACTIVEDRIPS_CREATE_SUCCESS";
export const ACTIVEDRIPS_CREATE_FAIL = "ACTIVEDRIPS_CREATE_FAIL";
export const ACTIVEDRIPS_DELETE_START = "ACTIVEDRIPS_DELETE_START";
export const ACTIVEDRIPS_DELETE_SUCCESS = "ACTIVEDRIPS_DELETE_SUCCESS";
export const ACTIVEDRIPS_DELETE_FAIL = "ACTIVEDRIPS_DELETE_FAIL";
export const ACTIVEDRIP_UPDATE_START = "ACTIVEDRIPS_CREATE_START";
export const ACTIVEDRIP_UPDATE_SUCCESS = "ACTIVEDRIPS_CREATE_SUCCESS";
export const ACTIVEDRIP_UPDATE_FAIL = "ACTIVEDRIPS_CREATE_FAIL";
export const LIKELYLEADS_IMPORT_START = "LIKELYLEADS_IMPORT_START";
export const LIKELYLEADS_IMPORT_PROGRESS = "LIKELYLEADS_IMPORT_PROGRESS";
export const LIKELYLEADS_IMPORT_SUCCESS = "LIKELYLEADS_IMPORT_SUCCESS";
export const LIKELYLEADS_IMPORT_FAIL = "LIKELYLEADS_IMPORT_FAIL";
export const BULKLEADS_IMPORT_START = "BULKLEADS_IMPORT_START";
export const BULKLEADS_IMPORT_PROGRESS = "BULKLEADS_IMPORT_PROGRESS";
export const BULKLEADS_IMPORT_SUCCESS = "BULKLEADS_IMPORT_SUCCESS";
export const BULKLEADS_IMPORT_FAIL = "BULKLEADS_IMPORT_FAIL";

// CRUD on leads
export const fetchAllLeadsInChunks = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEADS_ALL_FETCH_START,
      });

      let leads = [] as any;

      const leadsCount = await api.leads.countLeads();

      if (leadsCount > 0) {
        const numPages = Math.ceil(
          leadsCount / config.globalVariables.fetchLeadsChunkSize
        );

        if (numPages === 1) {
          let isFetchingLeadsProgressValue = 99;
          dispatch({
            type: LEADS_ALL_FETCH_PROGRESS,
            payload: {
              isFetchingLeadsProgressValue,
            },
          });
        }

        // https://stackoverflow.com/questions/3746725/how-to-create-an-array-containing-1-n
        const pagesAsIterable = [...Array(numPages).keys()];
        for await (const page of pagesAsIterable) {
          // for (let page = 0; page < numPages; page++) {
          let offset = page * config.globalVariables.fetchLeadsChunkSize;
          const leadsChunk = await api.leads.fetchLeadsChunk(
            config.globalVariables.fetchLeadsChunkSize,
            offset
          );
          leads = leads.concat(leadsChunk);

          if (numPages !== 1) {
            let isFetchingLeadsProgressValue = Math.round(
              (100 * leads.length) / leadsCount
            );
            dispatch({
              type: LEADS_ALL_FETCH_PROGRESS,
              payload: {
                isFetchingLeadsProgressValue,
              },
            });
          }
        }
      }

      // The following function needs cache activated in order to work fine.
      // leads = await api.leads.fetchLeadsAll();

      dispatch({
        type: LEADS_ALL_FETCH_SUCCESS,
        payload: {
          leads,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LEADS_ALL_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const fetchRecentLeads = () => {
  return async (dispatch: Function) => {
    //WARNING: here we have a direct access to store
    const state = store.getState() as any;

    const latestLeadsFetchAt = state.leads.latestLeadsFetchAt;
    const isFetchingLeads = state.apiStatus.leads.isFetchingLeads;
    const isChangingLeads = state.apiStatus.leads.isChangingLeads;
    const isUpdatingCloudCMAReport =
      state.apiStatus.leads.isUpdatingCloudCMAReport;
    const isImportingLeads = state.apiStatus.leads.isImportingLeads;
    const isChangingTags = state.apiStatus.tags.isChangingTags;

    if (
      isFetchingLeads ||
      isChangingLeads ||
      isImportingLeads ||
      isUpdatingCloudCMAReport ||
      isChangingTags
    ) {
      return;
    }

    try {
      dispatch({
        type: LEADS_RECENT_FETCH_START,
      });

      let newLeads = [] as any;

      if (latestLeadsFetchAt) {
        // startingFrom = last update minus PollingInterval * 10
        let startingFrom = new Date(
          new Date(latestLeadsFetchAt).getTime() -
            PollingIntervalTypes.LEADS_FETCH * 10
        );

        const res = await api.leads.fetchRecentLeads(startingFrom);
        newLeads = res.newLeads;
      }

      dispatch({
        type: LEADS_RECENT_FETCH_SUCCESS,
        payload: {
          newLeads,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LEADS_RECENT_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const createLead = (leadForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_CREATE_START,
      });
      const createdLead = await api.leads.createLead(leadForm);
      dispatch({
        type: LEAD_CREATE_SUCCESS,
        payload: {
          lead: createdLead,
        },
      });
      // navigateToLeadsManager()(dispatch);
      return createdLead;
    } catch (error: any) {
      dispatch({
        type: LEAD_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteLeads = (leadIds: number[]) => {
  return async (dispatch: Function) => {
    if (!leadIds || !leadIds.length) return;
    try {
      dispatch({
        type: LEADS_DELETE_START,
      });
      await api.leads.deleteLeads(leadIds);
      dispatch({
        type: LEADS_DELETE_SUCCESS,
        payload: {
          leadIds,
        },
      });
      //it's necessary to refetch lists, because the ones
      //in the store may be outdated
      fetchLists()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: LEADS_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const updateLead = (leadForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_UPDATE_START,
      });
      const { updatedLead } = await api.leads.updateLead(leadForm);
      dispatch({
        type: LEAD_UPDATE_SUCCESS,
        payload: { updatedLead },
      });
      //navigateToLeadsManager()(dispatch);
      return updatedLead;
    } catch (error: any) {
      dispatch({
        type: LEAD_UPDATE_FAIL,
      });
      return false;
    }
  };
};

// Additional updates on leads
export const updateLeadPhoneNumbers = (
  leadId: number,
  number: string,
  action:
    | "create"
    | "priorityUp"
    | "priorityDown"
    | "switchDNCStatus"
    | "setPhoneTypeUnknown"
    | "setPhoneTypeCell"
    | "setPhoneTypeLandline"
    | "setPhoneTypeVoIP"
    | "carrierLookup"
    | "disable"
    | "delete"
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_UPDATE_PHONE_NUMBERS_START,
      });

      const { updatedLead } = await api.leads.updateLeadPhoneNumbers(
        leadId,
        number,
        action
      );

      dispatch({
        type: LEAD_UPDATE_PHONE_NUMBERS_SUCCESS,
        payload: { updatedLead },
      });
      return updatedLead;
    } catch (error: any) {
      dispatch({
        type: LEAD_UPDATE_PHONE_NUMBERS_FAIL,
      });
      return false;
    }
  };
};

export const updateLeadEmails = (
  leadId: number,
  address: string,
  action:
    | "create"
    | "priorityUp"
    | "priorityDown"
    | "switchSubscriptionStatus"
    | "disable"
    | "delete"
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_UPDATE_EMAILS_START,
      });

      const { updatedLead } = await api.leads.updateLeadEmails(
        leadId,
        address,
        action
      );

      dispatch({
        type: LEAD_UPDATE_EMAILS_SUCCESS,
        payload: { updatedLead },
      });
      return updatedLead;
    } catch (error: any) {
      dispatch({
        type: LEAD_UPDATE_EMAILS_FAIL,
      });
      return false;
    }
  };
};

export const updateLeadCloudCMAReport = (leadId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_UPDATE_CLOUDCMAREPORT_START,
      });
      const { updatedLead } = await api.leads.updateLeadCloudCMAReport(leadId);
      dispatch({
        type: LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS,
        payload: { updatedLead },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LEAD_UPDATE_CLOUDCMAREPORT_FAIL,
      });
      return false;
    }
  };
};

export const updateLeadEnhancedInfo = (leadId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_UPDATE_ENHANCEDINFO_START,
      });

      if (leadId > 0) {
        const {
          source_info,
          klevrfarm_info,
          likely_basic_info,
          likely_dbrefresh_info,
        } = await api.leads.updateLeadEnhancedInfo(leadId);

        dispatch({
          type: LEAD_UPDATE_ENHANCEDINFO_SUCCESS,
          payload: {
            currentLeadEnhancedInfo: {
              source_info,
              klevrfarm_info,
              likely_basic_info,
              likely_dbrefresh_info,
            },
          },
        });
      } else {
        dispatch({
          type: LEAD_UPDATE_ENHANCEDINFO_SUCCESS,
          payload: {
            currentLeadEnhancedInfo: null,
          },
        });
      }

      return true;
    } catch (error: any) {
      dispatch({
        type: LEAD_UPDATE_ENHANCEDINFO_FAIL,
      });
      return false;
    }
  };
};

export const fetchLeadActivityFeed = (leadId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LEAD_ACTIVITYFEED_FETCH_START,
      });
      const { activityFeed } = await api.leads.fetchLeadActivityFeed(leadId);

      dispatch({
        type: LEAD_ACTIVITYFEED_FETCH_SUCCESS,
      });

      return activityFeed;
    } catch (error: any) {
      dispatch({
        type: LEAD_ACTIVITYFEED_FETCH_FAIL,
      });

      return null;
    }
  };
};

export const createActiveDrips = (leadIds: number[], dripId: number) => {
  return async (dispatch: Function) => {
    try {
      if (leadIds.length && dripId !== 0) {
        dispatch({
          type: ACTIVEDRIPS_CREATE_START,
        });
        const { updatedLeads } = await api.activeDrips.createActiveDrips(
          leadIds,
          dripId
        );
        dispatch({
          type: ACTIVEDRIPS_CREATE_SUCCESS,
          payload: { updatedLeads },
        });

        fetchDrips()(dispatch);
        return true;
      }
    } catch (error: any) {
      dispatch({
        type: ACTIVEDRIPS_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteActiveDrips = (activeDripIds: number[]) => {
  if (!activeDripIds || !activeDripIds.length) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ACTIVEDRIPS_DELETE_START,
      });
      const { updatedLeads } = await api.activeDrips.deleteActiveDrips(
        activeDripIds
      );
      dispatch({
        type: ACTIVEDRIPS_DELETE_SUCCESS,
        payload: { updatedLeads },
      });
      fetchDrips()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: ACTIVEDRIPS_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const updateActiveDripStatus = (
  activeDripId: number,
  action: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ACTIVEDRIP_UPDATE_START,
      });
      const { updatedLeads } = await api.activeDrips.updateActiveDripStatus(
        activeDripId,
        action
      );
      dispatch({
        type: ACTIVEDRIP_UPDATE_SUCCESS,
        payload: { updatedLeads },
      });
      fetchDrips()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: ACTIVEDRIP_UPDATE_FAIL,
      });
    }
  };
};

// Imports for Leads
export const importLikelyLeads = (
  likelyLeads: any[],
  releasedAt: Date,
  county: string,
  tagsToAdd: string[],
  listName: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELYLEADS_IMPORT_START,
      });

      let createdLeads = [];
      let createdTags = [];
      const likelyLeadsChunks = chunkArray(
        likelyLeads,
        config.globalVariables.importLeadsChunkSize
      );
      for (const likelyLeadsChunk of likelyLeadsChunks) {
        const res = await api.leads.importLikelyLeads(
          likelyLeadsChunk,
          releasedAt,
          county,
          tagsToAdd,
          listName
        );

        createdLeads = res.createdLeads;
        createdTags = res.createdTags;

        dispatch({
          type: TAGS_FOR_USER_CREATE_SUCCESS,
          payload: {
            newTags: createdTags,
          },
        });

        dispatch({
          type: LIKELYLEADS_IMPORT_PROGRESS,
          payload: { newLeads: createdLeads },
        });
      }

      dispatch({
        type: LIKELYLEADS_IMPORT_SUCCESS,
      });

      fetchLists()(dispatch);
      navigateToLeadsManager()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELYLEADS_IMPORT_FAIL,
      });
      return false;
    }
  };
};

export const importBulkLeadsAndStartDrips = (
  leadForms: any[],
  dripId: number,
  tagsToAdd: string[]
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: BULKLEADS_IMPORT_START,
      });

      // with chunking
      let createdLeads = [];
      let createdTags = [];
      const bulkLeadsChunks = chunkArray(
        leadForms,
        config.globalVariables.importLeadsChunkSize
      );
      for (const bulkLeadsChunk of bulkLeadsChunks) {
        const res = await api.leads.importBulkLeadsAndStartDrips(
          bulkLeadsChunk,
          dripId,
          tagsToAdd
        );

        createdLeads = res.createdLeads;
        createdTags = res.createdTags;

        dispatch({
          type: TAGS_FOR_USER_CREATE_SUCCESS,
          payload: {
            newTags: createdTags,
          },
        });

        dispatch({
          type: BULKLEADS_IMPORT_PROGRESS,
          payload: { newLeads: createdLeads },
        });
      }

      dispatch({
        type: BULKLEADS_IMPORT_SUCCESS,
      });

      navigateToLeadsManager()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: BULKLEADS_IMPORT_FAIL,
      });
      return false;
    }
  };
};

// NOTES
export const createNote = (leadId: number, noteText: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: NOTE_CREATE_START,
      });
      const createdNote = await api.notes.createNote(leadId, noteText);
      dispatch({
        type: NOTE_CREATE_SUCCESS,
        payload: {
          note: createdNote,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: NOTE_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const updateNote = (noteId: number, noteText: string) => {
  return async (dispatch: Function) => {
    dispatch({
      type: NOTE_UPDATE_START,
      payload: {
        noteId,
      },
    });
    try {
      const updatedNote = await api.notes.updateNote(noteId, noteText);
      dispatch({
        type: NOTE_UPDATE_SUCCESS,
        payload: {
          updatedNote,
        },
      });
    } catch (error: any) {
      dispatch({
        type: NOTE_UPDATE_FAILED,
      });
    }
  };
};

// FILTERS & LAYOUT & additional operations
export const setLeadsSorting = (
  sortBy: string,
  sortDirection: SortDirectionType
) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_LEADS_SORTING,
      payload: { sortBy, sortDirection },
    });
  };
};

export const setLeadsFilters = (filterDataKey: string, filterValue: any) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_LEADS_FILTERS,
      payload: { filterDataKey, filterValue },
    });

    if (filterDataKey === "duplicates") {
      let sortBy = "alerts";
      switch (filterValue.value) {
        case "on Phone":
          sortBy = "phoneNumber";
          break;
        case "on Email":
          sortBy = "emailAddress";
          break;
        case "on Address":
          sortBy = "addressRoute";
          break;
        case "on Name":
          sortBy = "fullName";
          break;
        case "- any -":
          sortBy = "alerts";
          break;
        default:
          sortBy = "alerts";
          break;
      }
      setLeadsSorting(sortBy, "DESC")(dispatch);
    }
  };
};

export const resetLeadsFilters = () => {
  return (dispatch: Function) => {
    dispatch({
      type: RESET_LEADS_FILTERS,
    });
  };
};

export const tickSingleLead = (leadId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_LEAD,
      payload: {
        leadId,
      },
    });
  };
};

export const tickMultipleLeads = (leadIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_LEADS,
      payload: {
        leadIds,
      },
    });
  };
};

export const leadViewTabChanged = () => {
  return async (dispatch: Function) => {
    dispatch({
      type: LEAD_VIEW_TAB_CHANGED,
    });
  };
};

export const navigateToImportLikelyLeadsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.leads.importLikely));

export const navigateToCreateBulkLeadsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.leads.importBulk));

export const navigateToViewLeadPage = (id: number) => (dispatch: Function) =>
  dispatch(push(setPathId(routePaths.leads.view, id)));

export const navigateToCreateLeadPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.leads.create));

export const navigateToLeadsManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.leads.root));
