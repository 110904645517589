import {
  ReduxAction,
  EmailTemplate,
  SmsTemplate,
  voidSmsTemplate,
  voidEmailTemplate,
  voidPostcardTemplate,
  PostcardTemplate,
  PostcardmaniaDesign,
} from "../../common-interfaces/interfaces";

import { LOGOUT } from "../../user/redux/user-actions";
import { DRIPS_SHARE_SUCCESS } from "../../drips/redux/drips-actions";
import {
  POSTCARD_TEMPLATES_FETCH_SUCCESS,
  POSTCARDMANIA_DESIGNS_FETCH_SUCCESS,
  POSTCARD_TEMPLATES_DELETE_SUCCESS,
  TICK_SINGLE_POSTCARD_TEMPLATE,
  TICK_MULTIPLE_POSTCARD_TEMPLATES,
  //
  EMAIL_TEMPLATES_FETCH_SUCCESS,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATES_DUPLICATE_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATES_DELETE_SUCCESS,
  EMAIL_TEMPLATES_SHARE_SUCCESS,
  TICK_MULTIPLE_EMAIL_TEMPLATES,
  TICK_SINGLE_EMAIL_TEMPLATE,
  //
  SMS_TEMPLATES_FETCH_SUCCESS,
  SMS_TEMPLATE_CREATE_SUCCESS,
  SMS_TEMPLATE_UPDATE_SUCCESS,
  SMS_TEMPLATES_DELETE_SUCCESS,
  SMS_TEMPLATES_SHARE_SUCCESS,
  TICK_MULTIPLE_SMS_TEMPLATES,
  TICK_SINGLE_SMS_TEMPLATE,
} from "./templates-actions";

interface TemplatesState {
  smsTemplates: SmsTemplate[];
  tickedSmsTemplatesIds: number[];
  isInitializedSmsTemplates: boolean;
  //
  emailTemplates: EmailTemplate[];
  tickedEmailTemplatesIds: number[];
  isInitializedEmailTemplates: boolean;
  //
  postcardTemplates: PostcardTemplate[];
  postcardmaniaDesigns: PostcardmaniaDesign[];
  tickedDirectMailTemplatesIds: number[];
  isInitializedDirectMailTemplates: boolean;
  isInitializedPostcardmaniaDesigns: boolean;
}

const initialState: TemplatesState = {
  // Do not inject "Void Templates" here or some components would break checks
  // like 'emailTemplates.length === 0' or 'smsTemplates.length === 0' fall apart.
  smsTemplates: [],
  tickedSmsTemplatesIds: [],
  isInitializedSmsTemplates: false,
  //
  emailTemplates: [],
  tickedEmailTemplatesIds: [],
  isInitializedEmailTemplates: false,
  //
  postcardTemplates: [],
  postcardmaniaDesigns: [],
  tickedDirectMailTemplatesIds: [],
  isInitializedDirectMailTemplates: false,
  isInitializedPostcardmaniaDesigns: false,
};

export const templatesReducer = (
  state: TemplatesState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    // POSTCARDS
    case POSTCARDMANIA_DESIGNS_FETCH_SUCCESS:
      const designs = payload.postcardmaniaDesigns;

      // console.log(designs);

      // FOR DEBUGGING FIELDS
      let allFields = [] as string[];
      designs.forEach((design: any) => {
        //console.log(design);
        design.designFields.forEach((designField: any) => {
          //console.log(designField);
        });
        // console.log(design._size);
        // console.log(design._designFieldsNames);
        //allFields = allFields.concat(design._designFieldsNames);
      });
      //allFields = [...new Set(allFields)];

      return {
        ...state,
        isInitializedPostcardmaniaDesigns: true,
        postcardmaniaDesigns: payload.postcardmaniaDesigns,
      };

    case POSTCARD_TEMPLATES_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedDirectMailTemplates: true,
        postcardTemplates: [voidPostcardTemplate].concat(
          payload.postcardTemplates.sort((a: any, b: any) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        ),
      };

    case POSTCARD_TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
      };

    case TICK_SINGLE_POSTCARD_TEMPLATE:
      const { tickedDirectMailTemplatesIds } = state;
      const indexOfDirectMailTemplate = tickedDirectMailTemplatesIds.indexOf(
        payload.templateId
      );

      let newTickedDirectMailTemplatesIds;
      if (indexOfDirectMailTemplate > -1) {
        newTickedDirectMailTemplatesIds = tickedDirectMailTemplatesIds.slice(0);
        newTickedDirectMailTemplatesIds.splice(indexOfDirectMailTemplate, 1);
      } else {
        newTickedDirectMailTemplatesIds = tickedDirectMailTemplatesIds.concat(
          payload.templateId
        );
      }

      return {
        ...state,
        tickedDirectMailTemplatesIds: newTickedDirectMailTemplatesIds,
      };

    case TICK_MULTIPLE_POSTCARD_TEMPLATES:
      return {
        ...state,
        tickedDirectMailTemplatesIds: payload.templateIds,
      };

    // SMS
    case SMS_TEMPLATES_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedSmsTemplates: true,
        smsTemplates: [voidSmsTemplate].concat(
          payload.smsTemplates.sort((a: any, b: any) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        ),
      };

    case SMS_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        smsTemplates: state.smsTemplates.concat(payload.smsTemplate),
      };

    case SMS_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
      };

    case SMS_TEMPLATES_SHARE_SUCCESS:
    case DRIPS_SHARE_SUCCESS: // when Drips are made public also some templates are made public
      const newSmsTemplates = payload.newSmsTemplates;
      // Replacing multiple objects in array
      // https://stackoverflow.com/a/37585362
      let __smsTemplates = state.smsTemplates
        .filter(
          (smsTemplate: any) =>
            newSmsTemplates.findIndex((st: any) => st.id === smsTemplate.id) ===
            -1
        )
        .concat(newSmsTemplates);

      return {
        ...state,
        smsTemplates: __smsTemplates.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
        tickedSmsTemplatesIds: [],
      };

    case SMS_TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
        smsTemplates: state.smsTemplates.filter(
          (template) => !payload.templateIds.includes(template.id)
        ),
        tickedSmsTemplatesIds: [],
      };

    case TICK_SINGLE_SMS_TEMPLATE:
      const { tickedSmsTemplatesIds } = state;
      const indexOfSmsTemplate = tickedSmsTemplatesIds.indexOf(
        payload.templateId
      );

      let newTickedSmsTemplatesIds;
      if (indexOfSmsTemplate > -1) {
        newTickedSmsTemplatesIds = tickedSmsTemplatesIds.slice(0);
        newTickedSmsTemplatesIds.splice(indexOfSmsTemplate, 1);
      } else {
        newTickedSmsTemplatesIds = tickedSmsTemplatesIds.concat(
          payload.templateId
        );
      }
      return {
        ...state,
        tickedSmsTemplatesIds: newTickedSmsTemplatesIds,
      };

    case TICK_MULTIPLE_SMS_TEMPLATES:
      return {
        ...state,
        tickedSmsTemplatesIds: payload.templateIds,
      };

    // EMAILS
    case EMAIL_TEMPLATES_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedEmailTemplates: true,
        emailTemplates: [voidEmailTemplate].concat(
          payload.emailTemplates.sort((a: any, b: any) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        ),
      };

    case EMAIL_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.concat(payload.emailTemplate),
      };

    case EMAIL_TEMPLATES_DUPLICATE_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.concat(
          payload.newEmailTemplates.sort((a: any, b: any) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        ),
        tickedEmailTemplatesIds: [],
      };

    case EMAIL_TEMPLATES_SHARE_SUCCESS:
    case DRIPS_SHARE_SUCCESS: // when Drips are made public also some templates are made public
      const newEmailTemplates = payload.newEmailTemplates;
      // Replacing multiple objects in array
      // https://stackoverflow.com/a/37585362
      let __emailTemplates = state.emailTemplates
        .filter(
          (emailTemplate: any) =>
            newEmailTemplates.findIndex(
              (et: any) => et.id === emailTemplate.id
            ) === -1
        )
        .concat(newEmailTemplates);

      return {
        ...state,
        emailTemplates: __emailTemplates.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
        tickedEmailTemplatesIds: [],
      };

    case EMAIL_TEMPLATE_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _emailTemplates = state.emailTemplates.map((emailTemplate: any) =>
        emailTemplate.id === payload.updatedEmailTemplate.id
          ? payload.updatedEmailTemplate
          : emailTemplate
      );
      return {
        ...state,
        emailTemplates: _emailTemplates.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
      };

    case EMAIL_TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.filter(
          (template) => !payload.templateIds.includes(template.id)
        ),
        tickedEmailTemplatesIds: [],
      };

    case TICK_SINGLE_EMAIL_TEMPLATE:
      const { tickedEmailTemplatesIds } = state;
      const indexOfEmailTemplate = tickedEmailTemplatesIds.indexOf(
        payload.templateId
      );

      let newTickedEmailTemplatesIds;
      if (indexOfEmailTemplate > -1) {
        newTickedEmailTemplatesIds = tickedEmailTemplatesIds.slice(0);
        newTickedEmailTemplatesIds.splice(indexOfEmailTemplate, 1);
      } else {
        newTickedEmailTemplatesIds = tickedEmailTemplatesIds.concat(
          payload.templateId
        );
      }
      return {
        ...state,
        tickedEmailTemplatesIds: newTickedEmailTemplatesIds,
      };

    case TICK_MULTIPLE_EMAIL_TEMPLATES:
      return {
        ...state,
        tickedEmailTemplatesIds: payload.templateIds,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
