import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

export const SUPERUSER_RELOCATIONS_FETCH_START =
  "SUPERUSER_RELOCATIONS_FETCH_START";
export const SUPERUSER_RELOCATIONS_FETCH_SUCCESS =
  "SUPERUSER_RELOCATIONS_FETCH_SUCCESS";
export const SUPERUSER_RELOCATIONS_FETCH_FAIL =
  "SUPERUSER_RELOCATIONS_FETCH_FAIL";
export const SUPERUSER_RELOCATION_UPDATE_START =
  "SUPERUSER_RELOCATION_UPDATE_START";
export const SUPERUSER_RELOCATION_UPDATE_SUCCESS =
  "SUPERUSER_RELOCATION_UPDATE_SUCCESS";
export const SUPERUSER_RELOCATION_UPDATE_FAIL =
  "SUPERUSER_RELOCATION_UPDATE_FAIL";

export const updateRelocationForSuperuser = (relocationForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SUPERUSER_RELOCATION_UPDATE_START,
      });
      const { updatedRelocation } =
        await api.superuser.updateRelocationForSuperuser(relocationForm);

      dispatch({
        type: SUPERUSER_RELOCATION_UPDATE_SUCCESS,
        payload: { updatedRelocation },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: SUPERUSER_RELOCATION_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const fetchRelocationsForSuperUser = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SUPERUSER_RELOCATIONS_FETCH_START,
      });
      const { allRelocations } =
        await api.superuser.fetchRelocationsForSuperUser();

      dispatch({
        type: SUPERUSER_RELOCATIONS_FETCH_SUCCESS,
        payload: { allRelocations },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SUPERUSER_RELOCATIONS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const navigateToSuperUserRelocationsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.superuser.relocations));
