import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

export const FETCH_STRIPE_DATA_START = "FETCH_STRIPE_DATA_START";
export const FETCH_STRIPE_DATA_SUCCESS = "FETCH_STRIPE_DATA_SUCCESS";
export const FETCH_STRIPE_DATA_FAIL = "FETCH_STRIPE_DATA_FAIL";
export const CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_START =
  "CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_START";
export const CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_SUCCESS =
  "CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_SUCCESS";
export const CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_FAIL =
  "CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_FAIL";
export const CREATE_STRIPE_CHECKOUT_SESSION_START =
  "CREATE_STRIPE_CHECKOUT_SESSION_START";
export const CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS =
  "CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS";
export const CREATE_STRIPE_CHECKOUT_SESSION_FAIL =
  "CREATE_STRIPE_CHECKOUT_SESSION_FAIL";

export const NOTIFY_TO_ADMIN_START = "NOTIFY_TO_ADMIN_START";
export const NOTIFY_TO_ADMIN_SUCCESS = "NOTIFY_TO_ADMIN_SUCCESS";
export const NOTIFY_TO_ADMIN_FAIL = "NOTIFY_TO_ADMIN_FAIL";
export const BUYING_DIRECTMAIL_CAMPAIGN = "BUYING_DIRECTMAIL_CAMPAIGN";

export const RESET_CART = "RESET_CART";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_PUT_ACCOUNT_FIRST_SETUP = "CART_PUT_ACCOUNT_FIRST_SETUP";
export const CART_PUT_BRAND_CREATION_FIRST_SETUP =
  "CART_PUT_BRAND_CREATION_FIRST_SETUP";
export const CART_PUT_TRACKING_WEBSITE_FIRST_SETUP =
  "CART_PUT_TRACKING_WEBSITE_FIRST_SETUP";
export const CART_PUT_PLAN = "CART_PUT_PLAN";
export const CART_PUT_SUBSCRIPTION_ADDONS = "CART_PUT_SUBSCRIPTION_ADDONS";
export const CART_PUT_ADDONS = "CART_PUT_ADDONS";

export const createStripeCheckoutSession = (
  userId: number,
  cart: any,
  directMailCampaignIdUnderPayment: number,
  promotionCodeFromUser: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CREATE_STRIPE_CHECKOUT_SESSION_START,
      });

      const res = await api.payments.createStripeCheckoutSession(
        userId,
        cart,
        directMailCampaignIdUnderPayment,
        promotionCodeFromUser
      );
      const checkoutSessionId = res.checkoutSessionId || null;

      dispatch({
        type: CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS,
      });
      return checkoutSessionId;
    } catch (error: any) {
      dispatch({
        type: CREATE_STRIPE_CHECKOUT_SESSION_FAIL,
      });
      return null;
    }
  };
};

export const createStripeCustomerPortalSession = (
  stripeCustomerId: string,
  returnUrlType: string,
  stripeSubscriptionIdForPlan?: string,
  updateTowardsTier?: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_START,
      });

      const res = await api.payments.createStripeCustomerPortalSession(
        stripeCustomerId,
        returnUrlType,
        stripeSubscriptionIdForPlan,
        updateTowardsTier
      );
      const customerPortalSessionUrl = res.customerPortalSessionUrl;

      dispatch({
        type: CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_SUCCESS,
      });
      return customerPortalSessionUrl;
    } catch (error: any) {
      dispatch({
        type: CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_FAIL,
      });
      return null;
    }
  };
};

export const retrieveStripeData = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: FETCH_STRIPE_DATA_START,
      });

      const res = await api.payments.retrieveStripeData();
      const stripeSubscriptionForPlan = res.stripeSubscriptionForPlan || null;
      const stripeSubscriptionForSocialMediaManagement =
        res.stripeSubscriptionForSocialMediaManagement || null;
      const stripeSubscriptionForTrackingWebsite =
        res.stripeSubscriptionForTrackingWebsite || null;
      const stripeSubscriptionForVirtualAssistant =
        res.stripeSubscriptionForVirtualAssistant || null;
      const stripePaymentIntentsList = res.stripePaymentIntentsList || [];
      const stripeInvoicesList = res.stripeInvoicesList || [];

      dispatch({
        type: FETCH_STRIPE_DATA_SUCCESS,
        payload: {
          stripeSubscriptionForPlan,
          stripeSubscriptionForSocialMediaManagement,
          stripeSubscriptionForTrackingWebsite,
          stripeSubscriptionForVirtualAssistant,
          stripePaymentIntentsList,
          stripeInvoicesList,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: FETCH_STRIPE_DATA_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

// CART
export const resetCart = () => {
  return (dispatch: Function) => {
    dispatch({
      type: RESET_CART,
    });
  };
};

export const removeItemFromCart = (itemType: string) => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_REMOVE_ITEM,
      payload: { itemType },
    });
  };
};

export const putPlanIntoCart = (tier: string) => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_PUT_PLAN,
      payload: { tier },
    });
  };
};

export const putSubscriptionAddonsIntoCart = (
  subscriptionAddonsValues: any
) => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_PUT_SUBSCRIPTION_ADDONS,
      payload: { subscriptionAddonsValues },
    });
  };
};

export const putAddonsIntoCart = (
  addonsValues: any,
  directMailCampaignIdUnderPayment?: number
) => {
  return (dispatch: Function) => {
    if (typeof directMailCampaignIdUnderPayment !== "undefined") {
      dispatch({
        type: BUYING_DIRECTMAIL_CAMPAIGN,
        payload: { directMailCampaignIdUnderPayment },
      });
    }

    dispatch({
      type: CART_PUT_ADDONS,
      payload: { addonsValues },
    });
  };
};

export const putAccountFirstSetupIntoCart = () => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_PUT_ACCOUNT_FIRST_SETUP,
    });
  };
};

export const putBrandCreationFirstSetupIntoCart = () => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_PUT_BRAND_CREATION_FIRST_SETUP,
    });
  };
};

export const putTrackingWebsiteFirstSetupIntoCart = () => {
  return (dispatch: Function) => {
    dispatch({
      type: CART_PUT_TRACKING_WEBSITE_FIRST_SETUP,
    });
  };
};

// end CART

export const notifyToAdmin = (
  notificationType: string,
  additionalInfo: any
) => {
  return async (dispatch: Function) => {
    dispatch({
      type: NOTIFY_TO_ADMIN_START,
    });
    try {
      await api.emails.notifyToAdmin(notificationType, additionalInfo);
      dispatch({
        type: NOTIFY_TO_ADMIN_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: NOTIFY_TO_ADMIN_FAIL,
      });
      return false;
    }
  };
};

export const navigateToBillingPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.billing));

export const navigateToOrderPlanPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.orderPlan));

export const navigateToOrderExtraPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.orderExtra));

export const navigateToRedirectBeforeCheckoutPage =
  () => (dispatch: Function) =>
    dispatch(push(routePaths.user.checkout));
