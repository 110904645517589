import { ReduxAction } from "../../common-interfaces/interfaces";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../../user/redux/user-actions";
import {
  LEADS_ALL_FETCH_SUCCESS,
  LEADS_RECENT_FETCH_SUCCESS,
} from "../../leads/redux/leads-actions";
import {
  TAGS_FOR_USER_CREATE_SUCCESS,
  TAGS_FOR_USER_UPDATE_SUCCESS,
  TAGS_FOR_USER_DELETE_SUCCESS,
} from "./tags-actions";

import { differenceSet } from "../../common-utils/utils-sets";

interface TagsState {
  tags: any[];
}

const initialState: TagsState = {
  tags: [],
};

export const tagsReducer = (
  state: TagsState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;

  switch (type) {
    case TAGS_FOR_USER_CREATE_SUCCESS:
      //the following way of adding new tags is convenient in
      //the "Add tags to lead" modal.
      const existingTags = state.tags.map((tag: any) => tag.name);
      let newTags = Array.from(
        differenceSet(
          new Set<string>(payload.newTags),
          new Set<string>(existingTags)
        )
      );
      return {
        ...state,
        tags: newTags
          .map((tag: any) => ({
            name: tag,
            assignedTo: 0,
          }))
          .concat(state.tags),
      };

    case TAGS_FOR_USER_UPDATE_SUCCESS:
      const updatedUserTags = state.tags.map((tag: any) =>
        tag.name === payload.oldTag
          ? {
              name: payload.newTag,
              assignedTo: tag.assignedTo,
            }
          : tag
      );
      return {
        ...state,
        tags: updatedUserTags,
      };

    case TAGS_FOR_USER_DELETE_SUCCESS:
      const deletedTags = payload.deletedTags;
      const remainingTags = state.tags.filter(
        (tag) => !deletedTags.includes(tag.name)
      );
      return {
        ...state,
        tags: remainingTags,
      };

    case LEADS_ALL_FETCH_SUCCESS:
      const enrichedTags = state.tags.map((tag: any) => {
        tag.assignedTo = 0;
        payload.leads.forEach((lead: any) => {
          if (lead.tags.includes(tag.name)) tag.assignedTo++;
        });
        return tag;
      });
      return {
        ...state,
        tags: enrichedTags,
      };

    case LOGIN_SUCCESS:
      let tags = payload.user.globalTags.map((tag: any) => ({
        name: tag,
        assignedTo: 0,
      }));

      return {
        ...state,
        tags,
      };

    case LOGIN_FAIL:
      return {
        ...state,
      };
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};
