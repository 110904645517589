import { getCartIcon } from "../common-interfaces/assets";
import config from "../config";

const environment = config.reactAppKlevrEnv;

export const generateUrlForStripeCustomer = (stripeCustomerId: string) => {
  if (!stripeCustomerId) return "";
  const environment = config.reactAppKlevrEnv;
  return environment === "production"
    ? `https://dashboard.stripe.com/customers/${stripeCustomerId}`
    : environment === "staging"
    ? `https://dashboard.stripe.com/customers/${stripeCustomerId}`
    : environment === "development"
    ? `https://dashboard.stripe.com/test/customers/${stripeCustomerId}`
    : environment === "local"
    ? `https://dashboard.stripe.com/test/customers/${stripeCustomerId}`
    : "";
};

export const generateUrlForStripePaymentIntent = (
  stripePaymentIntentId: string | null
) => {
  if (!stripePaymentIntentId) return "";
  const environment = config.reactAppKlevrEnv;
  return environment === "production"
    ? `https://dashboard.stripe.com/payments/${stripePaymentIntentId}`
    : environment === "staging"
    ? `https://dashboard.stripe.com/payments/${stripePaymentIntentId}`
    : environment === "development"
    ? `https://dashboard.stripe.com/test/payments/${stripePaymentIntentId}`
    : environment === "local"
    ? `https://dashboard.stripe.com/test/payments/${stripePaymentIntentId}`
    : "";
};

export const getPlanFromTier = (tier: string) =>
  pricingPlans.find((obj) => obj.tier === tier) || null;

export const getPlanFromUserInfos = (user: any) => {
  if (environment === "staging" || environment === "production") {
    // these are the real-data selling prices on production
    if (user.stripePriceIdForPlan === "price_1K4qy2I36h9FsyfKNxupamc9")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.1") || null;
    if (user.stripePriceIdForPlan === "price_1K4qySI36h9FsyfKTIRKAIaO")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.2") || null;
    if (user.stripePriceIdForPlan === "price_1K4rKWI36h9FsyfKyYnLkF2x")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.3") || null;
    if (user.stripePriceIdForPlan === "price_1H2bxwI36h9FsyfKWYbBxAYY")
      return pricingPlans.find((plan: any) => plan.tier === "tier1") || null;
    // if (user.stripePriceIdForPlan === "price_1H2by5I36h9FsyfKPL8pVpYY")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier2") || null;
    // if (user.stripePriceIdForPlan === "price_1H2byBI36h9FsyfKGzMa6NVK")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier3") || null;
    if (user.stripePriceIdForPlan === "price_1IQHp0I36h9FsyfKyYdedLYH")
      return pricingPlans.find((plan: any) => plan.tier === "tier4") || null;
    // if (user.stripePriceIdForPlan === "price_1JPpIwI36h9FsyfKueVp54Wg")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier5") || null;
    // if (user.stripePriceIdForPlan === "price_1JPpJ3I36h9FsyfKigjc7Qh8")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier6") || null;
    // if (user.stripePriceIdForPlan === "price_1JPpJ8I36h9FsyfKeT8WOXwu")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier7") || null;
    // if (user.stripePriceIdForPlan === "price_1JPpJDI36h9FsyfKlXjPt1g6")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier8") || null;
  } else {
    // these are the test-data
    if (user.stripePriceIdForPlan === "price_1K4qsmI36h9FsyfKWfizs0Ts")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.1") || null;
    if (user.stripePriceIdForPlan === "price_1K4qtlI36h9FsyfKTphUPO5r")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.2") || null;
    if (user.stripePriceIdForPlan === "price_1K4qvFI36h9FsyfKLcInhisX")
      return pricingPlans.find((plan: any) => plan.tier === "tier0.3") || null;
    if (user.stripePriceIdForPlan === "price_1H5YY8I36h9FsyfKglqPet8N")
      return pricingPlans.find((plan: any) => plan.tier === "tier1") || null;
    // if (user.stripePriceIdForPlan === "price_1H5YZII36h9FsyfKrKbv0hEn")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier2") || null;
    // if (user.stripePriceIdForPlan === "price_1H6ziDI36h9FsyfKenLIVTRL")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier3") || null;
    if (user.stripePriceIdForPlan === "price_1IQHxSI36h9FsyfKWnLOsDWs")
      return pricingPlans.find((plan: any) => plan.tier === "tier4") || null;
    // if (user.stripePriceIdForPlan === "price_1JPoopI36h9FsyfKImx5z7Dw")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier5") || null;
    // if (user.stripePriceIdForPlan === "price_1JPos8I36h9FsyfKXKg5HDuQ")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier6") || null;
    // if (user.stripePriceIdForPlan === "price_1JPot0I36h9FsyfKsSOMnMeg")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier7") || null;
    // if (user.stripePriceIdForPlan === "price_1JPou7I36h9FsyfKaRurYm8o")
    //   return pricingPlans.find((plan: any) => plan.tier === "tier8") || null;
  }

  return pricingPlans.find((plan: any) => plan.tier === "tier0") || null;
};

export const pricingPlans = [
  {
    tier: "tier0",
    label: "Trial",
    registrationPath: "/trial",
    price: 0,
    displayPrice: 0,
    phoneMinutesIncluded: 0,
    smsMessagesIncluded: 0,
    emailsIncluded: 500,
    features: ["500 Emails", "Direct Mail", "Email Templates", "Lead Imports"],
  },
  {
    tier: "tier0.1",
    label: "Basic",
    registrationPath: "/basic",
    price: 19.97,
    displayPrice: 19.97,
    phoneMinutesIncluded: 0,
    smsMessagesIncluded: 0,
    emailsIncluded: 0,
    features: [
      // "Pay As You Go!",
      "60 Calling Minutes, 100 SMS Messages & 500 Emails Included FREE!",
      "Credits don't expire",
      "Upgrade at anytime",
    ],
  },
  {
    tier: "tier0.2",
    label: "Startup",
    registrationPath: "/startup",
    price: 69.94,
    displayPrice: 49.97,
    phoneMinutesIncluded: 500,
    smsMessagesIncluded: 1000,
    emailsIncluded: 2500,
    features: [
      "Personalized Number",
      "500 Calling Minutes",
      "1,000 SMS Messages",
      "2,500 Emails",
    ],
  },
  {
    tier: "tier0.3",
    label: "Pro",
    registrationPath: "/pro",
    price: 119.94,
    displayPrice: 99.97,
    phoneMinutesIncluded: 1000,
    smsMessagesIncluded: 2500,
    emailsIncluded: 3500,
    features: [
      "Personalized Number",
      "1,000 Calling Minutes",
      "2,500 SMS Messages",
      "3,500 Emails",
    ],
  },
  {
    tier: "tier1",
    label: "Enterprise",
    registrationPath: "/enterprise",
    price: 249,
    displayPrice: 249,
    phoneMinutesIncluded: 3000,
    smsMessagesIncluded: 5500,
    emailsIncluded: 10000,
    features: [
      "Personalized Number",
      "3,000 Calling Minutes",
      "5,500 SMS Messages",
      "10,500 Emails",
    ],
  },
  {
    tier: "tier2",
    label: "Legacy Pro",
    isLegacy: true,
    registrationPath: "/legacy-pro",
    price: 349,
    displayPrice: 349,
    phoneMinutesIncluded: 1000,
    smsMessagesIncluded: 2500,
    emailsIncluded: 3500,
    features: [
      "Personalized Number",
      "1,000 Calling Minutes",
      "2,500 SMS Messages",
      "3,500 Emails",
    ],
  },
  {
    tier: "tier3",
    label: "Legacy Enterprise",
    isLegacy: true,
    registrationPath: "/legacy-enterprise",
    price: 475,
    displayPrice: 475,
    phoneMinutesIncluded: 3000,
    smsMessagesIncluded: 5500,
    emailsIncluded: 7500,
    features: [
      "Personalized Number",
      "3,000 Calling Minutes",
      "5,500 SMS Messages",
      "7,500 Emails",
    ],
  },
  {
    tier: "tier4",
    label: "Fully Managed",
    registrationPath: "/fully-managed",
    price: 1775,
    displayPrice: 1775,
    phoneMinutesIncluded: 7000,
    smsMessagesIncluded: 10000,
    emailsIncluded: 100000,
    features: [
      "Dedicated Account Rep",
      "7,000 Calling Minutes",
      "10,000 SMS Messages",
      "Unlimited Emails",
    ],
  },
  {
    tier: "tier5",
    label: "Legacy Pipeline Primer",
    isLegacy: true,
    registrationPath: "/pipeline-primer",
    price: 939,
    displayPrice: 939,
    phoneMinutesIncluded: 500,
    smsMessagesIncluded: 1000,
    emailsIncluded: 10000,
    features: [
      "1 Blog posts per week",
      "1 Email per week",
      "1 Video edit per month",
      "Advanced Tracking Website",
      "Klevr CRM Included",
      "500 Calling Minutes",
      "1,000 SMS Messages",
      "10,000 Emails",
    ],
  },
  {
    tier: "tier6",
    label: "Legacy Pipeline Boost",
    isLegacy: true,
    registrationPath: "/pipeline-boost",
    price: 1729,
    displayPrice: 1729,
    phoneMinutesIncluded: 1000,
    smsMessagesIncluded: 2500,
    emailsIncluded: 15000,
    features: [
      "2 Blog posts per week",
      "2 Emails per week",
      "2 Video edits per month",
      "Advanced Tracking Website",
      "Klevr CRM Included",
      "1,000 Calling Minutes",
      "2,500 SMS Messages",
      "15,000 Emails",
    ],
  },
  {
    tier: "tier7",
    label: "Legacy Pipeline Pro",
    isLegacy: true,
    registrationPath: "/pipeline-pro",
    price: 2317,
    displayPrice: 2317,
    phoneMinutesIncluded: 3000,
    smsMessagesIncluded: 5500,
    emailsIncluded: 100000,
    features: [
      "2 Blog posts per week",
      "2 Emails per week",
      "3 Video edits per month",
      "Advanced Tracking Website",
      "Klevr CRM Included",
      "3,000 Calling Minutes",
      "5,500 SMS Messages",
      "Unlimited Emails",
    ],
  },
  {
    tier: "tier8",
    label: "Legacy Pipeline Pro+",
    isLegacy: true,
    registrationPath: "/pipeline-pro-plus",
    price: 2997,
    displayPrice: 2997,
    phoneMinutesIncluded: 3000,
    smsMessagesIncluded: 5500,
    emailsIncluded: 100000,
    features: [
      "3 Blog posts per week",
      "3 Emails per week",
      "4 Video edits per month",
      "Advanced Tracking Website",
      "Klevr CRM Included",
      "3,000 Calling Minutes",
      "5,500 SMS Messages",
      "Unlimited Emails",
    ],
  },
];

export const phoneMinutesRefills = [
  { quantity: 0, label: "0", price: 0, color: "#393939" }, // pricePer: 0
  { quantity: 500, label: "", price: 40, color: "#393939" }, // pricePer: 0.08
  { quantity: 1000, label: "1000", price: 72, color: "#2196f3" }, // pricePer: 0.072
  { quantity: 1500, label: "", price: 108, color: "#2196f3" }, // pricePer: 0.072
  { quantity: 2000, label: "2000", price: 144, color: "#2196f3" }, // pricePer: 0.072
  { quantity: 2500, label: "", price: 180, color: "#2196f3" }, // pricePer: 0.072
  { quantity: 3000, label: "3000", price: 192, color: "#37a882" }, // pricePer: 0.064
  { quantity: 3500, label: "", price: 224, color: "#37a882" }, // pricePer: 0.064
  { quantity: 4000, label: "4000", price: 256, color: "#37a882" }, // pricePer: 0.064
  { quantity: 4500, label: "", price: 288, color: "#37a882" }, // pricePer: 0.064
  { quantity: 5000, label: "5000", price: 320, color: "#37a882" }, // pricePer: 0.064
];

export const smsMessagesRefills = [
  { quantity: 0, label: "0", price: 0, color: "#00d88e" },
  { quantity: 500, label: "", price: 15, color: "#00d88e" },
  { quantity: 1000, label: "1K", price: 30, color: "#00D88E" },
  { quantity: 1500, label: "", price: 45, color: "#00D88E" },
  { quantity: 2000, label: "2K", price: 60, color: "#00D88E" },
  { quantity: 2500, label: "", price: 75, color: "#00D88E" },
  { quantity: 3000, label: "3K", price: 90, color: "#00D88E" },
  { quantity: 3500, label: "", price: 105, color: "#00D88E" },
  { quantity: 4000, label: "4K", price: 120, color: "#00D88E" },
  { quantity: 4500, label: "", price: 135, color: "#00D88E" },
  { quantity: 5000, label: "5K", price: 150, color: "#00D88E" },
  { quantity: 5500, label: "", price: 165, color: "#00D88E" },
  { quantity: 6000, label: "6K", price: 180, color: "#00D88E" },
  { quantity: 6500, label: "", price: 195, color: "#00D88E" },
  { quantity: 7000, label: "7K", price: 210, color: "#00D88E" },
  { quantity: 7500, label: "", price: 225, color: "#00D88E" },
  { quantity: 8000, label: "8K", price: 240, color: "#00D88E" },
  { quantity: 8500, label: "", price: 255, color: "#00D88E" },
  { quantity: 9000, label: "9K", price: 270, color: "#00D88E" },
  { quantity: 9500, label: "", price: 285, color: "#00D88E" },
  { quantity: 10000, label: "10K", price: 300, color: "#00D88E" },
];

export const emailsRefills = [
  { quantity: 0, label: "0", price: 0, color: "#ff9800" },
  { quantity: 10000, label: "10K", price: 10, color: "#ff9800" },
  { quantity: 20000, label: "20K", price: 20, color: "#ff9800" },
  { quantity: 30000, label: "30K", price: 30, color: "#ff9800" },
  { quantity: 40000, label: "40K", price: 40, color: "#ff9800" },
  { quantity: 50000, label: "50K", price: 50, color: "#ff9800" },
  { quantity: 60000, label: "60K", price: 60, color: "#ff9800" },
  { quantity: 70000, label: "70K", price: 70, color: "#ff9800" },
  { quantity: 80000, label: "80K", price: 80, color: "#ff9800" },
  { quantity: 90000, label: "90K", price: 90, color: "#ff9800" },
  { quantity: 100000, label: "100K", price: 100, color: "#ff9800" },
];

export const klevrCreditsRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 250, label: "250", price: 250, color: null },
  { quantity: 500, label: "500", price: 500, color: null },
  { quantity: 750, label: "750", price: 750, color: null },
  { quantity: 1000, label: "1000", price: 1000, color: null },
  { quantity: 1250, label: "1250", price: 1250, color: null },
  { quantity: 1500, label: "1500", price: 1500, color: null },
  { quantity: 1750, label: "1750", price: 1750, color: null },
  { quantity: 2000, label: "2000", price: 2000, color: null },
  { quantity: 2250, label: "2250", price: 2250, color: null },
  { quantity: 2500, label: "2500", price: 2500, color: null },
];

export const coachingHoursRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "1", price: 100, color: null },
  { quantity: 2, label: "2", price: 200, color: null },
  { quantity: 3, label: "3", price: 300, color: null },
  { quantity: 4, label: "4", price: 400, color: null },
  { quantity: 5, label: "5", price: 500, color: null },
  { quantity: 6, label: "6", price: 600, color: null },
  { quantity: 7, label: "7", price: 700, color: null },
  { quantity: 8, label: "8", price: 800, color: null },
  { quantity: 9, label: "9", price: 900, color: null },
  { quantity: 10, label: "10", price: 1000, color: null },
];

export const farmSizeRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1000, label: "1000", price: 50, color: null },
  { quantity: 2000, label: "2000", price: 100, color: null },
  { quantity: 3000, label: "3000", price: 150, color: null },
  { quantity: 4000, label: "4000", price: 200, color: null },
  { quantity: 5000, label: "5000", price: 250, color: null },
];

export const socialMediaManagementRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "1", price: 499, color: null },
];

export const advertisingRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "$250", price: 250, color: null },
  { quantity: 2, label: "$500", price: 500, color: null },
  { quantity: 3, label: "$750", price: 750, color: null },
  { quantity: 4, label: "$1,000", price: 1000, color: null },
  { quantity: 5, label: "$1,250", price: 1250, color: null },
  { quantity: 6, label: "$1,500", price: 1500, color: null },
  { quantity: 7, label: "$1,750", price: 1750, color: null },
  { quantity: 8, label: "$2,000", price: 2000, color: null },
  { quantity: 9, label: "$2,250", price: 2250, color: null },
  { quantity: 10, label: "$2,500", price: 2500, color: null },
  { quantity: 11, label: "$2,750", price: 2750, color: null },
  { quantity: 12, label: "$3,000", price: 3000, color: null },
];

export const trackingWebsiteRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "1", price: 97, color: null },
];

export const virtualAssistantRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "1", price: 127, color: null },
];

export const zipCodeLicenseRefills = [
  { quantity: 0, label: "0", price: 0, color: null },
  { quantity: 1, label: "1", price: 97, color: null },
];

export const getRefillPriceFromTypeAndQuantity = (
  refillType: string,
  quantity: number
) => {
  let refill = null as any;
  switch (refillType) {
    case "phoneMinutes":
      refill = phoneMinutesRefills.find((obj) => obj.quantity === quantity);
      break;
    case "smsMessages":
      refill = smsMessagesRefills.find((obj) => obj.quantity === quantity);
      break;
    case "emails":
      refill = emailsRefills.find((obj) => obj.quantity === quantity);
      break;
    case "klevrCredits":
      refill = klevrCreditsRefills.find((obj) => obj.quantity === quantity);
      break;
    case "farmSize":
      refill = farmSizeRefills.find((obj) => obj.quantity === quantity);
      break;
    case "coachingHours":
      refill = coachingHoursRefills.find((obj) => obj.quantity === quantity);
      break;
    case "socialMediaManagement":
      refill = socialMediaManagementRefills.find(
        (obj) => obj.quantity === quantity
      );
      break;
    case "trackingWebsite":
      refill = trackingWebsiteRefills.find((obj) => obj.quantity === quantity);
      break;
    case "advertising":
      refill = advertisingRefills.find((obj) => obj.quantity === quantity);
      break;
    case "virtualAssistant":
      refill = virtualAssistantRefills.find((obj) => obj.quantity === quantity);
      break;
    case "zipCodeLicense":
      refill = zipCodeLicenseRefills.find((obj) => obj.quantity === quantity);
      break;
    default:
      break;
  }

  return refill && refill.price ? refill.price : 0;
};

export const getRefillColorFromTypeAndQuantity = (
  refillType: string,
  quantity: number
) => {
  let refill = null as any;
  switch (refillType) {
    case "phoneMinutes":
      refill = phoneMinutesRefills.find((obj) => obj.quantity === quantity);
      break;
    case "smsMessages":
      refill = smsMessagesRefills.find((obj) => obj.quantity === quantity);
      break;
    case "emails":
      refill = emailsRefills.find((obj) => obj.quantity === quantity);
      break;
    case "klevrCredits":
      refill = klevrCreditsRefills.find((obj) => obj.quantity === quantity);
      break;
    case "farmSize":
      refill = farmSizeRefills.find((obj) => obj.quantity === quantity);
      break;
    case "coachingHours":
      refill = coachingHoursRefills.find((obj) => obj.quantity === quantity);
      break;
    case "socialMediaManagement":
      refill = socialMediaManagementRefills.find(
        (obj) => obj.quantity === quantity
      );
      break;
    default:
      break;
  }

  return refill && refill.color ? refill.color : "#000000";
};

export const compareTiers = (
  currentTier: string | null,
  towardsTier: string
) => {
  if (currentTier === towardsTier) return "same";

  if (currentTier === "tier0" || currentTier === null) return "upgrade";

  let currentTierNumber = parseFloat(currentTier.slice(4)) || 0;
  let towardsTierNumber = parseFloat(towardsTier.slice(4)) || 0;

  if (currentTierNumber === towardsTierNumber) return "same";
  if (currentTierNumber < towardsTierNumber) return "upgrade";
  if (currentTierNumber > towardsTierNumber) return "downgrade";
};

export const createCheckoutListAndTotalFromCart = (cart: any) => {
  let checkoutList = [] as any[];

  if (cart.accountFirstSetup.price > 0) {
    checkoutList.push({
      type: "accountFirstSetup",
      price: cart.accountFirstSetup.price,
      primaryText: "Account First Setup Fee",
      secondaryText: "One-Time Fee",
      icon: getCartIcon("accountFirstSetup"),
    });
  }

  if (cart.brandCreationFirstSetup.price > 0) {
    checkoutList.push({
      type: "brandCreationFirstSetup",
      price: cart.brandCreationFirstSetup.price,
      primaryText: "Brand Creation Setup Fee",
      secondaryText: "One-Time Fee",
      icon: getCartIcon("brandCreationFirstSetup"),
    });
  }

  if (cart.trackingWebsiteFirstSetup.price > 0) {
    checkoutList.push({
      type: "trackingWebsiteFirstSetup",
      price: cart.trackingWebsiteFirstSetup.price,
      primaryText: "Advanced Tracking Website First Setup Fee",
      secondaryText: "One-Time Fee",
      icon: getCartIcon("trackingWebsiteFirstSetup"),
    });
  }

  if (cart.plan.tier) {
    const plan = getPlanFromTier(cart.plan.tier);
    if (plan) {
      checkoutList.push({
        type: "plan",
        price: plan.price,
        primaryText: plan.label + " plan",
        secondaryText: "Monthly Subscription",
        icon: getCartIcon("plan"),
      });
    }
  }

  Object.keys(cart.subscriptionAddons).forEach((key: any) => {
    if (cart.subscriptionAddons[key].quantity > 0) {
      switch (key) {
        case "socialMediaManagement":
          checkoutList.push({
            type: "socialMediaManagement",
            price: cart.subscriptionAddons[key].price,
            primaryText: "Social Media Management Package",
            secondaryText: "Monthly Subscription",
            icon: getCartIcon(key),
          });
          break;
        case "trackingWebsite":
          checkoutList.push({
            type: "trackingWebsite",
            price: cart.subscriptionAddons[key].price,
            primaryText: "Advanced Tracking Website Maintenance and Hosting",
            secondaryText: "Monthly Subscription",
            icon: getCartIcon(key),
          });
          break;
        case "advertising":
          checkoutList.push({
            type: "advertising",
            price: cart.subscriptionAddons[key].price,
            primaryText: "Advertising",
            secondaryText: "Monthly Subscription",
            icon: getCartIcon(key),
          });
          break;
        case "virtualAssistant":
          checkoutList.push({
            type: "virtualAssistant",
            price: cart.subscriptionAddons[key].price,
            primaryText: "Virtual Assistant",
            secondaryText: "Monthly Subscription",
            icon: getCartIcon(key),
          });
          break;
        case "zipCodeLicense":
          checkoutList.push({
            type: "zipCodeLicense",
            price: cart.subscriptionAddons[key].price,
            primaryText: "Zipcode License Monthly Subscription",
            secondaryText:
              cart.subscriptionAddons[key].zipCode +
              `${
                cart.subscriptionAddons[key].promotionCode
                  ? " (with Promo " +
                    cart.subscriptionAddons[key].promotionCode +
                    " )"
                  : ""
              }`,
            icon: getCartIcon(key),
          });
          break;
        default:
          break;
      }
    }
  });

  Object.keys(cart.addons).forEach((key: any) => {
    if (cart.addons[key].quantity > 0) {
      switch (key) {
        case "phoneMinutes":
          checkoutList.push({
            type: key,
            price: cart.addons[key].price,
            primaryText: "Phone Minutes - Refill",
            secondaryText: cart.addons[key].quantity + " minutes",
            icon: getCartIcon(key, "refill"),
          });

          break;
        case "smsMessages":
          checkoutList.push({
            type: key,
            price: cart.addons[key].price,
            primaryText: "Sms Messages - Refill",
            secondaryText: cart.addons[key].quantity + " messages",
            icon: getCartIcon(key, "refill"),
          });
          break;
        case "emails":
          checkoutList.push({
            type: key,
            price: cart.addons[key].price,
            primaryText: "Emails - Refill",
            secondaryText: cart.addons[key].quantity + " emails",
            icon: getCartIcon(key, "refill"),
          });
          break;
        case "klevrCredits":
          checkoutList.push({
            type: key,
            price: cart.addons[key].price,
            primaryText: "Klevr Credits",
            secondaryText: "$ " + cart.addons[key].quantity,
            icon: getCartIcon(key),
          });
          break;
        case "farmSize":
          checkoutList.push({
            type: "farmSize",
            price: cart.addons[key].price,
            primaryText: "Klevr Farm",
            secondaryText:
              cart.addons[key].quantity +
              " homes " +
              "One-Time - Farm builder pack",
            icon: getCartIcon(key),
          });
          break;
        case "coachingHours":
          checkoutList.push({
            type: "coachingHours",
            price: cart.addons[key].price,
            primaryText: "Coaching",
            secondaryText: cart.addons[key].quantity + " hours",
            icon: getCartIcon(key),
          });
          break;
        case "socialMediaManagement":
          checkoutList.push({
            type: "socialMediaManagement",
            price: cart.addons[key].price,
            primaryText: "Social Media Management Package",
            secondaryText: cart.addons[key].quantity + " days support",
            icon: getCartIcon(key),
          });
          break;
        default:
          break;
      }
    }
  });

  const checkoutTotal = checkoutList.reduce(
    (accumulator: number, currentValue: any) =>
      accumulator + currentValue.price,
    0
  );
  return { checkoutList, checkoutTotal };
};

export const getPromotionCodeFromCart = (cart: any) => {
  let promotionCodeFromUser = "";
  Object.keys(cart.subscriptionAddons).forEach((key: any) => {
    if (cart.subscriptionAddons[key].quantity > 0) {
      switch (key) {
        case "zipCodeLicense":
          promotionCodeFromUser =
            cart.subscriptionAddons[key].promotionCode || "";
          break;
        default:
          break;
      }
    }
  });

  return promotionCodeFromUser;
};
