import { ReduxAction, voidLanding } from "../../common-interfaces/interfaces";

import { LOGOUT } from "../../user/redux/user-actions";
import {
  INITIALIZE_LANDING_FORM,
  LANDING_CREATED,
  LANDING_FORM_UPDATED,
  LANDINGS_DELETED,
  LANDINGS_FETCH_START,
  LANDINGS_FETCH_SUCCESS,
  TICK_MULTIPLE_LANDINGS,
  TICK_SINGLE_LANDING,
} from "./landings-actions";

interface LandingsState {
  isInitializedLandings: boolean;
  isFetchingLandings: boolean;
  landings: any[];
  tickedLandingsIds: number[];
  form: any;
}

const initialLandingSettings = {
  userId: 0,
  name: "",
  dripId: 0,
  settings: {
    templateCode: 1,
    templateType: "seller",
    appPhoneNumber: "",
    userProfileImage: "",
    userLogoImage: "",
    userFirstName: "",
    userLastName: "",
    userCompanyName: "",
  },
};

const initialState: LandingsState = {
  isInitializedLandings: false,
  form: initialLandingSettings,
  isFetchingLandings: false,
  landings: [],
  tickedLandingsIds: [],
};

export const landingsReducer = (
  state: LandingsState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case LANDING_CREATED:
      return {
        ...state,
        form: initialState.form,
      };

    case INITIALIZE_LANDING_FORM:
      return {
        ...state,
        form: payload.landing ? payload.landing : initialState.form,
      };

    case LANDING_FORM_UPDATED:
      return {
        ...state,
        form: payload.form,
      };

    case LANDINGS_DELETED:
      const { landingIds } = payload;
      return {
        ...state,
        landings: state.landings.filter(
          (landing) => !landingIds.includes(landing.id)
        ),
        tickedLandingsIds: [],
      };

    // //this is the case that was causing a bug in create-reducer.ts
    // //that was solved by setting "as any".
    // //combineReducers({...} as any)
    case TICK_MULTIPLE_LANDINGS:
      return {
        ...state,
        tickedLandingsIds: payload.landingIds,
      };

    case TICK_SINGLE_LANDING:
      const { landingId } = payload;
      const { tickedLandingsIds } = state;
      const indexOfLanding = tickedLandingsIds.indexOf(landingId);

      let newTickedLandingsIds;
      if (indexOfLanding > -1) {
        newTickedLandingsIds = tickedLandingsIds.slice(0);
        newTickedLandingsIds.splice(indexOfLanding, 1);
      } else {
        newTickedLandingsIds = tickedLandingsIds.concat(landingId);
      }
      return {
        ...state,
        tickedLandingsIds: newTickedLandingsIds,
      };

    case LANDINGS_FETCH_START:
      return {
        ...state,
        isFetchingLandings: true,
      };

    case LANDINGS_FETCH_SUCCESS:
      const landings = { payload };
      //let newLandings
      let origin = window.location.origin;
      if (origin.indexOf("localhost:3000") !== -1) {
        origin = origin.replace("localhost:3000", "localhost:3002"); //for local landing-pages
      } else {
        if (
          origin === "https://app.klevrleads.com" ||
          origin === "http://app.klevrleads.com"
        ) {
          origin = "https://www.digitalcma.com";
        }
      }

      return {
        ...state,
        isInitializedLandings: true,
        isFetchingLandings: false,
        landings: [voidLanding].concat(
          payload.landings
            .map((l: any) => ({
              ...l,
              url:
                origin +
                "/lp/" +
                l.settings.templateCode +
                "/" +
                l.id +
                "/step1",
            }))
            .sort((a: any, b: any) => (a.createdAt < b.createdAt ? -1 : 1))
        ),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
