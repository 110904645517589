import { differenceInMilliseconds } from "date-fns";
import { ReduxAction } from "../../common-interfaces/interfaces";
import { RelocationInterface } from "../../common-interfaces/relocations";
import { ZipCodeInterface } from "../../common-interfaces/zipcodes";
import { LOGOUT } from "../../user/redux/user-actions";
import {
  ZIPCODES_FETCH_SUCCESS,
  RELOCATIONS_FETCH_SUCCESS,
  RELOCATION_CREATE_SUCCESS,
  RELOCATION_UPDATE_SUCCESS,
} from "./zipcodes-actions";

interface ZipCodesState {
  zipCodes: ZipCodeInterface[];
  isInitializedZipCodes: boolean;
  relocations: RelocationInterface[];
  isInitializedRelocations: boolean;
}

const initialState: ZipCodesState = {
  zipCodes: [],
  isInitializedZipCodes: false,
  relocations: [],
  isInitializedRelocations: false,
};

export const zipCodesReducer = (
  state: ZipCodesState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;

  switch (type) {
    case RELOCATION_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _relocations = state.relocations.map((relocation: any) =>
        relocation.id === payload.updatedRelocation.id
          ? payload.updatedRelocation
          : relocation
      );

      return {
        ...state,
        relocations: _relocations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(a.createdAt), new Date(b.createdAt))
        ),
      };

    case RELOCATION_CREATE_SUCCESS:
      let _newRelocations = state.relocations.concat(payload.newRelocation);

      return {
        ...state,
        relocations: _newRelocations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(a.createdAt), new Date(b.createdAt))
        ),
      };

    case RELOCATIONS_FETCH_SUCCESS:
      return {
        ...state,
        relocations: payload.relocations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(a.createdAt), new Date(b.createdAt))
        ),
        isInitializedRelocations: true,
      };

    case ZIPCODES_FETCH_SUCCESS:
      return {
        ...state,
        zipCodes: payload.zipCodes,
        isInitializedZipCodes: true,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
