import api from "../../api/api";
import { PollingIntervalTypes } from "../../common-interfaces/interfaces";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

export const SMS_SEND_START = "SMS_SEND_START";
export const SMS_SEND_SUCCESS = "SMS_SEND_SUCCESS";
export const SMS_SEND_FAIL = "SMS_SEND_FAIL";
export const SMS_SET_READ_START = "SMS_SET_READ_START";
export const SMS_SET_READ_SUCCESS = "SMS_SET_READ_SUCCESS";
export const SMS_SET_READ_FAIL = "SMS_SET_READ_FAIL";

export const SMS_FETCH_CONVERSATIONS_START = "SMS_FETCH_CONVERSATIONS_START";
export const SMS_FETCH_CONVERSATIONS_SUCCESS =
  "SMS_FETCH_CONVERSATIONS_SUCCESS";
export const SMS_FETCH_CONVERSATIONS_FAIL = "SMS_FETCH_CONVERSATIONS_FAIL";
export const SMS_FETCH_SINGLE_CONVERSATION_START =
  "SMS_FETCH_SINGLE_CONVERSATION_START";
export const SMS_FETCH_SINGLE_CONVERSATION_SUCCESS =
  "SMS_FETCH_SINGLE_CONVERSATION_SUCCESS";
export const SMS_FETCH_SINGLE_CONVERSATION_FAIL =
  "SMS_FETCH_SINGLE_CONVERSATION_FAIL";

export const fetchSmsConversations = () => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const latestSmsConversationsInitializedAt =
        state.inboxSms.latestSmsConversationsInitializedAt;

      dispatch({
        type: SMS_FETCH_CONVERSATIONS_START,
      });

      let smsConversations = [] as any;
      if (!latestSmsConversationsInitializedAt) {
        const res = await api.sms.fetchSmsConversations();

        smsConversations = res.smsConversations;
      } else {
        // startingFrom = last update minus PollingInterval * 2
        let startingFrom = new Date(
          new Date(latestSmsConversationsInitializedAt).getTime() -
            PollingIntervalTypes.INBOX_SMS * 2
        );

        const res = await api.sms.fetchSmsConversations(startingFrom);
        smsConversations = res.smsConversations;
      }

      dispatch({
        type: SMS_FETCH_CONVERSATIONS_SUCCESS,
        payload: {
          smsConversations,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_FETCH_CONVERSATIONS_FAIL,
      });
      return false;
    }
  };
};

export const fetchSingleSmsConversation = (phoneNumber: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_FETCH_SINGLE_CONVERSATION_START,
      });

      const { smsMessagesByNumberForUser } =
        await api.sms.fetchSingleSmsConversation(phoneNumber);

      dispatch({
        type: SMS_FETCH_SINGLE_CONVERSATION_SUCCESS,
        payload: {
          smsMessagesByNumberForUser,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_FETCH_SINGLE_CONVERSATION_FAIL,
      });
      return false;
    }
  };
};

export const sendSmsMessage = (
  toPhoneNumber: string,
  message: string,
  leadId: number
) => {
  if (!toPhoneNumber || message.length === 0) return;
  return async (dispatch: Function) => {
    dispatch({
      type: SMS_SEND_START,
    });

    try {
      const res = await api.sms.sendSmsMessage(toPhoneNumber, message, leadId);

      const { sentSms } = res;

      dispatch({
        type: SMS_SEND_SUCCESS,
        payload: { sentSms },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_SEND_FAIL,
      });
      return false;
    }
  };
};

export const setSmsMessagesAsRead = (phoneNumber: string) => {
  if (!phoneNumber) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_SET_READ_START,
        payload: { phoneNumber },
      });

      await api.sms.setSmsMessagesAsRead(phoneNumber);

      dispatch({
        type: SMS_SET_READ_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_SET_READ_FAIL,
      });
      return false;
    }
  };
};
