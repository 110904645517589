import api from "../../api/api";
import { PollingIntervalTypes } from "../../common-interfaces/interfaces";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

export const EMAIL_SEND_START = "EMAIL_SEND_START";
export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAIL = "EMAIL_SEND_FAIL";
export const EMAILS_FETCH_START = "EMAILS_FETCH_START";
export const EMAILS_FETCH_SUCCESS = "EMAILS_FETCH_SUCCESS";
export const EMAILS_FETCH_FAIL = "EMAILS_FETCH_FAIL";
export const EMAIL_FETCH_SINGLE_START = "EMAIL_FETCH_SINGLE_START";
export const EMAIL_FETCH_SINGLE_SUCCESS = "EMAIL_FETCH_SINGLE_SUCCESS";
export const EMAIL_FETCH_SINGLE_FAIL = "EMAIL_FETCH_SINGLE_FAIL";
export const EMAILS_DELETE_START = "EMAILS_DELETE_START";
export const EMAILS_DELETE_SUCCESS = "EMAILS_DELETE_SUCCESS";
export const EMAILS_DELETE_FAIL = "EMAILS_DELETE_FAIL";

export const fetchEmails = () => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const latestEmailsInitializedAt =
        state.inboxEmails.latestEmailsInitializedAt;

      dispatch({
        type: EMAILS_FETCH_START,
      });

      let emails = [] as any;
      if (!latestEmailsInitializedAt) {
        emails = await api.emails.fetchEmails();
      } else {
        // startingFrom = last update minus PollingInterval * 2
        let startingFrom = new Date(
          new Date(latestEmailsInitializedAt).getTime() -
            PollingIntervalTypes.INBOX_EMAILS * 2
        );

        emails = await api.emails.fetchEmails(startingFrom);
      }

      dispatch({
        type: EMAILS_FETCH_SUCCESS,
        payload: {
          emails,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: EMAILS_FETCH_FAIL,
      });
      return false;
    }
  };
};

// export const fetchSingleEmailAndSetRead = (emailId: number) => {
//   if (!emailId) return;
//   return async (dispatch: Function) => {
//     try {
//       dispatch({
//         type: EMAIL_FETCH_SINGLE_START,
//         payload: { emailId },
//       });

//       const { updatedEmail } = await api.emails.fetchSingleEmailAndSetRead(emailId);

//       dispatch({
//         type: EMAIL_FETCH_SINGLE_SUCCESS,
//       });

//       return true;
//     } catch (error: any) {
//       dispatch({
//         type: EMAIL_FETCH_SINGLE_FAIL,
//       });
//       return false;
//     }
//   };
// }

export const fetchSingleEmailAndSetRead = (emailId: number) => {
  if (!emailId) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_FETCH_SINGLE_START,
        payload: { emailId },
      });

      const { updatedEmail } = await api.emails.fetchSingleEmailAndSetRead(
        emailId
      );

      dispatch({
        type: EMAIL_FETCH_SINGLE_SUCCESS,
        payload: {
          updatedEmail,
        },
      });

      return updatedEmail;
    } catch (error: any) {
      dispatch({
        type: EMAIL_FETCH_SINGLE_FAIL,
      });
      return false;
    }
  };
};

export const saveAndSendEmail = (
  leadId: number,
  subject: string,
  bodyInPlainText: string,
  bodyInHtml: string
) => {
  return async (dispatch: Function) => {
    dispatch({
      type: EMAIL_SEND_START,
    });
    try {
      await api.emails.saveAndSendEmail(
        leadId,
        subject,
        bodyInPlainText,
        bodyInHtml
      );
      dispatch({
        type: EMAIL_SEND_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_SEND_FAIL,
      });
      return false;
    }
  };
};

export const deleteEmails = (emailIds: number[]) => {
  return async (dispatch: Function) => {
    if (!emailIds || !emailIds.length) {
      return;
    }
    dispatch({
      type: EMAILS_DELETE_START,
    });
    try {
      await api.emails.deleteEmails(emailIds.filter((id: any) => id !== 0));
      dispatch({
        type: EMAILS_DELETE_SUCCESS,
        payload: {
          emailIds,
        },
      });
    } catch (error: any) {
      dispatch({
        type: EMAILS_DELETE_FAIL,
      });
    }
  };
};
