import { ReduxAction } from "../../common-interfaces/interfaces";

import {
  SMS_FETCH_CONVERSATIONS_SUCCESS,
  SMS_SEND_SUCCESS,
  SMS_SET_READ_START,
  SMS_FETCH_SINGLE_CONVERSATION_SUCCESS,
} from "./sms-inbox-actions";
import { LOGOUT } from "../../user/redux/user-actions";

import {
  format,
  parseISO,
  formatISO,
  differenceInMilliseconds,
} from "date-fns";

interface SMSInboxState {
  latestSmsConversationsInitializedAt: Date | null;
  latestSmsConversationsUpdateAt: Date | null;
  smsConversations: any[];
  smsCurrentConversation: any[];
}

const initialState: SMSInboxState = {
  latestSmsConversationsInitializedAt: null,
  latestSmsConversationsUpdateAt: null,
  smsConversations: [],
  smsCurrentConversation: [],
};

export const inboxSmsReducer = (
  state: SMSInboxState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case SMS_FETCH_CONVERSATIONS_SUCCESS:
      const newSmsConversations = payload.smsConversations;

      let _smsConversations = [] as any[];

      if (state.smsConversations.length === 0) {
        _smsConversations = newSmsConversations;
      } else {
        _smsConversations = state.smsConversations.filter(
          (conversation: any) => {
            let updatingConversation = newSmsConversations.find(
              (newConversation: any) =>
                conversation.number === newConversation.number
            );

            // remove conversations that are in the array of updated conversations
            if (updatingConversation) {
              return false;
            } else {
              return true;
            }
          }
        );

        // add all the newly fetched conversations
        _smsConversations = _smsConversations.concat(newSmsConversations);
      }

      return {
        ...state,
        smsConversations: _smsConversations.sort((a: any, b: any) =>
          differenceInMilliseconds(
            new Date(b.latestDate),
            new Date(a.latestDate)
          )
        ),
        latestSmsConversationsInitializedAt: new Date(),
        latestSmsConversationsUpdateAt: new Date(),
      };

    case SMS_FETCH_SINGLE_CONVERSATION_SUCCESS:
      let smsMessagesByNumberForUser = payload.smsMessagesByNumberForUser;

      //here the array is grouped by date
      let dateInMilliseconds = 0;
      const groups = smsMessagesByNumberForUser.reduce(
        (groups: any, smsMessage: any) => {
          // normalize the hours and returns milliseconds
          dateInMilliseconds = new Date(smsMessage.createdAt).setHours(
            0,
            0,
            0,
            0
          );

          if (!groups[dateInMilliseconds]) {
            groups[dateInMilliseconds] = [];
          }
          groups[dateInMilliseconds].push(smsMessage);
          return groups;
        },
        {}
      );

      const _smsSingleConversation = Object.keys(groups)
        .map((dateInMilliseconds: any) => ({
          dateInMilliseconds: dateInMilliseconds,
          messages: groups[dateInMilliseconds].sort((a: any, b: any) =>
            differenceInMilliseconds(
              new Date(a.createdAt),
              new Date(b.createdAt)
            )
          ),
        }))
        .sort((a: any, b: any) => a.dateInMilliseconds - b.dateInMilliseconds);

      return {
        ...state,
        smsCurrentConversation: _smsSingleConversation,
      };

    case SMS_SEND_SUCCESS:
      const sentSms = payload.sentSms;

      let _dateInMilliseconds = new Date(sentSms.createdAt)
        .setHours(0, 0, 0, 0)
        .toString();

      let currentDay = state.smsCurrentConversation.find(
        (dailyConversation: any) =>
          dailyConversation.dateInMilliseconds === _dateInMilliseconds
      );

      if (currentDay) {
        currentDay = {
          dateInMilliseconds: _dateInMilliseconds,
          messages: currentDay.messages.concat(sentSms),
        };
      } else {
        currentDay = {
          dateInMilliseconds: _dateInMilliseconds,
          messages: [sentSms],
        };
      }

      let _smsCurrentConversation = state.smsCurrentConversation;
      let dailyConversationIndex = _smsCurrentConversation.findIndex(
        (dailyConversation: any) =>
          dailyConversation.dateInMilliseconds === currentDay.dateInMilliseconds
      );

      if (dailyConversationIndex === -1) {
        _smsCurrentConversation = _smsCurrentConversation.concat(currentDay);
      } else {
        _smsCurrentConversation[dailyConversationIndex] = currentDay;
      }

      // update conversations list
      let ___smsConversations = state.smsConversations;

      const ___index = ___smsConversations.findIndex(
        (conversation: any) => conversation.number === sentSms.to
      );

      if (___index >= 0) {
        ___smsConversations[___index].latestSms = sentSms;
        ___smsConversations[___index].latestDate = sentSms.createdAt;
      }

      return {
        ...state,
        smsConversations: ___smsConversations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(b.createdAt), new Date(a.createdAt))
        ),
        smsCurrentConversation: _smsCurrentConversation,
        latestSmsConversationsUpdateAt: new Date(),
      };

    case SMS_SET_READ_START:
      const __smsConversations = state.smsConversations;

      const __index = __smsConversations.findIndex(
        (conversation: any) => conversation.number === payload.phoneNumber
      );

      if (__index >= 0) {
        __smsConversations[__index].newMessages = 0;
      }

      return {
        ...state,
        smsConversations: __smsConversations,
        latestSmsConversationsUpdateAt: new Date(),
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
