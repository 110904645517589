import api from "../../api/api";
import { PollingIntervalTypes } from "../../common-interfaces/interfaces";
import { Device, Connection } from "twilio-client";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

export const CALLLOGS_FETCH_START = "CALLLOGS_FETCH_START";
export const CALLLOGS_FETCH_SUCCESS = "CALLLOGS_FETCH_SUCCESS";
export const CALLLOGS_FETCH_FAIL = "CALLLOGS_FETCH_FAIL";
export const CALLLOG_CREATE_START = "CALLLOG_CREATE_START";
export const CALLLOG_CREATE_SUCCESS = "CALLLOG_CREATE_SUCCESS";
export const CALLLOG_CREATE_FAIL = "CALLLOG_CREATE_FAIL";
export const CALLLOG_UPDATE_NOTETEXT = "CALLLOG_UPDATE_NOTETEXT";
export const CALLLOG_UPDATE_NOTETEXT_SUCCESS =
  "CALLLOG_UPDATE_NOTETEXT_SUCCESS";
export const CALLLOG_UPDATE_NOTETEXT_FAIL = "CALLLOG_UPDATE_NOTETEXT_FAIL";
export const CALLLOG_DELETE_VOICEMESSAGE_START =
  "CALLLOG_DELETE_VOICEMESSAGE_START";
export const CALLLOG_DELETE_VOICEMESSAGE_SUCCESS =
  "CALLLOG_DELETE_VOICEMESSAGE_SUCCESS";
export const CALLLOG_DELETE_VOICEMESSAGE_START_FAIL =
  "CALLLOG_DELETE_VOICEMESSAGE_FAIL";
export const SEND_RECORDED_MESSAGE_START = "SEND_RECORDED_MESSAGE_START";
export const SEND_RECORDED_MESSAGE_SUCCESS = "SEND_RECORDED_MESSAGE_SUCCESS";
export const SEND_RECORDED_MESSAGE_FAIL = "SEND_RECORDED_MESSAGE_FAIL";
export const DIALER_SET_LIST = "DIALER_SET_LIST";
export const DIALER_SET_PLAYING = "DIALER_SET_PLAYING";
export const DIALER_SET_PAUSED = "DIALER_SET_PAUSED";
export const DIALER_SET_STOPPED = "DIALER_SET_STOPPED";
export const DIALER_SET_SELECTED_DIALLEAD_FROM_LIST =
  "DIALER_SET_SELECTED_DIALLEAD_FROM_LIST";
export const DIALER_PREPARE_NEXT_CALL = "DIALER_PREPARE_NEXT_CALL";
export const SET_INBOUNDCALLSID_FOR_OUTGOING_CALL =
  "SET_INBOUNDCALLSID_FOR_OUTGOING_CALL";
export const SET_LEAD_IN_OUTGOING_CALL = "SET_LEAD_IN_OUTGOING_CALL";
export const SET_RECORDED_MESSAGE_ENABLED = "SET_RECORDED_MESSAGE_ENABLED";
export const INCOMING_CALL_SET_STATUS = "INCOMING_CALL_SET_STATUS";
//
export const SET_FORWARD_CALL_TO_USERNUMBER_ENABLED =
  "SET_FORWARD_CALL_TO_USERNUMBER_ENABLED";
export const FORWARD_CALL_TO_USERNUMBER_START =
  "FORWARD_CALL_TO_USERNUMBER_START";
export const FORWARD_CALL_TO_USERNUMBER_SUCCESS =
  "FORWARD_CALL_TO_USERNUMBER_SUCCESS";
export const FORWARD_CALL_TO_USERNUMBER_FAIL =
  "FORWARD_CALL_TO_USERNUMBER_FAIL";
export const FORWARD_CALL_TO_VOICEMAIL_START =
  "FORWARD_CALL_TO_VOICEMAIL_START";
export const FORWARD_CALL_TO_VOICEMAIL_SUCCESS =
  "FORWARD_CALL_TO_VOICEMAIL_SUCCESS";
export const FORWARD_CALL_TO_VOICEMAIL_FAIL = "FORWARD_CALL_TO_VOICEMAIL_FAIL";

// old way of creating the Device
// const device = Twilio.Device;
//
// the Device must be re-instantiated every time after
// destroying it. The best place to re-instantiate it is just after
// destroying it, so it is available for incoming calls.
let device = new Device("this_is_a_fake_Twilio_token_just_for_initializing");
//let device = null as any;

// Call Logs section
export const fetchCallLogs = (leadId: number) => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const latestCallLogsInitializedAt =
        state.dialer.latestCallLogsInitializedAt;

      dispatch({
        type: CALLLOGS_FETCH_START,
      });

      let callLogs = [] as any;
      if (!latestCallLogsInitializedAt) {
        const res = await api.voice.fetchCallLogs();
        callLogs = res.callLogs;
      } else {
        // startingFrom = last update minus PollingInterval * 2
        let startingFrom = new Date(
          new Date(latestCallLogsInitializedAt).getTime() -
            PollingIntervalTypes.INBOX_CALLLOGS * 2
        );
        const res = await api.voice.fetchCallLogs(startingFrom);
        callLogs = res.callLogs;
      }

      dispatch({
        type: CALLLOGS_FETCH_SUCCESS,
        payload: { callLogs },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: CALLLOGS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const createCallLog = (
  leadId: number | null,
  inboundCallSid: string,
  fromPhoneNumber: string,
  toPhoneNumber: string,
  noteText: string,
  autodialerIsPlaying: boolean
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CALLLOG_CREATE_START,
        payload: {},
      });
      const res = await api.voice.createCallLog(
        leadId,
        inboundCallSid,
        fromPhoneNumber,
        toPhoneNumber,
        noteText
      );
      dispatch({
        type: CALLLOG_CREATE_SUCCESS,
        payload: {},
      });
      if (!autodialerIsPlaying) {
        // fetchLeadActivityFeed(leadId)(dispatch);
      }
    } catch (error: any) {
      dispatch({
        type: CALLLOG_CREATE_FAIL,
        payload: {},
      });
    }
  };
};

export const updateCallLogNoteText = (
  leadId: number,
  inboundCallSid: string,
  noteText: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CALLLOG_UPDATE_NOTETEXT,
        payload: {
          inboundCallSid,
        },
      });
      const res = await api.voice.updateCallLogNoteText(
        leadId,
        inboundCallSid,
        noteText
      );
      const newCallLog = res.newCallLog;
      dispatch({
        type: CALLLOG_UPDATE_NOTETEXT_SUCCESS,
        payload: {
          newCallLog,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: CALLLOG_UPDATE_NOTETEXT_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const deleteVoiceMessage = (callLogId: number) => {
  if (!callLogId) {
    return false;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CALLLOG_DELETE_VOICEMESSAGE_START,
        payload: { callLogId },
      });

      const res = await api.voice.deleteVoiceMessages(callLogId);
      dispatch({
        type: CALLLOG_DELETE_VOICEMESSAGE_SUCCESS,
        payload: {
          callLogId,
        },
      });
      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: CALLLOG_DELETE_VOICEMESSAGE_START_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

// Twilio integration
export const forwardCallToVoiceMail = (inboundCallSid: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: FORWARD_CALL_TO_VOICEMAIL_START,
      });
      await api.voice.forwardCallToVoiceMail(inboundCallSid);
      dispatch({
        type: FORWARD_CALL_TO_VOICEMAIL_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: FORWARD_CALL_TO_VOICEMAIL_FAIL,
      });
      return false;
    }
  };
};

export const forwardCallToUserNumber = (
  inboundCallSid: string,
  callType: string,
  leadId?: number
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: FORWARD_CALL_TO_USERNUMBER_START,
      });
      await api.voice.forwardCallToUserNumber(inboundCallSid, callType, leadId);
      dispatch({
        type: FORWARD_CALL_TO_USERNUMBER_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: FORWARD_CALL_TO_USERNUMBER_FAIL,
      });
      return false;
    }
  };
};

export const setIncomingCallStatus = (
  phoneNumberOfIncomingPhoneCall: string,
  inboundCallSidForIncomingPhoneCall: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: INCOMING_CALL_SET_STATUS,
        payload: {
          phoneNumberOfIncomingPhoneCall,
          inboundCallSidForIncomingPhoneCall,
        },
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const acceptIncomingCall = () => {
  return async (dispatch: Function) => {
    try {
      const activeConnection = device.activeConnection();
      if (activeConnection) {
        activeConnection.accept();
      }
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const createTwilioDeviceForIncomingCalls = () => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const user = state.user.user;
      const autodialerStatus = state.dialer.autodialerStatus;
      const inboundCallSidForOutgoingPhoneCall =
        state.dialer.inboundCallSidForOutgoingPhoneCall;
      const leadInOutgoingCall = state.dialer.leadInOutgoingCall;
      const phoneNumberOfIncomingPhoneCall =
        state.dialer.phoneNumberOfIncomingPhoneCall;
      const inboundCallSidForIncomingPhoneCall =
        state.dialer.inboundCallSidForIncomingPhoneCall;

      // if the autodialer is active or the user is already in a conversation
      // then the token is not regenerated and the new Device is not created.
      // The Device will be created as soon as the autodialer gets stopped or
      // the user ends the call.
      if (
        autodialerStatus !== "stopped" ||
        inboundCallSidForOutgoingPhoneCall !== "" ||
        leadInOutgoingCall !== null ||
        phoneNumberOfIncomingPhoneCall ||
        inboundCallSidForIncomingPhoneCall ||
        device.status() === "busy"
      ) {
        return false;
      }

      const res = await api.voice.getTwilioToken();
      const twilioToken = res.token;
      if (!twilioToken) return false;

      // console.log("Device for Incoming calls active");
      //.destroy() triggers the 'offline' event handler and should destroy all listeners,
      // otherwise use .removeListener(eventName, handler)
      device.destroy();
      device = null;
      device = new Device();
      device.setup(twilioToken, {
        // debug: true,
        closeProtection: true, //makes a warning if leaving the page while a call is ongoing
        enableRingingState: true, //this must be set to true, or the autodialer won't pause when someone answers
        fakeLocalDTMF: true, //default setting for next release of Twilio.Device 2.0
        allowIncomingWhileBusy: true,
      });

      device.on("ready", (device: any) => {
        // console.log("1 - device ready");
      });
      device.on("disconnect", (connection: any) => {
        console.log("3 - device disconnect");
        // User had accepted the call and then user has hung down
        // or user had accepted the call the caller has hung down.
        setIncomingCallStatus("", "")(dispatch);
      });
      device.on("offline", (device: any) => {
        // this event is triggered by device.destroy()
        // console.log("offline - this event is triggered by device.destroy()");
      });
      device.on("error", (error: any) => {
        //  console.log("on error: ", error);
        setIncomingCallStatus("", "")(dispatch);
      });
      device.on("connect", (connection: any) => {
        // console.log("2 - device connect");
        // User has accepted the call
      });
      device.on("cancel", (connection: any) => {
        console.log("on cancel");
        setIncomingCallStatus("", "")(dispatch);
      });
      device.on("incoming", (connection: any) => {
        console.log("Incoming connection from " + connection.parameters.From);

        if (device.status() === ("ready" as "ready" | "offline" | "busy")) {
          // the user is free and the call is accepted
          setIncomingCallStatus(
            connection.parameters.From,
            connection.parameters.CallSid
          )(dispatch);
          enableForwardCall()(dispatch);
        } else {
          if (user.isVoiceMailEnabled) {
            // if the user is already in a call then the new call is forwarded to his mobile
            forwardCallToVoiceMail(connection.parameters.CallSid)(dispatch);
          }
        }

        // const bannedPhoneNumber = "+15417280966";
        // if (connection.parameters.From === bannedPhoneNumber) {
        //   conn.reject();
        // } else {
        //   // accept the incoming connection and start two-way audio
        //   connection.accept();
        // }
      });

      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setDialerList = (list: any) => {
  if (!list) return;
  return async (dispatch: Function) => {
    try {
      const state = store.getState() as any;
      const leads = state.leads.leads;
      dispatch({
        type: DIALER_SET_LIST,
        payload: { list, leads },
      });

      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setDialerPlaying = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIALER_SET_PLAYING,
      });
      dialNext()(dispatch);
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const dialNext = () => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const dialList = state.dialer.dialList;
      const hasFinishedDialingList = state.dialer.hasFinishedDialingList;
      const nextDialLeadToCall = state.dialer.nextDialLeadToCall;
      const autodialerStatus = state.dialer.autodialerStatus;

      if (hasFinishedDialingList) {
        setDialerStopped()(dispatch);
        return false;
      }
      if (autodialerStatus === "playing") {
        const autodialerIsPlaying = true;
        await destroyCall(autodialerStatus === "playing")(dispatch);
        let hasCreatedOutgoingPhoneCall = await createOutgoingCall(
          nextDialLeadToCall,
          autodialerIsPlaying
        )(dispatch);

        if (hasCreatedOutgoingPhoneCall) {
          await prepareNextCall(nextDialLeadToCall)(dispatch);
        } else {
          await destroyCall(autodialerIsPlaying)(dispatch);
          await setDialerPaused()(dispatch);
        }
      }
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setDialerPaused = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIALER_SET_PAUSED,
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setDialerStopped = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIALER_SET_STOPPED,
      });
      createTwilioDeviceForIncomingCalls()(dispatch);
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setSelectedDialLeadFromList = (selectedDialLeadFromList: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIALER_SET_SELECTED_DIALLEAD_FROM_LIST,
        payload: { selectedDialLeadFromList },
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setDialerInboundCallSidForOutgoingCall = (
  inboundCallSid: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SET_INBOUNDCALLSID_FOR_OUTGOING_CALL,
        payload: { inboundCallSid },
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const setLeadInOutgoingCall = (leadInOutgoingCall: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SET_LEAD_IN_OUTGOING_CALL,
        payload: { leadInOutgoingCall },
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const prepareNextCall = (currentDialLeadSelected: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIALER_PREPARE_NEXT_CALL,
        payload: { currentDialLeadSelected },
      });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const playDTMFtone = (digit: string) => {
  return async (dispatch: Function) => {
    const activeConnection = device.activeConnection();
    if (activeConnection) {
      activeConnection.sendDigits(digit);
      return true;
    } else {
      return false;
    }
  };
};

export const enableForwardCall = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: SET_FORWARD_CALL_TO_USERNUMBER_ENABLED });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const enableRecordedMessage = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: SET_RECORDED_MESSAGE_ENABLED });
      return true;
    } catch (error: any) {
      return false;
    }
  };
};

export const sendRecordedMessage = (leadId: number, inboundCallSid: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SEND_RECORDED_MESSAGE_START,
      });
      await api.voice.sendRecordedMessage(leadId, inboundCallSid);
      dispatch({
        type: SEND_RECORDED_MESSAGE_SUCCESS,
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SEND_RECORDED_MESSAGE_FAIL,
      });
      return false;
    }
  };
};

export const destroyCall = (autodialerIsPlaying: boolean) => {
  return async (dispatch: Function) => {
    try {
      // this is for incoming calls
      const activeConnection = device.activeConnection();
      if (activeConnection) {
        activeConnection.reject();
      }

      // this is for outgoing calls and established incoming calls
      device.disconnectAll();

      // this is for all cases
      setLeadInOutgoingCall(null)(dispatch);
      setIncomingCallStatus("", "")(dispatch);
      if (!autodialerIsPlaying) {
        // if the autodialer is playing then the Device for incoming calls
        // is restored when the autodialer is set as stopped.
        createTwilioDeviceForIncomingCalls()(dispatch);
      }
    } catch (error: any) {
      return false;
    }
  };
};

export const createOutgoingCall = (lead: any, autodialerIsPlaying: boolean) => {
  return async (dispatch: Function) => {
    try {
      //WARNING: here we have a direct access to store
      const state = store.getState() as any;
      const user = state.user.user;
      const phoneNumberOfIncomingPhoneCall =
        state.dialer.phoneNumberOfIncomingPhoneCall;
      const inboundCallSidForIncomingPhoneCall =
        state.dialer.inboundCallSidForIncomingPhoneCall;

      // if there is an incoming call going on then the device for outgoing calls
      // cannot be created.
      if (
        phoneNumberOfIncomingPhoneCall ||
        inboundCallSidForIncomingPhoneCall
      ) {
        return false;
      }

      setDialerInboundCallSidForOutgoingCall("")(dispatch);

      //if user hasn't been assigned a number then the global number will be used
      let fromPhoneNumber = state.user.user.appPhoneNumber || "";
      let toPhoneNumber = lead.phoneNumber;
      //fromPhoneNumber = ""; //for testing
      //toPhoneNumber = "+15554443333"; //invalid number for testing
      if (!toPhoneNumber) return false;
      const res = await api.voice.getTwilioToken();
      const twilioToken = res.token;
      if (!twilioToken) return false;
      let timeout = 30; //this is the standard timeout

      //this is necessary because for some reason the "ringing" is fired twice
      //and this results in the creation of 2 callLogs
      let hasCreatedCallLog = false;

      // console.log("Device for Outgoing calls active");
      //.destroy() triggers the 'offline' event handler and should destroy all listeners,
      // otherwise use .removeListener(eventName, handler)
      device.destroy();
      device = null;
      device = new Device();
      device.setup(twilioToken, {
        // debug: true,
        closeProtection: true, //makes a warning if leaving the page while a call is ongoing
        enableRingingState: true, //this must be set to true, or the autodialer won't pause when someone answers
        fakeLocalDTMF: true, //default setting for next release of Twilio.Device 2.0
        allowIncomingWhileBusy: true,
      });
      // https://www.twilio.com/docs/voice/client/javascript/device#audio
      device.audio.outgoing(false); // Enable or disable the outgoing event sound.

      if (autodialerIsPlaying) {
        //autodialing specific parameters
        timeout = 20;
        device.audio.disconnect(false); // Enable or disable the disconnect event sound.
      }

      //The flow of events is:
      //1 - device ready, means it has just set the token and is ready to call
      //2 - connection ringing
      //3 - device connect, means connection is established with Twilio's servers
      //4 - connection accept, means connection has been routed. BILLING STARTS HERE.
      //At this point it may be left ringing, or an autoresponder/human has answered or has declined,
      //or the person called has hung. Almost everything happens while in this state.
      //5 - device disconnect, here is the disconnection from Twilio's server
      //6 - connection disconnect, BILLING ENDS HERE.
      //0 - device.destroy() triggers device offline
      //the other events haven't been considered.
      device.on("ready", (device: any) => {
        // console.log("1 - device ready");
        try {
          // Documentation for the Connection object
          // https://www.twilio.com/docs/voice/client/javascript/connection
          let connection = device.connect({
            fromPhoneNumber,
            toPhoneNumber,
            timeout,
          });
          // these are additional infos that can be extracted from Connection:
          //console.log(connection.customParameters);
          //console.log(connection.parameters);
          //console.log(connection.outboundConnectionId); //thisi is the temporarySid, undocumented
          //console.log(connection._codec);
          connection.on("ringing", async (hasEarlyMedia: boolean) => {
            // console.log("2 - connection ringing");
            //this is the very first moment that 'connection.parameters.CallSid' is available
            setDialerInboundCallSidForOutgoingCall(
              connection.parameters.CallSid || ""
            )(dispatch);

            if (
              !hasCreatedCallLog &&
              connection.parameters.CallSid &&
              connection.parameters.CallSid.length > 0
            ) {
              if (!hasCreatedCallLog) {
                createCallLog(
                  lead.id,
                  connection.parameters.CallSid,
                  fromPhoneNumber,
                  toPhoneNumber,
                  "",
                  autodialerIsPlaying
                )(dispatch);
              }

              hasCreatedCallLog = true;

              setLeadInOutgoingCall(lead)(dispatch);
            }
          });
          connection.on("accept", async (connection: any) => {
            //console.log("4 - connection accept");
            //Elapsed time should start here
            enableRecordedMessage()(dispatch);
            enableForwardCall()(dispatch);
            // if (autodialerIsPlaying) {
            //   setDialerPaused()(dispatch);
            // }
          });
          connection.on("disconnect", async (connection: any) => {
            // console.log("5 connection disconnect");
            // Elapsed time should stop here
          });
          //.on('error, handler(error))
          //.on('mute', handler(boolean, connection))
          //.on('reconnecting', handler(error))
          //.on('reconnected', handler())
          //.on('sample', handler(rtcSample)
          //.on('volume', handler(inputVolume, outputVolume))
          //.on('warning', handler(warningName))
          //.on('warning-cleared', handler(warningName))
        } catch (error: any) {
          console.log("Error setting up Twilio.Connection: ", error);
          setLeadInOutgoingCall(null)(dispatch);
        }
      });
      device.on("disconnect", (connection: any) => {
        // console.log("6 device disconnect");
        setLeadInOutgoingCall(null)(dispatch);
        if (autodialerIsPlaying) {
          dialNext()(dispatch);
        } else {
          createTwilioDeviceForIncomingCalls()(dispatch);
        }
      });
      device.on("offline", (device: any) => {
        // this event is triggered by device.destroy()
        // console.log("offline - this event is triggered by device.destroy()");
      });
      device.on("error", (error: any) => {
        console.log("on error: ", error);
      });
      device.on("connect", (connection: any) => {
        // console.log("3 device connect");
      });
      device.on("cancel", (connection: any) => {
        // console.log("on cancel");
      });
      device.on("incoming", (connection: any) => {
        console.log("Incoming connection from " + connection.parameters.From);

        if (
          device.status() === ("busy" as "ready" | "offline" | "busy") ||
          device.status() === ("offline" as "ready" | "offline" | "busy")
        ) {
          if (user.isVoiceMailEnabled) {
            // if the user is already in a call then the new call is forwardedto his mobile
            forwardCallToVoiceMail(connection.parameters.CallSid)(dispatch);
          }
        }
      });
      return true;
    } catch (error: any) {
      console.log("Error setting up Twilio.Device: ", error);
      return false;
    }
  };
};
