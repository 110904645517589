import { parsePhoneNumberWithError, CountryCode } from "libphonenumber-js";
import isAlpha from "validator/lib/isAlpha";
import isLowercase from "validator/lib/isLowercase";
import isUppercase from "validator/lib/isUppercase";

export const hardwiredUserTagNames = [
  "real estate",
  "buyer",
  "enhanced",
  "imported",
  "junk lead",
  "klevr farm",
  "likely ai",
  "seller",
  "trust",
  "wrong number",
];

export const socialPagePrefix = `${window.location.origin}/klevr-nation/profile/`;

export const sanitizeTag = (tag: string): string => tag.toLowerCase();

export const sortTagNames = (
  tagNames: string[],
  useAlphabeticalOrder: boolean
) => {
  if (useAlphabeticalOrder === false)
    return hardwiredUserTagNames.concat(
      tagNames
        .filter((tagName: string) => !hardwiredUserTagNames.includes(tagName))
        .sort()
    );

  return tagNames.sort();
};

export const sortTags = (tags: any[], useAlphabeticalOrder: boolean) => {
  if (useAlphabeticalOrder === false)
    return tags.sort((a: any, b: any) => {
      if (
        hardwiredUserTagNames.includes(a.name) &&
        !hardwiredUserTagNames.includes(b.name)
      ) {
        return -1;
      } else {
        if (
          !hardwiredUserTagNames.includes(a.name) &&
          hardwiredUserTagNames.includes(b.name)
        ) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      }
    });

  //using alphabeticalOrder
  return tags.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
};

export const parsePhoneNumberForDisplay = (phoneNumber: string) =>
  phoneNumber.length > 0
    ? "(" +
      (phoneNumber[2] || "_") +
      (phoneNumber[3] || "_") +
      (phoneNumber[4] || "_") +
      ")" +
      " " +
      (phoneNumber[5] || "_") +
      (phoneNumber[6] || "_") +
      (phoneNumber[7] || "_") +
      "-" +
      (phoneNumber[8] || "_") +
      (phoneNumber[9] || "_") +
      (phoneNumber[10] || "_") +
      (phoneNumber[11] || "_")
    : "";

export const passwordRequirements =
  "Minimum 6 characters, one uppercase, one lowercase and one special character";

export const isValidPassword = (password: string) =>
  !(
    password.length < 6 ||
    isLowercase(password) || //only lowercase
    isUppercase(password) || //only uppercase
    isAlpha(password)
  ); //only letters (a-zA-Z)

export const isDefined = (variable: any) =>
  typeof variable === "undefined" ? false : true;

export const formatPhoneNumberIntoE164 = (phoneNumber: string) => {
  //if phoneNumber is parseable then it is returned in E.164 format,
  //otherwise the empty string is returned.
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(phoneNumber, "US");
    //the following line can check if the parsed number is also a valid one
    //an example of valid US number is "(702) 365-1234"
    //console.log(formattedPhoneNumber ? formattedPhoneNumber.isValid() : "");
    //
    //Output number is of type E164Number, so it's convenient to cast it.
    //https://github.com/catamphetamine/libphonenumber-js/blob/master/types.d.ts
    return formattedPhoneNumber ? String(formattedPhoneNumber.number) : "";
  } catch (error: any) {
    return "";
  }
};

export const formatPhoneNumberIntoNational = (
  phoneNumber: string,
  countryCode?: CountryCode
) => {
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(
      phoneNumber,
      countryCode || "US"
    );
    return formattedPhoneNumber
      ? String(formattedPhoneNumber.formatNational())
      : "";
  } catch (error: any) {
    return "";
  }
};

export const getCountryCodeFromPhoneNumber = (phoneNumber: string) => {
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(phoneNumber);
    return formattedPhoneNumber
      ? String(formattedPhoneNumber.country).toLowerCase()
      : "";
  } catch (error: any) {
    return "";
  }
};

export const isDripWithActiveDripsRunning = (drip: any) =>
  drip.activeDrips.length > 0
    ? drip.activeDrips.filter(
        (activeDrip: any) =>
          activeDrip.status === "playing" || activeDrip.status === "paused"
      ).length !== 0
    : false;

export const dripsWithoutActiveDripsRunning = (drips: any) =>
  drips && drips.length > 0
    ? drips
        .filter(
          (drip: any) => drip.id !== 0 && !isDripWithActiveDripsRunning(drip)
        )
        .map((drip: any) => drip.id)
        .sort()
    : [];

//https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
export const groupBy = (items: any, key: string) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const getInitials = (name: string) =>
  name
    ? name
        .trim()
        .split(" ")
        .map((n: string) => (n && n.length ? n[0].toUpperCase() : ""))
    : "";

export const capitalizeInitials = (words: string) =>
  words
    .trim()
    .split(" ")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const createRandomString = (
  length: number = 10,
  characters = "abcdefghjkmnpqrstuvwxyz23456789"
): string => {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getLeadSource = (lead: any): string => {
  if (lead.source) {
    return lead.source
      .trim()
      .split(" ")
      .map((n: string) =>
        n && n.length ? n[0].toUpperCase() + n.slice(1) + " " : ""
      );
  } else {
    if (lead.fromLandingPageId === 0) {
      return "Manual";
    } else {
      return "Landing Page";
    }
  }
};

// is this stuff really needed anymore ? React18 dropped support to IE
declare global {
  interface Document {
    documentMode?: any;
  }
}
export const isIE = () => !!document.documentMode;

// Google Maps Zip Code Conversion to Lat Long
export const zipCodeToCoordinates = (zipCode: number) => {
  const getGoogleObject = () => {
    const { google } = window as any;
    return google;
  };
  const google = getGoogleObject();
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: zipCode }, (results: any, status: string) => {
      if (status == google.maps.GeocoderStatus.OK) {
        resolve(results[0].geometry.location);
        return;
      }
      reject(status);
    });
  });
};

// formatter for parsing currency
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

// formatter for parsing number only
export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
});

// formatter for elapsed seconds
export const secondsToFormattedTime = (secs: number) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
  return obj;
};

export const chunkArray = (arr: any[], sizeOfChunks: number) =>
  Array.from({ length: Math.ceil(arr.length / sizeOfChunks) }, (v, i) =>
    arr.slice(i * sizeOfChunks, i * sizeOfChunks + sizeOfChunks)
  );

export const relocationStatuses = [
  {
    name: "estimateRequested",
    label: "Estimate Requested",
    stepperStep: 0,
    messageForUserStepper: "Your estimate request has been received.",
    messageForSuperUserStepper: "Agent Requested Estimate",
  },
  {
    name: "estimateSent",
    label: "Estimate Sent",
    stepperStep: 1,
    messageForUserStepper: "Your move estimate is available!",
    messageForSuperUserStepper:
      "Estimate Sent to Agent. Waiting for Relocation confirmation by Agent",
  },
  {
    name: "abortedByAgent",
    label: "Aborted by Agent",
    stepperStep: -1,
    messageForUserStepper: "You aborted this move",
    messageForSuperUserStepper: "Aborted by Agent",
  },
  {
    name: "relocationConfirmed",
    label: "Relocation Confirmed",
    stepperStep: 2,
    messageForUserStepper:
      "Move Confirmed! An agent will reach out to you shortly.",
    messageForSuperUserStepper:
      "Relocation Confirmed. Waiting for Relocator to start relocation. Pending start...",
  },
  {
    name: "relocationStarted",
    label: "Relocation Started",
    stepperStep: 3,
    messageForUserStepper: "Relocation In Progress...",
    messageForSuperUserStepper: "Relocation Started. Ongoing. In progress...",
  },
  {
    name: "relocationCompleted",
    label: "Relocation Completed",
    stepperStep: 4,
    messageForUserStepper: "Congratulations! Your move is complete!",
    messageForSuperUserStepper: "Relocation Completed. Finished.",
  },
  {
    name: "abortedByRelocator",
    label: "Aborted by Relocator",
    stepperStep: -1,
    messageForUserStepper: "Aborted by Relocation Agent",
    messageForSuperUserStepper: "Aborted by Relocator",
  },
];

// dictionary taken from here:
// https://gist.github.com/calebgrove/c285a9510948b633aa47
export const statesDictionary = {
  __: "undefined",
  AL: "Alabama",
  AK: "Alaska",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
} as { [index: string]: string };

// standard: ISO 3166-1 alpha-2
export const countriesDictionary = [
  {
    countryCode: "__",
    name: "undefined",
    dialCode: "",
  },
  {
    countryCode: "AU",
    name: "Australia",
    dialCode: "61",
  },
  {
    countryCode: "CA",
    name: "Canada",
    dialCode: "1",
  },
  {
    countryCode: "IT",
    name: "Italy",
    dialCode: "39",
  },
  {
    countryCode: "MX",
    name: "Mexico",
    dialCode: "52",
  },

  {
    countryCode: "ES",
    name: "Spain",
    dialCode: "34",
  },
  {
    countryCode: "US",
    name: "USA",
    dialCode: "1",
  },
];
