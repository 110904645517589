// DOM sanitization - there are 3 main libraries to sanitize html:
// https://medium.com/@rezaduty/how-to-write-secure-code-in-react-937579011d3c
// https://github.com/cure53/DOMPurify
// https://github.com/leizongmin/js-xss
// https://github.com/YahooArchive/xss-filters
import dompurify from "dompurify";
import { parseAddresses } from "mimelib";
import config from "../config";
const environment = config.reactAppKlevrEnv;

const { htmlToText } = require("html-to-text");

export const availableMergeFields = [
  { key: "city", value: "[city]", label: "City" },
  { key: "cloudCMALink", value: "[cloudCMALink]", label: "CloudCMA Link" },
  { key: "email", value: "[email]", label: "Email" },
  { key: "firstname", value: "[firstname]", label: "First Name" },
  { key: "name", value: "[name]", label: "Full Name" },
  { key: "route", value: "[route]", label: "Route" },
  { key: "state", value: "[state]", label: "State" },
  { key: "userFirstName", value: "[userFirstName]", label: "User First Name" },
  { key: "userLastName", value: "[userLastName]", label: "User Last Name" },
  { key: "userCompanyName", value: "[userCompanyName]", label: "Company Name" },
  { key: "zipcode", value: "[zipcode]", label: "Zip Code" },
  { key: "month", value: "[month]", label: "Month during which text is sent" },
];

export const areValidMergeFields = (textBody: string) => {
  //CHECKS:
  // -number of "[" and "]" is equal?
  // -are there unwanted nested parenthesis?
  // -is the field inside [] a recognized one?
  // -if it is not recognized, has there been a HTML formatting abuse?

  const str = textBody;
  let leftParenthesisIndices = [];
  let rightParenthesisIndices = [];
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "[") leftParenthesisIndices.push(i);
    if (str[i] === "]") rightParenthesisIndices.push(i);
  }

  let len = leftParenthesisIndices.length;

  //check if number of "[" and "]" is equal
  if (leftParenthesisIndices.length !== rightParenthesisIndices.length) {
    return {
      areValid: false,
      error: "Parenthesis misalignement in merge field application",
    };
  } else {
    //check if there are unwanted nested parenthesis
    //test is passed if every one '[' is then followed by a ']'
    let arr1 = [],
      arr2 = [];
    for (let i = 0; i < len; i++) {
      arr1.push(leftParenthesisIndices[i]);
      arr1.push(rightParenthesisIndices[i]);
    }

    arr2 = leftParenthesisIndices
      .concat(rightParenthesisIndices)
      .sort((a, b) => a - b);

    for (let i = 0; i < len * 2; i++) {
      if (arr1[i] !== arr2[i]) {
        return {
          areValid: false,
          error: "Parenthesis misalignement in merge field application",
        };
      }
    }
  }

  let mergeFields = [];
  for (let i = 0; i < len; i++) {
    mergeFields[i] = str
      .substring(leftParenthesisIndices[i] + 1, rightParenthesisIndices[i])
      .toLowerCase();
  }

  for (const field of mergeFields) {
    if (field.length === 0)
      return {
        areValid: false,
        error: "Empty merge field found",
      };

    if (
      !field.match(
        new RegExp(
          `^(${availableMergeFields.map((item) => item.key).join("|")})$`,
          "i"
        )
      )
    ) {
      if (field.includes("<") || field.includes(">")) {
        return {
          areValid: false,
          error: "Merge field improperly formatted or abuse of HTML syntax",
        };
      } else {
        return {
          areValid: false,
          error: "One or more merge fields is not valid",
        };
      }
    }
  }

  return { areValid: true, error: "" };
};

export const parseSenderFromEmail = (email: any) => {
  //https://stackoverflow.com/questions/9475881/how-can-i-properly-parse-an-email-address-with-name
  try {
    const parsed = parseAddresses(email.from)[0];
    email.senderName = parsed.name;
    email.senderEmail = parsed.address;
    return email;
  } catch (error: any) {
    return email;
  }
};

export const sanitizeDesign = (design: any) => {
  return design && Object.keys(design).length !== 0 ? design : null;
};

export const htmlToPlainText = (html: string) => {
  // The most popular library for extracting text from HTML is html-to-text,
  // It's good also because it extracts links from <a> in a good way
  // this is a feature found also in textversionjs.
  //
  // html-to-text:
  // https://github.com/html-to-text/node-html-to-text
  //
  // Other libraries are:
  // html2plaintext, textract, textversionjs, unfluff
  //
  // For HTML entities use HE
  // https://stackoverflow.com/questions/40263803/native-javascript-or-es6-way-to-encode-and-decode-html-entities/57702435#57702435
  // https://github.com/mathiasbynens/he
  //
  // This is a very interesting discussion about the problem, with various solutions,
  // including some solutions using DOMParser() and document.
  // https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/
  // https://stackoverflow.com/a/8803657
  //
  // Other solutions:
  // https://dev.to/sanchithasr/3-ways-to-convert-html-text-to-plain-text-52l8

  const sanitizedHtml = dompurify.sanitize(html);
  const text = htmlToText(sanitizedHtml, {
    wordwrap: 130,
  });
  return text;
};

export const quoteEmailText = (text: string, user?: any) => {
  let userSignature =
    user?.isEmailSignatureEnabled && user?.emailSignature
      ? user?.emailSignature
      : "";
  let quotedText =
    "\n\n\n" + userSignature + "\n>" + text.replace(/\n/g, "\n> ");
  return quotedText;
};

export const quoteEmailHtml = (html: string, user?: any) => {
  let userSignature =
    user?.isEmailSignatureEnabled && user?.emailSignature
      ? `<p>${("\n\n\n" + user?.emailSignature).replace(/\n/g, "<br>")}</p>`
      : "";

  // The <body> tag can also have props, hence a regexp is required. Example:
  // `<body text="#000000" bgcolor="#FFFFFF">`.
  const isBodyTagPresent =
    html.search(new RegExp("<body(.*?)>")) === -1 ? false : true;

  let quotedHtml = "<p> </p>";

  if (isBodyTagPresent) {
    quotedHtml = html
      .replace(
        new RegExp("<body(.*?)>"),
        `$&` + // In str.replace(), "$&" is the matched string
          userSignature +
          '<p></p><blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">'
      )
      .replace("</body>", "</blockquote><p></p></body>");
  } else {
    quotedHtml =
      userSignature +
      '<p></p><blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">' +
      html +
      "</blockquote><p></p>";
  }

  return quotedHtml;
};

export const generateMailUsername = (user: any) => {
  if (user.customMailDomainFromSendGrid) {
    let aliasName =
      user.customMailAliasesForSendGrid.find((alias: any) => alias.isPrimary)
        ?.name || "";

    return aliasName;
  } else {
    return `${user.appEmailUsername}`;
  }
};

export const generateMailDomain = (user: any) => {
  if (user.customMailDomainFromSendGrid) {
    return `${user.customMailDomainFromSendGrid}`;
  } else {
    return environment === "production"
      ? `klevrmail.com`
      : environment === "staging"
      ? `outboundmail-staging.dev.klevrmail.com`
      : environment === "development"
      ? `outboundmail-dev.dev.klevrmail.com`
      : environment === "local"
      ? `outboundmail-dev.dev.klevrmail.com`
      : "";
  }
};

export const generateSendGridInboundParse = () => {
  return environment === "production"
    ? `https://app.klevrleads.com/api/emails/webhook-sendgrid-inbound-parse`
    : environment === "staging"
    ? `https://staging-app.klevrleads.com/api/emails/webhook-sendgrid-inbound-parse`
    : environment === "development"
    ? `https://dev-app.klevrleads.com/api/emails/webhook-sendgrid-inbound-parse`
    : environment === "local"
    ? `https://dev-app.klevrleads.com/api/emails/webhook-sendgrid-inbound-parse`
    : "";
};

export const generateSendGridEventWebhook = () => {
  return environment === "production"
    ? `https://app.klevrleads.com/api/emails/webhook-sendgrid-email-events`
    : environment === "staging"
    ? `https://staging-app.klevrleads.com/api/emails/webhook-sendgrid-email-events`
    : environment === "development"
    ? `https://dev-app.klevrleads.com/api/emails/webhook-sendgrid-email-events`
    : environment === "local"
    ? `https://dev-app.klevrleads.com/api/emails/webhook-sendgrid-email-events`
    : "";
};
