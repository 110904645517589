import httpService from "./http-service";

import { UserInterface } from "../common-interfaces/user";
import {
  CampaignInterface,
  DirectMailCampaignInterface,
  FilePurpose,
  PostcardmaniaRecipientForm,
} from "../common-interfaces/interfaces";
import { ListInterface } from "../common-interfaces/lists";

const mapResponseToPayload = (res: any) => res.data;

const { axios, axiosWithoutInterceptors } = httpService;

export default {
  activeDrips: {
    createActiveDrips: (leadIds: number[], dripId: number) =>
      axios
        .post("/api/activeDrips", { leadIds, dripId })
        .then(mapResponseToPayload),
    updateActiveDripStatus: (activeDripId: number, action: string) =>
      axios
        .put("/api/activeDrips", { activeDripId, action })
        .then(mapResponseToPayload),
    deleteActiveDrips: (activeDripIds: number[]) =>
      axios
        .delete("/api/activeDrips", { data: { activeDripIds } })
        .then(mapResponseToPayload),
  },

  admin: {
    updateTransactionForAdmin: (transactionForm: any) =>
      axios
        .put("/api/admin/transaction", {
          transactionForm,
        })
        .then(mapResponseToPayload),
    fetchTransactionsForAdmin: () =>
      axios.get(`/api/admin/transactions`).then(mapResponseToPayload),
    cloneTemplates: (operation: string, templateIds: number[]) =>
      axios
        .post("/api/admin/clone-templates", { operation, templateIds })
        .then(mapResponseToPayload),
    cloneDrips: (operation: string, dripIds: number[]) =>
      axios
        .post("/api/admin/clone-drips", { operation, dripIds })
        .then(mapResponseToPayload),
    approveDirectMailCampaigns: (
      directMailCampaignIds: number[],
      isApproving: boolean
    ) =>
      axios
        .put("/api/admin/direct-mail-campaigns/approve", {
          directMailCampaignIds,
          isApproving,
        })
        .then(mapResponseToPayload),
    fetchZipCodesForAdmin: () =>
      axios.get(`/api/admin/zipcodes`).then(mapResponseToPayload),
    updateZipCodeForAdmin: (
      zip: number,
      maxAvailableLicenses: number,
      licenseeId: number,
      freeLicenseesToRemove: number[]
    ) =>
      axios
        .put("/api/admin/zipcodes", {
          zip,
          maxAvailableLicenses,
          licenseeId,
          freeLicenseesToRemove,
        })
        .then(mapResponseToPayload),
    fetchDirectMailCampaigns: () =>
      axios.get("/api/admin/direct-mail-campaigns").then(mapResponseToPayload),
    fetchUsersForAdmin: () =>
      axios.get("/api/admin/users").then(mapResponseToPayload),
    fetchAppStates: () =>
      axios.get("/api/admin/app-states").then(mapResponseToPayload),
    updateUserForAdmin: (updatedUserForm: any) =>
      axios
        .put("/api/admin/user", { updatedUserForm })
        .then(mapResponseToPayload),
    setAppStateWorkerProcessLock: (isLockWorkerProcess: boolean) =>
      axios
        .put("/api/admin/app-states/worker-process-lock", {
          isLockWorkerProcess,
        })
        .then(mapResponseToPayload),
    registerUser: (
      emailAddress: string,
      password: string,
      firstName: string,
      lastName: string,
      appPhoneNumber: string,
      appEmailUsername: string,
      cloudCmaApiKey: string,
      phoneNumber: string,
      companyName: string,
      streetAddress: string,
      city: string,
      state: string,
      zipCode: string,
      license: string,
      hasPaidAccountSetup: boolean,
      hasPaidTrackingWebsiteSetup: boolean
    ) =>
      axiosWithoutInterceptors
        .post("/api/admin/register-user", {
          emailAddress,
          password,
          firstName,
          lastName,
          appPhoneNumber,
          appEmailUsername,
          cloudCmaApiKey,
          phoneNumber,
          companyName,
          streetAddress,
          city,
          state,
          zipCode,
          license,
          hasPaidAccountSetup,
          hasPaidTrackingWebsiteSetup,
        })
        .then(mapResponseToPayload),
    importColeLeads: (
      coleLeads: any[],
      releasedAt: Date,
      listName: string,
      recipientUserIds: number[]
    ) =>
      axios
        .post("/api/data-sources/klevr_farm/import", {
          coleLeads,
          releasedAt,
          listName,
          recipientUserIds,
        })
        .then(mapResponseToPayload),
    runDangerousScripts: () =>
      axios.post("/api/admin/dangerous-scripts", {}).then(mapResponseToPayload),
  },

  campaigns: {
    createDirectMailCampaign: (
      directmailCampaign: Partial<DirectMailCampaignInterface>,
      withPayment: boolean
    ) =>
      axios
        .post("/api/direct-mail-campaigns", {
          directmailCampaign,
          withPayment,
        })
        .then(mapResponseToPayload),

    deleteDirectMailCampaigns: (directMailCampaignIds: number[]) =>
      axios.delete("/api/direct-mail-campaigns", {
        data: { directMailCampaignIds },
      }),
    fetchDirectMailCampaigns: () =>
      axios.get("/api/direct-mail-campaigns").then(mapResponseToPayload),
    updateDirectMailCampaign: (
      directMailCampaignId: number,
      newName: string,
      newScheduledStartDate: string
    ) =>
      axios
        .put("/api/direct-mail-campaigns", {
          directMailCampaignId,
          newName,
          newScheduledStartDate,
        })
        .then(mapResponseToPayload),
    payDirectMailCampaignWithCredits: (directMailCampaignId: number) =>
      axios
        .put("/api/direct-mail-campaigns/pay-with-credits", {
          directMailCampaignId,
        })
        .then(mapResponseToPayload),
    cancelAndRefundDirectMailCampaign: (directMailCampaignId: number) =>
      axios
        .put("/api/direct-mail-campaigns/cancel-and-refund", {
          directMailCampaignId,
        })
        .then(mapResponseToPayload),
    //
    getEstimate: (listId: number) =>
      axios
        .get("/api/direct-mail-campaigns/estimate", {
          params: {
            listId,
          },
        })
        .then(mapResponseToPayload),
    //
    fetchCampaigns: () =>
      axios.get("/api/campaigns").then(mapResponseToPayload),
    createCampaign: (campaign: Partial<CampaignInterface>) =>
      axios
        .post("/api/campaigns", {
          campaign,
        })
        .then(mapResponseToPayload),
    updateCampaign: (campaign: Partial<CampaignInterface>) =>
      axios
        .put("/api/campaigns", {
          campaign,
        })
        .then(mapResponseToPayload),
    deleteCampaigns: (campaignIds: number[]) =>
      axios
        .delete("/api/campaigns", { data: { campaignIds } })
        .then(mapResponseToPayload),
  },

  drips: {
    fetchDrips: () => axios.get("/api/drips").then(mapResponseToPayload),
    createDrip: (drip: any) =>
      axios.post("/api/drips", { ...drip }).then(mapResponseToPayload),
    updateDrip: (drip: any) =>
      axios.put("/api/drips", { ...drip }).then(mapResponseToPayload),
    shareDrips: (operation: string, dripIds: number[]) =>
      axios
        .put("/api/drips/share", { operation, dripIds })
        .then(mapResponseToPayload),
    deleteDrips: (dripIds: number[]) =>
      axios
        .delete("/api/drips", { data: { dripIds } })
        .then(mapResponseToPayload),
  },

  emails: {
    fetchEmails: (startingFrom?: Date) =>
      axios
        .get("/api/emails", {
          params: {
            startingFrom,
            isOnlyReceivedEmails: true,
          },
        })
        .then(mapResponseToPayload),
    fetchSingleEmailAndSetRead: (emailId: number) =>
      axios
        .put("/api/emails/fetch-and-set-read", {
          emailId,
        })
        .then(mapResponseToPayload),
    saveAndSendEmail: (
      leadId: number,
      subject: string,
      bodyInPlainText: string,
      bodyInHtml: string
    ) =>
      axios
        .post("/api/emails", {
          leadId,
          subject,
          bodyInPlainText,
          bodyInHtml,
        })
        .then(mapResponseToPayload),
    deleteEmails: (emailIds: number[]) =>
      axios
        .delete("/api/emails", { data: { emailIds } })
        .then(mapResponseToPayload),
    notifyToAdmin: (notificationType: string, additionalInfo: any) =>
      axios
        .post("/api/emails/notify-to-admin", {
          notificationType,
          additionalInfo,
        })
        .then(mapResponseToPayload),
  },

  landings: {
    fetchLandings: () =>
      axios.get("/api/landing-pages").then(mapResponseToPayload),
    createLanding: (landing: any) =>
      axios
        .post("/api/landing-pages", { ...landing })
        .then(mapResponseToPayload),
    updateLanding: (landing: any) =>
      axios
        .put("/api/landing-pages", { ...landing })
        .then(mapResponseToPayload),
    deleteLandings: (landingIds: number[]) =>
      axios
        .delete("/api/landing-pages", { data: { landingIds } })
        .then(mapResponseToPayload),
  },

  leads: {
    fetchLeadsChunk: (limit: number, offset: number) => {
      const params: { [key: string]: any } = {
        limit,
        offset,
      };
      return axios.get("/api/leads", { params }).then(mapResponseToPayload);
    },
    fetchRecentLeads: (startingFrom?: Date) =>
      axios
        .get("/api/leads/recent", {
          params: {
            startingFrom,
          },
        })
        .then(mapResponseToPayload),
    createLead: (leadForm: any) =>
      axios.post("/api/leads", { leadForm }).then(mapResponseToPayload),
    createLeadView: (leadId: number) =>
      axios.post(`/api/leads/${leadId}/view`).then(mapResponseToPayload),
    updateLead: (leadForm: any) =>
      axios.put("/api/leads", { leadForm }).then(mapResponseToPayload),
    // updateLeadDNC: (leadId: number, newPhoneNumberDNC: boolean) =>
    //   axios
    //     .put("/api/leads/dnc", { leadId, newPhoneNumberDNC })
    //     .then(mapResponseToPayload),
    updateLeadEmails: (leadId: number, address: string, action: string) =>
      axios
        .put("/api/leads/update-emails", { leadId, address, action })
        .then(mapResponseToPayload),
    updateLeadPhoneNumbers: (
      leadId: number,
      number: string,
      action: string,
      newValue?: boolean | string
    ) =>
      axios
        .put("/api/leads/update-phone-numbers", {
          leadId,
          number,
          action,
          newValue,
        })
        .then(mapResponseToPayload),
    updateLeadCloudCMAReport: (leadId: number) =>
      axios.put("/api/leads/cloudcma", { leadId }).then(mapResponseToPayload),
    updateLeadEnhancedInfo: (leadId: number) =>
      axios
        .get(`/api/leads/${leadId}/enhanced_infos`)
        .then(mapResponseToPayload),
    deleteLeads: (leadIds: number[]) =>
      axios
        .delete("/api/leads", { data: { leadIds } })
        .then(mapResponseToPayload),
    countLeads: () => axios.get("/api/leads/count").then(mapResponseToPayload),
    // fetchLeadsStats: () =>
    //   axios.get("/api/leads/stats").then(mapResponseToPayload),
    fetchLeadActivityFeed: (leadId: number, startingFrom?: Date) =>
      axios
        .get("/api/leads/activityfeed", {
          params: {
            leadId,
            startingFrom,
          },
        })
        .then(mapResponseToPayload),
    importBulkLeadsAndStartDrips: (
      leadForms: any[],
      dripId: number,
      tagsToAdd: string[]
    ) =>
      axios
        .post("/api/data-sources/bulk/import", {
          leadForms,
          dripId,
          tagsToAdd,
        })
        .then(mapResponseToPayload),
    importLikelyLeads: (
      likelyLeads: any[],
      releasedAt: Date,
      county: string,
      tagsToAdd: string[],
      listName: string
    ) =>
      axios
        .post("/api/data-sources/likely/import", {
          likelyLeads,
          releasedAt,
          county,
          tagsToAdd,
          listName,
        })
        .then(mapResponseToPayload),
  },

  lists: {
    likelyAiCompleteOperation: (
      listId: number,
      operation: "dbrefresh" | "skiptrace"
    ) =>
      axios
        .post("/api/data-sources/likely-complete-operation", {
          listId,
          operation,
        })
        .then(mapResponseToPayload),
    likelyAiAbortOperation: (
      listId: number,
      operation: "dbrefresh" | "skiptrace"
    ) =>
      axios
        .post("/api/data-sources/likely-abort-operation", {
          listId,
          operation,
        })
        .then(mapResponseToPayload),
    likelyDBRefreshEstimateCost: (listId: number) =>
      axios
        .post("/api/data-sources/likely-dbrefresh-estimate-cost", { listId })
        .then(mapResponseToPayload),
    likelyDBRefreshPayAndProcess: (listId: number) =>
      axios
        .post("/api/data-sources/likely-dbrefresh-pay-and-process", {
          listId,
        })
        .then(mapResponseToPayload),
    likelySkipTraceEstimateCost: (listId: number) =>
      axios
        .post("/api/data-sources/likely-skiptrace-estimate-cost", {
          listId,
        })
        .then(mapResponseToPayload),
    likelySkipTracePayAndProcess: (listId: number) =>
      axios
        .post("/api/data-sources/likely-skiptrace-pay-and-process", { listId })
        .then(mapResponseToPayload),
    //
    fetchLists: (startingFrom?: Date) =>
      axios
        .get("/api/lists", {
          params: {
            startingFrom,
          },
        })
        .then(mapResponseToPayload),
    createList: (list: ListInterface) =>
      axios.post("/api/lists", { ...list }).then(mapResponseToPayload),
    updateList: (list: ListInterface) =>
      axios.put("/api/lists", { ...list }).then(mapResponseToPayload),
    deleteLists: (listIds: number[]) =>
      axios
        .delete("/api/lists", { data: { listIds } })
        .then(mapResponseToPayload),
    splitList: (
      listId: number,
      chunkSize: number,
      prefixName: string,
      prefixDescription: string
    ) =>
      axios
        .post("/api/lists/split", {
          listId,
          chunkSize,
          prefixName,
          prefixDescription,
        })
        .then(mapResponseToPayload),
  },

  notes: {
    createNote: (leadId: number, noteText: string) =>
      axios.post("/api/notes", { noteText, leadId }).then(mapResponseToPayload),
    deleteNote: (noteId: number) =>
      axios
        .delete("/api/notes", { data: { noteId } })
        .then(mapResponseToPayload),
    updateNote: (noteId: number, noteText: string) =>
      axios.put("/api/notes", { noteId, noteText }).then(mapResponseToPayload),
  },

  payments: {
    createStripeCustomerPortalSession: (
      stripeCustomerId: string,
      returnUrlType: string,
      stripeSubscriptionIdForPlan?: string,
      updateTowardsTier?: string
    ) =>
      axios
        .post("/api/payments/stripe-customer-portal-session", {
          stripeCustomerId,
          returnUrlType,
          stripeSubscriptionIdForPlan,
          updateTowardsTier,
        })
        .then(mapResponseToPayload),
    createStripeCheckoutSession: (
      userId: number,
      cart: any,
      directMailCampaignIdUnderPayment: number,
      promotionCodeFromUser: string
    ) =>
      axios
        .post("/api/payments/stripe-checkout-session", {
          userId,
          cart,
          directMailCampaignIdUnderPayment,
          promotionCodeFromUser,
        })
        .then(mapResponseToPayload),
    retrieveStripeData: () =>
      axios
        .get("/api/payments/stripe-retrieve-data")
        .then(mapResponseToPayload),
  },

  postcards: {
    createPostcardTemplate: (
      templateName: string,
      designId: number,
      postcardForm: PostcardmaniaRecipientForm,
      proofFrontsideUrl: string,
      proofBacksideUrl: string
    ) =>
      axios
        .post("/api/postcards", {
          templateName,
          designId,
          postcardForm,
          proofFrontsideUrl,
          proofBacksideUrl,
        })
        .then(mapResponseToPayload),
    updatePostcardTemplate: (
      postcardTemplateId: number,
      newTemplateName: string
    ) =>
      axios
        .put("/api/postcards", {
          postcardTemplateId,
          newTemplateName,
        })
        .then(mapResponseToPayload),
    deletePostcardTemplates: (templateIds: number[]) =>
      axios.delete("/api/postcards", {
        data: {
          templateIds,
        },
      }),
    fetchPostcardmaniaDesigns: () =>
      axios
        .get("/api/postcards/postcardmania-designs")
        .then(mapResponseToPayload),
    fetchPostcardTemplates: () =>
      axios.get("/api/postcards").then(mapResponseToPayload),
    generatePostcardmaniaProofs: (designId: number, postcardForm: any) =>
      axios
        .post("/api/postcards/postcardmania-proofs", {
          designId,
          postcardForm,
        })
        .then(mapResponseToPayload),
  },

  postcards2: {
    createPostcardTemplate: (
      templateName: string,
      designId: number,
      postcardForm: PostcardmaniaRecipientForm,
      proofFrontsideUrl: string,
      proofBacksideUrl: string
    ) =>
      axios
        .post("/api/postcards", {
          templateName,
          designId,
          postcardForm,
          proofFrontsideUrl,
          proofBacksideUrl,
        })
        .then(mapResponseToPayload),
    updatePostcardTemplate: (
      postcardTemplateId: number,
      newTemplateName: string
    ) =>
      axios
        .put("/api/postcards", {
          postcardTemplateId,
          newTemplateName,
        })
        .then(mapResponseToPayload),
    deletePostcardTemplates: (templateIds: number[]) =>
      axios.delete("/api/postcards", {
        data: {
          templateIds,
        },
      }),
    fetchPostcardmaniaDesigns: () =>
      axios
        .get("/api/postcards/postcardmania-designs")
        .then(mapResponseToPayload),
    fetchPostcardTemplates: () =>
      axios.get("/api/postcards").then(mapResponseToPayload),
    generatePostcardmaniaProofs: (designId: number, postcardForm: any) =>
      axios
        .post("/api/postcards/postcardmania-proofs", {
          designId,
          postcardForm,
        })
        .then(mapResponseToPayload),
  },

  sms: {
    fetchSmsConversations: (startingFrom?: Date) =>
      axios
        .get("/api/sms/sms-conversations", {
          params: {
            startingFrom,
          },
        })
        .then(mapResponseToPayload),
    fetchSingleSmsConversation: (phoneNumber: string) =>
      axios
        .get("/api/sms/sms-single-conversation", {
          params: {
            phoneNumber,
          },
        })
        .then(mapResponseToPayload),
    sendSmsMessage: (toPhoneNumber: string, message: string, leadId: number) =>
      axios
        .post("/api/sms", {
          toPhoneNumber,
          message,
          leadId,
        })
        .then(mapResponseToPayload),
    setSmsMessagesAsRead: (phoneNumber: string) =>
      axios
        .put("/api/sms/set-read", { phoneNumber })
        .then(mapResponseToPayload),
  },

  superuser: {
    updateRelocationForSuperuser: (relocationForm: any) =>
      axios
        .put("/api/superuser/relocation", {
          relocationForm,
        })
        .then(mapResponseToPayload),
    fetchRelocationsForSuperUser: () =>
      axios.get(`/api/superuser/all-relocations`).then(mapResponseToPayload),
  },

  templates: {
    // EMAILS
    fetchEmailTemplates: () =>
      axios.get("/api/templates/email").then(mapResponseToPayload),
    createEmailTemplate: (templateForm: any) =>
      axios
        .post("/api/templates/email", templateForm)
        .then(mapResponseToPayload),
    duplicateEmailTemplates: (templateIds: number[]) =>
      axios
        .post("/api/templates/email/duplicate", {
          templateIds,
        })
        .then(mapResponseToPayload),
    updateEmailTemplate: (templateForm: any) =>
      axios
        .put("/api/templates/email", { templateForm })
        .then(mapResponseToPayload),
    shareEmailTemplates: (operation: string, templateIds: number[]) =>
      axios
        .put("/api/templates/email/share", { operation, templateIds })
        .then(mapResponseToPayload),
    deleteEmailTemplates: (templateIds: number[]) =>
      axios
        .delete("/api/templates/email", {
          data: {
            templateIds,
          },
        })
        .then(mapResponseToPayload),

    // SMS
    fetchSmsTemplates: () =>
      axios.get("/api/templates/sms").then(mapResponseToPayload),
    createSmsTemplate: (templateForm: any) =>
      axios.post("/api/templates/sms", templateForm).then(mapResponseToPayload),
    updateSmsTemplate: (templateForm: any) =>
      axios
        .put("/api/templates/sms", { templateForm })
        .then(mapResponseToPayload),
    shareSmsTemplates: (operation: string, templateIds: number[]) =>
      axios
        .put("/api/templates/sms/share", { operation, templateIds })
        .then(mapResponseToPayload),
    deleteSmsTemplates: (templateIds: number[]) =>
      axios
        .delete("/api/templates/sms", {
          data: {
            templateIds,
          },
        })
        .then(mapResponseToPayload),
  },

  tags: {
    createTagsForUser: (tags: string[]) =>
      axios.post("/api/tags/user", { tags }).then(mapResponseToPayload),
    updateTagForUser: (oldTag: string, newTag: string) =>
      axios
        .put("/api/tags/user", { oldTag, newTag })
        .then(mapResponseToPayload),
    deleteTagsForUser: (tags: string[]) =>
      axios
        .delete("/api/tags/user", { data: { tags } })
        .then(mapResponseToPayload),
    createTagsForLeads: (leadIds: number[], tags: string[]) =>
      axios
        .post("/api/tags/leads", { leadIds, tags })
        .then(mapResponseToPayload),
    deleteTagsForLead: (leadIds: number[], tags: string[]) =>
      axios
        .delete("/api/tags/leads", { data: { leadIds, tags } })
        .then(mapResponseToPayload),
  },

  uploads: {
    uploadFileToS3: (presignedS3Url: string, file: File) =>
      axios.put(presignedS3Url, file, {
        headers: {
          "Content-Type": file.type,
        },
      }),
    createPresignedS3Url: (
      fileName: string,
      fileType: string,
      filePurpose: FilePurpose
    ) =>
      axios
        .post("/api/users/s3-url", { fileName, fileType, filePurpose })
        .then(mapResponseToPayload),
    fileUploadedPostprocessing: (fileName: string, filePurpose: FilePurpose) =>
      axios
        .post("/api/users/file-uploaded-postprocessing", {
          fileName,
          filePurpose,
        })
        .then(mapResponseToPayload),
  },

  users: {
    fetchTransactionsForUser: () =>
      axios.get(`/api/users/transactions`).then(mapResponseToPayload),
    updateUserAliasForSendGrid: (aliasName: string, action: string) =>
      axios
        .put("/api/users/update-user-aliases-for-sendgrid", {
          aliasName,
          action,
        })
        .then(mapResponseToPayload),

    updateUserBusinessProfile: (businessProfileForm: any) =>
      axios
        .put("/api/users/business-profile", {
          businessProfileForm,
        })
        .then(mapResponseToPayload),

    updateUserSettings: (userAttributesEdited: Partial<UserInterface>) =>
      axios
        .put("/api/users/settings", {
          userAttributesEdited,
        })
        .then(mapResponseToPayload),

    retrievePublicProfileData: (
      searchedProfileIdentifier: string,
      askingUserId?: number | null
    ) =>
      axios
        .get("/api/users/public", {
          params: {
            searchedProfileIdentifier,
            askingUserId,
          },
        })
        .then(mapResponseToPayload), // this does not require authentication

    fetchUser: () => axios.get(`/api/users/user`).then(mapResponseToPayload),

    changeUserPassword: (currentPassword: string, newPassword: string) =>
      axios
        .put("/api/users/password", {
          currentPassword,
          newPassword,
        })
        .then(mapResponseToPayload),

    register: (
      emailAddress: string,
      password: string,
      firstName: string,
      lastName: string,
      appEmailUsername: string,
      phoneNumber: string,
      companyName: string,
      streetAddress: string,
      city: string,
      state: string,
      zipCode: string,
      country: string,
      license: string,
      noteText: string
    ) =>
      axiosWithoutInterceptors
        .post("/api/users/register", {
          emailAddress,
          password,
          firstName,
          lastName,
          appEmailUsername,
          phoneNumber,
          companyName,
          streetAddress,
          city,
          state,
          zipCode,
          country,
          license,
          noteText,
        })
        .then(mapResponseToPayload),

    login: (emailAddress: string, password: string) =>
      axiosWithoutInterceptors
        .post("/api/users/login", {
          emailAddress,
          password,
        })
        .then(mapResponseToPayload),

    logout: () => axios.post("/api/users/logout", {}),

    authenticateUserWithSessionCookie: () =>
      axios.post("/api/users/validate", {}).then(mapResponseToPayload),
  },

  voice: {
    getTwilioToken: () =>
      axios.get("/api/voice/token").then((res: any) => res.data),
    fetchCallLogs: (startingFrom?: Date) =>
      axios
        .get("/api/voice/call-logs", {
          params: {
            startingFrom,
          },
        })
        .then(mapResponseToPayload),
    deleteVoiceMessages: (callLogId: number) =>
      axios
        .delete("/api/voice/voice-messages", {
          data: {
            callLogId,
          },
        })
        .then(mapResponseToPayload),
    createCallLog: (
      leadId: number | null,
      inboundCallSid: string,
      fromPhoneNumber: string,
      toPhoneNumber: string,
      noteText: string
    ) =>
      axios
        .post("/api/voice", {
          leadId,
          inboundCallSid,
          fromPhoneNumber,
          toPhoneNumber,
          noteText,
        })
        .then((res: any) => res.data),
    updateCallLogNoteText: (
      leadId: number,
      inboundCallSid: string,
      noteText: string
    ) =>
      axios
        .post("/api/voice/addnote", {
          leadId,
          inboundCallSid,
          noteText,
        })
        .then((res: any) => res.data),
    sendRecordedMessage: (leadId: number, inboundCallSid: string) =>
      axios
        .put("/api/voice/uservoicemessage2lead", {
          leadId,
          inboundCallSid,
        })
        .then(mapResponseToPayload),
    forwardCallToUserNumber: (
      inboundCallSid: string,
      callType: string,
      leadId?: number
    ) =>
      axios
        .put("/api/voice/forwardcall", {
          inboundCallSid,
          callType,
          leadId,
        })
        .then(mapResponseToPayload),
    forwardCallToVoiceMail: (inboundCallSid: string) =>
      axios
        .put("/api/voice/voicemail", { inboundCallSid })
        .then(mapResponseToPayload),
  },

  websites: {
    retrieveWebflowData: () =>
      axios
        .get("/api/websites/webflow-retrieve-data")
        .then(mapResponseToPayload),

    updateCollectionItemLive: (
      collectionId: string,
      itemId: string,
      collectionItemFields: any,
      state: any
    ) =>
      axios
        .patch(`/api/websites/collections/${collectionId}/items/${itemId}`, {
          collectionItemFields,
          state,
        })
        .then(mapResponseToPayload),

    updateCollectionItem: (state: any) =>
      axios
        .put(
          `/api/websites/collections/${state.collectionId}/items/${state.itemId}`,
          { state }
        )
        .then(mapResponseToPayload),

    publishSite: (siteId: string, state: any, domains: string[]) =>
      axios
        .post(`/api/websites/sites/${siteId}/publish`, {
          state,
          domains,
        })
        .then(mapResponseToPayload),
  },

  zipcodes: {
    updateRelocationForUser: (relocationForm: any) =>
      axios
        .put("/api/zipcodes/relocation", {
          relocationForm,
        })
        .then(mapResponseToPayload),
    fetchRelocations: () =>
      axios.get(`/api/zipcodes/relocations`).then(mapResponseToPayload),
    createRelocation: (relocationForm: any) =>
      axios
        .post("/api/zipcodes/relocation", { relocationForm })
        .then(mapResponseToPayload),
    searchZipCodesOwners: (searchQuery: any) =>
      axios
        .get("/api/zipcodes/search", {
          params: {
            cityToSearch: searchQuery.cityToSearch || "",
            stateToSearch: searchQuery.stateToSearch || "",
            zipCodeToSearch: searchQuery.zipCodeToSearch || "",
            availabilityToSearch: searchQuery.availabilityToSearch || "",
          },
        })
        .then(mapResponseToPayload),
    checkZipCodeAvailability: (zip: string) =>
      axios
        .get("/api/zipcodes/availability", {
          params: {
            zip,
          },
        })
        .then(mapResponseToPayload),
    retrievePulicZipCodeData: (zip: string) =>
      axios
        .get("/api/zipcodes/public", {
          params: {
            zip,
          },
        })
        .then(mapResponseToPayload), // this does not require authentication

    fetchZipCodes: () => axios.get(`/api/zipcodes`).then(mapResponseToPayload),
  },

  mockup: {
    mock: (input: any) =>
      new Promise((resolve, reject) => {
        console.log(input);
        let error = false;
        if (!error) {
          let res = {
            success: true,
            messsage: "everything fine",
          };
          resolve(res);
        } else {
          //let err = new Error("Wrong username/password");
          let err = {
            success: false,
            message: "awful error",
          };
          reject(err);
        }
      }),
  },
};
