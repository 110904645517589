import { UserInterface } from "./user";

export const PollingIntervalTypes = {
  INBOX_SMS: 1000 * 45, // production setting must be: 45 seconds
  INBOX_EMAILS: 1000 * 60 * 10,
  INBOX_CALLLOGS: 1000 * 60 * 5, // 5 minutes
  LISTS_FETCH: 1000 * 60 * 5, // 5 minutes
  LEADS_FETCH: 1000 * 60 * 15, // production setting must be: 3 minutes
  ACTIVITY_FEED: 1000 * 60 * 2,
  TWILIO_TOKEN: 1000 * 60 * 60, // 1 hour
};

export interface ReduxAction {
  payload: any;
  type: string;
}

export const voidDrip = {
  id: 0,
  isPublic: false,
  name: "- no drip -",
  steps: [],
};

export const voidLanding = {
  id: 0,
  name: "- no landing page -",
};

export const voidEmailTemplate: EmailTemplate = {
  id: 0,
  isPublic: false,
  name: "- select template -",
  subject: "",
  body: "",
  html: "<p></p>",
  designJson: null,
  numSent: 0,
};

export const voidSmsTemplate: SmsTemplate = {
  id: 0,
  isPublic: false,
  name: "- select template -",
  body: "",
  numSent: 0,
};

export type FilePurpose =
  | "user-voicemessage"
  | "user-voicemail"
  | "user-logo"
  | "user-profileimage"
  | "email-attachment"
  | "postcardmania-logo"
  | "postcardmania-profileimage"
  | "postcardmania-backgroundimage"
  | "postcardmania-interiorimage1";

export type PostcardmaniaSizeKey =
  | "46"
  | "68"
  | "611"
  | "425x6FirstClass"
  | "4x6FirstClass"
  | "5x7FirstClass"
  | "5x7Standard"
  | "6x11FirstClass"
  | "6x11Standard"
  | "6x85FirstClass"
  | "6x85Standard"
  | "LetterStandard";

export interface PostcardmaniaDesign {
  approvalDate: string;
  bAccount: number;
  createdDate: string;
  customName?: string;
  designFields: any[];
  designId: number;
  designIndustry: string | null;
  friendlyName?: string;
  lastModifiedDate: string;
  mailClasses: any[];
  proofBack: string;
  proofFront: string;
  sizes: any[];
  _size: PostcardmaniaSizeKey;
  _designFieldsNames?: string[];
  _hasConfirmationCode?: boolean;
  _isCustom?: boolean;
}

export interface PostcardmaniaRecipientForm {
  address: string;
  address2: string;
  backgroundimage: string; // base64 string for image
  bathrooms: string;
  bedrooms: string;
  backheadline1: string;
  backheadline2: string;
  bulletpoint1: string;
  bulletpoint2: string;
  bulletpoint3: string;
  bulletpoint4: string;
  city: string;
  companyaddress: string;
  companycity: string;
  companyname: string;
  companystate: string;
  companyurl: string;
  companyzipcode: string;
  confirmationcode: string;
  confirmationurl: string;
  country: string;
  discountdollar: string;
  email: string;
  expiration: string;
  firstname: string;
  frontheadline1: string;
  frontheadline2: string;
  interiorimage1: string; // base64 string for image
  lastname: string;
  logo: string; // base64 string for image
  numberofyears: string; // number of years the realtor has been in business
  officenumber: string;
  openhousedate: string;
  openhousetimes: string;
  phone: string;
  phonenumber: string;
  profileimage: string; // base64 string for image
  propertyaddress: string;
  propertycity: string;
  propertylistedprice: string;
  propertystate: string;
  propertyzip: string;
  shortpara: string;
  state: string;
  subheadline: string;
  testimonial: string;
  titleorlicense: string;
  websiteurl: string;
  yourfirstname: string;
  yourlastname: string;
  zipcode: string;
}

export interface PostcardTemplate {
  id?: number;
  name?: string;
  size: PostcardmaniaSizeKey;
  postcardmaniaDesignId: number;
  postcardmaniaRecipientFields: PostcardmaniaRecipientForm | {};
  proofFrontUrl?: string;
  proofBackUrl?: string;
  hasConfirmationCode?: boolean;
  createdAt?: any;
  updatedAt?: any;
}

export const voidPostcardTemplate: PostcardTemplate = {
  id: 0,
  name: "- select template -",
  postcardmaniaDesignId: 0,
  postcardmaniaRecipientFields: {},
  proofFrontUrl: "",
  proofBackUrl: "",
  size: "46",
  hasConfirmationCode: false,
};

export interface DirectMailCampaignInterface {
  id?: number | undefined;
  name: string;
  size: PostcardmaniaSizeKey;
  costPerPieceToUser: number;
  postcardmaniaRecipientFields: any;
  postcardmaniaRecipientLeads: any;
  postcardmaniaDesignId: number;
  proofFrontUrl: string;
  proofBackUrl: string;
  hasConfirmationCode: boolean;
  costToUser: number;
  refundedToUser: number;
  postcardmaniaTotalPrice: number;
  status:
    | "klevrCreated"
    | "klevrPaidByUser"
    | "klevrApprovedByAdmin"
    | "klevrError" // totally refunded
    | "klevrRejected" // totally refunded
    | "postcardmaniaPending"
    | "postcardmaniaCancelled" // totally refunded - only admin can make this case happen
    | "postcardmaniaRequiresPayment" // order stays in limbo until admin allows payment
    | "postcardmaniaProcessing"
    | "postcardmaniaMailing"
    | "postcardmaniaDelivered" // partial refund
    | "postcardmaniaUndeliverable"; // no total refund: order stays in limbo until unblocked
  postcardmaniaOrderId: number;
  postcardmaniaBatchId: number;
  scheduledStartDate: Date;
  postcardmaniaStartedElaborationAt: Date;
  postcardmaniaDeliveryCompletedAt: Date;
  canceledAt: Date;
  userId: number;
  directMailTemplateId: number | null;
  listId: number | null;
  isReplacingNamesWithCurrentOwner: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface CampaignInterface {
  id?: number | undefined;
  userId: number;
  name: string;
  scheduledStartDateInUTCWithoutTimezone: Date | null;
  scheduledStartDateInUTCWithTimezone: Date | null;
  timezone: string;
  listId: number | null;
  type: string;
  templateId?: number | null;
  smsTemplateId?: number | null;
  emailTemplateId?: number | null;
  deliveredAt: Date;
  numSuccessfulDeliveries: number;
  numOpens: number;
  numClicks: number;
  processingJobId: string;
}

export interface LandingInterface {
  userId?: number;
  id?: number;
  name?: string;
  confirmationCode?: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  dripId?: number;
  url?: string;
}

export interface LeadInterface {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  addressRoute: string;
  addressRoute2: string; // second address line
  city: string;
  zipCode: string;
  state: string;
  country: string;
  phoneNumber: string;
  emailAddress: string;
  // Enhancements
  source: null | "landing page" | "website" | "likely ai" | "klevr farm";
  source_id: string | number | null;
  fromLandingPageId: string;
  klevrFarmId: string | number;
  likelyId: string | number;
  source_info?: any;
  klevrfarm_info?: any;
  likely_basic_info?: any;
  likely_dbrefresh_info?: any;
  lastLikelyDBRefreshAt: Date;
  lastLikelySkipTraceAt: Date;
  activeDrips?: any;
  lastViewedAt: Date;
  lastIncomingEmailAt: Date;
  lastIncomingSmsMessageAt: Date;
  createdAt: string;
  updatedAt: string;
}

// not used now but useful for the future
export interface LikelyLeadSourceInfo {
  //#region lead.source_info
  likely_id: string;
  owner1_name_full: string;
  owner1_name_first: string;
  owner1_name_middle: string;
  owner1_name_last: string;
  owner1_name_suffix: string;
  owner2_name_full: string;
  owner2_name_first: string;
  owner2_name_middle: string;
  owner2_name_last: string;
  owner2_name_suffix: string;
  trust_description: string;
  company_flag: string;
  owner_occupied_flag: string;
  property_address_full: string;
  property_address_house_number: string;
  property_address_street_direction: string;
  property_address_street_name: string;
  property_address_street_suffix: string;
  property_address_street_post_direction: string;
  property_address_unit_prefix: string;
  property_address_unit_value: string;
  property_address_city: string;
  property_address_state: string;
  property_address_zip: string;
  contact_owner_mail_address_full: string;
  contact_owner_mail_address_house_number: string;
  contact_owner_mail_address_street_direction: string;
  contact_owner_mail_address_street_name: string;
  contact_owner_mail_address_street_suffix: string;
  contact_owner_mail_address_street_post_direction: string;
  contact_owner_mail_address_unit_prefix: string;
  contact_owner_mail_address_unit: string;
  contact_owner_mail_address_city: string;
  contact_owner_mail_address_state: string;
  contact_owner_mail_address_zip: string;
  property_use: string;
  area_building: string;
  area_lot_sf: string;
  bedrooms_count: string;
  bath_count: string;
  year_built: string;
  year_built_effective: string;
  stories_count: string;
  rooms_count: string;
  parking_garage: string;
  pool: string;
  deed_last_sale_date: string;
  deed_last_sale_price: string;
  tax_year_assessed: string;
  tax_assessed_value_total: string;
  estimated_value: string;
  estimated_min_value: string;
  estimated_max_value: string;
  valuation_date: string;
  confidence_score: string;
  property_latitude: string;
  property_longitude: string;
  current_first_position_open_loan_lender_name_first: string;
  current_first_position_open_loan_lender_name_last: string;
  current_first_position_open_loan_lender_info_entity_classificat: string;
  current_first_position_open_loan_amount: string;
  current_first_position_open_loan_recording_date: string;
  current_first_position_open_loan_type: string;
  ltv: string;
  available_equity: string;
  record_type: string;
  foreclosure_recording_date: string;
  borrower_name_owner: string;
  auction_date: string;
  person1_first_name: string;
  person1_last_name: string;
  person1_inferred_household_rank: string;
  person1_exact_age: string;
  person1_date_of_birth_year: string;
  person1_presence_of_children: string;
  person1_number_of_children: string;
  number_of_persons_in_living_unit: string;
  person1_payday_loan_long_form: string;
  person1_payday_loan_short_form: string;
  person1_debt_consolidation: string;
  person1_credit_repair: string;
  person1_credit_rating: string;
  person1_net_worth: string;
  person1_number_of_lines_of_credit: string;
  person1_single_parent: string;
  person1_delivery_point_validation: string;
  vacant: string;
  person1_gender: string;
  person1_marital_status: string;
  person1_estimated_income: string;
  person1_ethnic_group: string;
  person1_language: string;
  person1_presence_of_credit_card: string;
  person1_presence_of_bank_card: string;
  person1_gaming: string;
  person1_gaming_casino: string;
  person1_education: string;
  person1_occupation: string;
  senior_adult_in_household: string;
  veteran_in_household: string;
  person1_mail_responder: string;
  tax_exemption_disabled_flag: string;
  tax_exemption_senior_flag: string;
  tax_exemption_veteran_flag: string;
  tax_exemption_widow_flag: string;
  person1_most_recent_email: string;
  person1_most_recent_email_date: string;
  person1_url: string;
  person1_landline: string;
  person1_do_not_call_landline: string;
  person1_most_recent_cell_phone: string;
  person1_do_not_call_cell_phone: string;
  person1_most_recent_cell_phone_date: string;
  person2_first_name: string;
  person2_last_name: string;
  person2_inferred_household_rank: string;
  person2_exact_age: string;
  person2_date_of_birth_year: string;
  person2_payday_loan_long_form: string;
  person2_payday_loan_short_form: string;
  person2_debt_consolidation: string;
  person2_credit_repair: string;
  person2_credit_rating: string;
  person2_net_worth: string;
  person2_number_of_lines_of_credit: string;
  person2_single_parent: string;
  person2_delivery_point_validation: string;
  person2_gender: string;
  person2_marital_status: string;
  person2_estimated_income: string;
  person2_ethnic_group: string;
  person2_language: string;
  person2_presence_of_credit_card: string;
  person2_presence_of_bank_card: string;
  person2_gaming: string;
  person2_gaming_casino: string;
  person2_education: string;
  person2_occupation: string;
  person2_mail_responder: string;
  person2_most_recent_email: string;
  person2_most_recent_email_date: string;
  person2_url: string;
  person2_landline: string;
  person2_do_not_call_landline: string;
  person2_most_recent_cell_phone: string;
  person2_do_not_call_cell_phone: string;
  person2_most_recent_cell_phone_date: string;
  releasedAt: string;
  county: string;
  halfExpiryAt: string;
  fullExpiryAt: string;
  //#endregion lead.source_info
}

export interface EmailTemplate {
  id: number;
  isPublic?: boolean;
  name: string;
  subject: string;
  body: string;
  html: string;
  designJson: any;
  numSent: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface SmsTemplate {
  id: number;
  isPublic?: boolean;
  name: string;
  body: string;
  numSent: number;
  createdAt?: string;
  updatedAt?: string;
}

// interfaces for Drips
export const DripActionTypes = {
  SMS: "sms",
  EMAIL: "email",
  PAUSE_ONE: "pause_one",
  PAUSE_ALL: "pause_all",
};

export const TimeUnits = {
  HOURS: "hours",
  MINUTES: "minutes",
  DAYS: "days",
};

export const SMSTemplates = {
  WELCOME: "welcome",
  OTHER: "other",
};

export const EMAILTemplates = {
  CLOUDCMA: "cloudcma",
  OTHER: "other",
};

export interface KlevrdripTime {
  value: number;
  unit: string;
}

export interface KlevrdripStepBase {
  action: string;
  time: KlevrdripTime;
}

export interface KlevrdripStepSMS extends KlevrdripStepBase {
  templateId: number;
}

export interface KlevrdripStepEmail extends KlevrdripStepBase {
  templateId: number;
}

export interface KlevrdripStepPauseOne extends KlevrdripStepBase {}

export interface KlevrdripStepPauseAll extends KlevrdripStepBase {}

export interface KlevrdripStepNone extends KlevrdripStepBase {}

export type DripStep =
  | KlevrdripStepSMS
  | KlevrdripStepEmail
  | KlevrdripStepPauseOne
  | KlevrdripStepPauseAll
  | KlevrdripStepNone;

export interface DripInterface {
  id?: number | undefined;
  isPublic?: boolean;
  activeDrips?: any;
  name: string;
  steps: DripStep[];
}
