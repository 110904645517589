import { push } from "connected-react-router";
import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";
import {
  CampaignInterface,
  DirectMailCampaignInterface,
} from "../../common-interfaces/interfaces";

import { refreshUser } from "../../user/redux/user-actions";

export const TICK_SINGLE_CAMPAIGN = "TICK_SINGLE_CAMPAIGN";
export const TICK_MULTIPLE_CAMPAIGNS = "TICK_MULTIPLE_CAMPAIGNS";
export const CAMPAIGN_CREATE_START = "CAMPAIGN_CREATE_START";
export const CAMPAIGN_CREATE_SUCCESS = "CAMPAIGN_CREATE_SUCCESS";
export const CAMPAIGN_CREATE_FAIL = "CAMPAIGN_CREATE_FAIL";
export const CAMPAIGNS_FETCH_START = "CAMPAIGNS_FETCH_START";
export const CAMPAIGNS_FETCH_SUCCESS = "CAMPAIGNS_FETCH_SUCCESS";
export const CAMPAIGNS_FETCH_FAIL = "CAMPAIGNS_FETCH_SUCCESS";
export const CAMPAIGN_UPDATE_START = "CAMPAIGN_UPDATE_START";
export const CAMPAIGN_UPDATE_SUCCESS = "CAMPAIGN_UPDATE_SUCCESS";
export const CAMPAIGN_UPDATE_FAIL = "CAMPAIGN_UPDATE_FAIL";
export const CAMPAIGNS_DELETE_START = "CAMPAIGNS_DELETE_START";
export const CAMPAIGNS_DELETE_SUCCESS = "CAMPAIGNS_DELETE_SUCCESS";
export const CAMPAIGNS_DELETE_FAIL = "CAMPAIGNS_DELETE_FAIL";
//
export const TICK_SINGLE_DIRECTMAIL_CAMPAIGN =
  "TICK_SINGLE_DIRECTMAIL_CAMPAIGN";
export const TICK_MULTIPLE_DIRECTMAIL_CAMPAIGNS =
  "TICK_MULTIPLE_DIRECTMAIL_CAMPAIGNS";
export const DIRECTMAIL_CAMPAIGNS_FETCH_START =
  "DIRECTMAIL_CAMPAIGNS_FETCH_START";
export const DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS =
  "DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS";
export const DIRECTMAIL_CAMPAIGNS_FETCH_FAIL =
  "DIRECTMAIL_CAMPAIGNS_FETCH_FAIL";
export const DIRECTMAIL_CAMPAIGN_UPDATE_START =
  "DIRECTMAIL_CAMPAIGN_UPDATE_START";
export const DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS =
  "DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS";
export const DIRECTMAIL_CAMPAIGN_UPDATE_FAIL =
  "DIRECTMAIL_CAMPAIGN_UPDATE_FAIL";
export const DIRECTMAIL_CAMPAIGN_CREATE_START =
  "DIRECTMAIL_CAMPAIGN_CREATE_START";
export const DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS =
  "DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS";
export const DIRECTMAIL_CAMPAIGN_CREATE_FAIL =
  "DIRECTMAIL_CAMPAIGN_CREATE_FAIL";
export const DIRECTMAIL_CAMPAIGNS_DELETE_START =
  "DIRECTMAIL_CAMPAIGNS_DELETE_START";
export const DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS =
  "DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS";
export const DIRECTMAIL_CAMPAIGNS_DELETE_FAIL =
  "DIRECTMAIL_CAMPAIGNS_DELETE_FAIL";
export const DIRECTMAIL_CAMPAIGN_PAY_START = "DIRECTMAIL_CAMPAIGN_PAY_START";
export const DIRECTMAIL_CAMPAIGN_PAY_SUCCESS =
  "DIRECTMAIL_CAMPAIGN_PAY_SUCCESS";
export const DIRECTMAIL_CAMPAIGN_PAY_FAIL = "DIRECTMAIL_CAMPAIGN_PAY_FAIL";
export const DIRECTMAIL_CAMPAIGN_CANCEL_START =
  "DIRECTMAIL_CAMPAIGN_CANCEL_START";
export const DIRECTMAIL_CAMPAIGN_CANCEL_SUCCESS =
  "DIRECTMAIL_CAMPAIGN_CANCEL_SUCCESS";
export const DIRECTMAIL_CAMPAIGN_CANCEL_FAIL =
  "DIRECTMAIL_CAMPAIGN_CANCEL_FAIL";

export const updateDirectMailCampaign = (
  directMailCampaignId: number,
  newName: string,
  newScheduledStartDate: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_UPDATE_START,
      });

      const { updatedDirectMailCampaign } =
        await api.campaigns.updateDirectMailCampaign(
          directMailCampaignId,
          newName,
          newScheduledStartDate
        );

      dispatch({
        type: DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS,
        payload: { updatedDirectMailCampaign },
      });

      // fetchDirectMailCampaigns()(dispatch);
      // navigateToDirectMailCampaignManager()(dispatch);
      return { updatedDirectMailCampaign };
      // return true;
    } catch (error: any) {
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_UPDATE_FAIL,
      });
      return { updatedDirectMailCampaign: null };
      // return false;
    }
  };
};

export const cancelAndRefundDirectMailCampaign = (
  directMailCampaignId: number
) => {
  return async (dispatch: Function) => {
    dispatch({
      type: DIRECTMAIL_CAMPAIGN_CANCEL_START,
    });
    try {
      const { cancelledDirectMailCampaign } =
        await api.campaigns.cancelAndRefundDirectMailCampaign(
          directMailCampaignId
        );

      refreshUser()(dispatch);
      await fetchDirectMailCampaigns()(dispatch);

      dispatch({
        type: DIRECTMAIL_CAMPAIGN_CANCEL_SUCCESS,
      });

      return { cancelledDirectMailCampaign };
      // return true;
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_CANCEL_FAIL,
      });
      return { cancelledDirectMailCampaign: null };
      // return false;
    }
  };
};

export const payDirectMailCampaignWithCredits = (
  directMailCampaignId: number
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_PAY_START,
      });

      const { isDirectMailCampaignPaid, paidDirectMailCampaign } =
        await api.campaigns.payDirectMailCampaignWithCredits(
          directMailCampaignId
        );

      if (isDirectMailCampaignPaid) {
        refreshUser()(dispatch);
        await fetchDirectMailCampaigns()(dispatch);
      }

      dispatch({
        type: DIRECTMAIL_CAMPAIGN_PAY_SUCCESS,
      });

      return { isDirectMailCampaignPaid, paidDirectMailCampaign };
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_PAY_FAIL,
      });
      return { isDirectMailCampaignPaid: false, paidDirectMailCampaign: null };
    }
  };
};

export const createDirectMailCampaign = (
  campaign: Partial<DirectMailCampaignInterface>,
  withPayment: boolean
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_CREATE_START,
      });

      const { newDirectMailCampaign } =
        await api.campaigns.createDirectMailCampaign(campaign, withPayment);

      dispatch({
        type: DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS,
        payload: { directMailCampaign: newDirectMailCampaign },
      });

      // navigateToDirectMailCampaignManager()(dispatch);
      return newDirectMailCampaign;
      // return true;
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_CREATE_FAIL,
      });
      return null;
      // return false;
    }
  };
};

export const deleteDirectMailCampaigns = (directMailCampaignIds: number[]) => {
  if (!directMailCampaignIds || !directMailCampaignIds.length) {
    return false;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_DELETE_START,
      });
      const res = await api.campaigns.deleteDirectMailCampaigns(
        directMailCampaignIds
      );
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS,
        payload: {
          directMailCampaignIds,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const fetchDirectMailCampaigns = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_FETCH_START,
      });
      const directMailCampaigns =
        await api.campaigns.fetchDirectMailCampaigns();
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS,
        payload: {
          directMailCampaigns,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: DIRECTMAIL_CAMPAIGNS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const tickSingleDirectMailCampaign = (directMailCampaignId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_DIRECTMAIL_CAMPAIGN,
      payload: {
        directMailCampaignId,
      },
    });
  };
};

export const tickMultipleDirectMailCampaigns = (
  directMailCampaignIds: number[]
) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_DIRECTMAIL_CAMPAIGNS,
      payload: { directMailCampaignIds },
    });
  };
};

// NORMAL CAMPAIGNS
export const createCampaign = (campaign: Partial<CampaignInterface>) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CAMPAIGN_CREATE_START,
      });

      const { newCampaign } = await api.campaigns.createCampaign(campaign);

      dispatch({
        type: CAMPAIGN_CREATE_SUCCESS,
        payload: { campaign: newCampaign },
      });

      // navigateToCampaignManager()(dispatch);
      return newCampaign;
      // return true;
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: CAMPAIGN_CREATE_FAIL,
      });
      return null;
      // return false;
    }
  };
};

export const updateCampaign = (campaign: Partial<CampaignInterface>) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: CAMPAIGN_UPDATE_START,
      });

      const { updatedCampaign } = await api.campaigns.updateCampaign(campaign);

      dispatch({
        type: CAMPAIGN_UPDATE_SUCCESS,
        payload: { updatedCampaign },
      });

      // fetchCampaigns(campaign.userId)(dispatch);
      // navigateToCampaignManager()(dispatch);
      return { updatedCampaign };
      // return true;
    } catch (error: any) {
      dispatch({
        type: DIRECTMAIL_CAMPAIGN_UPDATE_FAIL,
      });
      return { updatedDirectMailCampaign: null };
      // return false;
    }
  };
};

export const deleteCampaigns = (campaignIds: number[]) => {
  return async (dispatch: Function) => {
    if (!campaignIds || !campaignIds.length) {
      return;
    }
    try {
      dispatch({
        type: CAMPAIGNS_DELETE_START,
      });
      await api.campaigns.deleteCampaigns(campaignIds);
      dispatch({
        type: CAMPAIGNS_DELETE_SUCCESS,
        payload: {
          campaignIds,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: CAMPAIGNS_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const fetchCampaigns = () => {
  return async (dispatch: Function) => {
    dispatch({
      type: CAMPAIGNS_FETCH_START,
      payload: {},
    });
    const campaigns = await api.campaigns.fetchCampaigns();
    dispatch({
      type: CAMPAIGNS_FETCH_SUCCESS,
      payload: {
        campaigns,
      },
    });
  };
};

export const tickSingleCampaign = (campaignId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_CAMPAIGN,
      payload: {
        campaignId,
      },
    });
  };
};

export const tickMultipleCampaigns = (campaignIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_CAMPAIGNS,
      payload: { campaignIds },
    });
  };
};

export const navigateToCreateDirectMailCampaignPage =
  () => (dispatch: Function) =>
    dispatch(push(routePaths.directMailCampaigns.create));

export const navigateToDirectMailCampaignManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.directMailCampaigns.root));

export const navigateToViewDirectMailCampaignPage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.directMailCampaigns.view, id)));
//
export const navigateToCampaignsManagerPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.campaigns.root));

export const navigateToCreateCampaignPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.campaigns.create));

export const navigateToViewCampaignPage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.campaigns.view, id)));
