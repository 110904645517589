import { push } from "connected-react-router";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";
import api from "../../api/api";

export const TAGS_FOR_USER_CREATE_START = "TAGS_FOR_USER_CREATE_START";
export const TAGS_FOR_USER_CREATE_SUCCESS = "TAGS_FOR_USER_CREATE_SUCCESS";
export const TAGS_FOR_USER_CREATE_FAIL = "TAGS_FOR_USER_CREATE_FAIL";
export const TAGS_FOR_USER_UPDATE_START = "TAGS_FOR_USER_UPDATE_START";
export const TAGS_FOR_USER_UPDATE_SUCCESS = "TAGS_FOR_USER_UPDATE_SUCCESS";
export const TAGS_FOR_USER_UPDATE_FAIL = "TAGS_FOR_USER_UPDATE_FAIL";
export const TAGS_FOR_USER_DELETE_START = "TAGS_FOR_USER_DELETE_START";
export const TAGS_FOR_USER_DELETE_SUCCESS = "TAGS_FOR_USER_DELETE_SUCCESS";
export const TAGS_FOR_USER_DELETE_FAIL = "TAGS_FOR_USER_DELETE_FAIL";
export const TAGS_FOR_LEADS_CREATE_START = "TAGS_FOR_LEADS_CREATE_START";
export const TAGS_FOR_LEADS_CREATE_SUCCESS = "TAGS_FOR_LEADS_CREATE_SUCCESS";
export const TAGS_FOR_LEADS_CREATE_FAIL = "TAGS_FOR_LEADS_CREATE_FAIL";
export const TAGS_FOR_LEADS_DELETE_START = "TAGS_FOR_LEADS_DELETE_START";
export const TAGS_FOR_LEADS_DELETE_SUCCESS = "TAGS_FOR_LEADS_DELETE_SUCCESS";
export const TAGS_FOR_LEADS_DELETE_FAIL = "TAGS_FOR_LEADS_DELETE_FAIL";

// FOR LEADS
export const createTagsForLeads = (leadIds: number[], tags: string[]) => {
  if (!leadIds.length || !tags.length) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: TAGS_FOR_LEADS_CREATE_START,
      });
      const { updatedLeads, newTags } = await api.tags.createTagsForLeads(
        leadIds,
        tags
      );

      dispatch({
        type: TAGS_FOR_LEADS_CREATE_SUCCESS,
        payload: {
          newTags,
          updatedLeads,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: TAGS_FOR_LEADS_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteTagsForLeads = (leadIds: number[], tags: string[]) => {
  if (!leadIds.length || !tags.length) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: TAGS_FOR_LEADS_DELETE_START,
      });

      const { updatedLeads, deletedTags } = await api.tags.deleteTagsForLead(
        leadIds,
        tags
      );

      dispatch({
        type: TAGS_FOR_LEADS_DELETE_SUCCESS,
        payload: {
          deletedTags,
          updatedLeads,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: TAGS_FOR_LEADS_DELETE_FAIL,
      });
      return false;
    }
  };
};

// FOR USERS
export const updateTagForUser = (oldTag: string, newTag: string) => {
  if (!oldTag || !newTag) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: TAGS_FOR_USER_UPDATE_START,
      });

      const { updatedLeads } = await api.tags.updateTagForUser(oldTag, newTag);

      dispatch({
        type: TAGS_FOR_USER_UPDATE_SUCCESS,
        payload: {
          oldTag,
          newTag,
          updatedLeads,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: TAGS_FOR_USER_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteTagsForUser = (tags: string[]) => {
  if (!tags.length) return;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: TAGS_FOR_USER_DELETE_START,
      });
      const { updatedLeads, deletedTags } = await api.tags.deleteTagsForUser(
        tags
      );

      dispatch({
        type: TAGS_FOR_USER_DELETE_SUCCESS,
        payload: {
          deletedTags,
          updatedLeads,
        },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: TAGS_FOR_USER_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const createTagsForUser = (tags: string[]) => {
  if (!tags.length) return;

  return async (dispatch: Function) => {
    try {
      dispatch({
        type: TAGS_FOR_USER_CREATE_START,
      });

      const { newTags } = await api.tags.createTagsForUser(tags);

      dispatch({
        type: TAGS_FOR_USER_CREATE_SUCCESS,
        payload: {
          newTags,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: TAGS_FOR_USER_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const navigateToTagsManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.tags.root));
