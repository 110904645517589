import {
  ReduxAction,
  CampaignInterface,
  DirectMailCampaignInterface,
} from "../../common-interfaces/interfaces";

import { isDirectMailCampaignDeletable } from "../../common-utils/utils-postcardmania";

import { LOGOUT } from "../../user/redux/user-actions";
import {
  CAMPAIGNS_FETCH_SUCCESS,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGNS_DELETE_SUCCESS,
  TICK_SINGLE_CAMPAIGN,
  TICK_MULTIPLE_CAMPAIGNS,
  DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS,
  DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS,
  DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS,
  DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS,
  TICK_SINGLE_DIRECTMAIL_CAMPAIGN,
  TICK_MULTIPLE_DIRECTMAIL_CAMPAIGNS,
} from "./campaigns-actions";

interface CampaignsState {
  isInitializedCampaigns: boolean;
  campaigns: CampaignInterface[];
  tickedCampaignsIds: number[];
  //
  isInitializedDirectMailCampaigns: boolean;
  directMailCampaigns: DirectMailCampaignInterface[];
  tickedDirectMailCampaignsIds: number[];
}

const initialState: CampaignsState = {
  isInitializedCampaigns: false,
  campaigns: [],
  tickedCampaignsIds: [],
  //
  isInitializedDirectMailCampaigns: false,
  directMailCampaigns: [],
  tickedDirectMailCampaignsIds: [],
};

export const campaignsReducer = (
  state: CampaignsState = initialState,
  action: ReduxAction
): CampaignsState => {
  const { payload, type } = action;
  switch (type) {
    case DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedDirectMailCampaigns: true,
        directMailCampaigns: payload.directMailCampaigns.sort(
          (a: any, b: any) => (a.createdAt < b.createdAt ? -1 : 1)
        ),
      };

    case DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        directMailCampaigns: state.directMailCampaigns.concat(
          payload.directMailCampaign
        ),
      };

    case DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _directMailCampaigns = state.directMailCampaigns
        .map((directMailCampaign: any) =>
          directMailCampaign.id === payload.updatedDirectMailCampaign.id
            ? payload.updatedDirectMailCampaign
            : directMailCampaign
        )
        .sort((a: any, b: any) => (a.createdAt < b.createdAt ? -1 : 1));

      return {
        ...state,
        directMailCampaigns: _directMailCampaigns,
      };

    case DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS:
      const { directMailCampaignIds } = payload;
      return {
        ...state,
        directMailCampaigns: state.directMailCampaigns.filter(
          (directMailCampaign) =>
            !directMailCampaignIds.includes(directMailCampaign.id)
        ),
        tickedDirectMailCampaignsIds: [],
      };

    case TICK_SINGLE_DIRECTMAIL_CAMPAIGN:
      const { tickedDirectMailCampaignsIds } = state;
      const indexOfDirectMailCampaign = tickedDirectMailCampaignsIds.indexOf(
        payload.directMailCampaignId
      );

      let newTickedDirectMailCampaignsIds = [] as number[];
      if (indexOfDirectMailCampaign > -1) {
        newTickedDirectMailCampaignsIds = tickedDirectMailCampaignsIds.slice(0);
        newTickedDirectMailCampaignsIds.splice(indexOfDirectMailCampaign, 1);
      } else {
        newTickedDirectMailCampaignsIds = tickedDirectMailCampaignsIds.concat(
          payload.directMailCampaignId
        );
      }

      // this filtering part is specific for directMailCampaigns
      let filteredNewTickedDirectMailCampaignsIds = [] as number[];
      state.directMailCampaigns.forEach((directMailCampaign: any) => {
        if (
          newTickedDirectMailCampaignsIds.includes(directMailCampaign.id) &&
          isDirectMailCampaignDeletable(directMailCampaign)
        ) {
          filteredNewTickedDirectMailCampaignsIds =
            filteredNewTickedDirectMailCampaignsIds.concat(
              directMailCampaign.id
            );
        }
      });

      return {
        ...state,
        tickedDirectMailCampaignsIds: filteredNewTickedDirectMailCampaignsIds,
      };

    case TICK_MULTIPLE_DIRECTMAIL_CAMPAIGNS:
      const _newTickedDirectMailCampaignsIds = payload.directMailCampaignIds;
      // this filtering part is specific for directMailCampaigns
      let _filteredNewTickedDirectMailCampaignsIds = [] as number[];
      state.directMailCampaigns.forEach((directMailCampaign: any) => {
        if (
          _newTickedDirectMailCampaignsIds.includes(directMailCampaign.id) &&
          isDirectMailCampaignDeletable(directMailCampaign)
        ) {
          _filteredNewTickedDirectMailCampaignsIds =
            _filteredNewTickedDirectMailCampaignsIds.concat(
              directMailCampaign.id
            );
        }
      });

      return {
        ...state,
        tickedDirectMailCampaignsIds: _filteredNewTickedDirectMailCampaignsIds,
      };

    // NORMAL CAMPAIGNS
    case CAMPAIGNS_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedCampaigns: true,
        campaigns: payload.campaigns.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
      };

    case CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.concat(payload.campaign),
      };

    case CAMPAIGN_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _campaigns = state.campaigns
        .map((campaign: any) =>
          campaign.id === payload.updatedCampaign.id
            ? payload.updatedCampaign
            : campaign
        )
        .sort((a: any, b: any) => (a.createdAt < b.createdAt ? -1 : 1));
      return {
        ...state,
        campaigns: _campaigns,
      };

    case CAMPAIGNS_DELETE_SUCCESS:
      const { campaignIds } = payload;
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (campaign) => !campaignIds.includes(campaign.id)
        ),
        tickedCampaignsIds: [],
      };

    case TICK_SINGLE_CAMPAIGN:
      const { campaignId } = payload;
      const { tickedCampaignsIds } = state;
      const indexOfCampaign = tickedCampaignsIds.indexOf(campaignId);

      let newTickedCampaignsIds;
      if (indexOfCampaign > -1) {
        newTickedCampaignsIds = tickedCampaignsIds.slice(0);
        newTickedCampaignsIds.splice(indexOfCampaign, 1);
      } else {
        newTickedCampaignsIds = tickedCampaignsIds.concat(campaignId);
      }
      return {
        ...state,
        tickedCampaignsIds: newTickedCampaignsIds,
      };
    case TICK_MULTIPLE_CAMPAIGNS:
      return {
        ...state,
        tickedCampaignsIds: payload.campaignIds,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
