export const routePaths = {
  admin: {
    root: "/admin",
    transactions: "/admin/transactions",
    appStates: "/admin/app-states",
    dangerousScripts: "/admin/dangerous-scripts",
    directMailCampaigns: "/admin/direct-mail-campaigns",
    fetchInfoDemo: "/admin/fetch-info-demo",
    importCole: "/admin/import-cole",
    register: "/admin/register",
    relocations: "/admin/relocations",
    users: "/admin/users",
    usersView: "/admin/users/:id",
    zipCodes: "/admin/zipcodes",
  },
  superuser: {
    root: "/superuser",
    relocations: "/superuser/relocations",
  },
  campaigns: {
    root: "/campaigns",
    create: "/campaigns/create",
    view: "/campaigns/:id",
  },
  dialer: {
    root: "/dialer",
  },
  directMailCampaigns: {
    root: "/direct-mail-campaigns",
    create: "/direct-mail-campaigns/create",
    view: "/direct-mail-campaigns/:id",
  },
  drips: {
    root: "/drips",
    create: "/drips/create",
    view: "/drips/:id",
    edit: "/drips/:id/edit",
  },
  public: {
    klevrNation: "/klevr-nation",
    klevrNationUser: "/klevr-nation/profile/:searchedProfileIdentifier",
    // klevrNationZipCode: "/klevr-nation/zipcode/:zip",
    klevrNationZipCode: "/klevr-nation/zipcode",
    login: "/login",
    register: "/register",
    registerWithType: "/register/:registrationType",
  },
  inboxEmails: {
    root: "/inbox-email",
  },
  inboxSms: {
    root: "/inbox-sms",
  },
  landings: {
    root: "/landings",
    create: "/landings/create",
    view: "/landings/:id",
    edit: "/landings/:id/edit",
  },
  landingPagesMockup: {
    root: "/landing-pages-mockup",
  },
  leads: {
    root: "/leads",
    create: "/leads/create",
    importBulk: "/leads/import/file",
    importLikely: "/leads/import/likely/",
    view: "/leads/:id",
  },
  lists: {
    root: "/lists",
    create: "/lists/create",
    edit: "/lists/:id/edit",
    view: "/lists/:id",
  },
  root: "/",
  tags: {
    root: "/tags",
  },
  templates: {
    root: "/templates",

    postcardTemplates: "/templates/postcard",
    createPostcardTemplates: "/templates/postcard/create",
    viewPostcardTemplate: "/templates/postcard/:id",

    postcardTemplates2: "/templates/postcard2",
    createPostcardTemplates2: "/templates/postcard2/create",
    viewPostcardTemplate2: "/templates/postcard2/:id",

    emailTemplates: "/templates/email",
    createEmailTemplate: "/templates/email/create",
    editEmailTemplate: "/templates/email/:id/edit",
    viewEmailTemplate: "/templates/email/:id",

    smsTemplates: "/templates/sms",
    createSmsTemplate: "/templates/sms/create",
    editSmsTemplate: "/templates/sms/:id/edit",
    viewSmsTemplate: "/templates/sms/:id",
  },
  user: {
    root: "/",
    billing: "/billing",
    checkout: "/checkout",
    checkoutSuccess: "/checkout-success",
    checkoutSuccessWithDirectmailCampaign:
      "/checkout-success/:directMailCampaignIdUnderPayment",
    dashboard: "/dashboard",
    orderPlan: "/order-plan",
    orderExtra: "/order-extra",
    settings: "/settings",
  },
  websites: { root: "/websites" },
  zipcodes: {
    root: "/zipcodes",
    buy: "/zipcodes/buy",
  },
  creativeEditor: { root: "/editor" },
};

export const parseRouteIdFromProps = (props: any): number =>
  parseInt(props.match.params.id);

export const setPathId = (path: string, id: number | string): string =>
  path.replace(":id", String(id));
