import { differenceInMilliseconds } from "date-fns";
import { ReduxAction } from "../../common-interfaces/interfaces";
import { RelocationInterface } from "../../common-interfaces/relocations";
import { LOGOUT } from "../../user/redux/user-actions";
import {
  SUPERUSER_RELOCATIONS_FETCH_SUCCESS,
  SUPERUSER_RELOCATION_UPDATE_SUCCESS,
} from "./superuser-actions";

interface SuperUserState {
  allRelocations: RelocationInterface[];
}

const initialState: SuperUserState = {
  allRelocations: [],
};

export const superUserReducer = (
  state: SuperUserState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;

  switch (type) {
    case SUPERUSER_RELOCATION_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _allRelocations = state.allRelocations.map((relocation: any) =>
        relocation.id === payload.updatedRelocation.id
          ? payload.updatedRelocation
          : relocation
      );

      return {
        ...state,
        allRelocations: _allRelocations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(a.createdAt), new Date(b.createdAt))
        ),
      };

    case SUPERUSER_RELOCATIONS_FETCH_SUCCESS:
      return {
        ...state,
        allRelocations: payload.allRelocations.sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(a.createdAt), new Date(b.createdAt))
        ),
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
