import { unparse } from "papaparse";
import isAlpha from "validator/lib/isAlpha";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";

import {
  formatPhoneNumberIntoE164,
  formatPhoneNumberIntoNational,
} from "./utils";

export const formatSpaces = (text: string): string =>
  text ? text.trim().split(/\s+/).join(" ") : "";

const roomsCountParser = (count: string): string => {
  const c = parseInt(count);
  if (!count || !c || isNaN(c) || c <= 1) return "1";
  if (c >= 2 && c <= 5) return c.toString();
  if (c > 5) return "5+";
  return "1";
};

const squareFeetParser = (squareFeet: string): string => {
  const sf = parseInt(squareFeet);
  if (!squareFeet || !sf || isNaN(sf) || sf < 0) return "Not sure";
  if (sf >= 0 && sf <= 1000) return "0-1000";
  if (sf > 1000 && sf <= 2000) return "1000-2000";
  if (sf > 2000 && sf <= 3000) return "2000-3000";
  if (sf > 3000 && sf <= 4000) return "3000-4000";
  if (sf > 4000 && sf <= 5000) return "4000-5000";
  if (sf > 5000) return "5000+";
  return "Not sure";
};

const timeframeParser = (timeframe: string): string => {
  const tf = parseInt(timeframe);
  if (!timeframe || !tf || isNaN(tf) || tf < 0) return "Just curious";
  if (tf >= 0 && tf <= 3) return "1-3 months";
  if (tf > 3 && tf <= 6) return "3-6 months";
  if (tf > 6) return "6-12 months";
  return "Just curious";
};

export const isDoNotCallPhone = (lead: any): boolean => {
  return !!lead?.phoneNumberDNC;
};

export const createKlevrFarmId = (coleLead: any): string => {
  let lastName = coleLead.Last_Name;
  let firstName = coleLead.First_Name;
  let addressRoute =
    (coleLead.House_Number ? formatSpaces(coleLead.House_Number) : "") +
    (coleLead.Dir ? " " + formatSpaces(coleLead.Dir) : "") +
    (coleLead.Street_Name ? " " + formatSpaces(coleLead.Street_Name) : "") +
    (coleLead.Apt ? " " + formatSpaces(coleLead.Apt) : "") +
    (coleLead.N_Of_Dwelling_Unit
      ? " " + formatSpaces(coleLead.N_Of_Dwelling_Unit)
      : "");

  let latitude = coleLead.Latitude || null;
  let longitude = coleLead.Longitude || null;

  // the klevrFarmId is currently calculated by address+lat/lng, but
  // this causes a loss of about 10% of the leads.
  // Another soltuion is calculating by lastName+firstName+lat/lng
  //
  // let klevrFarmId = (
  //   (lastName ? lastName : "") +
  //   (firstName ? firstName : "") +
  //   (latitude ? latitude : "") +
  //   (longitude ? longitude : "")
  // ).toUpperCase();

  let klevr_farm_id = (
    addressRoute +
    (latitude ? latitude : "") +
    (longitude ? longitude : "")
  ).toUpperCase();

  return klevr_farm_id;
};

export const convertLeadCandidateToLead = (leadCandidate: any) => {
  const lead = {} as any;

  // name
  lead.fullName = formatSpaces(leadCandidate.fullName);
  lead.firstName = formatSpaces(leadCandidate.firstName);
  lead.middleName = formatSpaces(leadCandidate.middleName);
  lead.lastName = formatSpaces(leadCandidate.lastName);
  if (lead.firstName || lead.middleName || lead.lastName) {
    lead.fullName = `${lead.firstName.trim() || ""}${
      lead.middleName.trim() ? " " + lead.middleName.trim() : ""
    }${lead.lastName.trim() ? " " + lead.lastName.trim() : ""}`;
  }

  // email
  lead.allEmailAddresses = [] as any[];
  if (isEmail(formatSpaces(leadCandidate.emailAddress).toLowerCase())) {
    lead.allEmailAddresses = [
      {
        address: formatSpaces(leadCandidate.emailAddress).toLowerCase(),
        isUnsubscribed: false,
        isLockedUnsubscribed: false,
        isFromLikelySkipTrace: false,
        isDisabled: false,
        isPrimary: true, // in this case it is set to true because it's the only one
      },
    ];
  }
  lead.emailAddress = lead.allEmailAddresses[0]?.address;
  lead.isUnsubscribedFromEmails = false;

  // phone number
  lead.allPhoneNumbers = [] as any[];
  if (formatPhoneNumberIntoE164(leadCandidate.phoneNumber)?.length > 0) {
    lead.allPhoneNumbers = [
      {
        number: formatPhoneNumberIntoE164(leadCandidate.phoneNumber),
        DNC: false,
        isLockedDNC: false,
        phoneType: "unknown" as "unknown" | "cell" | "landline" | "voip",
        lastDialedAt: null as null | Date,
        isFromLikelySkipTrace: false,
        isDisabled: false,
        isPrimary: false,
      },
    ];
  }
  lead.phoneNumber = lead.allPhoneNumbers[0]?.number;
  lead.phoneNumberDNC = false;
  lead.phoneNumberType = "unknown";

  // other
  lead.addressRoute = formatSpaces(leadCandidate.addressRoute);
  lead.city = formatSpaces(leadCandidate.city);
  lead.state = formatSpaces(leadCandidate.state);
  lead.zipCode = formatSpaces(leadCandidate.zipCode);

  //fields containing inconsistent data are corrected
  if (!lead.city.split(" ").every((word: string) => isAlpha(word)))
    lead.city = "";

  if (!lead.state.split(" ").every((word: string) => isAlpha(word)))
    lead.state = "";

  if (!isNumeric(lead.zipCode)) lead.zipCode = "";

  //lead is finalized with additional infos
  lead.fromLandingPageId = 0;
  lead.tags = [];

  //check if there is enough information to consider the lead significant
  if (
    lead.fullName.length === 0 &&
    lead.emailAddress.length === 0 &&
    lead.phoneNumber.length === 0 &&
    (lead.addressRoute.length === 0 ||
      lead.city.length === 0 ||
      lead.state.length === 0 ||
      lead.zipCode.length === 0)
  ) {
    return null;
  } else {
    return lead;
  }
};

export const exportLeads = (leads: any) => {
  const data = leads.map((lead: any) => ({
    firstName: lead.firstName || "",
    middleName: lead.middleName || "",
    lastName: lead.lastName || "",
    fullName: lead.fullName || "",
    email: lead.emailAddress || "",
    phone: formatPhoneNumberIntoNational(lead.phoneNumber),
    addressRoute: lead.addressRoute || "",
    city: lead.city || "",
    state: lead.state || "",
    zipCode: lead.zipCode || "",
  }));

  //using papaparse
  const csvData = unparse(data);

  //solution for downloading file obtained digging here;
  //https://github.com/mholt/PapaParse/issues/175
  //https://rafaelquintanilha.com/export-your-json-data-to-csv-format/
  const filename = "klevr-leads-exported.csv";

  //mimetype could also be this:
  //const mimetype = "text/csv;charset=UTF-8;"
  const mimetype = "text/plain";

  const blob = new Blob([csvData], { type: mimetype });

  // this works for IE 10+ //UPDATE: IE is not supported after React 18
  // if (navigator.msSaveBlob) {
  //   navigator.msSaveBlob(blob, filename);
  //   return;
  // }

  //this works for other browsers
  let lnk = document.createElement("a"),
    url = window.URL,
    objectURL;

  if (mimetype) {
    lnk.type = mimetype;
  }

  lnk.download = filename || "untitled";
  lnk.href = objectURL = url.createObjectURL(blob);
  lnk.dispatchEvent(new MouseEvent("click"));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};
