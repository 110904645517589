import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import { store, history } from "./redux/configure-store";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { loadTranslations } from "./theme/translations";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import theme from "./theme/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { IntercomProvider } from "react-use-intercom";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import api from "./api/api";
import config from "./config";

// - loadStripe is related to the whole Stripe system
// - Elements is a react wrapper arount the React Elements, otherwise used through vanilla JS
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// The Stripe script is put at top level in the DOM because this makes it
// available in every page, and so allows Stripe to detect anomalous behavior
// that may be indicative of fraud.
// Make sure to call 'loadStripe' outside of a component's rerender to avoid
// recreating the 'Stripe' object on every rerender
const environment = config.reactAppKlevrEnv;
let stripePromise = null as any;
switch (environment) {
  case "local":
    stripePromise = loadStripe(config.stripe.publishable_key_test);
    break;
  case "development":
    stripePromise = loadStripe(config.stripe.publishable_key_test);
    break;
  case "staging":
    stripePromise = loadStripe(config.stripe.publishable_key_live);
    break;
  case "production":
    stripePromise = loadStripe(config.stripe.publishable_key_live);
    break;
  default:
    throw new Error();
}

// this snippet is needed  if user refresh page through browser.
// In that case authentication through Axios cookie is retrieved,
// and corresponding action is dispatched.
try {
  api.users.authenticateUserWithSessionCookie().then((user: any) => {
    store.dispatch({
      type: "LOGIN_SUCCESS", // this is using the string, not the const
      payload: {
        user,
      },
    });
  });
} catch (e) {
  store.dispatch({
    type: "LOGOUT",
  });
}

const rootElement = document.getElementById("root");

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Elements stripe={stripePromise}>
                  {/* Intercom Setup

                For testing with ngrok through terminal:
                > ngrok http 3000 -host-header="localhost:3000"

                For testing with ngrok through account at ngrok.com:
                Download ngrok and create an account at ngrok.com.
                Navigate in terminal to folder where ngrok is saved.
                Create tunnel for port 3000 (This can be used at any port)
                > ngrok http 3000 -host-header="localhost:3000"

                Log into intercom
                - username: joe@klevrleads.com
                - password: 3TbB4QKF!kWvQzt

                Select "Messenger" (Three icons up from user avatar - bottom left),
                under "Set Your Controls" select "Keep your Messenger secure"
                and add your ngrok http://.... url to the trusted domains input.
            */}
                  <IntercomProvider appId={config.intercom.app_id}>
                    <CssBaseline />
                    <App />
                  </IntercomProvider>
                </Elements>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
};

loadTranslations(renderApp);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
