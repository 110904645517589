import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

import { DripInterface } from "../../common-interfaces/interfaces";

import { fetchLandings } from "../../landings/redux/landings-actions";

export const TICK_SINGLE_DRIP = "TICK_SINGLE_DRIP";
export const TICK_MULTIPLE_DRIPS = "TICK_MULTIPLE_DRIPS";
export const DRIPS_FETCH_START = "DRIPS_FETCH_START";
export const DRIPS_FETCH_SUCCESS = "DRIPS_FETCH_SUCCESS";
export const DRIPS_FETCH_FAIL = "DRIPS_FETCH_FAIL";
export const INITIALIZE_DRIP_FORM = "INITIALIZE_DRIP_FORM";
export const DRIP_CREATED = "DRIP_CREATED";
export const DRIP_UPDATED = "DRIP_UPDATED";
export const DRIPS_DELETED = "DRIPS_DELETED";
export const DRIP_FORM_UPDATED = "DRIP_FORM_UPDATED";
//
export const DRIPS_SHARE_START = "DRIPS_SHARE_START";
export const DRIPS_SHARE_SUCCESS = "DRIPS_SHARE_SUCCESS";
export const DRIPS_SHARE_FAIL = "DRIPS_SHARE_FAIL";

export const shareDrips = (operation: string, dripIds: number[]) => {
  if (!dripIds || !dripIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: DRIPS_SHARE_START,
      });

      const { newSmsTemplates, newEmailTemplates, newDrips } =
        await api.drips.shareDrips(
          operation,
          dripIds.filter((id: any) => id !== 0)
        );

      dispatch({
        type: DRIPS_SHARE_SUCCESS,
        payload: { newSmsTemplates, newEmailTemplates, newDrips },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: DRIPS_SHARE_FAIL,
      });
      return false;
    }
  };
};

export const updateDrip = (drip: any) => {
  const { steps } = drip;
  drip.steps = steps.map((step: any) => ({
    action: step.action,
    templateId: step.templateId,
    time: step.time,
    numSecondsAfterPreviousStep: convertDripStepTimeToSeconds(step.time),
  }));

  return async (dispatch: Function) => {
    try {
      const updatedDrip = await api.drips.updateDrip(drip);
      dispatch({
        type: DRIP_UPDATED,
        payload: {
          drip: updatedDrip,
        },
      });
      fetchDrips()(dispatch);
      dispatch(push(routePaths.drips.root));
    } catch (error: any) {}
  };
};

export const initializeDripForm = (drip?: DripInterface) => {
  return (dispatch: Function) => {
    dispatch({
      type: INITIALIZE_DRIP_FORM,
      payload: { drip } || {},
    });
  };
};

export const updateDripForm = (form: DripInterface) => ({
  type: DRIP_FORM_UPDATED,
  payload: { form },
});

export const fetchDrips = () => {
  return async (dispatch: Function) => {
    dispatch({
      type: DRIPS_FETCH_START,
      payload: {},
    });
    const drips = await api.drips.fetchDrips();
    dispatch({
      type: DRIPS_FETCH_SUCCESS,
      payload: {
        drips,
      },
    });
  };
};

const convertDripStepTimeToSeconds = (time: {
  unit: "minutes" | "hours" | "days";
  value: any;
}) => {
  const { unit, value } = time;
  const secondsValues = {
    minutes: 60,
    hours: 3600,
    days: 86400,
  };

  return secondsValues[unit] * parseInt(value, 10);
};

export const saveDrip = (drip: any) => {
  // need to transform the drip-step.time values
  const { steps } = drip;
  drip.steps = steps.map((step: any) => ({
    action: step.action,
    templateId: step.templateId,
    time: step.time,
    numSecondsAfterPreviousStep: convertDripStepTimeToSeconds(step.time),
  }));

  return async (dispatch: Function) => {
    try {
      const createdDrip = await api.drips.createDrip(drip);
      dispatch({
        type: DRIP_CREATED,
        payload: {
          drip: createdDrip,
        },
      });
      dispatch(push(routePaths.drips.root));
    } catch (error: any) {}
  };
};

export const deleteDrips = (dripIds: number[]) => {
  if (!dripIds || !dripIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    const res = await api.drips.deleteDrips(
      dripIds.filter((id: any) => id !== 0)
    );
    dispatch({
      type: DRIPS_DELETED,
      payload: {
        dripIds,
      },
    });
    //this is dependant on it, so it should be updated
    fetchLandings(res.userId)(dispatch);
  };
};

export const tickSingleDrip = (dripId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_DRIP,
      payload: {
        dripId,
      },
    });
  };
};

export const tickMultipleDrips = (dripIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_DRIPS,
      payload: { dripIds },
    });
  };
};

export const navigateToCreateDripPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.drips.create));

export const navigateToViewDripPage =
  (dripId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.drips.view, dripId)));

export const navigateToEditDripPage =
  (dripId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.drips.edit, dripId)));

export const navigateToDripsManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.drips.root));
