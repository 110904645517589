export default Object.freeze({
  nodeEnv: process.env.NODE_ENV || "",
  reactAppKlevrEnv: process.env.REACT_APP_KLEVR_ENV || "local",
  emails: {
    domain: process.env.EMAILS_DOMAIN || "outboundmail-dev.dev.klevrmail.com",
  },
  flatfile: { license_key: "8a6501ae-0ec4-4e8e-b340-6b4e525f0dc4" },
  googleMaps: { apiKey: "AIzaSyC83yJWR5xU2X9jSFhpWwgAka0wRzg1ARk" },
  intercom: { app_id: "uyqa8dij" },
  stripe: {
    publishable_key_test: "pk_test_TRQUJ94vTVUduVYJDEPMVOUw00MY8RQXUB",
    publishable_key_live: "pk_live_sfojaO0ZoC6cy0kYJaEsWouz00Oorw3dDg",
  },
  features: {
    // this is for documentation purpose
    mockupFeature: {
      isEnabled: process.env.REACT_APP_MOCKUP_FEATURE_ENABLED === "1" || false,
    },
  },
  globalVariables: {
    fetchLeadsChunkSize: 1500, // 1000 is too low. Too many db requests.
    importLeadsChunkSize: 1000,
  },
});
