import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

export const getLanguage = () => {
  return i18n.language.split("-")[0];
};
export const setLanguage = async (languageCode: string) => {
  await i18n.changeLanguage(languageCode);
};

export const loadTranslations = (callback: any) => {
  return (
    i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(reactI18nextModule)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init(
        {
          fallbackLng: "en",
          debug: false,
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          preload: ["en"],
          backend: {
            loadPath: "/locales/{{lng}}.json",
            //   parse: data => data,
            //   ajax: loadLocales
          },
        },
        callback
      )
  );
};
