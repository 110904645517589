export const usersFiltersDefaultValue = { id: 0, value: "- any -" };

export const usersFilters = [
  { label: "Search Bar", dataKey: "searchBar", hidden: true },
  { label: "Plan Sub", dataKey: "plan" },
  { label: "Twilio Phone Number", dataKey: "twilioPhoneNumber" },
  { label: "Business Profile", dataKey: "businessProfile" },
  { label: "Unsubscribed", dataKey: "unsubscribed" },
  { label: "Klevr Nation", dataKey: "klevrNation" },
  { label: "Website Sub", dataKey: "website" },
  { label: "Social Media Sub", dataKey: "socialMedia" },
  { label: "Virtual Assistant Sub", dataKey: "virtualAssistant" },
  { label: "Virtual Assistant Name", dataKey: "assignedVirtualAssistant" },
  { label: "Website Status", dataKey: "websiteCreationStatus" },
  { label: "Strategy Call", dataKey: "strategyCall" },
  { label: "Advertising Budget", dataKey: "advertisingBudget" },
  { label: "Note", dataKey: "noteText" },
  { label: "City", dataKey: "city" },
  { label: "State", dataKey: "state" },
  { label: "Zip Code", dataKey: "zipCode" },
  { label: "How Many Leads", dataKey: "howManyLeads" },
  { label: "Permissions", dataKey: "permissions" },
];

export const zipCodesFiltersDefaultValue = { id: 0, value: "- any -" };

export const zipCodesFilters = [
  { label: "Search Bar", dataKey: "searchBar", hidden: true },
  { label: "State", dataKey: "state" },
  { label: "Max Licenses", dataKey: "maxAvailableLicenses" },
  { label: "# Assigned Licenses", dataKey: "assignedLicenses" },
  { label: "User owning license", dataKey: "userOwningLicense" },
];

export const leadsFiltersDefaultValue = { id: 0, value: "- any -" };

export const leadsFilters = [
  { label: "Search Bar", dataKey: "searchBar", hidden: true },
  { label: "Leads Subset", dataKey: "leadsSubset", hidden: true },
  { label: "Hidden List", dataKey: "hiddenLists", hidden: true },
  { label: "Duplicates", dataKey: "duplicates" },
  { label: "Data Source", dataKey: "source" },
  { label: "List", dataKey: "lists" },
  { label: "Tag", dataKey: "tags" },
  { label: "Landing Page", dataKey: "landings" },
  { label: "Email", dataKey: "emailAddress" },
  { label: "Phone 1", dataKey: "phoneNumber1" },
  { label: "Phone 2", dataKey: "phoneNumber2" },
  { label: "City", dataKey: "city" },
  { label: "State", dataKey: "state" },
  { label: "Zip Code", dataKey: "zipCode" },
  { label: "Trust Owned", dataKey: "isTrustOwned" },
  { label: "Owner Occupied", dataKey: "isOwnerOccupied" },
  { label: "Drips", dataKey: "drips" },
  { label: "Active Drips", dataKey: "activeDrips" },
  { label: "Accuracy", dataKey: "accuracy" },
  { label: "Haunted", dataKey: "haunted" },
];

export const isTrustOwned = (lead: any): boolean =>
  lead.isTrustOwned ||
  lead.fullName.toLowerCase().includes(" trust ") || //blank spaces are relevant
  lead.fullName.toLowerCase().includes("trust ") ||
  lead.fullName.toLowerCase().includes(" trust") ||
  lead.fullName.toLowerCase().includes(",trust,") ||
  lead.fullName.toLowerCase().includes(" trust,") ||
  lead.fullName.toLowerCase().includes(",trust ") ||
  lead.fullName.toLowerCase().includes(".trust ") ||
  lead.fullName.toLowerCase().includes(" trust.") ||
  lead.fullName.toLowerCase().includes(" family ") ||
  lead.fullName.toLowerCase().includes("family ") ||
  lead.fullName.toLowerCase().includes(" family") ||
  lead.fullName.toLowerCase().includes(",family,") ||
  lead.fullName.toLowerCase().includes(" family,") ||
  lead.fullName.toLowerCase().includes(",family ") ||
  lead.fullName.toLowerCase().includes(".family ") ||
  lead.fullName.toLowerCase().includes(" family.");
