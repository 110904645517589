import config from "../config";
import {
  DirectMailCampaignInterface,
  PostcardmaniaSizeKey,
} from "../common-interfaces/interfaces";
import { currencyFormatter } from "./utils";
import { intersectSets } from "./utils-sets";

const environment = config.reactAppKlevrEnv;

export const getDirectMailCampaignProfitToKlevr = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  let _howManyRejectedRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.filter(
      (recipient: any) => recipient.status === "rejected"
    ).length || 0;

  let _howManyDeliverableRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.filter(
      (recipient: any) => recipient.status === "deliverable"
    ).length || 0;

  let costToUser = directMailCampaign.costToUser;
  let refundToUser = 0;
  let pendingCostToKlevr = 0;
  let actualCostToKlevr = 0;
  let profitToKlevr = 0;

  if (directMailCampaign.refundedToUser) {
    refundToUser = directMailCampaign.refundedToUser;
  } else {
    refundToUser =
      _howManyRejectedRecipients * directMailCampaign.costPerPieceToUser;
  }

  if (
    directMailCampaign.status === "klevrError" ||
    directMailCampaign.status === "klevrRejected" ||
    directMailCampaign.status === "postcardmaniaCancelled"
  ) {
    actualCostToKlevr = 0;
    pendingCostToKlevr = 0;
  }

  if (
    directMailCampaign.status === "klevrCreated" ||
    directMailCampaign.status === "klevrPaidByUser" ||
    directMailCampaign.status === "klevrApprovedByAdmin" ||
    directMailCampaign.status === "postcardmaniaPending" ||
    directMailCampaign.status === "postcardmaniaRequiresPayment"
  ) {
    actualCostToKlevr = 0;

    if (directMailCampaign.postcardmaniaTotalPrice) {
      pendingCostToKlevr = directMailCampaign.postcardmaniaTotalPrice;
    } else {
      pendingCostToKlevr =
        _howManyDeliverableRecipients *
        parseFloat(
          getPostcardInfoBySize(directMailCampaign.size, "costPerPieceToKlevr")
        );
    }
  }

  if (
    directMailCampaign.status === "postcardmaniaProcessing" ||
    directMailCampaign.status === "postcardmaniaMailing" ||
    directMailCampaign.status === "postcardmaniaDelivered" ||
    directMailCampaign.status === "postcardmaniaUndeliverable"
  ) {
    actualCostToKlevr = directMailCampaign.postcardmaniaTotalPrice || 0;
    pendingCostToKlevr = 0;
  }

  if (actualCostToKlevr > 0) {
    profitToKlevr = costToUser - refundToUser - actualCostToKlevr;
    profitToKlevr = Math.round(profitToKlevr * 100) / 100;
    return `$${profitToKlevr} (final)`;
  }
  if (pendingCostToKlevr > 0) {
    profitToKlevr = costToUser - refundToUser - pendingCostToKlevr;
    profitToKlevr = Math.round(profitToKlevr * 100) / 100;
    return `$${profitToKlevr} (pending)`;
  }
  return `$0`;
};

export const getDirectMailCampaignCostToKlevrEvaluation = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  let _howManyDeliverableRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.filter(
      (recipient: any) => recipient.status === "deliverable"
    ).length || 0;

  let actualCostToKlevr = 0;
  let pendingCostToKlevr = 0;

  if (
    directMailCampaign.status === "klevrError" ||
    directMailCampaign.status === "klevrRejected" ||
    directMailCampaign.status === "postcardmaniaCancelled"
  ) {
    actualCostToKlevr = 0;
    pendingCostToKlevr = 0;
  }

  if (
    directMailCampaign.status === "klevrCreated" ||
    directMailCampaign.status === "klevrPaidByUser" ||
    directMailCampaign.status === "klevrApprovedByAdmin" ||
    directMailCampaign.status === "postcardmaniaPending" ||
    directMailCampaign.status === "postcardmaniaRequiresPayment"
  ) {
    actualCostToKlevr = 0;

    if (directMailCampaign.postcardmaniaTotalPrice) {
      pendingCostToKlevr = directMailCampaign.postcardmaniaTotalPrice;
    } else {
      pendingCostToKlevr =
        _howManyDeliverableRecipients *
        parseFloat(
          getPostcardInfoBySize(directMailCampaign.size, "costPerPieceToKlevr")
        );
    }
  }

  if (
    directMailCampaign.status === "postcardmaniaProcessing" ||
    directMailCampaign.status === "postcardmaniaMailing" ||
    directMailCampaign.status === "postcardmaniaDelivered" ||
    directMailCampaign.status === "postcardmaniaUndeliverable"
  ) {
    actualCostToKlevr = directMailCampaign.postcardmaniaTotalPrice || 0;
    pendingCostToKlevr = 0;
  }

  if (actualCostToKlevr > 0) {
    return `$${actualCostToKlevr} (paid)`;
  }
  if (pendingCostToKlevr > 0) {
    return `$${pendingCostToKlevr} (pending)`;
  }

  return `$0`;
};

export const getDirectMailCampaignRecipientsEvaluation = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  let howManyRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.length || 0;

  let howManyDeliverableRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.filter(
      (recipient: any) => recipient.status === "deliverable"
    ).length || 0;

  let howManyDeliveredRecipients =
    directMailCampaign?.postcardmaniaRecipientLeads.filter(
      (recipient: any) => recipient.status === "delivered"
    ).length || 0;

  if (
    directMailCampaign.status === "klevrCreated" ||
    directMailCampaign.status === "klevrPaidByUser" ||
    directMailCampaign.status === "klevrApprovedByAdmin"
  ) {
    return `${howManyRecipients} (estimate)`;
  }

  if (
    directMailCampaign.status === "postcardmaniaPending" ||
    directMailCampaign.status === "postcardmaniaProcessing" ||
    directMailCampaign.status === "postcardmaniaMailing" ||
    directMailCampaign.status === "postcardmaniaRequiresPayment" ||
    directMailCampaign.status === "postcardmaniaUndeliverable"
  ) {
    if (howManyRecipients === howManyDeliverableRecipients) {
      return `${howManyRecipients}`;
    } else {
      return `${howManyDeliverableRecipients} deliverable of ${howManyRecipients} total`;
    }
  }

  if (directMailCampaign.status === "postcardmaniaDelivered") {
    if (howManyRecipients === howManyDeliverableRecipients) {
      return `${howManyRecipients}`;
    } else {
      return `${howManyDeliveredRecipients} delivered of ${howManyRecipients} total`;
    }
  }

  if (directMailCampaign.status === "postcardmaniaCancelled") {
    return `${howManyRecipients} (cancelled)`;
  }

  if (
    directMailCampaign.status === "klevrError" ||
    directMailCampaign.status === "klevrRejected"
  ) {
    return `${howManyRecipients} (rejected)`;
  }

  return `${howManyRecipients}`;
};

export const getDirectMailCampaignRefundEvaluation = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  if (directMailCampaign.refundedToUser) {
    return directMailCampaign.refundedToUser > 0
      ? `$${Number(directMailCampaign.refundedToUser).toFixed(2)} (completed)`
      : "";
  } else {
    let howManyRejectedRecipients =
      directMailCampaign?.postcardmaniaRecipientLeads.filter(
        (recipient: any) => recipient.status === "rejected"
      ).length || 0;

    let pendingRefund =
      howManyRejectedRecipients * directMailCampaign.costPerPieceToUser;
    return pendingRefund > 0
      ? `$${Number(pendingRefund).toFixed(2)} (pending)`
      : "";
  }
};

export const isDirectMailCampaignPending = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  if (
    directMailCampaign?.status === "klevrCreated" ||
    directMailCampaign?.status === "klevrPaidByUser" ||
    directMailCampaign?.status === "klevrApprovedByAdmin"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isDirectMailCampaignStarted = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  if (
    directMailCampaign?.status === "postcardmaniaPending" ||
    directMailCampaign?.status === "postcardmaniaProcessing" ||
    directMailCampaign?.status === "postcardmaniaMailing" ||
    directMailCampaign?.status === "postcardmaniaRequiresPayment"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isDirectMailCampaignFinished = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  if (
    directMailCampaign?.status === "klevrError" ||
    directMailCampaign?.status === "klevrRejected" ||
    directMailCampaign?.status === "postcardmaniaCancelled" ||
    directMailCampaign?.status === "postcardmaniaDelivered"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isDirectMailCampaignDeletable = (
  directMailCampaign: DirectMailCampaignInterface
) => {
  if (environment !== "production") return true;

  if (
    directMailCampaign?.status === "klevrCreated" ||
    directMailCampaign?.status === "klevrRejected" ||
    directMailCampaign?.status === "postcardmaniaCancelled" ||
    directMailCampaign?.status === "postcardmaniaDelivered"
  ) {
    return true;
  } else {
    return false;
  }
};

export const getDirectMailCampaignStatus = (
  directMailCampaign: DirectMailCampaignInterface,
  viewer: "user" | "admin"
) => {
  switch (directMailCampaign.status) {
    case "klevrCreated":
      return viewer === "user"
        ? "Waiting payment"
        : viewer === "admin"
        ? "Waiting payment"
        : "error";
    case "klevrPaidByUser":
      return viewer === "user"
        ? "Waiting scheduled start"
        : viewer === "admin"
        ? "Waiting approval"
        : "error";
    case "klevrApprovedByAdmin":
      return viewer === "user"
        ? "Waiting scheduled start"
        : viewer === "admin"
        ? "Waiting user scheduled start"
        : "error";
    case "klevrRejected":
      return viewer === "user"
        ? "Rejected: no valid leads"
        : viewer === "admin"
        ? "Rejected by PostcardMania: no valid leads"
        : "error";
    case "klevrError":
      return viewer === "user"
        ? "Error: unable to process order (contact admin)"
        : viewer === "admin"
        ? "Error: unable to process order (bug in Klevr or Postcardmania)"
        : "error";
    case "postcardmaniaPending":
      return viewer === "user"
        ? "Queued for printing"
        : viewer === "admin"
        ? "Postcardmania Pending (is queued)"
        : "error";
    case "postcardmaniaCancelled":
      return viewer === "user"
        ? "Error: order cancelled by admin (contact admin)"
        : viewer === "admin"
        ? "Order Cancelled on PostcardMania dashboard"
        : "error";
      break;
    case "postcardmaniaRequiresPayment":
      return viewer === "user"
        ? "Queued for printing"
        : viewer === "admin"
        ? "Postcardmania RequiresPayment (requires payment and approval on PCM dashboard)"
        : "error";
    case "postcardmaniaProcessing":
      return viewer === "user"
        ? "Printing"
        : viewer === "admin"
        ? "Postcardmania Processing (is printing)"
        : "error";
    case "postcardmaniaMailing":
      return viewer === "user"
        ? "Mailing"
        : viewer === "admin"
        ? "Postcardmania Mailing (is sending)"
        : "error";
    case "postcardmaniaDelivered":
      return viewer === "user"
        ? "Delivered"
        : viewer === "admin"
        ? "Postcardmania Delivered (finished)"
        : "error";
    case "postcardmaniaUndeliverable":
      return viewer === "user"
        ? "Error: order Undeliverable (contact admin)"
        : viewer === "admin"
        ? "Postcardmania Undeliverable (requires action or is an error)"
        : "error";
    default:
      return "error";
  }
};

export const getPostcardInfoBySize = (
  key: PostcardmaniaSizeKey | undefined,
  field: "label" | "costPerPieceToUser" | "costPerPieceToKlevr",
  user?: any
): string => {
  const postcardmaniaSizes = [
    // PCM v1
    {
      key: "46",
      label: "4.25'' x 6''",
      costPerPieceToKlevr: "0.42",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "0.53"
        : "0.53",
    },
    {
      key: "68",
      label: "6'' x 8.5''",
      costPerPieceToKlevr: "0.7",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "0.81"
        : "0.81",
    },
    {
      key: "611",
      label: "6'' x 11''",
      costPerPieceToKlevr: "unavailable",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    // PCM v2
    {
      key: "425x6FirstClass",
      label: "4.25'' x 6'' First Class",
      costPerPieceToKlevr: "0.47",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "4x6FirstClass",
      label: "4'' x 6'' First Class",
      costPerPieceToKlevr: "0.07",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "5x7FirstClass",
      label: "5'' x 7'' First Class",
      costPerPieceToKlevr: "0.74",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "5x7Standard",
      label: "5'' x 7'' Standard",
      costPerPieceToKlevr: "0.63",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "6x11FirstClass",
      label: "6'' x 11'' First Class",
      costPerPieceToKlevr: "0.84",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "6x11Standard",
      label: "6'' x 11'' Standard",
      costPerPieceToKlevr: "0.70",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "6x85FirstClass",
      label: "6'' x 8.5'' First Class",
      costPerPieceToKlevr: "0.61",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "6x85Standard",
      label: "6'' x 8.5'' Standard",
      costPerPieceToKlevr: "0.58",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
    {
      key: "LetterStandard",
      label: "Letter Standard",
      costPerPieceToKlevr: "1.07",
      costPerPieceToUser: key
        ? user?.externalIntegrationsPricing?.postcardmania[key] || "100"
        : "100",
    },
  ];

  if (field === "label")
    return (
      postcardmaniaSizes.find((s: any) => s.key === key)?.label || "unavailable"
    );

  if (field === "costPerPieceToUser")
    return (
      postcardmaniaSizes.find((s: any) => s.key === key)?.costPerPieceToUser ||
      "unavailable"
    );

  if (field === "costPerPieceToKlevr")
    return (
      postcardmaniaSizes.find((s: any) => s.key === key)?.costPerPieceToKlevr ||
      "unavailable"
    );

  return key || "";
};

export const allPostcardmaniaFields = [
  {
    name: "address",
    label: "Lead Address",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
  {
    name: "address2",
    label: "Lead Address 2",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "backgroundimage",
    label: "Background Image",
    mandatory: false,
    type: "image",
    purpose: "customization",
  }, //image
  {
    name: "bathrooms",
    label: "Number of Bathrooms",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "bedrooms",
    label: "Number of Bedrooms",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "backheadline1",
    label: "Backhead Line 1",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "backheadline2",
    label: "Backhead Line 2",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "bulletpoint1",
    label: "Bullet Point 1",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "bulletpoint2",
    label: "Bullet Point 2",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "bulletpoint3",
    label: "Bullet Point 3",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "bulletpoint4",
    label: "Bullet Point 4",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "city",
    label: "Lead City",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
  {
    name: "companyaddress",
    label: "Company Address",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "companycity",
    label: "Company City",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "companyname",
    label: "Company Name",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "companystate",
    label: "Company State",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "companyurl",
    label: "Company URL",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "companyzipcode",
    label: "Company Zipcode",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "confirmationcode",
    label: "Confirmation Code",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "confirmationurl",
    label: "Confirmation URL",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "country",
    label: "Country",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "discountdollar",
    label: "Discount Dollar",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "email",
    label: "User Email",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "expiration",
    label: "Expiration",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "ExternalRefNbr",
    label: "ExternalRefNbr",
    mandatory: false,
    type: "text",
    purpose: "lead",
  }, // this strange field is present only on customized templates
  {
    name: "firstname",
    label: "Lead First Name",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
  {
    name: "frontheadline1",
    label: "Fronthead Line 1",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "frontheadline2",
    label: "Fronthead Line 2",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "interiorimage1",
    label: "Interior Image",
    mandatory: false,
    type: "image",
    purpose: "customization",
  }, //image
  {
    name: "lastname",
    label: "Lead Last NAme",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
  {
    name: "logo",
    label: "User Logo",
    mandatory: false,
    type: "image",
    purpose: "user",
  }, // image
  {
    name: "numberofyears",
    label: "Number of years in business",
    mandatory: false,
    type: "text",
    purpose: "customization",
  }, // number of years the realtor has been in business
  {
    name: "officenumber",
    label: "User Phone Number",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "openhousedate",
    label: "Open House Date",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "openhousetimes",
    label: "Open House Time",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "phone",
    label: "User Phone Number",
    mandatory: true,
    type: "text",
    purpose: "user",
  }, //mandatory
  {
    name: "phonenumber",
    label: "User Phone Number",
    mandatory: true,
    type: "text",
    purpose: "user",
  }, //mandatory
  {
    name: "profileimage",
    label: "User Profile Image",
    mandatory: false,
    type: "image",
    purpose: "user",
  }, //image
  {
    name: "propertyaddress",
    label: "Lead Property Address",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "propertycity",
    label: "Lead Property City",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "propertylistedprice",
    label: "Property Listed Price",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "propertystate",
    label: "Lead Property State",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "propertyzip",
    label: "Lead Property Zipcode",
    mandatory: false,
    type: "text",
    purpose: "lead",
  },
  {
    name: "shortpara",
    label: "Short Para",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "state",
    label: "Lead State",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
  {
    name: "subheadline",
    label: "Subhead Line",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "testimonial",
    label: "Testimonial",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "titleorlicense",
    label: "Title or License",
    mandatory: false,
    type: "text",
    purpose: "customization",
  },
  {
    name: "websiteurl",
    label: "Website URL",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "yourfirstname",
    label: "User First Name",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "yourlastname",
    label: "User Last Name",
    mandatory: false,
    type: "text",
    purpose: "user",
  },
  {
    name: "zipcode",
    label: "Lead Zipcode",
    mandatory: true,
    type: "text",
    purpose: "lead",
  }, //mandatory
];

export const getFieldsToShowByPurpose = (
  frontsideOrBacksideFields: string[],
  purpose: "customization" | "user" | "lead"
) => {
  const allFieldNamesForPurpose = allPostcardmaniaFields
    .filter((field: any) => field.purpose === purpose)
    .map((field: any) => field.name);

  const fieldNamesToShow = [
    ...intersectSets(
      new Set<string>(frontsideOrBacksideFields),
      new Set<string>(allFieldNamesForPurpose)
    ),
  ];
  const fieldsSortedByVisualOrder = fieldNamesToShow.map((fieldName: any) =>
    allPostcardmaniaFields.find((field: any) => field.name === fieldName)
  );

  return fieldsSortedByVisualOrder;
};

export const PostcardmaniaDesignSupportInfos = [
  {
    designId: 1, // unsupported
    allFields: [
      "address",
      "address2",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "state",
      "zipcode",
    ],
    fieldsFront: [
      "firstname",
      "logo",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "phone",
      "companyurl",
    ],
    fieldsBack: [
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
      "phone",
      "companyurl",
    ],
    fieldsUnused: [],
  },
  {
    designId: 26,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "state",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: ["companyname", "companyurl", "phone"],
    fieldsBack: [
      "logo",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 27,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "expiration",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "companyurl",
      "profileimage",
    ],
    fieldsBack: [
      "logo",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "expiration",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 28,
    allFields: [
      "address",
      "address2",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "confirmationcode",
      "confirmationurl",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "confirmationcode",
      "phone",
      "confirmationurl",
      "logo",
      "companyurl",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "titleorlicense",
      "profileimage",
      "logo",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 29,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "bulletpoint4",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "lastname",
      "logo",
      "numberofyears",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "testimonial",
      "logo",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "phone",
      "email",
    ],
    fieldsBack: [
      "numberofyears",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "bulletpoint4",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "testimonial",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 30,
    allFields: [
      "address",
      "address2",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "discountdollar",
      "email",
      "firstname",
      "lastname",
      "logo",
      "officenumber",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "testimonial",
      "discountdollar",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "profileimage",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "discountdollar",
      "profileimage",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: [
      "officenumber", // I'm not sure if this template is displaying the office number field on the front or back... will need to generate a proof to see if and where.
      "country",
    ],
  },
  {
    designId: 31,
    allFields: [
      "address",
      "address2",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "profileimage",
      "companyname",
      "companyurl",
      "phone",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 32,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "confirmationcode",
      "confirmationurl",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "confirmationcode",
      "phone",
      "confirmationurl",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyurl",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "logo",
      "profileimage",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 33, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "interiorimage1",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "propertylistedprice",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "propertylistedprice",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "testimonial",
    ],
    fieldsBack: [
      "logo",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "phone",
      "email",
      "profileimage",
      "interiorimage1",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 34, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "interiorimage1",
      "lastname",
      "logo",
      "numberofyears",
      "phone",
      "profileimage",
      "propertylistedprice",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "phone",
      "email",
      "bedrooms",
      "bathrooms",
      "backgroundimage",
      "propertylistedprice",
    ],
    fieldsBack: [
      "logo",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "phone",
      "email",
      "numberofyears",
      "interiorimage1",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 35, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "lastname",
      "logo",
      "openhousedate",
      "openhousetimes",
      "phone",
      "profileimage",
      "propertyaddress",
      "propertycity",
      "propertystate",
      "propertyzip",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "openhousedate",
      "openhousetimes",
      "logo",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "testimonial",
      "backgroundimage",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "companyname",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "propertyaddress",
      "propertycity",
      "propertystate",
      "propertyzip",
      "openhousedate",
      "openhousetimes",
      "logo",
      "profileimage",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 38,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: ["companyname", "companyurl", "phone"],
    fieldsBack: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "testimonial",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 39,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "expiration",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "companyurl",
      "profileimage",
    ],
    fieldsBack: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "expiration",
      "phone",
      "companyurl",
      "profileimage",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 40, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "confirmationcode",
      "confirmationurl",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "phonenumber",
      "profileimage",
      "state",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "confirmationcode",
      "phone",
      "confirmationurl",
      "logo",
      "companyurl",
    ],
    fieldsBack: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "titleorlicense",
      "confirmationcode",
      "phone",
      "confirmationurl",
      "confirmationcode",
      "phone",
      "confirmationurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 41,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "bulletpoint4",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "lastname",
      "logo",
      "numberofyears",
      "phone",
      "profileimage",
      "propertyaddress",
      "state",
      "testimonial",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "testimonial",
      "logo",
      "phone",
      "email",
      "profileimage",
      "yourfirstname",
      "yourlastname",
    ],
    fieldsBack: [
      "numberofyears",
      "logo",
      "yourfirstname",
      "yourlastname",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "profileimage",
      "phone",
      "companyurl",
      "email",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "bulletpoint4",
      "testimonial",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 42,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "discountdollar",
      "email",
      "firstname",
      "lastname",
      "logo",
      "officenumber",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "testimonial",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "discountdollar",
    ],
    fieldsBack: [
      "logo",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "discountdollar",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 43,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "profileimage",
      "companyname",
      "companyurl",
      "phone",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
    ],
    fieldsBack: [
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "logo",
      "testimonial",
      "profileimage",
      "titleorlicense",
      "phone",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 44,
    allFields: [
      "address",
      "address2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyurl",
      "companyzipcode",
      "confirmationcode",
      "confirmationurl",
      "country",
      "firstname",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "confirmationcode",
      "phone",
      "confirmationurl",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "companyname",
      "companyurl",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "testimonial",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "confirmationcode",
      "phone",
      "confirmationurl",
      "profileimage",
      "titleorlicense",
      "companyurl",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 45, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "interiorimage1",
      "lastname",
      "logo",
      "phone",
      "profileimage",
      "propertylistedprice",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "propertylistedprice",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "testimonial",
    ],
    fieldsBack: [
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "profileimage",
      "titleorlicense",
      "phone",
      "email",
      "interiorimage1",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 46, // unsupported - this was originally unsupported due to the images
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "bathrooms",
      "bedrooms",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "interiorimage1",
      "lastname",
      "logo",
      "numberofyears",
      "phone",
      "profileimage",
      "propertylistedprice",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "backgroundimage",
      "bedrooms",
      "bathrooms",
      "propertylistedprice",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
    ],
    fieldsBack: [
      "logo",
      "yourfirstname",
      "yourlastname",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "numberofyears",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "interiorimage1",
      "profileimage",
      "titleorlicense",
      "phone",
      "email",
      "testimonial",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 47,
    allFields: [
      "address",
      "address2",
      "backgroundimage",
      "city",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "country",
      "email",
      "firstname",
      "lastname",
      "logo",
      "openhousedate",
      "openhousetimes",
      "phone",
      "profileimage",
      "propertyaddress",
      "propertycity",
      "propertystate",
      "propertyzip",
      "state",
      "testimonial",
      "titleorlicense",
      "yourfirstname",
      "yourlastname",
      "zipcode",
    ],
    fieldsFront: [
      "backgroundimage",
      "openhousedate",
      "openhousetimes",
      "profileimage",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "email",
      "testimonial",
    ],
    fieldsBack: [
      "yourfirstname",
      "yourlastname",
      "companyname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "logo",
      "openhousedate",
      "openhousetimes",
      "propertyaddress",
      "propertycity",
      "propertystate",
      "propertyzip",
      "titleorlicense",
      "phone",
      "email",
      "firstname",
      "lastname",
      "address",
      "address2",
      "city",
      "state",
      "zipcode",
    ],
    fieldsUnused: ["country"],
  },
  {
    designId: 95,
    allFields: [
      "backheadline1",
      "backheadline2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "frontheadline1",
      "frontheadline2",
      "phone",
      "profileimage",
      "shortpara",
      "subheadline",
      "titleorlicense",
      "websiteurl",
      "yourfirstname",
      "yourlastname",
    ],
    fieldsFront: [
      "frontheadline1",
      "frontheadline2",
      "subheadline",
      "profileimage",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "websiteurl",
    ],
    fieldsBack: [
      "backheadline1",
      "backheadline2",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "shortpara",
      "profileimage",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "titleorlicense",
      "phone",
      "websiteurl",
      "firstname", // Included on all other templates - only needed for proof
      "lastname", // Included on all other templates - only needed for proof
      "address", // Included on all other templates - only needed for proof
      "address2", // Included on all other templates - only needed for proof
      "city", // Included on all other templates - only needed for proof
      "state", // Included on all other templates - only needed for proof
      "zipcode", // Included on all other templates - only needed for proof
    ],
    fieldsUnused: [],
  },
  {
    designId: 97,
    allFields: [
      "backheadline1",
      "backheadline2",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "companyaddress",
      "companycity",
      "companyname",
      "companystate",
      "companyzipcode",
      "frontheadline1",
      "frontheadline2",
      "logo",
      "phone",
      "profileimage",
      "shortpara",
      "subheadline",
      "titleorlicense",
      "websiteurl",
      "yourfirstname",
      "yourlastname",
    ],
    fieldsFront: [
      "frontheadline1",
      "frontheadline2",
      "subheadline",
      "profileimage",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "titleorlicense",
      "phone",
      "websiteurl",
    ],
    fieldsBack: [
      "backheadline1",
      "backheadline2",
      "companyname",
      "yourfirstname",
      "yourlastname",
      "companyaddress",
      "companycity",
      "companystate",
      "companyzipcode",
      "shortpara",
      "profileimage",
      "bulletpoint1",
      "bulletpoint2",
      "bulletpoint3",
      "titleorlicense",
      "phone",
      "websiteurl",
      "logo",
      "firstname", // Included on all other templates - only needed for proof
      "lastname", // Included on all other templates - only needed for proof
      "address", // Included on all other templates - only needed for proof
      "address2", // Included on all other templates - only needed for proof
      "city", // Included on all other templates - only needed for proof
      "state", // Included on all other templates - only needed for proof
      "zipcode", // Included on all other templates - only needed for proof
    ],
    fieldsUnused: [],
  },
];
