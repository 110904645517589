import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

export const LANDINGS_FETCH_START = "LANDINGS_FETCH_START";
export const LANDINGS_FETCH_SUCCESS = "LANDINGS_FETCH_SUCCESS";
export const LANDINGS_FETCH_FAIL = "LANDINGS_FETCH_FAIL";
export const INITIALIZE_LANDING_FORM = "INITIALIZE_LANDING_FORM";
export const LANDING_CREATED = "LANDING_CREATED";
export const LANDING_UPDATED = "LANDING_UPDATED";
export const TICK_SINGLE_LANDING = "TICK_SINGLE_LANDING";
export const TICK_MULTIPLE_LANDINGS = "TICK_MULTIPLE_LANDINGS";
export const LANDINGS_DELETED = "LANDINGS_DELETED";
export const LANDING_FORM_UPDATED = "LANDING_FORM_UPDATED";

export const updateLanding = (landing: any) => {
  return async (dispatch: Function) => {
    try {
      const updatedLanding = await api.landings.updateLanding(landing);
      dispatch({
        type: LANDING_UPDATED,
        payload: {
          landing: updatedLanding,
        },
      });
      fetchLandings(landing.userId)(dispatch);
      dispatch(push(routePaths.landings.root));
    } catch (error: any) {}
  };
};

export const saveLanding = (landing: any) => {
  return async (dispatch: Function) => {
    try {
      const createdLanding = await api.landings.createLanding(landing);
      dispatch({
        type: LANDING_CREATED,
        payload: {
          landing: createdLanding,
        },
      });
      fetchLandings(landing.userId)(dispatch);
      dispatch(push(routePaths.landings.root));
    } catch (error: any) {}
  };
};

export const initializeLandingForm = (landing?: any) => {
  return (dispatch: Function) => {
    dispatch({
      type: INITIALIZE_LANDING_FORM,
      payload: { landing } || {},
    });
  };
};

export const updateLandingForm = (form: any) => ({
  type: LANDING_FORM_UPDATED,
  payload: { form },
});

export const deleteLandings = (landingIds: number[]) => {
  if (!landingIds || !landingIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    await api.landings.deleteLandings(landingIds);
    dispatch({
      type: LANDINGS_DELETED,
      payload: {
        landingIds,
      },
    });
  };
};

export const fetchLandings = (userId: number) => {
  return async (dispatch: Function) => {
    dispatch({
      type: LANDINGS_FETCH_START,
      payload: {},
    });
    const landings = await api.landings.fetchLandings();
    dispatch({
      type: LANDINGS_FETCH_SUCCESS,
      payload: {
        landings,
      },
    });
  };
};

export const tickMultipleLandings = (landingIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_LANDINGS,
      payload: { landingIds },
    });
  };
};

export const tickSingleLanding = (landingId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_LANDING,
      payload: {
        landingId,
      },
    });
  };
};

export const navigateToCreateLandingsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.landings.create));

export const navigateToViewLandingPage =
  (landingId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.landings.view, landingId)));

export const navigateToEditLandingPage =
  (landingId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.landings.edit, landingId)));

export const navigateToLandingsManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.landings.root));
