import { ReduxAction } from "../../common-interfaces/interfaces";

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_CREATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS,
  USER_REFRESH_SUCCESS,
  TRANSACTIONS_FETCH_SUCCESS,
  //
  LOGOUT,
  CHANGE_LANGUAGE,
  SWITCH_MENU_DRAWER,
} from "./user-actions";

import {
  USER_IMPERSONATE,
  USER_STOP_IMPERSONATING,
} from "../../admin/redux/admin-actions";

import { UserInterface } from "../../common-interfaces/user";

interface UserState {
  languageCode: string;
  isMenuDrawerOpen: boolean;
  adminUser?: UserInterface | null; // this is used when the admin is impersonating another user
  user: UserInterface | null | undefined;
  isLoggingOrValidatingCookie: boolean;
  errorLogin: string;
  transactions: any[];
}

const initialState: UserState = {
  languageCode: "en",
  isMenuDrawerOpen: false,
  adminUser: null,
  user: null,
  isLoggingOrValidatingCookie: false,
  errorLogin: "",
  transactions: [],
};

export const userReducer = (
  state: UserState = initialState,
  action: ReduxAction
): UserState => {
  const { payload, type } = action;

  switch (type) {
    case TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        transactions: payload.transactions.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? -1 : 1
        ),
      };

    case LOGIN_START:
      return {
        ...state,
        isLoggingOrValidatingCookie: true,
      };
    //
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isLoggingOrValidatingCookie: false,
        errorLogin: "",
      };
    case LOGIN_FAIL:
      return {
        ...initialState, //this must be the initial state, not the current state
        isLoggingOrValidatingCookie: false,
        errorLogin: "error",
      };
    //
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        user: payload.user,
      };
    //
    case USER_REFRESH_SUCCESS:
    case USER_UPDATE_SUCCESS:
    case USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS:
      return {
        ...state,
        user: payload.user,
      };
    case USER_IMPERSONATE:
      return {
        ...state,
        adminUser: state.user,
        user: payload.user,
      };
    case USER_STOP_IMPERSONATING:
      return {
        ...state,
        adminUser: null,
        user: state.adminUser,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        languageCode: payload.languageCode,
      };
    case SWITCH_MENU_DRAWER:
      return {
        ...state,
        isMenuDrawerOpen: !state.isMenuDrawerOpen,
      };
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};
