import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

export const ZIPCODES_FETCH_START = "ZIPCODES_FETCH_START";
export const ZIPCODES_FETCH_SUCCESS = "ZIPCODES_FETCH_SUCCESS";
export const ZIPCODES_FETCH_FAIL = "ZIPCODES_FETCH_FAIL";
export const ZIPCODE_RETRIEVE_DATA_START = "ZIPCODE_RETRIEVE_DATA_START";
export const ZIPCODE_RETRIEVE_DATA_SUCCESS = "ZIPCODE_RETRIEVE_DATA_SUCCESS";
export const ZIPCODE_RETRIEVE_DATA_FAIL = "ZIPCODE_RETRIEVE_DATA_FAIL";
export const ZIPCODE_CHECK_AVAILABILITY_START =
  "ZIPCODE_CHECK_AVAILABILITY_START";
export const ZIPCODE_CHECK_AVAILABILITY_SUCCESS =
  "ZIPCODE_CHECK_AVAILABILITY_SUCCESS";
export const ZIPCODE_CHECK_AVAILABILITY_FAIL =
  "ZIPCODE_CHECK_AVAILABILITY_FAIL";
export const ZIPCODE_CHECK_OWNERS_START = "ZIPCODE_CHECK_OWNERS_START";
export const ZIPCODE_CHECK_OWNERS_SUCCESS = "ZIPCODE_CHECK_OWNERS_SUCCESS";
export const ZIPCODE_CHECK_OWNERS_FAIL = "ZIPCODE_CHECK_OWNERS_FAIL";
export const RELOCATION_CREATE_START = "RELOCATION_CREATE_START";
export const RELOCATION_CREATE_SUCCESS = "RELOCATION_CREATE_SUCCESS";
export const RELOCATION_CREATE_FAIL = "RELOCATION_CREATE_FAIL";
export const RELOCATIONS_FETCH_START = "RELOCATIONS_FETCH_START";
export const RELOCATIONS_FETCH_SUCCESS = "RELOCATIONS_FETCH_SUCCESS";
export const RELOCATIONS_FETCH_FAIL = "RELOCATIONS_FETCH_FAIL";
export const RELOCATION_UPDATE_START = "RELOCATION_UPDATE_START";
export const RELOCATION_UPDATE_SUCCESS = "RELOCATION_UPDATE_SUCCESS";
export const RELOCATION_UPDATE_FAIL = "RELOCATION_UPDATE_FAIL";

export const updateRelocationForUser = (relocationForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: RELOCATION_UPDATE_START,
      });
      const { updatedRelocation } = await api.zipcodes.updateRelocationForUser(
        relocationForm
      );

      dispatch({
        type: RELOCATION_UPDATE_SUCCESS,
        payload: { updatedRelocation },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: RELOCATION_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const createRelocation = (relocationForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: RELOCATION_CREATE_START,
      });

      const { newRelocation } = await api.zipcodes.createRelocation(
        relocationForm
      );

      dispatch({
        type: RELOCATION_CREATE_SUCCESS,
        payload: { newRelocation },
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: RELOCATION_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const fetchRelocations = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: RELOCATIONS_FETCH_START,
      });
      const { relocations } = await api.zipcodes.fetchRelocations();

      dispatch({
        type: RELOCATIONS_FETCH_SUCCESS,
        payload: { relocations },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: RELOCATIONS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const searchZipCodesOwners = (searchQuery: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ZIPCODE_CHECK_OWNERS_START,
      });

      const { searchedZipCodesWithOwners } =
        await api.zipcodes.searchZipCodesOwners(searchQuery);

      dispatch({
        type: ZIPCODE_CHECK_OWNERS_SUCCESS,
      });

      return { searchedZipCodesWithOwners };
    } catch (error: any) {
      dispatch({
        type: ZIPCODE_CHECK_OWNERS_FAIL,
      });
      return false;
    }
  };
};

export const checkZipCodeAvailability = (zip: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ZIPCODE_CHECK_AVAILABILITY_START,
      });

      const { zipCodeIsAvailable, zipCodeOwnerUsers } =
        await api.zipcodes.checkZipCodeAvailability(zip);

      dispatch({
        type: ZIPCODE_CHECK_AVAILABILITY_SUCCESS,
      });

      return { zipCodeIsAvailable, zipCodeOwnerUsers };
    } catch (error: any) {
      dispatch({
        type: ZIPCODE_CHECK_AVAILABILITY_FAIL,
      });
      return false;
    }
  };
};

export const retrievePulicZipCodeData = (zip: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ZIPCODE_RETRIEVE_DATA_START,
      });

      const { zipCode, usersPublic } =
        await api.zipcodes.retrievePulicZipCodeData(zip);

      dispatch({
        type: ZIPCODE_RETRIEVE_DATA_SUCCESS,
      });

      return { zipCode, usersPublic };
    } catch (error: any) {
      dispatch({
        type: ZIPCODE_RETRIEVE_DATA_FAIL,
      });
      return false;
    }
  };
};

export const fetchZipCodes = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: ZIPCODES_FETCH_START,
      });

      const res = await api.zipcodes.fetchZipCodes();
      const zipCodes = res.zipCodes;

      dispatch({
        type: ZIPCODES_FETCH_SUCCESS,
        payload: { zipCodes },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: ZIPCODES_FETCH_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const navigateToZipCodesManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.zipcodes.root));

export const navigateToZipCodesLearnMore = () => (dispatch: Function) =>
  dispatch(push(routePaths.zipcodes.buy));
