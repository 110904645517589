import { ReduxAction } from "../../common-interfaces/interfaces";

import { LOGOUT } from "../../user/redux/user-actions";
import {
  FETCH_STRIPE_DATA_START,
  FETCH_STRIPE_DATA_SUCCESS,
  FETCH_STRIPE_DATA_FAIL,
  CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_START,
  CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_SUCCESS,
  CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_FAIL,
  CREATE_STRIPE_CHECKOUT_SESSION_START,
  CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS,
  CREATE_STRIPE_CHECKOUT_SESSION_FAIL,
  NOTIFY_TO_ADMIN_START,
  NOTIFY_TO_ADMIN_SUCCESS,
  NOTIFY_TO_ADMIN_FAIL,
  BUYING_DIRECTMAIL_CAMPAIGN,
  CART_REMOVE_ITEM,
  RESET_CART,
  CART_PUT_ACCOUNT_FIRST_SETUP,
  CART_PUT_BRAND_CREATION_FIRST_SETUP,
  CART_PUT_TRACKING_WEBSITE_FIRST_SETUP,
  CART_PUT_PLAN,
  CART_PUT_SUBSCRIPTION_ADDONS,
  CART_PUT_ADDONS,
} from "./payments-actions";

import {
  getPlanFromTier,
  getRefillPriceFromTypeAndQuantity,
} from "../../common-utils/utils-stripe";

interface PaymentsState {
  isRedirectingToStripePortalOrSession: boolean;
  isFetchingStripeData: boolean;
  isInitializedStripeData: boolean;
  paymentsHistory: any[];
  stripeSubscriptionForPlan: any;
  stripeSubscriptionForSocialMediaManagement: any;
  stripeSubscriptionForTrackingWebsite: any;
  stripeSubscriptionForVirtualAssistant: any;
  isEmptyCart: boolean;
  cart: any;
  isNotifyingAdmin: boolean;
  directMailCampaignIdUnderPayment: number;
}

const isEmptyCart = (cart: any) => {
  let isEmptyCart = true;

  if (
    cart.accountFirstSetup.price !== 0 ||
    cart.brandCreationFirstSetup !== 0 ||
    cart.trackingWebsiteFirstSetup.price !== 0 ||
    cart.plan.price !== 0 ||
    cart.subscriptionAddons.socialMediaManagement.price !== 0 ||
    cart.subscriptionAddons.advertising.price !== 0 ||
    cart.subscriptionAddons.trackingWebsite.price !== 0 ||
    cart.subscriptionAddons.virtualAssistant.price !== 0 ||
    cart.addons.phoneMinutes.price !== 0 ||
    cart.addons.smsMessages.price !== 0 ||
    cart.addons.emails.price !== 0 ||
    cart.addons.klevrCredits.price !== 0 ||
    cart.addons.farmSize.price !== 0 ||
    cart.addons.coachingHours.price !== 0
  ) {
    isEmptyCart = false;
  }

  return isEmptyCart;
};

const initializeCart = () => {
  return {
    accountFirstSetup: { price: 0 },
    brandCreationFirstSetup: { price: 0 },
    trackingWebsiteFirstSetup: { price: 0 },
    plan: {
      tier: "",
      price: 0,
      phoneMinutesIncluded: 0,
      smsMessagesIncluded: 0,
      emailsIncluded: 0,
    },
    subscriptionAddons: {
      socialMediaManagement: { quantity: 0, price: 0 },
      advertising: { quantity: 0, price: 0 },
      trackingWebsite: { quantity: 0, price: 0 },
      virtualAssistant: { quantity: 0, price: 0 },
      zipCodeLicense: {
        quantity: 0,
        price: 0,
        zipCode: "",
        promotionCode: "",
      },
    },
    addons: {
      phoneMinutes: {
        quantity: 0,
        price: 0,
      },
      smsMessages: { quantity: 0, price: 0 },
      emails: { quantity: 0, price: 0 },
      klevrCredits: { quantity: 0, price: 0 },
      farmSize: { quantity: 0, price: 0 },
      coachingHours: { quantity: 0, price: 0 },
    },
  };
};

const initialState: PaymentsState = {
  isRedirectingToStripePortalOrSession: false,
  isFetchingStripeData: false,
  isInitializedStripeData: false,
  paymentsHistory: [],
  stripeSubscriptionForPlan: null,
  stripeSubscriptionForSocialMediaManagement: null,
  stripeSubscriptionForTrackingWebsite: null,
  stripeSubscriptionForVirtualAssistant: null,
  isEmptyCart: true,
  cart: initializeCart(),
  isNotifyingAdmin: false,
  directMailCampaignIdUnderPayment: 0,
};

export const paymentsReducer = (
  state: PaymentsState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case BUYING_DIRECTMAIL_CAMPAIGN:
      return {
        ...state,
        directMailCampaignIdUnderPayment:
          payload.directMailCampaignIdUnderPayment,
      };
    case FETCH_STRIPE_DATA_START:
      return {
        ...state,
        isFetchingStripeData: true,
        stripeSubscriptionForPlan: null,
        paymentsHistory: [],
      };

    case FETCH_STRIPE_DATA_FAIL:
      return {
        ...state,
        isFetchingStripeData: false,
      };

    case FETCH_STRIPE_DATA_SUCCESS:
      const {
        stripeSubscriptionForPlan,
        stripeSubscriptionForSocialMediaManagement,
        stripeSubscriptionForTrackingWebsite,
        stripeSubscriptionForVirtualAssistant,
        stripePaymentIntentsList,
        stripeInvoicesList,
      } = payload;

      // WARNING: this array contains objects of two different types mixed. It can contain:
      // - Stripe payment intents
      // - Stripe invoices
      const paymentsHistory = stripePaymentIntentsList
        .filter((pi: any) => pi.invoice === null)
        .concat(stripeInvoicesList)
        .sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));

      // return { ...state, isFetchingStripeData: false };

      return {
        ...state,
        isFetchingStripeData: false,
        isInitializedStripeData: true,
        stripeSubscriptionForPlan,
        stripeSubscriptionForSocialMediaManagement,
        stripeSubscriptionForTrackingWebsite,
        stripeSubscriptionForVirtualAssistant,
        paymentsHistory,
      };

    case RESET_CART:
      return {
        ...state,
        cart: initializeCart(),
        isEmptyCart: true,
      };

    case CART_PUT_SUBSCRIPTION_ADDONS:
      const socialMediaManagementRefillQuantity =
        payload.subscriptionAddonsValues.socialMediaManagementRefillQuantity ||
        state.cart.subscriptionAddons.socialMediaManagement.quantity;

      const advertisingRefillQuantity =
        payload.subscriptionAddonsValues.advertisingRefillQuantity ||
        state.cart.subscriptionAddons.advertising.quantity;

      const trackingWebsiteRefillQuantity =
        payload.subscriptionAddonsValues.trackingWebsiteRefillQuantity ||
        state.cart.subscriptionAddons.trackingWebsite.quantity;

      const virtualAssistantRefillQuantity =
        payload.subscriptionAddonsValues.virtualAssistantRefillQuantity ||
        state.cart.subscriptionAddons.virtualAssistant.quantity;

      const zipCodeLicenseRefillQuantity =
        payload.subscriptionAddonsValues.zipCodeLicenseRefillQuantity ||
        state.cart.subscriptionAddons.zipCodeLicense.quantity;

      const subscriptionAddonsInCart = {
        socialMediaManagement: {
          quantity: socialMediaManagementRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "socialMediaManagement",
            socialMediaManagementRefillQuantity
          ),
        },
        advertising: {
          quantity: advertisingRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "advertising",
            advertisingRefillQuantity
          ),
        },
        trackingWebsite: {
          quantity: trackingWebsiteRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "trackingWebsite",
            trackingWebsiteRefillQuantity
          ),
        },
        virtualAssistant: {
          quantity: virtualAssistantRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "virtualAssistant",
            virtualAssistantRefillQuantity
          ),
        },
        zipCodeLicense: {
          quantity: zipCodeLicenseRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "zipCodeLicense",
            zipCodeLicenseRefillQuantity
          ),
          zipCode: payload.subscriptionAddonsValues.zipCode || "",
          promotionCode: payload.subscriptionAddonsValues.promotionCode || "",
        },
      };

      return {
        ...state,
        cart: { ...state.cart, subscriptionAddons: subscriptionAddonsInCart },
        isEmptyCart: false,
      };

    case CART_PUT_ACCOUNT_FIRST_SETUP:
      return {
        ...state,
        cart: {
          ...state.cart,
          accountFirstSetup: {
            price: 100,
          },
        },
        isEmptyCart: false,
      };

    case CART_PUT_TRACKING_WEBSITE_FIRST_SETUP:
      return {
        ...state,
        cart: {
          ...state.cart,
          trackingWebsiteFirstSetup: {
            price: 500,
          },
        },
        isEmptyCart: false,
      };

    case CART_PUT_BRAND_CREATION_FIRST_SETUP:
      return {
        ...state,
        cart: {
          ...state.cart,
          brandCreationFirstSetup: {
            price: 1497,
          },
        },
        isEmptyCart: false,
      };

    case CART_PUT_PLAN:
      const orderedPlan = getPlanFromTier(payload.tier);

      const planInCart = orderedPlan
        ? {
            tier: orderedPlan.tier,
            price: orderedPlan.price,
            phoneMinutesIncluded: orderedPlan.phoneMinutesIncluded,
            smsMessagesIncluded: orderedPlan.smsMessagesIncluded,
            emailsIncluded: orderedPlan.emailsIncluded,
          }
        : {
            tier: "",
            price: 0,
            phoneMinutesIncluded: 0,
            smsMessagesIncluded: 0,
            emailsIncluded: 0,
          };
      return {
        ...state,
        cart: { ...state.cart, plan: planInCart },
        isEmptyCart: false,
      };

    case CART_PUT_ADDONS:
      const phoneMinutesRefillQuantity =
        payload.addonsValues.phoneMinutesRefillQuantity ||
        state.cart.addons.phoneMinutes.quantity;

      const smsMessagesRefillQuantity =
        payload.addonsValues.smsMessagesRefillQuantity ||
        state.cart.addons.smsMessages.quantity;

      const emailsRefillQuantity =
        payload.addonsValues.emailsRefillQuantity ||
        state.cart.addons.emails.quantity;

      const farmSizeRefillQuantity =
        payload.addonsValues.farmSizeRefillQuantity ||
        state.cart.addons.farmSize.quantity;

      const coachingHoursRefillQuantity =
        payload.addonsValues.coachingHoursRefillQuantity ||
        state.cart.addons.coachingHours.quantity;

      const klevrCreditsRefillQuantity =
        payload.addonsValues.klevrCreditsRefillQuantity ||
        state.cart.addons.klevrCredits.quantity;

      const addonsInCart = {
        phoneMinutes: {
          quantity: phoneMinutesRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "phoneMinutes",
            phoneMinutesRefillQuantity
          ),
        },
        smsMessages: {
          quantity: smsMessagesRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "smsMessages",
            smsMessagesRefillQuantity
          ),
        },
        emails: {
          quantity: emailsRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "emails",
            emailsRefillQuantity
          ),
        },
        klevrCredits: {
          quantity: klevrCreditsRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "klevrCredits",
            klevrCreditsRefillQuantity
          ),
        },
        farmSize: {
          quantity: farmSizeRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "farmSize",
            farmSizeRefillQuantity
          ),
        },
        coachingHours: {
          quantity: coachingHoursRefillQuantity,
          price: getRefillPriceFromTypeAndQuantity(
            "coachingHours",
            coachingHoursRefillQuantity
          ),
        },
      };

      console.log(addonsInCart);

      return {
        ...state,
        cart: { ...state.cart, addons: addonsInCart },
        isEmptyCart: false,
      };

    case CART_REMOVE_ITEM:
      let _accountFirstSetupInCart = state.cart.accountFirstSetup;
      let _brandCreationFirstSetup = state.cart.brandCreationFirstSetup;
      let _trackingWebsiteFirstSetupInCart =
        state.cart.trackingWebsiteFirstSetup;
      let _planInCart = state.cart.plan;
      let _subscriptionAddons = state.cart.subscriptionAddons;
      let _addonsInCart = state.cart.addons;

      switch (payload.itemType) {
        case "plan":
          _planInCart = {
            tier: "",
            price: 0,
            phoneMinutesIncluded: 0,
            smsMessagesIncluded: 0,
            emailsIncluded: 0,
          };
          break;
        case "phoneMinutes":
          _addonsInCart.phoneMinutes = { quantity: 0, price: 0 };
          break;
        case "smsMessages":
          _addonsInCart.smsMessages = { quantity: 0, price: 0 };
          break;
        case "emails":
          _addonsInCart.emails = { quantity: 0, price: 0 };
          break;
        case "klevrCredits":
          _addonsInCart.klevrCredits = { quantity: 0, price: 0 };
          break;
        case "farmSize":
          _addonsInCart.farmSize = { quantity: 0, price: 0 };
          break;
        case "coachingHours":
          _addonsInCart.coachingHours = { quantity: 0, price: 0 };
          break;
        case "socialMediaManagement":
          _subscriptionAddons.socialMediaManagement = { quantity: 0, price: 0 };
          break;
        case "trackingWebsite":
          _subscriptionAddons.trackingWebsite = { quantity: 0, price: 0 };
          break;
        case "advertising":
          _subscriptionAddons.advertising = { quantity: 0, price: 0 };
          break;
        case "virtualAssistant":
          _subscriptionAddons.virtualAssistant = { quantity: 0, price: 0 };
          break;
        case "zipCodeLicense":
          _subscriptionAddons.zipCodeLicense = {
            quantity: 0,
            price: 0,
            zipCode: "",
          };
          break;
        default:
          throw new Error();
      }

      let _cart = {
        accountFirstSetup: _accountFirstSetupInCart,
        trackingWebsiteFirstSetup: _trackingWebsiteFirstSetupInCart,
        brandCreationFirstSetup: _brandCreationFirstSetup,
        plan: _planInCart,
        subscriptionAddons: _subscriptionAddons,
        addons: _addonsInCart,
      };

      return { ...state, cart: _cart, isEmptyCart: isEmptyCart(_cart) };

    case NOTIFY_TO_ADMIN_START:
      return {
        ...state,
        isNotifyingAdmin: true,
      };
    case NOTIFY_TO_ADMIN_SUCCESS:
    case NOTIFY_TO_ADMIN_FAIL:
      return {
        ...state,
        isNotifyingAdmin: false,
      };

    case CREATE_STRIPE_CHECKOUT_SESSION_START:
    case CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_START:
      return {
        ...state,
        isRedirectingToStripePortalOrSession: true,
      };

    case CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS:
    case CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_SUCCESS:
      // in this case the variable should actually never be se to false, because the spinner goes on
      // until the redirect to Stripe Portal or Checkout Session is executed.
      return {
        ...state,
        //  isRedirectingToStripePortalOrSession: false,
      };

    case CREATE_STRIPE_CHECKOUT_SESSION_FAIL:
    case CREATE_STRIPE_CUSTOMER_PORTAL_SESSION_FAIL:
      return {
        ...state,
        isRedirectingToStripePortalOrSession: false,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};
