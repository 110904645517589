import { ReduxAction } from "../common-interfaces/interfaces";

import {
  ADMIN_FETCH_USERS_START,
  ADMIN_FETCH_USERS_SUCCESS,
  ADMIN_FETCH_USERS_FAIL,
  ADMIN_RELOCATIONS_FETCH_START,
  ADMIN_RELOCATIONS_FETCH_SUCCESS,
  ADMIN_RELOCATIONS_FETCH_FAIL,
  ADMIN_TRANSACTIONS_FETCH_START,
  ADMIN_TRANSACTIONS_FETCH_SUCCESS,
  ADMIN_TRANSACTIONS_FETCH_FAIL,
  ADMIN_TRANSACTION_UPDATE_START,
  ADMIN_TRANSACTION_UPDATE_SUCCESS,
  ADMIN_TRANSACTION_UPDATE_FAIL,
  ADMIN_ZIPCODES_FETCH_START,
  ADMIN_ZIPCODES_FETCH_SUCCESS,
  ADMIN_ZIPCODES_FETCH_FAIL,
  ADMIN_UPDATE_ZIPCODE_START,
  ADMIN_UPDATE_ZIPCODE_SUCCESS,
  ADMIN_UPDATE_ZIPCODE_FAIL,
  ADMIN_COLELEADS_IMPORT_FAIL,
  ADMIN_COLELEADS_IMPORT_START,
  ADMIN_COLELEADS_IMPORT_SUCCESS,
  ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL,
  ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START,
  ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS,
  ADMIN_CLONE_TEMPLATES_START,
  ADMIN_CLONE_TEMPLATES_SUCCESS,
  ADMIN_CLONE_TEMPLATES_FAIL,
  ADMIN_CLONE_DRIPS_START,
  ADMIN_CLONE_DRIPS_SUCCESS,
  ADMIN_CLONE_DRIPS_FAIL,
} from "../admin/redux/admin-actions";
import {
  CAMPAIGN_CREATE_FAIL,
  CAMPAIGN_CREATE_START,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_UPDATE_FAIL,
  CAMPAIGN_UPDATE_START,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGNS_DELETE_FAIL,
  CAMPAIGNS_DELETE_START,
  CAMPAIGNS_DELETE_SUCCESS,
  CAMPAIGNS_FETCH_FAIL,
  CAMPAIGNS_FETCH_START,
  CAMPAIGNS_FETCH_SUCCESS,
  DIRECTMAIL_CAMPAIGN_CANCEL_FAIL,
  DIRECTMAIL_CAMPAIGN_CANCEL_START,
  DIRECTMAIL_CAMPAIGN_CANCEL_SUCCESS,
  DIRECTMAIL_CAMPAIGN_CREATE_FAIL,
  DIRECTMAIL_CAMPAIGN_CREATE_START,
  DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS,
  DIRECTMAIL_CAMPAIGN_PAY_FAIL,
  DIRECTMAIL_CAMPAIGN_PAY_START,
  DIRECTMAIL_CAMPAIGN_PAY_SUCCESS,
  DIRECTMAIL_CAMPAIGN_UPDATE_FAIL,
  DIRECTMAIL_CAMPAIGN_UPDATE_START,
  DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS,
  DIRECTMAIL_CAMPAIGNS_DELETE_FAIL,
  DIRECTMAIL_CAMPAIGNS_DELETE_START,
  DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS,
  DIRECTMAIL_CAMPAIGNS_FETCH_FAIL,
  DIRECTMAIL_CAMPAIGNS_FETCH_START,
  DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS,
} from "../campaigns/redux/campaigns-actions";
import {
  CALLLOGS_FETCH_START,
  CALLLOGS_FETCH_SUCCESS,
  CALLLOGS_FETCH_FAIL,
  CALLLOG_CREATE_START,
  CALLLOG_CREATE_SUCCESS,
  CALLLOG_CREATE_FAIL,
  CALLLOG_UPDATE_NOTETEXT,
  CALLLOG_UPDATE_NOTETEXT_SUCCESS,
  CALLLOG_UPDATE_NOTETEXT_FAIL,
  CALLLOG_DELETE_VOICEMESSAGE_START,
  CALLLOG_DELETE_VOICEMESSAGE_SUCCESS,
  CALLLOG_DELETE_VOICEMESSAGE_START_FAIL,
} from "../dialer/redux/dialer-actions";
import {
  DRIPS_FETCH_START,
  DRIPS_FETCH_SUCCESS,
  DRIPS_FETCH_FAIL,
  DRIPS_SHARE_START,
  DRIPS_SHARE_SUCCESS,
  DRIPS_SHARE_FAIL,
} from "../drips/redux/drips-actions";
import {
  EMAILS_FETCH_START,
  EMAILS_FETCH_SUCCESS,
  EMAILS_FETCH_FAIL,
  EMAIL_FETCH_SINGLE_START,
  EMAIL_FETCH_SINGLE_SUCCESS,
  EMAIL_FETCH_SINGLE_FAIL,
  EMAIL_SEND_START,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
} from "../inbox-email/redux/inbox-actions";
import {
  SMS_FETCH_CONVERSATIONS_START,
  SMS_FETCH_CONVERSATIONS_SUCCESS,
  SMS_FETCH_CONVERSATIONS_FAIL,
  SMS_SEND_START,
  SMS_SEND_SUCCESS,
  SMS_SEND_FAIL,
  SMS_SET_READ_START,
  SMS_SET_READ_SUCCESS,
  SMS_SET_READ_FAIL,
  SMS_FETCH_SINGLE_CONVERSATION_START,
  SMS_FETCH_SINGLE_CONVERSATION_SUCCESS,
  SMS_FETCH_SINGLE_CONVERSATION_FAIL,
} from "../inbox-sms/redux/sms-inbox-actions";
import {
  LANDINGS_FETCH_FAIL,
  LANDINGS_FETCH_START,
  LANDINGS_FETCH_SUCCESS,
} from "../landings/redux/landings-actions";
import {
  LEADS_ALL_FETCH_START,
  LEADS_ALL_FETCH_PROGRESS,
  LEADS_ALL_FETCH_SUCCESS,
  LEADS_ALL_FETCH_FAIL,
  LEADS_RECENT_FETCH_START,
  LEADS_RECENT_FETCH_SUCCESS,
  LEADS_RECENT_FETCH_FAIL,
  LEAD_CREATE_START,
  LEAD_CREATE_SUCCESS,
  LEAD_CREATE_FAIL,
  LEADS_DELETE_START,
  LEADS_DELETE_SUCCESS,
  LEADS_DELETE_FAIL,
  LEAD_UPDATE_START,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_FAIL,
  LEAD_UPDATE_PHONE_NUMBERS_START,
  LEAD_UPDATE_PHONE_NUMBERS_SUCCESS,
  LEAD_UPDATE_PHONE_NUMBERS_FAIL,
  LEAD_UPDATE_EMAILS_START,
  LEAD_UPDATE_EMAILS_SUCCESS,
  LEAD_UPDATE_EMAILS_FAIL,
  LEAD_UPDATE_DNC_START,
  LEAD_UPDATE_DNC_SUCCESS,
  LEAD_UPDATE_DNC_FAIL,
  LEAD_UPDATE_CLOUDCMAREPORT_START,
  LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS,
  LEAD_UPDATE_CLOUDCMAREPORT_FAIL,
  LEAD_UPDATE_ENHANCEDINFO_START,
  LEAD_UPDATE_ENHANCEDINFO_SUCCESS,
  LEAD_UPDATE_ENHANCEDINFO_FAIL,
  ACTIVEDRIPS_CREATE_START,
  ACTIVEDRIPS_CREATE_SUCCESS,
  ACTIVEDRIPS_CREATE_FAIL,
  ACTIVEDRIPS_DELETE_START,
  ACTIVEDRIPS_DELETE_SUCCESS,
  ACTIVEDRIPS_DELETE_FAIL,
  ACTIVEDRIP_UPDATE_START,
  ACTIVEDRIP_UPDATE_SUCCESS,
  ACTIVEDRIP_UPDATE_FAIL,
  LIKELYLEADS_IMPORT_START,
  LIKELYLEADS_IMPORT_PROGRESS,
  LIKELYLEADS_IMPORT_SUCCESS,
  LIKELYLEADS_IMPORT_FAIL,
  BULKLEADS_IMPORT_START,
  BULKLEADS_IMPORT_PROGRESS,
  BULKLEADS_IMPORT_SUCCESS,
  BULKLEADS_IMPORT_FAIL,
} from "../leads/redux/leads-actions";
import {
  LIST_CREATE_FAIL,
  LIST_CREATE_START,
  LIST_CREATE_SUCCESS,
  LIST_SPLIT_FAIL,
  LIST_SPLIT_START,
  LIST_SPLIT_SUCCESS,
  LIST_UPDATE_FAIL,
  LIST_UPDATE_START,
  LIST_UPDATE_SUCCESS,
  LISTS_DELETE_FAIL,
  LISTS_DELETE_START,
  LISTS_DELETE_SUCCESS,
  LISTS_FETCH_FAIL,
  LISTS_FETCH_START,
  LISTS_FETCH_SUCCESS,
  LIKELY_ABORT_OPERATION_START,
  LIKELY_ABORT_OPERATION_SUCCESS,
  LIKELY_ABORT_OPERATION_FAIL,
  LIKELY_COMPLETE_OPERATION_START,
  LIKELY_COMPLETE_OPERATION_SUCCESS,
  LIKELY_COMPLETE_OPERATION_FAIL,
  LIKELY_DBREFRESH_ESTIMATECOST_START,
  LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS,
  LIKELY_DBREFRESH_ESTIMATECOST_FAIL,
  LIKELY_DBREFRESH_PAYANDPROCESS_START,
  LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS,
  LIKELY_DBREFRESH_PAYANDPROCESS_FAIL,
  LIKELY_SKIPTRACE_ESTIMATECOST_START,
  LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS,
  LIKELY_SKIPTRACE_ESTIMATECOST_FAIL,
  LIKELY_SKIPTRACE_PAYANDPROCESS_START,
  LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS,
  LIKELY_SKIPTRACE_PAYANDPROCESS_FAIL,
} from "../lists/redux/lists-actions";
import {} from "../payments/redux/payments-actions";
import {
  SUPERUSER_RELOCATIONS_FETCH_START,
  SUPERUSER_RELOCATIONS_FETCH_SUCCESS,
  SUPERUSER_RELOCATIONS_FETCH_FAIL,
  SUPERUSER_RELOCATION_UPDATE_START,
  SUPERUSER_RELOCATION_UPDATE_SUCCESS,
  SUPERUSER_RELOCATION_UPDATE_FAIL,
} from "../superuser/redux/superuser-actions";
import {
  TAGS_FOR_USER_CREATE_FAIL,
  TAGS_FOR_USER_CREATE_START,
  TAGS_FOR_USER_CREATE_SUCCESS,
  TAGS_FOR_USER_UPDATE_FAIL,
  TAGS_FOR_USER_UPDATE_START,
  TAGS_FOR_USER_UPDATE_SUCCESS,
  TAGS_FOR_USER_DELETE_FAIL,
  TAGS_FOR_USER_DELETE_START,
  TAGS_FOR_USER_DELETE_SUCCESS,
  TAGS_FOR_LEADS_CREATE_FAIL,
  TAGS_FOR_LEADS_CREATE_START,
  TAGS_FOR_LEADS_CREATE_SUCCESS,
  TAGS_FOR_LEADS_DELETE_FAIL,
  TAGS_FOR_LEADS_DELETE_START,
  TAGS_FOR_LEADS_DELETE_SUCCESS,
} from "../tags/redux/tags-actions";
import {
  POSTCARD_TEMPLATE_CREATE_FAIL,
  POSTCARD_TEMPLATE_CREATE_START,
  POSTCARD_TEMPLATE_CREATE_SUCCESS,
  POSTCARD_TEMPLATES_DELETE_FAIL,
  POSTCARD_TEMPLATES_DELETE_START,
  POSTCARD_TEMPLATES_DELETE_SUCCESS,
  POSTCARD_TEMPLATE_UPDATE_FAIL,
  POSTCARD_TEMPLATE_UPDATE_START,
  POSTCARD_TEMPLATE_UPDATE_SUCCESS,
  POSTCARD_TEMPLATES_FETCH_FAIL,
  POSTCARD_TEMPLATES_FETCH_START,
  POSTCARD_TEMPLATES_FETCH_SUCCESS,
  POSTCARDMANIA_DESIGNS_FETCH_FAIL,
  POSTCARDMANIA_DESIGNS_FETCH_START,
  POSTCARDMANIA_DESIGNS_FETCH_SUCCESS,
  POSTCARDMANIA_PROOF_GENERATE_FAIL,
  POSTCARDMANIA_PROOF_GENERATE_START,
  POSTCARDMANIA_PROOF_GENERATE_SUCCESS,
  //
  EMAIL_TEMPLATES_FETCH_START,
  EMAIL_TEMPLATES_FETCH_SUCCESS,
  EMAIL_TEMPLATES_FETCH_FAIL,
  EMAIL_TEMPLATE_CREATE_START,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_CREATE_FAIL,
  EMAIL_TEMPLATES_DUPLICATE_START,
  EMAIL_TEMPLATES_DUPLICATE_SUCCESS,
  EMAIL_TEMPLATES_DUPLICATE_FAIL,
  EMAIL_TEMPLATE_UPDATE_START,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_FAIL,
  EMAIL_TEMPLATES_DELETE_START,
  EMAIL_TEMPLATES_DELETE_SUCCESS,
  EMAIL_TEMPLATES_DELETE_FAIL,
  EMAIL_TEMPLATES_SHARE_START,
  EMAIL_TEMPLATES_SHARE_SUCCESS,
  EMAIL_TEMPLATES_SHARE_FAIL,

  //
  SMS_TEMPLATES_FETCH_START,
  SMS_TEMPLATES_FETCH_SUCCESS,
  SMS_TEMPLATES_FETCH_FAIL,
  SMS_TEMPLATE_CREATE_START,
  SMS_TEMPLATE_CREATE_SUCCESS,
  SMS_TEMPLATE_CREATE_FAIL,
  SMS_TEMPLATE_UPDATE_START,
  SMS_TEMPLATE_UPDATE_SUCCESS,
  SMS_TEMPLATE_UPDATE_FAIL,
  SMS_TEMPLATES_DELETE_START,
  SMS_TEMPLATES_DELETE_SUCCESS,
  SMS_TEMPLATES_DELETE_FAIL,
  SMS_TEMPLATES_SHARE_START,
  SMS_TEMPLATES_SHARE_SUCCESS,
  SMS_TEMPLATES_SHARE_FAIL,
} from "../templates/redux/templates-actions";
import {
  USER_CREATE_START,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_ALIAS_FOR_SENDGRID_START,
  USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS,
  USER_UPDATE_ALIAS_FOR_SENDGRID_FAIL,
  USER_PASSWORD_CHANGE_START,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  FILE_UPLOAD_START,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  RETRIEVE_SOCIAL_DATA_START,
  RETRIEVE_SOCIAL_DATA_SUCCESS,
  RETRIEVE_SOCIAL_DATA_FAIL,
  TRANSACTIONS_FETCH_START,
  TRANSACTIONS_FETCH_SUCCESS,
  TRANSACTIONS_FETCH_FAIL,
  LOGOUT,
} from "../user/redux/user-actions";
import {
  ZIPCODES_FETCH_START,
  ZIPCODES_FETCH_SUCCESS,
  ZIPCODES_FETCH_FAIL,
  ZIPCODE_RETRIEVE_DATA_START,
  ZIPCODE_RETRIEVE_DATA_SUCCESS,
  ZIPCODE_RETRIEVE_DATA_FAIL,
  ZIPCODE_CHECK_AVAILABILITY_START,
  ZIPCODE_CHECK_AVAILABILITY_SUCCESS,
  ZIPCODE_CHECK_AVAILABILITY_FAIL,
  ZIPCODE_CHECK_OWNERS_START,
  ZIPCODE_CHECK_OWNERS_SUCCESS,
  ZIPCODE_CHECK_OWNERS_FAIL,
  RELOCATIONS_FETCH_START,
  RELOCATIONS_FETCH_SUCCESS,
  RELOCATIONS_FETCH_FAIL,
  RELOCATION_CREATE_START,
  RELOCATION_CREATE_SUCCESS,
  RELOCATION_CREATE_FAIL,
  RELOCATION_UPDATE_START,
  RELOCATION_UPDATE_SUCCESS,
  RELOCATION_UPDATE_FAIL,
} from "../zipcodes/redux/zipcodes-actions";

interface ApiMessagesState {
  admin: {
    isImportingColeLeads: boolean;
    isAdminFetchingUsers: boolean;
    isAdminFetchingDirectMailCampaigns: boolean;
    isAdminChangingZipCodes: boolean;
    isAdminFetchingZipCodes: boolean;
    isAdminCloningTemplatesOrDrips: boolean;
    isAdminFetchingRelocations: boolean;
    isAdminFetchingTransactions: boolean;
    isAdminChangingTransaction: boolean;
  };
  campaigns: {
    isFetchingCampaigns: boolean;
    isChangingCampaigns: boolean;
    errorChangingCampaigns: string;
    isFetchingDirectMailCampaigns: boolean;
    isChangingDirectMailCampaigns: boolean;
  };
  dialer: {
    isCreatingCallLog: boolean;
    numberOfChangingCallLog: number;
    inboundCallSidOfUpdatingCallLogNote: any;
    isUpdatingCallLog: boolean;
  };
  drips: { isFetchingDrips: boolean; isChangingDrips: boolean };
  inboxEmail: {
    isFetchingEmails: boolean;
    isSendingEmail: boolean;
    errorSendingEmail: string;
    isChangingEmails: boolean;
  };
  inboxSms: {
    isFetchingSmsMessages: boolean;
    isSendingSmsMessage: boolean;
    errorSendingSmsMessage: string;
  };
  landings: { isFetchingLandings: boolean };
  leads: {
    isFetchingLeads: boolean;
    isChangingLeads: boolean;
    errorChangingLeads: string;
    isUpdatingCloudCMAReport: boolean;
    isFetchingLeadEnhancedInfo: boolean;
    isImportingLeads: boolean;
  };
  lists: {
    isFetchingLists: boolean;
    isChangingLists: boolean;
    errorChangingLists: string;
  };
  payments: any;
  superuser: {
    isFetchingSuperUserRelocations: boolean;
    isChangingSuperUserRelocation: boolean;
  };
  tags: { isChangingTags: boolean; errorChangingTags: string };
  templates: {
    isFetchingSmsTemplates: boolean;
    isFetchingEmailTemplates: boolean;
    isFetchingPostcardTemplates: boolean;
    isFetchingPostcardmaniaDesigns: boolean;
    isChangingSmsTemplates: boolean;
    isChangingEmailTemplates: boolean;
    isChangingPostcardTemplate: boolean;
    isGeneratingPostcardmaniaProof: boolean;
    errorCreatingPostcardmaniaProof: string;
  };
  user: {
    isChangingUser: boolean;
    isUploadingFile: boolean;
    fileUploadingPurpose: string;
    isRetrievingSocialData: boolean;
    errorChangingUser: string;
    isFetchingTransactions: boolean;
  };
  zipcodes: {
    isFetchingZipCodes: boolean;
    isCheckingZipCodes: boolean;
    isChangingRelocation: boolean;
    isRetrievingPublicZipCodesData: boolean;
    isFetchingRelocations: boolean;
  };
}

const initialState: ApiMessagesState = {
  admin: {
    isImportingColeLeads: false,
    isAdminFetchingUsers: false,
    isAdminFetchingDirectMailCampaigns: false,
    isAdminChangingZipCodes: false,
    isAdminFetchingZipCodes: false,
    isAdminCloningTemplatesOrDrips: false,
    isAdminFetchingRelocations: false,
    isAdminFetchingTransactions: false,
    isAdminChangingTransaction: false,
  },
  campaigns: {
    isFetchingCampaigns: false,
    isChangingCampaigns: false,
    errorChangingCampaigns: "",
    isFetchingDirectMailCampaigns: false,
    isChangingDirectMailCampaigns: false,
  },
  dialer: {
    isCreatingCallLog: false,
    numberOfChangingCallLog: -1,
    inboundCallSidOfUpdatingCallLogNote: null,
    isUpdatingCallLog: false,
  },
  drips: { isFetchingDrips: false, isChangingDrips: false },
  inboxEmail: {
    isFetchingEmails: false,
    isSendingEmail: false,
    errorSendingEmail: "",
    isChangingEmails: false,
  },
  inboxSms: {
    isFetchingSmsMessages: false,
    isSendingSmsMessage: false,
    errorSendingSmsMessage: "",
  },
  landings: { isFetchingLandings: false },
  leads: {
    isFetchingLeads: false,
    isChangingLeads: false,
    errorChangingLeads: "",
    isUpdatingCloudCMAReport: false,
    isFetchingLeadEnhancedInfo: false,
    isImportingLeads: false,
  },
  lists: {
    isFetchingLists: false,
    isChangingLists: false,
    errorChangingLists: "",
  },
  payments: {},
  superuser: {
    isFetchingSuperUserRelocations: false,
    isChangingSuperUserRelocation: false,
  },
  tags: { isChangingTags: false, errorChangingTags: "" },
  templates: {
    isFetchingSmsTemplates: false,
    isFetchingEmailTemplates: false,
    isFetchingPostcardTemplates: false,
    isFetchingPostcardmaniaDesigns: false,
    isChangingSmsTemplates: false,
    isChangingEmailTemplates: false,
    isChangingPostcardTemplate: false,
    isGeneratingPostcardmaniaProof: false,
    errorCreatingPostcardmaniaProof: "",
  },
  user: {
    isChangingUser: false,
    isUploadingFile: false,
    fileUploadingPurpose: "",
    isRetrievingSocialData: false,
    errorChangingUser: "",
    isFetchingTransactions: false,
  },
  zipcodes: {
    isFetchingZipCodes: false,
    isCheckingZipCodes: false,
    isChangingRelocation: false,
    isRetrievingPublicZipCodesData: false,
    isFetchingRelocations: false,
  },
};

export const apiStatusReducer = (
  state: ApiMessagesState = initialState,
  action: ReduxAction
): ApiMessagesState => {
  const { payload, type } = action;

  switch (type) {
    // admin
    case ADMIN_FETCH_USERS_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingUsers: true,
        },
      };
    case ADMIN_FETCH_USERS_SUCCESS:
    case ADMIN_FETCH_USERS_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingUsers: false,
        },
      };

    case ADMIN_RELOCATIONS_FETCH_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingRelocations: true,
        },
      };
    case ADMIN_RELOCATIONS_FETCH_SUCCESS:
    case ADMIN_RELOCATIONS_FETCH_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingRelocations: false,
        },
      };

    case ADMIN_TRANSACTIONS_FETCH_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingTransactions: true,
        },
      };
    case ADMIN_TRANSACTIONS_FETCH_SUCCESS:
    case ADMIN_TRANSACTIONS_FETCH_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingTransactions: false,
        },
      };

    case ADMIN_TRANSACTION_UPDATE_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminChangingTransaction: true,
        },
      };
    case ADMIN_TRANSACTION_UPDATE_SUCCESS:
    case ADMIN_TRANSACTION_UPDATE_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminChangingTransaction: false,
        },
      };

    case ADMIN_ZIPCODES_FETCH_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingZipCodes: true,
        },
      };
    case ADMIN_ZIPCODES_FETCH_SUCCESS:
    case ADMIN_ZIPCODES_FETCH_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingZipCodes: false,
        },
      };

    case ADMIN_UPDATE_ZIPCODE_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminChangingZipCodes: true,
        },
      };
    case ADMIN_UPDATE_ZIPCODE_SUCCESS:
    case ADMIN_UPDATE_ZIPCODE_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminChangingZipCodes: false,
        },
      };

    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingDirectMailCampaigns: true,
        },
      };
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS:
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminFetchingDirectMailCampaigns: false,
        },
      };

    case ADMIN_COLELEADS_IMPORT_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isImportingColeLeads: true,
        },
      };
    case ADMIN_COLELEADS_IMPORT_SUCCESS:
    case ADMIN_COLELEADS_IMPORT_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isImportingColeLeads: true,
        },
      };

    case ADMIN_CLONE_TEMPLATES_START:
    case ADMIN_CLONE_DRIPS_START:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminCloningTemplatesOrDrips: true,
        },
      };
    case ADMIN_CLONE_TEMPLATES_SUCCESS:
    case ADMIN_CLONE_TEMPLATES_FAIL:
    case ADMIN_CLONE_DRIPS_SUCCESS:
    case ADMIN_CLONE_DRIPS_FAIL:
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdminCloningTemplatesOrDrips: false,
        },
      };

    // campaigns
    case DIRECTMAIL_CAMPAIGNS_FETCH_START:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isFetchingDirectMailCampaigns: true,
        },
      };
    case DIRECTMAIL_CAMPAIGNS_FETCH_SUCCESS:
    case DIRECTMAIL_CAMPAIGNS_FETCH_FAIL:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isFetchingDirectMailCampaigns: false,
        },
      };

    case DIRECTMAIL_CAMPAIGN_CREATE_START:
    case DIRECTMAIL_CAMPAIGN_UPDATE_START:
    case DIRECTMAIL_CAMPAIGNS_DELETE_START:
    case DIRECTMAIL_CAMPAIGN_PAY_START:
    case DIRECTMAIL_CAMPAIGN_CANCEL_START:
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_START:
      return {
        ...state,
        campaigns: { ...state.campaigns, isChangingDirectMailCampaigns: true },
      };
    case DIRECTMAIL_CAMPAIGN_CREATE_SUCCESS:
    case DIRECTMAIL_CAMPAIGN_CREATE_FAIL:
    case DIRECTMAIL_CAMPAIGN_UPDATE_SUCCESS:
    case DIRECTMAIL_CAMPAIGN_UPDATE_FAIL:
    case DIRECTMAIL_CAMPAIGNS_DELETE_SUCCESS:
    case DIRECTMAIL_CAMPAIGNS_DELETE_FAIL:
    case DIRECTMAIL_CAMPAIGN_PAY_SUCCESS:
    case DIRECTMAIL_CAMPAIGN_PAY_FAIL:
    case DIRECTMAIL_CAMPAIGN_CANCEL_SUCCESS:
    case DIRECTMAIL_CAMPAIGN_CANCEL_FAIL:
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS:
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_FAIL:
      return {
        ...state,
        campaigns: { ...state.campaigns, isChangingDirectMailCampaigns: false },
      };

    case CAMPAIGNS_FETCH_START:
      return {
        ...state,
        campaigns: { ...state.campaigns, isFetchingCampaigns: true },
      };
    case CAMPAIGNS_FETCH_SUCCESS:
    case CAMPAIGNS_FETCH_FAIL:
      return {
        ...state,
        campaigns: { ...state.campaigns, isFetchingCampaigns: false },
      };
    case CAMPAIGN_CREATE_START:
    case CAMPAIGN_UPDATE_START:
    case CAMPAIGNS_DELETE_START:
      return {
        ...state,
        campaigns: { ...state.campaigns, isChangingCampaigns: true },
      };
    case CAMPAIGN_CREATE_SUCCESS:
    case CAMPAIGN_UPDATE_SUCCESS:
    case CAMPAIGNS_DELETE_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isChangingCampaigns: false,
          errorChangingCampaigns: "",
        },
      };
    case CAMPAIGN_CREATE_FAIL:
    case CAMPAIGN_UPDATE_FAIL:
    case CAMPAIGNS_DELETE_FAIL:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isChangingCampaigns: false,
          errorChangingCampaigns: "Server error on Campaigns operation",
        },
      };

    // dialer
    case CALLLOG_CREATE_START:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          isCreatingCallLog: true,
        },
      };
    case CALLLOG_CREATE_SUCCESS:
    case CALLLOG_CREATE_FAIL:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          isCreatingCallLog: false,
        },
      };

    case CALLLOG_UPDATE_NOTETEXT:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          inboundCallSidOfUpdatingCallLogNote: payload.inboundCallSid,
          isUpdatingCallLog: true,
        },
      };

    case CALLLOG_UPDATE_NOTETEXT_SUCCESS:
    case CALLLOG_UPDATE_NOTETEXT_FAIL:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          inboundCallSidOfUpdatingCallLogNote: null,
          isUpdatingCallLog: false,
        },
      };

    case CALLLOG_DELETE_VOICEMESSAGE_START:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          numberOfChangingCallLog: payload.callLogId,
        },
      };
    case CALLLOG_DELETE_VOICEMESSAGE_SUCCESS:
    case CALLLOG_DELETE_VOICEMESSAGE_START_FAIL:
      return {
        ...state,
        dialer: {
          ...state.dialer,
          numberOfChangingCallLog: -1,
        },
      };

    // drips
    case DRIPS_FETCH_START:
      return {
        ...state,
        drips: { ...state.drips, isFetchingDrips: true },
      };
    case DRIPS_FETCH_SUCCESS:
    case DRIPS_FETCH_FAIL:
      return {
        ...state,
        drips: { ...state.drips, isFetchingDrips: false },
      };
    case DRIPS_SHARE_START:
      return {
        ...state,
        drips: { ...state.drips, isChangingDrips: true },
      };
    case DRIPS_SHARE_SUCCESS:
    case DRIPS_SHARE_FAIL:
      return {
        ...state,
        drips: { ...state.drips, isChangingDrips: false },
      };

    // inboxEmail
    case EMAILS_FETCH_START:
      return {
        ...state,
        inboxEmail: { ...state.inboxEmail, isFetchingEmails: true },
      };
    case EMAILS_FETCH_SUCCESS:
    case EMAILS_FETCH_FAIL:
      return {
        ...state,
        inboxEmail: { ...state.inboxEmail, isFetchingEmails: false },
      };

    case EMAIL_FETCH_SINGLE_START:
      return {
        ...state,
        inboxEmail: { ...state.inboxEmail, isChangingEmails: true },
      };
    case EMAIL_FETCH_SINGLE_SUCCESS:
    case EMAIL_FETCH_SINGLE_FAIL:
      return {
        ...state,
        inboxEmail: { ...state.inboxEmail, isChangingEmails: false },
      };

    case EMAIL_SEND_START:
      return {
        ...state,
        inboxEmail: {
          ...state.inboxEmail,
          isSendingEmail: true,
          errorSendingEmail: "",
        },
      };
    case EMAIL_SEND_SUCCESS:
      return {
        ...state,
        inboxEmail: {
          ...state.inboxEmail,
          isSendingEmail: false,
          errorSendingEmail: "",
        },
      };
    case EMAIL_SEND_FAIL:
      return {
        ...state,
        inboxEmail: {
          ...state.inboxEmail,
          isSendingEmail: false,
          errorSendingEmail: "Error sending Email",
        },
      };

    // inboxSms
    case SMS_FETCH_CONVERSATIONS_START:
    case SMS_FETCH_SINGLE_CONVERSATION_START:
      return {
        ...state,
        inboxSms: { ...state.inboxSms, isFetchingSmsMessages: true },
      };
    case SMS_FETCH_CONVERSATIONS_SUCCESS:
    case SMS_FETCH_CONVERSATIONS_FAIL:
    case SMS_FETCH_SINGLE_CONVERSATION_SUCCESS:
    case SMS_FETCH_SINGLE_CONVERSATION_FAIL:
      return {
        ...state,
        inboxSms: { ...state.inboxSms, isFetchingSmsMessages: false },
      };

    case SMS_SEND_START:
      return {
        ...state,
        inboxSms: {
          ...state.inboxSms,
          isSendingSmsMessage: true,
          errorSendingSmsMessage: "",
        },
      };
    case SMS_SEND_SUCCESS:
      return {
        ...state,
        inboxSms: {
          ...state.inboxSms,
          isSendingSmsMessage: false,
          errorSendingSmsMessage: "",
        },
      };
    case SMS_SEND_FAIL:
      return {
        ...state,
        inboxSms: {
          ...state.inboxSms,
          isSendingSmsMessage: false,
          errorSendingSmsMessage: "Error sending SMS message",
        },
      };
    case SMS_SET_READ_START:
    case SMS_SET_READ_SUCCESS:
    case SMS_SET_READ_FAIL:
      return {
        ...state,
        inboxSms: { ...state.inboxSms },
      };

    // landings
    case LANDINGS_FETCH_START:
      return {
        ...state,
        landings: { ...state.landings, isFetchingLandings: true },
      };
    case LANDINGS_FETCH_SUCCESS:
    case LANDINGS_FETCH_FAIL:
      return {
        ...state,
        landings: { ...state.landings, isFetchingLandings: false },
      };

    // leads
    case LEADS_ALL_FETCH_START:
    case LEADS_RECENT_FETCH_START:
      return {
        ...state,
        leads: { ...state.leads, isFetchingLeads: true },
      };
    case LEADS_ALL_FETCH_SUCCESS:
    case LEADS_RECENT_FETCH_SUCCESS:
    case LEADS_ALL_FETCH_FAIL:
    case LEADS_RECENT_FETCH_FAIL:
      return {
        ...state,
        leads: { ...state.leads, isFetchingLeads: false },
      };

    case LEAD_CREATE_START:
    case LEADS_DELETE_START:
    case LEAD_UPDATE_START:
    case LEAD_UPDATE_PHONE_NUMBERS_START:
    case LEAD_UPDATE_EMAILS_START:
    case LEAD_UPDATE_DNC_START:
    case ACTIVEDRIPS_CREATE_START:
    case ACTIVEDRIPS_DELETE_START:
    case ACTIVEDRIP_UPDATE_START:
      return {
        ...state,
        leads: { ...state.leads, isChangingLeads: true },
      };

    case LEAD_CREATE_SUCCESS:
    case LEADS_DELETE_SUCCESS:
    case LEAD_UPDATE_SUCCESS:
    case LEAD_UPDATE_PHONE_NUMBERS_SUCCESS:
    case LEAD_UPDATE_EMAILS_SUCCESS:
    case LEAD_UPDATE_DNC_SUCCESS:
    case ACTIVEDRIPS_CREATE_SUCCESS:
    case ACTIVEDRIPS_DELETE_SUCCESS:
    case ACTIVEDRIP_UPDATE_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          isChangingLeads: false,
          errorChangingLeads: "",
        },
      };

    case LEAD_CREATE_FAIL:
    case LEADS_DELETE_FAIL:
    case LEAD_UPDATE_FAIL:
    case LEAD_UPDATE_PHONE_NUMBERS_FAIL:
    case LEAD_UPDATE_EMAILS_FAIL:
    case LEAD_UPDATE_DNC_FAIL:
    case ACTIVEDRIPS_CREATE_FAIL:
    case ACTIVEDRIPS_DELETE_FAIL:
    case ACTIVEDRIP_UPDATE_FAIL:
      return {
        ...state,
        leads: {
          ...state.leads,
          isChangingLeads: false,
          errorChangingLeads: "Server error on Leads operation",
        },
      };

    case LEAD_UPDATE_CLOUDCMAREPORT_START:
      return {
        ...state,
        leads: {
          ...state.leads,
          isUpdatingCloudCMAReport: true,
        },
      };
    case LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS:
    case LEAD_UPDATE_CLOUDCMAREPORT_FAIL:
      return {
        ...state,
        leads: {
          ...state.leads,
          isUpdatingCloudCMAReport: false,
        },
      };

    case LEAD_UPDATE_ENHANCEDINFO_START:
      return {
        ...state,
        leads: {
          ...state.leads,
          isFetchingLeadEnhancedInfo: true,
        },
      };
    case LEAD_UPDATE_ENHANCEDINFO_SUCCESS:
    case LEAD_UPDATE_ENHANCEDINFO_FAIL:
      return {
        ...state,
        leads: {
          ...state.leads,
          isFetchingLeadEnhancedInfo: false,
        },
      };

    case LIKELYLEADS_IMPORT_START:
    case LIKELYLEADS_IMPORT_PROGRESS:
    case BULKLEADS_IMPORT_START:
    case BULKLEADS_IMPORT_PROGRESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          isImportingLeads: true,
        },
      };
    case LIKELYLEADS_IMPORT_SUCCESS:
    case LIKELYLEADS_IMPORT_FAIL:
    case BULKLEADS_IMPORT_SUCCESS:
    case BULKLEADS_IMPORT_FAIL:
      return {
        ...state,
        leads: {
          ...state.leads,
          isImportingLeads: false,
        },
      };

    // lists
    case LISTS_FETCH_START:
      return {
        ...state,
        lists: { ...state.lists, isFetchingLists: true },
      };
    case LISTS_FETCH_SUCCESS:
    case LISTS_FETCH_FAIL:
      return {
        ...state,
        lists: { ...state.lists, isFetchingLists: false },
      };
    case LIST_CREATE_START:
    case LIST_UPDATE_START:
    case LISTS_DELETE_START:
    case LIST_SPLIT_START:
    case LIKELY_ABORT_OPERATION_START:
    case LIKELY_COMPLETE_OPERATION_START:
    case LIKELY_DBREFRESH_ESTIMATECOST_START:
    case LIKELY_DBREFRESH_PAYANDPROCESS_START:
    case LIKELY_SKIPTRACE_ESTIMATECOST_START:
    case LIKELY_SKIPTRACE_PAYANDPROCESS_START:
      return {
        ...state,
        lists: { ...state.lists, isChangingLists: true },
      };
    case LIST_CREATE_SUCCESS:
    case LIST_UPDATE_SUCCESS:
    case LISTS_DELETE_SUCCESS:
    case LIST_SPLIT_SUCCESS:
    case LIKELY_ABORT_OPERATION_SUCCESS:
    case LIKELY_COMPLETE_OPERATION_SUCCESS:
    case LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS:
    case LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS:
    case LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS:
    case LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          isChangingLists: false,
          errorChangingLists: "",
        },
      };
    case LIST_CREATE_FAIL:
    case LIST_UPDATE_FAIL:
    case LISTS_DELETE_FAIL:
    case LIST_SPLIT_FAIL:
    case LIKELY_ABORT_OPERATION_FAIL:
    case LIKELY_COMPLETE_OPERATION_FAIL:
    case LIKELY_DBREFRESH_ESTIMATECOST_FAIL:
    case LIKELY_DBREFRESH_PAYANDPROCESS_FAIL:
    case LIKELY_SKIPTRACE_ESTIMATECOST_FAIL:
    case LIKELY_SKIPTRACE_PAYANDPROCESS_FAIL:
      return {
        ...state,
        lists: {
          ...state.lists,
          isChangingLists: false,
          errorChangingLists: "Server error on Lists operation",
        },
      };

    //superuser
    case SUPERUSER_RELOCATION_UPDATE_START:
      return {
        ...state,
        superuser: {
          ...state.superuser,
          isChangingSuperUserRelocation: true,
        },
      };
    case SUPERUSER_RELOCATION_UPDATE_SUCCESS:
    case SUPERUSER_RELOCATION_UPDATE_FAIL:
      return {
        ...state,
        superuser: {
          ...state.superuser,
          isChangingSuperUserRelocation: false,
        },
      };

    case SUPERUSER_RELOCATIONS_FETCH_START:
      return {
        ...state,
        superuser: {
          ...state.superuser,
          isFetchingSuperUserRelocations: true,
        },
      };
    case SUPERUSER_RELOCATIONS_FETCH_SUCCESS:
    case SUPERUSER_RELOCATIONS_FETCH_FAIL:
      return {
        ...state,
        superuser: {
          ...state.superuser,
          isFetchingSuperUserRelocations: false,
        },
      };

    // tags
    case TAGS_FOR_USER_CREATE_START:
    case TAGS_FOR_USER_UPDATE_START:
    case TAGS_FOR_USER_DELETE_START:
    case TAGS_FOR_LEADS_CREATE_START:
    case TAGS_FOR_LEADS_DELETE_START:
      return {
        ...state,
        user: { ...state.user, isChangingUser: true, errorChangingUser: "" },
        leads: {
          ...state.leads,
          isChangingLeads: true,
          errorChangingLeads: "",
        },
        tags: { ...state.tags, isChangingTags: true, errorChangingTags: "" },
      };
    case TAGS_FOR_USER_CREATE_SUCCESS:
    case TAGS_FOR_USER_UPDATE_SUCCESS:
    case TAGS_FOR_USER_DELETE_SUCCESS:
    case TAGS_FOR_LEADS_CREATE_SUCCESS:
    case TAGS_FOR_LEADS_DELETE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, isChangingUser: false, errorChangingUser: "" },
        leads: {
          ...state.leads,
          isChangingLeads: false,
          errorChangingLeads: "",
        },
        tags: { ...state.tags, isChangingTags: false, errorChangingTags: "" },
      };
    case TAGS_FOR_USER_CREATE_FAIL:
    case TAGS_FOR_USER_UPDATE_FAIL:
    case TAGS_FOR_USER_DELETE_FAIL:
    case TAGS_FOR_LEADS_CREATE_FAIL:
    case TAGS_FOR_LEADS_DELETE_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isChangingUser: false,
          errorChangingUser: "Server error on Tags operation",
        },
        leads: {
          ...state.leads,
          isChangingLeads: false,
          errorChangingLeads: "Server error on Tags operation",
        },
        tags: {
          ...state.tags,
          isChangingTags: false,
          errorChangingTags: "Server error on Tags operation",
        },
      };

    // templates POSTCARDS
    case POSTCARD_TEMPLATES_FETCH_START:
      return {
        ...state,
        templates: { ...state.templates, isFetchingPostcardTemplates: true },
      };
    case POSTCARD_TEMPLATES_FETCH_SUCCESS:
    case POSTCARD_TEMPLATES_FETCH_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isFetchingPostcardTemplates: false },
      };

    case POSTCARD_TEMPLATE_CREATE_START:
    case POSTCARD_TEMPLATE_UPDATE_START:
    case POSTCARD_TEMPLATES_DELETE_START:
      return {
        ...state,
        templates: { ...state.templates, isChangingPostcardTemplate: true },
      };
    case POSTCARD_TEMPLATE_CREATE_SUCCESS:
    case POSTCARD_TEMPLATE_UPDATE_SUCCESS:
    case POSTCARD_TEMPLATES_DELETE_SUCCESS:
    case POSTCARD_TEMPLATE_CREATE_FAIL:
    case POSTCARD_TEMPLATE_UPDATE_FAIL:
    case POSTCARD_TEMPLATES_DELETE_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isChangingPostcardTemplate: false },
      };

    case POSTCARDMANIA_DESIGNS_FETCH_START:
      return {
        ...state,
        templates: { ...state.templates, isFetchingPostcardmaniaDesigns: true },
      };
    case POSTCARDMANIA_DESIGNS_FETCH_SUCCESS:
    case POSTCARDMANIA_DESIGNS_FETCH_FAIL:
      return {
        ...state,
        templates: {
          ...state.templates,
          isFetchingPostcardmaniaDesigns: false,
        },
      };

    case POSTCARDMANIA_PROOF_GENERATE_START:
      return {
        ...state,
        templates: {
          ...state.templates,
          isGeneratingPostcardmaniaProof: true,
          errorCreatingPostcardmaniaProof: "",
        },
      };
    case POSTCARDMANIA_PROOF_GENERATE_SUCCESS:
      return {
        ...state,
        templates: {
          ...state.templates,
          isGeneratingPostcardmaniaProof: false,
          errorCreatingPostcardmaniaProof: "",
        },
      };
    case POSTCARDMANIA_PROOF_GENERATE_FAIL:
      return {
        ...state,
        templates: {
          ...state.templates,
          isGeneratingPostcardmaniaProof: false,
          errorCreatingPostcardmaniaProof:
            "There has been an error in creating the proof. Please try again.",
        },
      };

    // templates SMS
    case SMS_TEMPLATES_FETCH_START:
      return {
        ...state,
        templates: { ...state.templates, isFetchingSmsTemplates: true },
      };
    case SMS_TEMPLATES_FETCH_SUCCESS:
    case SMS_TEMPLATES_FETCH_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isFetchingSmsTemplates: false },
      };

    case SMS_TEMPLATE_CREATE_START:
    case SMS_TEMPLATE_UPDATE_START:
    case SMS_TEMPLATES_DELETE_START:
    case SMS_TEMPLATES_SHARE_START:
      return {
        ...state,
        templates: { ...state.templates, isChangingSmsTemplates: true },
      };
    case SMS_TEMPLATE_CREATE_SUCCESS:
    case SMS_TEMPLATE_UPDATE_SUCCESS:
    case SMS_TEMPLATES_DELETE_SUCCESS:
    case SMS_TEMPLATES_SHARE_SUCCESS:
    case SMS_TEMPLATE_CREATE_FAIL:
    case SMS_TEMPLATE_UPDATE_FAIL:
    case SMS_TEMPLATES_DELETE_FAIL:
    case SMS_TEMPLATES_SHARE_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isChangingSmsTemplates: false },
      };

    // templates EMAIL
    case EMAIL_TEMPLATES_FETCH_START:
      return {
        ...state,
        templates: { ...state.templates, isFetchingEmailTemplates: true },
      };
    case EMAIL_TEMPLATES_FETCH_SUCCESS:
    case EMAIL_TEMPLATES_FETCH_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isFetchingEmailTemplates: false },
      };
    case EMAIL_TEMPLATE_CREATE_START:
    case EMAIL_TEMPLATES_DUPLICATE_START:
    case EMAIL_TEMPLATE_UPDATE_START:
    case EMAIL_TEMPLATES_DELETE_START:
    case EMAIL_TEMPLATES_SHARE_START:
      return {
        ...state,
        templates: { ...state.templates, isChangingEmailTemplates: true },
      };
    case EMAIL_TEMPLATE_CREATE_SUCCESS:
    case EMAIL_TEMPLATES_DUPLICATE_SUCCESS:
    case EMAIL_TEMPLATE_UPDATE_SUCCESS:
    case EMAIL_TEMPLATES_DELETE_SUCCESS:
    case EMAIL_TEMPLATES_SHARE_SUCCESS:
    case EMAIL_TEMPLATE_CREATE_FAIL:
    case EMAIL_TEMPLATES_DUPLICATE_FAIL:
    case EMAIL_TEMPLATE_UPDATE_FAIL:
    case EMAIL_TEMPLATES_DELETE_FAIL:
    case EMAIL_TEMPLATES_SHARE_FAIL:
      return {
        ...state,
        templates: { ...state.templates, isChangingEmailTemplates: false },
      };

    // user
    case TRANSACTIONS_FETCH_START:
      return {
        ...state,
        user: {
          ...state.user,
          isFetchingTransactions: true,
        },
      };
    case TRANSACTIONS_FETCH_SUCCESS:
    case TRANSACTIONS_FETCH_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isFetchingTransactions: false,
        },
      };

    case FILE_UPLOAD_START:
      return {
        ...state,
        user: {
          ...state.user,
          isUploadingFile: true,
          fileUploadingPurpose: payload.filePurpose,
        },
      };
    case FILE_UPLOAD_SUCCESS:
    case FILE_UPLOAD_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isUploadingFile: false,
          fileUploadingPurpose: "",
        },
      };

    case USER_CREATE_START:
    case USER_UPDATE_START:
    case USER_UPDATE_ALIAS_FOR_SENDGRID_START:
    case USER_PASSWORD_CHANGE_START:
      return {
        ...state,
        user: {
          ...state.user,
          isChangingUser: true,
          errorChangingUser: "",
        },
      };
    case USER_CREATE_SUCCESS:
    case USER_UPDATE_SUCCESS:
    case USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS:
    case USER_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isChangingUser: false,
          errorChangingUser: "",
        },
      };

    case USER_CREATE_FAIL:
    case USER_PASSWORD_CHANGE_FAIL:
    case USER_UPDATE_ALIAS_FOR_SENDGRID_FAIL:
    case USER_UPDATE_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isChangingUser: false,
          errorChangingUser:
            payload.errorMessage || "Server error on User operation",
        },
      };

    case RETRIEVE_SOCIAL_DATA_START:
      return {
        ...state,
        user: {
          ...state.user,
          isRetrievingSocialData: true,
        },
      };
    case RETRIEVE_SOCIAL_DATA_SUCCESS:
    case RETRIEVE_SOCIAL_DATA_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isRetrievingSocialData: false,
        },
      };

    // zipcodes
    case ZIPCODE_CHECK_AVAILABILITY_START:
    case ZIPCODE_CHECK_OWNERS_START:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isCheckingZipCodes: true,
        },
      };
    case ZIPCODE_CHECK_AVAILABILITY_SUCCESS:
    case ZIPCODE_CHECK_AVAILABILITY_FAIL:
    case ZIPCODE_CHECK_OWNERS_SUCCESS:
    case ZIPCODE_CHECK_OWNERS_FAIL:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isCheckingZipCodes: false,
        },
      };

    case ZIPCODES_FETCH_START:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isFetchingZipCodes: true,
        },
      };
    case ZIPCODES_FETCH_SUCCESS:
    case ZIPCODES_FETCH_FAIL:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isFetchingZipCodes: false,
        },
      };

    case ZIPCODE_RETRIEVE_DATA_START:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isRetrievingPublicZipCodesData: true,
        },
      };
    case ZIPCODE_RETRIEVE_DATA_SUCCESS:
    case ZIPCODE_RETRIEVE_DATA_FAIL:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isRetrievingPublicZipCodesData: false,
        },
      };

    case RELOCATIONS_FETCH_START:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isFetchingRelocations: true,
        },
      };
    case RELOCATIONS_FETCH_SUCCESS:
    case RELOCATIONS_FETCH_FAIL:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isFetchingRelocations: false,
        },
      };

    case RELOCATION_CREATE_START:
    case RELOCATION_UPDATE_START:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isChangingRelocation: true,
        },
      };
    case RELOCATION_CREATE_SUCCESS:
    case RELOCATION_CREATE_FAIL:
    case RELOCATION_UPDATE_SUCCESS:
    case RELOCATION_UPDATE_FAIL:
      return {
        ...state,
        zipcodes: {
          ...state.zipcodes,
          isChangingRelocation: false,
        },
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
