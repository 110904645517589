import { ReduxAction } from "../../common-interfaces/interfaces";

import {
  RESET_LEADS_FILTERS,
  LEAD_VIEW_TAB_CHANGED,
  NOTE_CREATE_SUCCESS,
  NOTE_UPDATE_START,
  NOTE_UPDATE_SUCCESS,
  NOTE_UPDATE_FAILED,
  LEAD_ACTIVITYFEED_FETCH_SUCCESS,
  LEAD_UPDATE_ENHANCEDINFO_START,
  LEAD_UPDATE_ENHANCEDINFO_SUCCESS,
  //
  TICK_SINGLE_LEAD,
  TICK_MULTIPLE_LEADS,
  SET_LEADS_SORTING,
  SET_LEADS_FILTERS,
  LEADS_ALL_FETCH_PROGRESS,
  LEADS_ALL_FETCH_SUCCESS,
  LEADS_RECENT_FETCH_SUCCESS,
  LEAD_CREATE_SUCCESS,
  LEADS_DELETE_SUCCESS,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_PHONE_NUMBERS_SUCCESS,
  LEAD_UPDATE_EMAILS_SUCCESS,
  LEAD_UPDATE_DNC_SUCCESS,
  LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS,
  ACTIVEDRIPS_CREATE_SUCCESS,
  ACTIVEDRIPS_DELETE_SUCCESS,
  ACTIVEDRIP_UPDATE_SUCCESS,
  LIKELYLEADS_IMPORT_PROGRESS,
  BULKLEADS_IMPORT_PROGRESS,
} from "./leads-actions";

import { SMS_SEND_SUCCESS } from "../../inbox-sms/redux/sms-inbox-actions";

import { LIKELY_COMPLETE_OPERATION_SUCCESS } from "../../lists/redux/lists-actions";

import {
  TAGS_FOR_USER_UPDATE_SUCCESS,
  TAGS_FOR_USER_DELETE_SUCCESS,
  TAGS_FOR_LEADS_CREATE_SUCCESS,
  TAGS_FOR_LEADS_DELETE_SUCCESS,
} from "../../tags/redux/tags-actions";

import { LOGOUT } from "../../user/redux/user-actions";
import {
  leadsFilters,
  leadsFiltersDefaultValue,
  isTrustOwned,
} from "../../filters/utils-filters";

import { differenceInMilliseconds } from "date-fns";

interface LeadsState {
  //activityFeeds: { [leadId: number]: any[] };
  activityFeed: any[];
  isSendSmsSuccess: boolean;
  isCreateNoteSuccess: boolean;
  idOfUpdatingCallLogNote: number | null;
  idOfUpdatingNote: number | null;
  isUpdateNoteSuccess: boolean;
  isFetchingLeadsProgressValue: number;
  isInitializedLeads: boolean;
  leads: any[];
  leadsSortingValues: any;
  leadsFiltered: any[];
  leadsFiltersValues: any[];
  tickedLeadsIds: number[];
  currentLeadEnhancedInfo: any;
  latestLeadsFetchAt: Date | null;
}

const initialState: LeadsState = {
  //activityFeeds: {},
  activityFeed: [] as any,
  isSendSmsSuccess: false,
  isCreateNoteSuccess: false,
  idOfUpdatingCallLogNote: null,
  idOfUpdatingNote: null,
  isUpdateNoteSuccess: false,
  isFetchingLeadsProgressValue: 0,
  isInitializedLeads: false,
  leads: [],
  // leadsSortingValues: { sortBy: "alerts", sortDirection: "DESC" },
  leadsSortingValues: { sortBy: "createdAt", sortDirection: "DESC" },
  leadsFiltered: [],
  leadsFiltersValues: leadsFilters.map((leadsFilter: any) => ({
    ...leadsFilter,
    valueObj: leadsFiltersDefaultValue,
  })),
  tickedLeadsIds: [],
  currentLeadEnhancedInfo: null,
  latestLeadsFetchAt: null,
};

const sortLeads = (leads: any[], leadsSortingValues: any) => {
  if (
    !leadsSortingValues ||
    !leadsSortingValues.sortBy ||
    !leadsSortingValues.sortDirection
  )
    return leads;

  let sortingFunction = (a: any, b: any, sortBy: string): number => {
    if (
      (typeof a[sortBy] === "string" || a[sortBy] instanceof String) &&
      (typeof b[sortBy] === "string" || b[sortBy] instanceof String)
    ) {
      return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
    } else {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    }
  };

  if (
    leadsSortingValues.sortBy === "createdAt" ||
    leadsSortingValues.sortBy === "updatedAt"
  ) {
    sortingFunction = (a: any, b: any, sortBy: string) =>
      differenceInMilliseconds(new Date(b[sortBy]), new Date(a[sortBy]));
  }

  if (leadsSortingValues.sortBy === "alerts") {
    sortingFunction = (a: any, b: any, sortBy: string) => {
      const aMax = Math.max(
        a.lastIncomingSmsMessageAt && a.lastViewedAt
          ? differenceInMilliseconds(
              new Date(a.lastIncomingSmsMessageAt),
              new Date(a.lastViewedAt)
            )
          : 0,
        a.lastIncomingEmailAt && a.lastViewedAt
          ? differenceInMilliseconds(
              new Date(a.lastIncomingEmailAt),
              new Date(a.lastViewedAt)
            )
          : 0,
        0
      );

      const bMax = Math.max(
        b.lastIncomingSmsMessageAt && b.lastViewedAt
          ? differenceInMilliseconds(
              new Date(b.lastIncomingSmsMessageAt),
              new Date(b.lastViewedAt)
            )
          : 0,
        b.lastIncomingEmailAt && b.lastViewedAt
          ? differenceInMilliseconds(
              new Date(b.lastIncomingEmailAt),
              new Date(b.lastViewedAt)
            )
          : 0,
        0
      );

      if (aMax - bMax === 0) {
        return a.updatedAt < b.updatedAt ? 1 : -1;
      }
      return aMax < bMax ? 1 : -1;
    };
  }

  return leads.sort((a: any, b: any) =>
    leadsSortingValues.sortDirection === "DESC"
      ? sortingFunction(a, b, leadsSortingValues.sortBy)
      : -1 * sortingFunction(a, b, leadsSortingValues.sortBy)
  );
};

const filterLeads = (leads: any[], leadsFiltersValues: any[]) => {
  let leadsFiltered = leads;

  leadsFiltersValues.forEach((filter: any) => {
    if (filter.valueObj && filter.valueObj.id && filter.valueObj.id !== 0) {
      switch (filter.dataKey) {
        case "searchBar":
          leadsFiltered = leadsFiltered.filter(
            (lead: any) =>
              (lead.fullName &&
                lead.fullName
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (lead.allEmailAddresses.length > 0 &&
                lead.allEmailAddresses.some((e: any) =>
                  e.address
                    .toLowerCase()
                    .includes(filter.valueObj.value.toLowerCase())
                )) ||
              (lead.emailAddress &&
                lead.emailAddress
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (lead.addressRoute &&
                lead.addressRoute
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (lead.city &&
                lead.city
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (lead.zipCode &&
                lead.zipCode
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase()))
          );
          break;
        case "duplicates":
          const objProperty =
            filter.valueObj.value === "on Phone"
              ? "phoneNumber"
              : filter.valueObj.value === "on Email"
              ? "emailAddress"
              : filter.valueObj.value === "on Address"
              ? "addressRoute"
              : filter.valueObj.value === "on Name"
              ? "fullName"
              : null;

          if (objProperty) {
            // the technique for efficiently find duplicates is here
            // https://stackoverflow.com/questions/53212020/get-list-of-duplicate-objects-in-an-array-of-objects/53212154#53212154
            const lookupTable = leadsFiltered.reduce(
              (accumulator: any, lead: any) => {
                accumulator[lead[objProperty]] =
                  ++accumulator[lead[objProperty]] || 0;
                return accumulator;
              },
              {}
            );

            leadsFiltered = leadsFiltered.filter(
              (lead: any) =>
                !!lead[objProperty] && lookupTable[lead[objProperty]] > 0
            );
          }
          break;
        case "source":
          leadsFiltered =
            filter.valueObj.value === "My Leads"
              ? leadsFiltered.filter(
                  (lead: any) =>
                    lead.source !== "klevr farm" && lead.source !== "likely ai"
                )
              : filter.valueObj.value === "Tracking Website"
              ? leadsFiltered.filter((lead: any) => lead.source === "website")
              : filter.valueObj.value === "Klevr Farms"
              ? leadsFiltered.filter(
                  (lead: any) => lead.source === "klevr farm"
                )
              : filter.valueObj.value === "Likely AI - Spreadsheet"
              ? leadsFiltered.filter((lead: any) => lead.source === "likely ai")
              : filter.valueObj.value === "Likely AI - DB Refresh"
              ? leadsFiltered.filter(
                  (lead: any) => lead.lastLikelyDBRefreshAt !== null
                )
              : filter.valueObj.value === "Likely AI - SkipTrace"
              ? leadsFiltered.filter(
                  (lead: any) => lead.lastLikelySkipTraceAt !== null
                )
              : filter.valueObj.value === "Likely AI - Likely Sellers"
              ? leadsFiltered.filter((lead: any) => lead.likelySeller)
              : leadsFiltered;
          break;
        case "lists":
          leadsFiltered = leadsFiltered.filter((lead: any) =>
            filter.valueObj.leadIds.includes(lead.id)
          );
          break;
        case "tags":
          leadsFiltered = leadsFiltered.filter((lead: any) =>
            lead.tags.includes(filter.valueObj.value)
          );
          break;
        case "landings":
          leadsFiltered = leadsFiltered.filter(
            (lead: any) => filter.valueObj.landingId === lead.fromLandingPageId
          );
          break;
        case "emailAddress":
          leadsFiltered =
            filter.valueObj.value === "Has Primary Email"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.emailAddress
                ))
              : filter.valueObj.value === "Primary Email is Opt-in"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.emailAddress && !lead.isUnsubscribedFromEmails
                ))
              : filter.valueObj.value === "Primary Email is Opt-out"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.emailAddress && lead.isUnsubscribedFromEmails
                ))
              : filter.valueObj.value === "Has Multiple Emails"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.allEmailAddresses.length > 1
                ))
              : filter.valueObj.value === "No Primary Email"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => !lead.emailAddress
                ))
              : leadsFiltered;
          break;
        case "phoneNumber1":
        case "phoneNumber2":
          leadsFiltered =
            filter.valueObj.value === "Has Primary Phone"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.phoneNumber
                ))
              : filter.valueObj.value === "Has Multiple Phones"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.allPhoneNumbers && lead.allPhoneNumbers.length > 1
                ))
              : filter.valueObj.value === "Primary DNC is Off"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.phoneNumber && !lead.phoneNumberDNC
                ))
              : filter.valueObj.value === "Primary DNC is On"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.phoneNumber && lead.phoneNumberDNC
                ))
              : filter.valueObj.value === "Primary DNC is On"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.phoneNumber && lead.phoneNumberDNC
                ))
              : filter.valueObj.value === "Primary is Mobile"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.phoneNumber && lead.phoneNumberType === "cell"
                ))
              : filter.valueObj.value === "Primary is Landline"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.phoneNumber && lead.phoneNumberType === "landline"
                ))
              : filter.valueObj.value === "Primary is Unknown type"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.phoneNumber && lead.phoneNumberType === "unknown"
                ))
              : filter.valueObj.value === "Primary is Mobile or Unknown type"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.phoneNumber &&
                    (lead.phoneNumberType === "cell" ||
                      lead.phoneNumberType === "unknown")
                ))
              : filter.valueObj.value === "Primary is Landline or Unknown type"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    lead.phoneNumber &&
                    (lead.phoneNumberType === "landline" ||
                      lead.phoneNumberType === "unknown")
                ))
              : filter.valueObj.value === "No Primary Phone"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => !lead.phoneNumber
                ))
              : leadsFiltered;
          break;
        case "city":
          leadsFiltered = leadsFiltered.filter(
            (lead: any) =>
              lead.city !== null &&
              lead.city.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "state":
          leadsFiltered = leadsFiltered.filter(
            (lead: any) =>
              lead.state !== null &&
              lead.state.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "zipCode":
          leadsFiltered = leadsFiltered.filter(
            (lead: any) =>
              lead.zipCode !== null &&
              lead.zipCode.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "isTrustOwned":
          leadsFiltered =
            filter.valueObj.value === "Trust Owned"
              ? (leadsFiltered = leadsFiltered.filter((lead: any) =>
                  isTrustOwned(lead)
                ))
              : filter.valueObj.value === "Not Trust Owned"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => !isTrustOwned(lead)
                ))
              : leadsFiltered;
          break;
        case "isOwnerOccupied":
          leadsFiltered =
            filter.valueObj.value === "Owner Occupied"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => lead.isOwnerOccupied
                ))
              : filter.valueObj.value === "Not Owner Occupied"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) => !lead.isOwnerOccupied
                ))
              : leadsFiltered;
          break;
        case "drips":
          leadsFiltered = leadsFiltered.filter((lead: any) => {
            return (
              lead.activeDrips.filter(
                (activeDrip: any) =>
                  activeDrip.dripId === filter.valueObj.dripId
              ).length > 0
            );
          });
          break;
        case "activeDrips":
          leadsFiltered = leadsFiltered.filter((lead: any) => {
            return (
              lead.activeDrips.filter(
                (activeDrip: any) =>
                  activeDrip.status === filter.valueObj.value.toLowerCase()
              ).length > 0
            );
          });
          break;
        case "accuracy":
          leadsFiltered =
            filter.valueObj.value === "addressCapture"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    (lead.source === "website" || lead.fromLandingPageId > 0) &&
                    lead.addressRoute &&
                    (lead.zipCode ||
                      (lead.city && lead.state && lead.state !== "__"))
                ))
              : filter.valueObj.value === "partialLead"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    (lead.source === "website" || lead.fromLandingPageId > 0) &&
                    lead.addressRoute &&
                    (lead.zipCode ||
                      (lead.city && lead.state && lead.state !== "__")) &&
                    (lead.emailAddress || lead.phoneNumber)
                ))
              : filter.valueObj.value === "fullLead"
              ? (leadsFiltered = leadsFiltered.filter(
                  (lead: any) =>
                    (lead.source === "website" || lead.fromLandingPageId > 0) &&
                    lead.addressRoute &&
                    (lead.zipCode ||
                      (lead.city && lead.state && lead.state !== "__")) &&
                    lead.emailAddress &&
                    lead.phoneNumber
                ))
              : leadsFiltered;
          break;
        case "haunted":
          leadsFiltered =
            filter.valueObj.value === "Yes"
              ? (leadsFiltered = leadsFiltered.filter((lead: any) => false))
              : filter.valueObj.value === "No"
              ? leadsFiltered
              : leadsFiltered;
          break;
        default:
          break;
      }
    }
  });

  return leadsFiltered;
};

export const leadsReducer = (
  state: LeadsState = initialState,
  action: ReduxAction
): LeadsState => {
  const { payload, type } = action;

  switch (type) {
    case LEADS_ALL_FETCH_PROGRESS:
      return {
        ...state,
        isFetchingLeadsProgressValue: payload.isFetchingLeadsProgressValue,
      };

    case LEADS_ALL_FETCH_SUCCESS:
      const _leads = sortLeads(payload.leads, state.leadsSortingValues);
      return {
        ...state,
        latestLeadsFetchAt: new Date(),
        isInitializedLeads: true,
        isFetchingLeadsProgressValue: 0,
        leads: _leads,
        leadsFiltered: filterLeads(_leads, state.leadsFiltersValues),
      };

    case LEADS_RECENT_FETCH_SUCCESS:
    case BULKLEADS_IMPORT_PROGRESS:
    case LIKELYLEADS_IMPORT_PROGRESS:
      const leadsReceived = payload.newLeads.map((lead: any) => ({
        ...lead,
        activeDrips: lead.activeDrips || [],
      }));

      let __newLeads = [] as any[];

      if (state.leads.length === 0) {
        __newLeads = leadsReceived;
      } else {
        // remove duplicates
        __newLeads = state.leads
          .filter(
            (lead: any) =>
              leadsReceived.findIndex((l: any) => l.id === lead.id) === -1
          )
          .concat(leadsReceived);
      }

      __newLeads = sortLeads(__newLeads, state.leadsSortingValues);

      return {
        ...state,
        latestLeadsFetchAt: new Date(),
        leads: __newLeads,
        leadsFiltered: filterLeads(__newLeads, state.leadsFiltersValues),
      };

    case ACTIVEDRIPS_CREATE_SUCCESS:
    case ACTIVEDRIPS_DELETE_SUCCESS:
    case ACTIVEDRIP_UPDATE_SUCCESS:
    case LIKELY_COMPLETE_OPERATION_SUCCESS:
      const updatedLeads = payload.updatedLeads;

      let ____newLeads = [] as any[];
      // remove duplicates
      ____newLeads = state.leads
        .filter(
          (lead: any) =>
            updatedLeads.findIndex((l: any) => l.id === lead.id) === -1
        )
        .concat(updatedLeads);

      ____newLeads = sortLeads(____newLeads, state.leadsSortingValues);

      // this is similar to LEADS_RECENT_FETCH_SUCCESS
      // but there is no "latestLeadsFetchAt"
      return {
        ...state,
        tickedLeadsIds: [],
        leads: ____newLeads,
        leadsFiltered: filterLeads(____newLeads, state.leadsFiltersValues),
      };

    case LEAD_CREATE_SUCCESS:
      let newLead = payload.lead;
      if (!newLead.activeDrips) newLead.activeDrips = [];
      let _newLeads = sortLeads(
        state.leads.concat(newLead),
        state.leadsSortingValues
      );
      return {
        ...state,
        leads: _newLeads,
        leadsFiltered: filterLeads(_newLeads, state.leadsFiltersValues),
      };

    case LEADS_DELETE_SUCCESS:
      const { leadIds } = payload;
      return {
        ...state,
        leads: state.leads.filter((lead) => !leadIds.includes(lead.id)),
        leadsFiltered: state.leadsFiltered.filter(
          (lead) => !leadIds.includes(lead.id)
        ),
        tickedLeadsIds: [],
      };

    case LEAD_UPDATE_SUCCESS:
    case LEAD_UPDATE_PHONE_NUMBERS_SUCCESS:
    case LEAD_UPDATE_EMAILS_SUCCESS:
    case LEAD_UPDATE_DNC_SUCCESS:
    case LEAD_UPDATE_CLOUDCMAREPORT_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let ___newLeads = sortLeads(
        state.leads.map((lead: any) =>
          lead.id === payload.updatedLead.id
            ? { ...payload.updatedLead, activeDrips: lead.activeDrips } //activeDrips must be added
            : lead
        ),
        state.leadsSortingValues
      );
      return {
        ...state,
        leads: ___newLeads,
        leadsFiltered: filterLeads(___newLeads, state.leadsFiltersValues),
      };

    case LEAD_UPDATE_ENHANCEDINFO_START:
      return { ...state, currentLeadEnhancedInfo: null };
    case LEAD_UPDATE_ENHANCEDINFO_SUCCESS:
      return {
        ...state,
        currentLeadEnhancedInfo: payload.currentLeadEnhancedInfo,
      };

    case TAGS_FOR_USER_UPDATE_SUCCESS:
    case TAGS_FOR_USER_DELETE_SUCCESS:
    case TAGS_FOR_LEADS_CREATE_SUCCESS:
    case TAGS_FOR_LEADS_DELETE_SUCCESS:
      // Replacing multiple objects in array
      // https://stackoverflow.com/a/37585362
      const ___leads = state.leads.map((lead: any) => {
        let incomingReplacingLead = payload.updatedLeads.find(
          (updatedLead: any) => lead.id === updatedLead.id
        );

        if (incomingReplacingLead) {
          lead.tags = incomingReplacingLead.tags;
        }

        return lead;
      });

      return {
        ...state,
        tickedLeadsIds: [],
        leads: ___leads,
        leadsFiltered: filterLeads(___leads, state.leadsFiltersValues),
      };

    //

    case RESET_LEADS_FILTERS:
      let __leadsFiltersValues = state.leadsFiltersValues;
      __leadsFiltersValues.forEach((leadsFilter: any) => {
        if (!leadsFilter.hidden) {
          leadsFilter.valueObj = leadsFiltersDefaultValue;
        }
      });
      return {
        ...state,
        leadsFiltersValues: __leadsFiltersValues,
        leadsFiltered: filterLeads(state.leads, __leadsFiltersValues),
        tickedLeadsIds: [],
      };

    case SET_LEADS_FILTERS:
      const { filterDataKey, filterValue } = payload;
      let _leadsFiltersValues = state.leadsFiltersValues;

      _leadsFiltersValues[
        _leadsFiltersValues.findIndex(
          (filterValue: any) => filterValue.dataKey === filterDataKey
        )
      ].valueObj = filterValue;

      return {
        ...state,
        leadsFiltersValues: _leadsFiltersValues,
        leadsFiltered: filterLeads(state.leads, _leadsFiltersValues),
        tickedLeadsIds: [],
      };

    case SET_LEADS_SORTING:
      let _leadsSortingValues = {
        sortBy: payload.sortBy,
        sortDirection: payload.sortDirection,
      };

      const __leads = sortLeads(state.leads, _leadsSortingValues);

      return {
        ...state,
        leads: __leads,
        leadsSortingValues: _leadsSortingValues,
        leadsFiltered: filterLeads(__leads, state.leadsFiltersValues),
      };

    case TICK_SINGLE_LEAD:
      const { leadId } = payload;
      const { tickedLeadsIds } = state;
      const indexOfLead = tickedLeadsIds.indexOf(leadId);

      let newTickedLeadsIds;
      if (indexOfLead > -1) {
        newTickedLeadsIds = tickedLeadsIds.slice(0);
        newTickedLeadsIds.splice(indexOfLead, 1);
      } else {
        newTickedLeadsIds = tickedLeadsIds.concat(leadId);
      }

      return {
        ...state,
        tickedLeadsIds: newTickedLeadsIds,
      };

    case TICK_MULTIPLE_LEADS:
      return {
        ...state,
        tickedLeadsIds: payload.leadIds,
      };

    case LEAD_VIEW_TAB_CHANGED:
      return {
        ...state,
        isCreateNoteSuccess: false,
        isSendSmsSuccess: false,
      };

    case SMS_SEND_SUCCESS:
      return {
        ...state,
        isSendSmsSuccess: true,
      };

    case NOTE_CREATE_SUCCESS:
      return {
        ...state,
        isCreateNoteSuccess: true,
      };

    case NOTE_UPDATE_START:
      return {
        ...state,
        idOfUpdatingNote: payload.noteId,
      };

    case NOTE_UPDATE_SUCCESS:
      return {
        ...state,
        idOfUpdatingNote: null,
        isUpdateNoteSuccess: true,
      };

    case NOTE_UPDATE_FAILED:
      return {
        ...state,
        idOfUpdatingNote: null,
        isUpdateNoteSuccess: false,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
