import { ReduxAction } from "../../common-interfaces/interfaces";

import {
  COLLECTION_ITEM_UPDATE_FAIL,
  COLLECTION_ITEM_UPDATE_START,
  COLLECTION_ITEM_UPDATE_SUCCESS,
  FETCH_WEBFLOW_DATA_FAIL,
  FETCH_WEBFLOW_DATA_START,
  FETCH_WEBFLOW_DATA_SUCCESS,
} from "./websites-actions";

interface WebsiteState {
  isInitializedWebflowData: boolean;
  isFetchingWebflowData: boolean;
  webflowWebsites: any[];
  isUpdatingItems: boolean;
  itemUpdateErrorMsg: string | null;
}

const initialState: WebsiteState = {
  isInitializedWebflowData: false,
  isFetchingWebflowData: false,
  webflowWebsites: [],
  isUpdatingItems: false,
  itemUpdateErrorMsg: null,
};

export const websitesReducer = (
  state: WebsiteState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;

  switch (type) {
    case FETCH_WEBFLOW_DATA_START:
      return {
        ...state,
        isFetchingWebflowData: true,
      };

    case FETCH_WEBFLOW_DATA_SUCCESS:
      const { webflowWebsites } = payload;
      return {
        ...state,
        isInitializedWebflowData: true,
        isFetchingWebflowData: false,
        webflowWebsites: webflowWebsites,
      };

    case FETCH_WEBFLOW_DATA_FAIL:
      return {
        ...state,
        isFetchingWebflowData: false,
      };

    //
    case COLLECTION_ITEM_UPDATE_START:
      return {
        ...state,
        isUpdatingItems: true,
        itemUpdateErrorMsg: null,
      };

    case COLLECTION_ITEM_UPDATE_SUCCESS:
      const { updatedWebsites } = payload;
      return {
        ...state,
        isUpdatingItems: false,
        webflowWebsites: updatedWebsites,
        itemUpdateErrorMsg: null,
      };

    case COLLECTION_ITEM_UPDATE_FAIL:
      return {
        ...state,
        isUpdatingItems: false,
        itemUpdateErrorMsg: payload,
      };

    default:
      return state;
  }
};
