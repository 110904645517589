import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";

import { PostcardmaniaRecipientForm } from "../../common-interfaces/interfaces";

import { fetchCampaigns } from "../../campaigns/redux/campaigns-actions";
import { fetchDrips } from "../../drips/redux/drips-actions";

export const TICK_MULTIPLE_EMAIL_TEMPLATES = "TICK_MULTIPLE_EMAIL_TEMPLATES";
export const TICK_SINGLE_EMAIL_TEMPLATE = "TICK_SINGLE_EMAIL_TEMPLATE";
export const EMAIL_TEMPLATES_FETCH_START = "EMAIL_TEMPLATES_FETCH_START";
export const EMAIL_TEMPLATES_FETCH_SUCCESS = "EMAIL_TEMPLATES_FETCH_SUCCESS";
export const EMAIL_TEMPLATES_FETCH_FAIL = "EMAIL_TEMPLATES_FETCH_FAIL";
export const EMAIL_TEMPLATE_CREATE_START = "EMAIL_TEMPLATE_CREATE_START";
export const EMAIL_TEMPLATE_CREATE_SUCCESS = "EMAIL_TEMPLATE_CREATE_SUCCESS";
export const EMAIL_TEMPLATE_CREATE_FAIL = "EMAIL_TEMPLATE_CREATE_FAIL";
export const EMAIL_TEMPLATE_UPDATE_START = "EMAIL_TEMPLATE_UPDATE_START";
export const EMAIL_TEMPLATE_UPDATE_SUCCESS = "EMAIL_TEMPLATE_UPDATE_SUCCESS";
export const EMAIL_TEMPLATE_UPDATE_FAIL = "EMAIL_TEMPLATE_UPDATE_FAIL";
export const EMAIL_TEMPLATES_DELETE_START = "EMAIL_TEMPLATES_DELETE_START";
export const EMAIL_TEMPLATES_DELETE_SUCCESS = "EMAIL_TEMPLATES_DELETE_SUCCESS";
export const EMAIL_TEMPLATES_DELETE_FAIL = "EMAIL_TEMPLATES_DELETE_FAIL";
export const EMAIL_TEMPLATES_SHARE_START = "EMAIL_TEMPLATES_SHARE_START";
export const EMAIL_TEMPLATES_SHARE_SUCCESS = "EMAIL_TEMPLATES_SHARE_SUCCESS";
export const EMAIL_TEMPLATES_SHARE_FAIL = "EMAIL_TEMPLATES_SHARE_FAIL";
export const EMAIL_TEMPLATES_DUPLICATE_START =
  "EMAIL_TEMPLATES_DUPLICATE_START";
export const EMAIL_TEMPLATES_DUPLICATE_SUCCESS =
  "EMAIL_TEMPLATES_DUPLICATE_SUCCESS";
export const EMAIL_TEMPLATES_DUPLICATE_FAIL = "EMAIL_TEMPLATES_DUPLICATE_FAIL";
//
export const TICK_SINGLE_SMS_TEMPLATE = "TICK_SINGLE_SMS_TEMPLATE";
export const TICK_MULTIPLE_SMS_TEMPLATES = "TICK_MULTIPLE_SMS_TEMPLATES";
export const SMS_TEMPLATES_FETCH_START = "SMS_TEMPLATES_FETCH_START";
export const SMS_TEMPLATES_FETCH_SUCCESS = "SMS_TEMPLATES_FETCH_SUCCESS";
export const SMS_TEMPLATES_FETCH_FAIL = "SMS_TEMPLATES_FETCH_FAIL";
export const SMS_TEMPLATE_CREATE_START = "SMS_TEMPLATE_CREATE_START";
export const SMS_TEMPLATE_CREATE_SUCCESS = "SMS_TEMPLATE_CREATE_SUCCESS";
export const SMS_TEMPLATE_CREATE_FAIL = "SMS_TEMPLATE_CREATE_FAIL";
export const SMS_TEMPLATE_UPDATE_START = "SMS_TEMPLATE_UPDATE_START";
export const SMS_TEMPLATE_UPDATE_SUCCESS = "SMS_TEMPLATE_UPDATE_SUCCESS";
export const SMS_TEMPLATE_UPDATE_FAIL = "SMS_TEMPLATE_UPDATE_FAIL";
export const SMS_TEMPLATES_DELETE_START = "SMS_TEMPLATES_DELETE_START";
export const SMS_TEMPLATES_DELETE_SUCCESS = "SMS_TEMPLATES_DELETE_SUCCESS";
export const SMS_TEMPLATES_DELETE_FAIL = "SMS_TEMPLATES_DELETE_FAIL";
export const SMS_TEMPLATES_SHARE_START = "SMS_TEMPLATES_SHARE_START";
export const SMS_TEMPLATES_SHARE_SUCCESS = "SMS_TEMPLATES_SHARE_SUCCESS";
export const SMS_TEMPLATES_SHARE_FAIL = "SMS_TEMPLATES_SHARE_FAIL";
//
export const TICK_SINGLE_POSTCARD_TEMPLATE = "TICK_SINGLE_POSTCARD_TEMPLATE";
export const TICK_MULTIPLE_POSTCARD_TEMPLATES =
  "TICK_MULTIPLE_POSTCARD_TEMPLATES";
//
export const POSTCARDMANIA_DESIGNS_FETCH_START =
  "POSTCARDMANIA_DESIGNS_FETCH_START";
export const POSTCARDMANIA_DESIGNS_FETCH_SUCCESS =
  "POSTCARDMANIA_DESIGNS_FETCH_SUCCESS";
export const POSTCARDMANIA_DESIGNS_FETCH_FAIL =
  "POSTCARDMANIA_DESIGNS_FETCH_FAIL";
export const POSTCARDMANIA_PROOF_GENERATE_START =
  "POSTCARDMANIA_PROOF_GENERATE_START";
export const POSTCARDMANIA_PROOF_GENERATE_SUCCESS =
  "POSTCARDMANIA_PROOF_GENERATE_SUCCESS";
export const POSTCARDMANIA_PROOF_GENERATE_FAIL =
  "POSTCARDMANIA_PROOF_GENERATE_FAIL";
export const POSTCARD_TEMPLATES_FETCH_START = "POSTCARD_TEMPLATES_FETCH_START";
export const POSTCARD_TEMPLATES_FETCH_SUCCESS =
  "POSTCARD_TEMPLATES_FETCH_SUCCESS";
export const POSTCARD_TEMPLATES_FETCH_FAIL = "POSTCARD_TEMPLATES_FETCH_FAIL";
export const POSTCARD_TEMPLATE_CREATE_START = "POSTCARD_TEMPLATE_CREATE_START";
export const POSTCARD_TEMPLATE_CREATE_SUCCESS =
  "POSTCARD_TEMPLATE_CREATE_SUCCESS";
export const POSTCARD_TEMPLATE_CREATE_FAIL = "POSTCARD_TEMPLATE_CREATE_FAIL";
export const POSTCARD_TEMPLATE_UPDATE_START = "POSTCARD_TEMPLATE_UPDATE_START";
export const POSTCARD_TEMPLATE_UPDATE_SUCCESS =
  "POSTCARD_TEMPLATE_UPDATE_SUCCESS";
export const POSTCARD_TEMPLATE_UPDATE_FAIL = "POSTCARD_TEMPLATE_UPDATE_FAIL";
export const POSTCARD_TEMPLATES_DELETE_START =
  "POSTCARD_TEMPLATES_DELETE_START";
export const POSTCARD_TEMPLATES_DELETE_SUCCESS =
  "POSTCARD_TEMPLATES_DELETE_SUCCESS";
export const POSTCARD_TEMPLATES_DELETE_FAIL = "POSTCARD_TEMPLATES_DELETE_FAIL";

// EMAILS
export const shareEmailTemplates = (
  operation: string,
  templateIds: number[]
) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATES_SHARE_START,
      });

      const { newEmailTemplates } = await api.templates.shareEmailTemplates(
        operation,
        templateIds.filter((id: any) => id !== 0)
      );

      dispatch({
        type: EMAIL_TEMPLATES_SHARE_SUCCESS,
        payload: {
          newEmailTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATES_SHARE_FAIL,
      });
      return false;
    }
  };
};

export const duplicateEmailTemplates = (templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATES_DUPLICATE_START,
      });

      const { newEmailTemplates } = await api.templates.duplicateEmailTemplates(
        templateIds.filter((id: any) => id !== 0)
      );

      dispatch({
        type: EMAIL_TEMPLATES_DUPLICATE_SUCCESS,
        payload: {
          newEmailTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATES_DUPLICATE_FAIL,
      });
      return false;
    }
  };
};

export const createEmailTemplate = (templateForm: any) => {
  //const { name, subject, bodyInPlainText, bodyInHtml } = templateForm;
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_CREATE_START,
      });

      const createdEmailTemplate = await api.templates.createEmailTemplate(
        templateForm
      );

      dispatch({
        type: EMAIL_TEMPLATE_CREATE_SUCCESS,
        payload: {
          emailTemplate: createdEmailTemplate,
        },
      });
      dispatch(push(routePaths.templates.emailTemplates));
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATE_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const updateEmailTemplate = (templateForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_UPDATE_START,
      });

      const { updatedEmailTemplate } = await api.templates.updateEmailTemplate(
        templateForm
      );

      dispatch({
        type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
        payload: { updatedEmailTemplate },
      });

      // fetchEmailTemplates()(dispatch);
      dispatch(push(routePaths.templates.emailTemplates));
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATE_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteEmailTemplates = (templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATES_DELETE_START,
      });
      const res = await api.templates.deleteEmailTemplates(
        templateIds.filter((id: any) => id !== 0)
      );
      dispatch({
        type: EMAIL_TEMPLATES_DELETE_SUCCESS,
        payload: {
          templateIds,
        },
      });
      //this is dependant on it, so it should be updated
      fetchCampaigns()(dispatch);
      fetchDrips()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATES_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const fetchEmailTemplates = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATES_FETCH_START,
      });
      const emailTemplates = await api.templates.fetchEmailTemplates();
      dispatch({
        type: EMAIL_TEMPLATES_FETCH_SUCCESS,
        payload: {
          emailTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: EMAIL_TEMPLATES_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const tickSingleEmailTemplate = (templateId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_EMAIL_TEMPLATE,
      payload: {
        templateId,
      },
    });
  };
};

export const tickMultipleEmailTemplates = (templateIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_EMAIL_TEMPLATES,
      payload: { templateIds },
    });
  };
};

// Postcards
export const updatePostcardTemplate = (
  postcardTemplateId: number,
  newTemplateName: string
) => {
  if (!postcardTemplateId || !newTemplateName) {
    return null;
  }

  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARD_TEMPLATE_UPDATE_START,
      });

      const { updatedPostcardTemplate } =
        await api.postcards.updatePostcardTemplate(
          postcardTemplateId,
          newTemplateName
        );

      dispatch({
        type: POSTCARD_TEMPLATE_UPDATE_SUCCESS,
      });

      fetchPostcardTemplates()(dispatch);
      return updatedPostcardTemplate;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: POSTCARD_TEMPLATE_UPDATE_FAIL,
      });
      return null;
    }
  };
};

export const createPostcardTemplate = (
  templateName: string,
  designId: number,
  postcardForm: PostcardmaniaRecipientForm,
  proofFrontsideUrl: string,
  proofBacksideUrl: string
) => {
  if (!templateName || !designId) {
    return null;
  }

  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARD_TEMPLATE_CREATE_START,
      });

      const { createdPostcardTemplate } =
        await api.postcards.createPostcardTemplate(
          templateName,
          designId,
          postcardForm,
          proofFrontsideUrl,
          proofBacksideUrl
        );

      dispatch({
        type: POSTCARD_TEMPLATE_CREATE_SUCCESS,
      });

      //navigateToPostcardTemplatesManager()(dispatch);
      fetchPostcardTemplates()(dispatch);
      return createdPostcardTemplate;
      // return true;
    } catch (error: any) {
      dispatch({
        type: POSTCARD_TEMPLATE_CREATE_FAIL,
      });
      return null;
      // return false;
    }
  };
};

export const generatePostcardmaniaProofs = (
  designId: number,
  postcardForm: any
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARDMANIA_PROOF_GENERATE_START,
      });

      const postcardmaniaProofResults: {
        proofFrontsideUrl: string;
        proofBacksideUrl: string;
      } = await api.postcards.generatePostcardmaniaProofs(
        designId,
        postcardForm
      );
      dispatch({
        type: POSTCARDMANIA_PROOF_GENERATE_SUCCESS,
      });
      return postcardmaniaProofResults;
      // return true;
    } catch (error: any) {
      dispatch({
        type: POSTCARDMANIA_PROOF_GENERATE_FAIL,
        payload: error.response.data.message,
      });
      return {
        proofFrontsideUrl: "",
        proofBacksideUrl: "",
      };
      // return false;
    }
  };
};

export const deletePostcardTemplates = (templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARD_TEMPLATES_DELETE_START,
      });

      const res = await api.postcards.deletePostcardTemplates(
        templateIds.filter((id: any) => id !== 0)
      );

      dispatch({
        type: POSTCARD_TEMPLATES_DELETE_SUCCESS,
      });

      fetchPostcardTemplates()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: POSTCARD_TEMPLATES_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const fetchPostcardTemplates = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARD_TEMPLATES_FETCH_START,
      });
      const postcardTemplates = await api.postcards.fetchPostcardTemplates();
      dispatch({
        type: POSTCARD_TEMPLATES_FETCH_SUCCESS,
        payload: {
          postcardTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: POSTCARD_TEMPLATES_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const fetchPostcardmaniaDesigns = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: POSTCARDMANIA_DESIGNS_FETCH_START,
      });
      const postcardmaniaDesigns =
        await api.postcards.fetchPostcardmaniaDesigns();
      dispatch({
        type: POSTCARDMANIA_DESIGNS_FETCH_SUCCESS,
        payload: {
          postcardmaniaDesigns,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: POSTCARDMANIA_DESIGNS_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const tickSinglePostcardTemplate = (templateId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_POSTCARD_TEMPLATE,
      payload: {
        templateId,
      },
    });
  };
};

export const tickMultiplePostcardTemplates = (templateIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_POSTCARD_TEMPLATES,
      payload: { templateIds },
    });
  };
};

// SMS
export const shareSmsTemplates = (operation: string, templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_TEMPLATES_SHARE_START,
      });

      const { newSmsTemplates } = await api.templates.shareSmsTemplates(
        operation,
        templateIds.filter((id: any) => id !== 0)
      );

      dispatch({
        type: SMS_TEMPLATES_SHARE_SUCCESS,
        payload: {
          newSmsTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_TEMPLATES_SHARE_FAIL,
      });
      return false;
    }
  };
};

export const updateSmsTemplate = (templateForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_TEMPLATE_UPDATE_START,
      });
      await api.templates.updateSmsTemplate(templateForm);
      dispatch({
        type: SMS_TEMPLATE_UPDATE_SUCCESS,
      });
      fetchSmsTemplates()(dispatch);
      dispatch(push(routePaths.templates.smsTemplates));
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_TEMPLATE_UPDATE_FAIL,
      });
      return false;
    }
  };
};

export const deleteSmsTemplates = (templateIds: number[]) => {
  if (!templateIds || !templateIds.length) {
    return;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_TEMPLATES_DELETE_START,
      });
      const res = await api.templates.deleteSmsTemplates(
        templateIds.filter((id: any) => id !== 0)
      );
      dispatch({
        type: SMS_TEMPLATES_DELETE_SUCCESS,
        payload: {
          templateIds,
        },
      });
      //this is dependant on it, so it should be updated
      fetchCampaigns()(dispatch);
      fetchDrips()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_TEMPLATES_DELETE_FAIL,
      });
      return false;
    }
  };
};

export const fetchSmsTemplates = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_TEMPLATES_FETCH_START,
      });
      const smsTemplates = await api.templates.fetchSmsTemplates();
      dispatch({
        type: SMS_TEMPLATES_FETCH_SUCCESS,
        payload: {
          smsTemplates,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_TEMPLATES_FETCH_FAIL,
      });
      return false;
    }
  };
};

export const createSmsTemplate = (templateForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: SMS_TEMPLATE_CREATE_START,
      });
      const createdSmsTemplate = await api.templates.createSmsTemplate(
        templateForm
      );
      dispatch({
        type: SMS_TEMPLATE_CREATE_SUCCESS,
        payload: {
          smsTemplate: createdSmsTemplate,
        },
      });
      dispatch(push(routePaths.templates.smsTemplates));
      return true;
    } catch (error: any) {
      dispatch({
        type: SMS_TEMPLATE_CREATE_FAIL,
      });
      return false;
    }
  };
};

export const tickSingleSmsTemplate = (templateId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_SMS_TEMPLATE,
      payload: {
        templateId,
      },
    });
  };
};

export const tickMultipleSmsTemplates = (templateIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_SMS_TEMPLATES,
      payload: { templateIds },
    });
  };
};

export const navigateToCreatePostcardTemplatePage =
  () => (dispatch: Function) =>
    dispatch(push(routePaths.templates.createPostcardTemplates));

export const navigateToPostcardTemplatesManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.templates.postcardTemplates));

export const navigateToViewPostcardTemplatePage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.templates.viewPostcardTemplate, id)));

export const navigateToCreateSmsTemplatePage = () => (dispatch: Function) =>
  dispatch(push(routePaths.templates.createSmsTemplate));

export const navigateToCreateEmailTemplatePage = () => (dispatch: Function) =>
  dispatch(push(routePaths.templates.createEmailTemplate));

export const navigateToViewSmsTemplatePage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.templates.viewSmsTemplate, id)));

export const navigateToViewEmailTemplatePage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.templates.viewEmailTemplate, id)));

export const navigateToEditSmsTemplatePage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.templates.editSmsTemplate, id)));

export const navigateToEditEmailTemplatePage =
  (id: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.templates.editEmailTemplate, id)));

export const navigateToEmailTemplatesManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.templates.emailTemplates));

export const navigateToSmsTemplatesManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.templates.smsTemplates));
