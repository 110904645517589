import { ReduxAction } from "../../common-interfaces/interfaces";
import { UserInterface } from "../../common-interfaces/user";

import {
  FETCH_APP_STATES_SUCCESS,
  ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS,
  ADMIN_TRANSACTIONS_FETCH_SUCCESS,
  ADMIN_TRANSACTION_UPDATE_SUCCESS,
  ADMIN_FETCH_USERS_SUCCESS,
  ADMIN_ZIPCODES_FETCH_SUCCESS,
  //
  SET_ZIPCODES_FILTERS,
  RESET_ZIPCODES_FILTERS,
  SET_USERS_FILTERS,
  SET_USERS_SORTING,
  RESET_USERS_FILTERS,
  USER_IMPERSONATE,
  USER_STOP_IMPERSONATING,
  ADMIN_EDIT_USER_START,
  ADMIN_EDIT_USER_SUCCESS,
  ADMIN_EDIT_USER_FAIL,
  ADMIN_UPDATE_ZIPCODE_SUCCESS,
} from "./admin-actions";

import { LOGOUT } from "../../user/redux/user-actions";

import {
  usersFilters,
  usersFiltersDefaultValue,
  zipCodesFilters,
  zipCodesFiltersDefaultValue,
} from "../../filters/utils-filters";

import { getPlanFromUserInfos } from "../../common-utils/utils-stripe";

interface AdminState {
  appStates: any[];
  numberOfLockedDrips: number;
  users: UserInterface[];
  usersSortingValues: any;
  usersFiltered: UserInterface[];
  usersFiltersValues: any[];
  zipCodes: any[];
  zipCodesFiltered: any[];
  zipCodesFiltersValues: any[];
  directMailCampaigns: any[];
  landingPages: any[];
  impersonatingUserId: number | null;
  isUpdatingUser: boolean;
  errorEditingUser: string;
  isImportingColeLeads: boolean;
  transactions: any[];
}

const createInitialState = (): AdminState => ({
  appStates: [],
  numberOfLockedDrips: 0,
  landingPages: [],
  users: [],
  usersSortingValues: { sortBy: "isAdmin", sortDirection: "DESC" },
  usersFiltered: [],
  usersFiltersValues: usersFilters.map((usersFilter: any) => ({
    ...usersFilter,
    valueObj: usersFiltersDefaultValue,
  })),
  zipCodes: [],
  zipCodesFiltered: [],
  zipCodesFiltersValues: zipCodesFilters.map((zipCodesFilter: any) => ({
    ...zipCodesFilter,
    valueObj: zipCodesFiltersDefaultValue,
  })),
  directMailCampaigns: [],
  impersonatingUserId: null,
  isUpdatingUser: false,
  errorEditingUser: "",
  isImportingColeLeads: false,
  transactions: [],
});

const filterZipCodes = (zipCodes: any[], zipCodesFiltersValues: any[]) => {
  let zipCodesFiltered = zipCodes;

  zipCodesFiltersValues.forEach((filter: any) => {
    if (filter.valueObj && filter.valueObj.id && filter.valueObj.id !== 0) {
      switch (filter.dataKey) {
        case "searchBar":
          zipCodesFiltered = zipCodesFiltered.filter(
            (zipCode: any) =>
              (zipCode.zip &&
                zipCode.zip.includes(filter.valueObj.value.toLowerCase())) ||
              (zipCode.city &&
                zipCode.city
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (zipCode.state &&
                zipCode.state
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (zipCode.owningUsers?.length > 0 &&
                zipCode.owningUsers.some(
                  (user: any) =>
                    user.firstName
                      .toLowerCase()
                      .includes(filter.valueObj.value.toLowerCase()) ||
                    user.lastName
                      .toLowerCase()
                      .includes(filter.valueObj.value.toLowerCase()) ||
                    `${user.firstName + " " + user.lastName}`
                      .toLowerCase()
                      .includes(filter.valueObj.value.toLowerCase())
                ))
          );
          break;
        case "state":
          zipCodesFiltered = zipCodesFiltered.filter(
            (zipCode: any) =>
              zipCode.state !== null &&
              zipCode.state.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "maxAvailableLicenses":
          zipCodesFiltered = zipCodesFiltered.filter(
            (zipCode: any) =>
              zipCode.maxAvailableLicenses === parseInt(filter.valueObj.value)
          );
          break;
        case "assignedLicenses":
          zipCodesFiltered = zipCodesFiltered.filter((zipCode: any) => {
            let assignedLicenses = zipCode.ownedByUsers.length;
            let availableLicenses =
              zipCode.maxAvailableLicenses - assignedLicenses;
            if (
              filter.valueObj.value === "Underbooked" &&
              availableLicenses > 0
            )
              return true;
            if (filter.valueObj.value === "Full" && availableLicenses === 0)
              return true;
            if (filter.valueObj.value === "Overbooked" && availableLicenses < 0)
              return true;
            if (assignedLicenses === parseInt(filter.valueObj.value))
              return true;
            return false;
          });
          break;
        case "userOwningLicense":
          zipCodesFiltered = zipCodesFiltered.filter(
            (zipCode: any) =>
              zipCode.owningUsers?.length > 0 &&
              zipCode.owningUsers
                .map((user: any) =>
                  `${user.firstName + " " + user.lastName}`.toLowerCase()
                )
                .includes(filter.valueObj.value.toLowerCase())
          );
          break;
        default:
          break;
      }
    }
  });

  return zipCodesFiltered;
};

const filterUsers = (users: UserInterface[], usersFiltersValues: any[]) => {
  let usersFiltered = users;

  usersFiltersValues.forEach((filter: any) => {
    if (filter.valueObj && filter.valueObj.id && filter.valueObj.id !== 0) {
      switch (filter.dataKey) {
        case "searchBar":
          usersFiltered = usersFiltered.filter(
            (user: any) =>
              (user.firstName &&
                user.firstName
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.lastName &&
                user.lastName
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.companyName &&
                user.companyName
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.emailAddress &&
                user.emailAddress
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.appEmailUsername &&
                user.appEmailUsername
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.phoneNumber &&
                user.phoneNumber
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.appPhoneNumber &&
                user.appPhoneNumber
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase())) ||
              (user.assignedVirtualAssistant &&
                user.assignedVirtualAssistant
                  .toLowerCase()
                  .includes(filter.valueObj.value.toLowerCase()))
          );
          break;
        case "plan":
          usersFiltered =
            filter.valueObj.value === "on Paying Plan"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier !== "tier0"
                )
              : filter.valueObj.value === "Basic"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier0.1"
                )
              : filter.valueObj.value === "Startup"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier0.2"
                )
              : filter.valueObj.value === "Pro"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier0.3"
                )
              : filter.valueObj.value === "Enterprise"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier1"
                )
              : filter.valueObj.value === "Fully Managed"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier4"
                )
              : filter.valueObj.value === "Trial"
              ? usersFiltered.filter(
                  (user: any) => getPlanFromUserInfos(user)?.tier === "tier0"
                )
              : usersFiltered;
          break;
        case "twilioPhoneNumber":
          usersFiltered =
            filter.valueObj.value === "Pending Assignment"
              ? usersFiltered.filter(
                  (user: any) =>
                    !user.appPhoneNumber &&
                    getPlanFromUserInfos(user)?.tier !== "tier0"
                )
              : filter.valueObj.value === "Assigned"
              ? usersFiltered.filter((user: any) => user.appPhoneNumber)
              : usersFiltered;
          break;
        case "businessProfile":
          usersFiltered =
            filter.valueObj.value === "Completed"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    !user.isAdmin &&
                    (user.appPhoneNumber ||
                      getPlanFromUserInfos(user)?.tier !== "tier0") &&
                    user.businessProfile?.isBusinessProfileFormCompleted
                ))
              : filter.valueObj.value === "Not Completed"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    !user.isAdmin &&
                    (user.appPhoneNumber ||
                      getPlanFromUserInfos(user)?.tier !== "tier0") &&
                    !user.businessProfile?.isBusinessProfileFormCompleted
                ))
              : usersFiltered;
          break;
        case "unsubscribed":
          usersFiltered =
            filter.valueObj.value === "Has Unsubscribed"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    !user.isAdmin &&
                    !user.stripeSubscriptionIdForPlan &&
                    (user.hasPaidAccountSetup || user.hasPaidBrandSetup)
                ))
              : usersFiltered;
          break;
        case "klevrNation":
          usersFiltered =
            filter.valueObj.value === "Has Licenses"
              ? usersFiltered.filter(
                  (user: any) => user.zipCodeLicenses.length > 1
                )
              : filter.valueObj.value === "Does not have Licenses"
              ? usersFiltered.filter(
                  (user: any) =>
                    !user.zipCodeLicenses || user.zipCodeLicenses.length === 0
                )
              : usersFiltered;
          break;
        case "website":
          usersFiltered =
            filter.valueObj.value === "Has Subscription"
              ? usersFiltered.filter(
                  (user: any) => user.stripeSubscriptionIdForTrackingWebsite
                )
              : filter.valueObj.value === "No Subscription"
              ? usersFiltered.filter(
                  (user: any) => !user.stripeSubscriptionIdForTrackingWebsite
                )
              : usersFiltered;
          break;
        case "socialMedia":
          usersFiltered =
            filter.valueObj.value === "Has Subscription"
              ? usersFiltered.filter(
                  (user: any) =>
                    user.stripeSubscriptionIdForSocialMediaManagement
                )
              : filter.valueObj.value === "No Subscription"
              ? usersFiltered.filter(
                  (user: any) =>
                    !user.stripeSubscriptionIdForSocialMediaManagement
                )
              : usersFiltered;
          break;
        case "virtualAssistant":
          usersFiltered =
            filter.valueObj.value === "Has Subscription"
              ? usersFiltered.filter(
                  (user: any) => user.stripeSubscriptionIdForVirtualAssistant
                )
              : filter.valueObj.value === "No Subscription"
              ? usersFiltered.filter(
                  (user: any) => !user.stripeSubscriptionIdForVirtualAssistant
                )
              : usersFiltered;
          break;
        case "assignedVirtualAssistant":
          usersFiltered = usersFiltered.filter(
            (user: any) =>
              user.assignedVirtualAssistant !== null &&
              user.assignedVirtualAssistant
                .toLowerCase()
                .trim()
                .split(/\s+/)
                .join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "websiteCreationStatus":
          usersFiltered =
            filter.valueObj.value === "No Status"
              ? usersFiltered.filter(
                  (user: any) =>
                    !user.websiteCreationStatus === null ||
                    user.websiteCreationStatus === "No Status"
                )
              : filter.valueObj.value !== "- any -"
              ? usersFiltered.filter(
                  (user: any) =>
                    user.websiteCreationStatus === filter.valueObj.value
                )
              : usersFiltered;
          break;
        case "strategyCall":
          usersFiltered =
            filter.valueObj.value === "Past calls"
              ? usersFiltered.filter(
                  (user: any) =>
                    // strategyCallDate plus 24 hours
                    new Date(user.strategyCallDate).getTime() +
                      1000 * 60 * 60 * 24 <
                    new Date().getTime()
                )
              : filter.valueObj.value === "Recent & Upcoming calls"
              ? usersFiltered.filter(
                  (user: any) =>
                    // strategyCallDate plus or minus 24 hours
                    new Date(user.strategyCallDate).getTime() -
                      1000 * 60 * 60 * 24 <=
                      new Date().getTime() &&
                    new Date().getTime() <=
                      new Date(user.strategyCallDate).getTime() +
                        1000 * 60 * 60 * 24
                )
              : filter.valueObj.value === "Future calls"
              ? usersFiltered.filter(
                  (user: any) =>
                    // strategyCallDate minus 24 hours
                    new Date().getTime() <
                    new Date(user.strategyCallDate).getTime() -
                      1000 * 60 * 60 * 24
                )
              : filter.valueObj.value !== "- any -"
              ? usersFiltered.filter(
                  (user: any) =>
                    user.strategyCallStatus === filter.valueObj.value
                )
              : usersFiltered;
          break;
        case "advertisingBudget":
          usersFiltered =
            filter.valueObj.value === "No Budget"
              ? usersFiltered.filter(
                  (user: any) =>
                    user.advertisingBudget === null ||
                    parseInt(user.advertisingBudget) === 0
                )
              : filter.valueObj.value === "1-500"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 1 &&
                    parseInt(user.advertisingBudget) <= 500
                )
              : filter.valueObj.value === "501-1000"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 501 &&
                    parseInt(user.advertisingBudget) <= 1000
                )
              : filter.valueObj.value === "1001-1500"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 1001 &&
                    parseInt(user.advertisingBudget) <= 1500
                )
              : filter.valueObj.value === "1501-2000"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 1501 &&
                    parseInt(user.advertisingBudget) <= 2000
                )
              : filter.valueObj.value === "2001-2500"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 2001 &&
                    parseInt(user.advertisingBudget) <= 2500
                )
              : filter.valueObj.value === "2501-3000"
              ? usersFiltered.filter(
                  (user: any) =>
                    parseInt(user.advertisingBudget) >= 2501 &&
                    parseInt(user.advertisingBudget) <= 3000
                )
              : usersFiltered;
          break;
        case "noteText":
          usersFiltered =
            filter.valueObj.value === "Has Note"
              ? usersFiltered.filter((user: any) => user.noteText)
              : filter.valueObj.value === "No Note"
              ? usersFiltered.filter((user: any) => !user.noteText)
              : usersFiltered;
          break;
        case "city":
          usersFiltered = usersFiltered.filter(
            (user: any) =>
              user.city !== null &&
              user.city.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "state":
          usersFiltered = usersFiltered.filter(
            (user: any) =>
              user.state !== null &&
              user.state.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "zipCode":
          usersFiltered = usersFiltered.filter(
            (user: any) =>
              user.zipCode !== null &&
              user.zipCode.toLowerCase().trim().split(/\s+/).join(" ") ===
                filter.valueObj.value
                  .toLowerCase()
                  .trim()
                  .split(/\s+/)
                  .join(" ")
          );
          break;
        case "howManyLeads":
          usersFiltered =
            filter.valueObj.value === "0"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) => user.howManyLeads === 0
                ))
              : filter.valueObj.value === "1-1000"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    user.howManyLeads >= 1 && user.howManyLeads <= 1000
                ))
              : filter.valueObj.value === "1001-5000"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    user.howManyLeads >= 1001 && user.howManyLeads <= 5000
                ))
              : filter.valueObj.value === "5001-10000"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    user.howManyLeads >= 5001 && user.howManyLeads <= 10000
                ))
              : filter.valueObj.value === "10001-20000"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) =>
                    user.howManyLeads >= 10001 && user.howManyLeads <= 20000
                ))
              : filter.valueObj.value === "20001+"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) => user.howManyLeads >= 20001
                ))
              : usersFiltered;
          break;
        case "permissions":
          usersFiltered =
            filter.valueObj.value === "is Admin"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) => user.isAdmin
                ))
              : filter.valueObj.value === "is SuperUser"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) => user.isSuperUser
                ))
              : filter.valueObj.value === "is Paused"
              ? (usersFiltered = usersFiltered.filter(
                  (user: any) => user.isPaused
                ))
              : usersFiltered;
          break;
        default:
          break;
      }
    }
  });

  return usersFiltered;
};

const sortUsers = (users: UserInterface[], usersSortingValues: any) => {
  if (
    !usersSortingValues ||
    !usersSortingValues.sortBy ||
    !usersSortingValues.sortDirection
  )
    return users;

  let sortingFunction = (a: any, b: any, sortBy: string) =>
    a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;

  if (usersSortingValues.sortBy === "strategyCallDate") {
    sortingFunction = (a: any, b: any, sortBy: string) =>
      a.strategyCallDate < b.strategyCallDate
        ? 1
        : a.strategyCallDate > b.strategyCallDate
        ? -1
        : 0;

    let usersWithoutStrategyCallDate = users
      .filter((user: any) => user.strategyCallDate === null)
      .sort((a: any, b: any) =>
        a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
      );

    let usersWithStrategyCallDate = users
      .filter((user: any) => user.strategyCallDate !== null)
      .sort((a: any, b: any) =>
        usersSortingValues.sortDirection === "DESC"
          ? sortingFunction(a, b, usersSortingValues.sortBy)
          : -1 * sortingFunction(a, b, usersSortingValues.sortBy)
      );
    return usersWithStrategyCallDate.concat(usersWithoutStrategyCallDate);
  }

  return users.sort((a: any, b: any) =>
    usersSortingValues.sortDirection === "DESC"
      ? sortingFunction(a, b, usersSortingValues.sortBy)
      : -1 * sortingFunction(a, b, usersSortingValues.sortBy)
  );
};

export const adminReducer = (
  state: AdminState = createInitialState(),
  action: ReduxAction
): AdminState => {
  const { payload, type } = action;
  switch (type) {
    case RESET_ZIPCODES_FILTERS:
      let __zipCodesFiltersValues = state.zipCodesFiltersValues;
      __zipCodesFiltersValues.forEach((filter: any) => {
        if (!filter.hidden) {
          filter.valueObj = zipCodesFiltersDefaultValue;
        }
      });
      return {
        ...state,
        zipCodesFiltersValues: __zipCodesFiltersValues,
        zipCodesFiltered: filterZipCodes(
          state.zipCodes,
          __zipCodesFiltersValues
        ),
      };

    case SET_ZIPCODES_FILTERS:
      let _zipCodesFiltersValues = state.zipCodesFiltersValues;

      _zipCodesFiltersValues[
        _zipCodesFiltersValues.findIndex(
          (value: any) => value.dataKey === payload.filterDataKey
        )
      ].valueObj = payload.filterValue;

      return {
        ...state,
        zipCodesFiltersValues: _zipCodesFiltersValues,
        zipCodesFiltered: filterZipCodes(
          state.zipCodes,
          _zipCodesFiltersValues
        ),
      };

    case RESET_USERS_FILTERS:
      let __usersFiltersValues = state.usersFiltersValues;
      __usersFiltersValues.forEach((filter: any) => {
        if (!filter.hidden) {
          filter.valueObj = usersFiltersDefaultValue;
        }
      });
      return {
        ...state,
        usersFiltersValues: __usersFiltersValues,
        usersFiltered: filterUsers(state.users, __usersFiltersValues),
      };

    case SET_USERS_FILTERS:
      let _usersFiltersValues = state.usersFiltersValues;

      _usersFiltersValues[
        _usersFiltersValues.findIndex(
          (value: any) => value.dataKey === payload.filterDataKey
        )
      ].valueObj = payload.filterValue;

      return {
        ...state,
        usersFiltersValues: _usersFiltersValues,
        usersFiltered: filterUsers(state.users, _usersFiltersValues),
      };

    case SET_USERS_SORTING:
      let _usersSortingValues = {
        sortBy: payload.sortBy,
        sortDirection: payload.sortDirection,
      };

      const __users = sortUsers(state.users, _usersSortingValues);

      return {
        ...state,
        users: __users,
        usersSortingValues: _usersSortingValues,
        usersFiltered: filterUsers(__users, state.usersFiltersValues),
      };

    case ADMIN_ZIPCODES_FETCH_SUCCESS:
      const _zipCodes = payload.zipCodes.sort((a: any, b: any) =>
        a.zip.localeCompare(b.zip)
      );

      return {
        ...state,
        zipCodes: _zipCodes,
        zipCodesFiltered: filterZipCodes(
          _zipCodes,
          state.zipCodesFiltersValues
        ),
      };

    case ADMIN_UPDATE_ZIPCODE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let __zipCodes = state.zipCodes
        .map((zipCode: any) =>
          zipCode.zip === payload.updatedZipCode.zip
            ? payload.updatedZipCode
            : zipCode
        )
        .sort((a: any, b: any) => a.zip.localeCompare(b.zip));

      return {
        ...state,
        zipCodes: __zipCodes,
        zipCodesFiltered: filterZipCodes(
          __zipCodes,
          state.zipCodesFiltersValues
        ),
      };

    case FETCH_APP_STATES_SUCCESS:
      return {
        ...state,
        appStates: payload.appStates,
        numberOfLockedDrips: payload.numberOfLockedDrips,
      };

    case ADMIN_FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        usersFiltered: payload.users,
      };

    case ADMIN_TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        transactions: payload.transactions.sort((a: any, b: any) =>
          b.createdAt < a.createdAt ? -1 : 1
        ),
      };

    case ADMIN_TRANSACTION_UPDATE_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _transactions = state.transactions
        .map((transaction: any) =>
          transaction.id === payload.updatedTransaction.id
            ? payload.updatedTransaction
            : transaction
        )
        .sort((a: any, b: any) => (b.createdAt < a.createdAt ? -1 : 1));
      return {
        ...state,
        transactions: _transactions,
      };

    case USER_IMPERSONATE:
      return {
        ...state,
        impersonatingUserId: payload.user.id,
      };
    case USER_STOP_IMPERSONATING:
      return {
        ...state,
        impersonatingUserId: null,
      };
    case ADMIN_FETCH_DIRECTMAIL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        directMailCampaigns: payload.directMailCampaigns.sort(
          (a: any, b: any) =>
            a.scheduledStartDate < b.scheduledStartDate ? -1 : 1
        ),
      };
    case ADMIN_EDIT_USER_START:
      return {
        ...state,
        isUpdatingUser: true,
        errorEditingUser: "",
      };
    case ADMIN_EDIT_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        errorEditingUser: "",
      };
    case ADMIN_EDIT_USER_FAIL:
      return {
        ...state,
        isUpdatingUser: false,
        errorEditingUser: "Error editing user",
      };
    case LOGOUT:
      return createInitialState();
    default:
      return state;
  }
};
