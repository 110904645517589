import { ReduxAction } from "../../common-interfaces/interfaces";
import { ListInterface, voidList } from "../../common-interfaces/lists";
import { LOGOUT } from "../../user/redux/user-actions";
import {
  TICK_SINGLE_LIST,
  TICK_MULTIPLE_LISTS,
  LISTS_FETCH_SUCCESS,
  LISTS_DELETE_SUCCESS,
  LIKELY_ABORT_OPERATION_SUCCESS,
  LIKELY_COMPLETE_OPERATION_SUCCESS,
  LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS,
  LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS,
  LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS,
  LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS,
} from "./lists-actions";

interface ListsState {
  tickedListsIds: number[];
  isInitializedLists: boolean;
  lists: ListInterface[];
}

const initialState: ListsState = {
  tickedListsIds: [],
  isInitializedLists: false,
  lists: [],
};

export const listsReducer = (
  state: ListsState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case LIKELY_ABORT_OPERATION_SUCCESS:
    case LIKELY_COMPLETE_OPERATION_SUCCESS:
    case LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS:
    case LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS:
    case LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS:
    case LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS:
      // Replacing one object in array with one-liner
      // https://stackoverflow.com/a/61304412
      let _lists = state.lists
        .map((list: any) =>
          list.id === payload.updatedList.id ? payload.updatedList : list
        )
        .sort((a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

      return {
        ...state,
        lists: _lists,
      };

    case LISTS_FETCH_SUCCESS:
      const newLists = [voidList].concat(payload.lists);

      return {
        ...state,
        isInitializedLists: true,
        lists: newLists.sort((a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ),
      };

    case LISTS_DELETE_SUCCESS:
      const { listIds } = payload;
      return {
        ...state,
        lists: state.lists.filter((list) => !listIds.includes(list.id)),
        tickedListsIds: [],
      };

    case TICK_SINGLE_LIST:
      const { listId } = payload;
      const { tickedListsIds } = state;
      const indexOfList = tickedListsIds.indexOf(listId);

      let newTickedListsIds;
      if (indexOfList > -1) {
        newTickedListsIds = tickedListsIds.slice(0);
        newTickedListsIds.splice(indexOfList, 1);
      } else {
        newTickedListsIds = tickedListsIds.concat(listId);
      }
      return {
        ...state,
        tickedListsIds: newTickedListsIds,
      };

    case TICK_MULTIPLE_LISTS:
      return {
        ...state,
        tickedListsIds: payload.listIds,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
