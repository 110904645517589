// import { responsiveFontSizes } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "@mui/lab/themeAugmentation";

let theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: "0.5rem",
          height: "0.5rem",
        },
        "*::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgba(240,240,240,1)",
          borderRadius: "2px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(57,57,57,1)",
          borderRadius: "2px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "16px 0px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&$hover:hover": {
            backgroundColor: "#00d88e",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&$selected, &$selected:hover": {
            backgroundColor: "#00d88e",
          },
        },
        button: {
          "&:hover": {
            backgroundColor: "#62ffbf",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          // marginTop: -8,
          // marginLeft: -12,
          "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
          },
        },
        mark: {
          backgroundColor: "transparent",
        },
        markActive: {
          backgroundColor: "transparent",
        },
        valueLabel: {
          left: "calc(-50% + 4px)",
          "& *": {
            color: "white",
          },
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#393939",
      light: "#636363",
      dark: "#131313",
      contrastText: "#00d88e",
    },
    secondary: {
      main: "#00d88e",
      light: "#62ffbf",
      dark: "#00a560",
      contrastText: "#ffffff",
    },
  },
  //https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {},
});

theme = responsiveFontSizes(theme);

export default theme;
