import {
  EMAILS_FETCH_SUCCESS,
  EMAIL_FETCH_SINGLE_START,
  EMAIL_FETCH_SINGLE_SUCCESS,
  EMAILS_DELETE_SUCCESS,
} from "./inbox-actions";
import { LOGOUT } from "../../user/redux/user-actions";

import { ReduxAction } from "../../common-interfaces/interfaces";
import { parseSenderFromEmail } from "../../common-utils/utils-emails";

import { differenceInMilliseconds } from "date-fns";

interface InboxState {
  latestEmailsInitializedAt: Date | null;
  latestEmailsUpdateAt: Date | null;
  emails: any[];
}

const initialState: InboxState = {
  latestEmailsInitializedAt: null,
  latestEmailsUpdateAt: null,
  emails: [],
};

export const inboxEmailsReducer = (
  state: InboxState = initialState,
  action: ReduxAction
): InboxState => {
  const { payload, type } = action;
  switch (type) {
    case EMAILS_FETCH_SUCCESS:
      const newEmails = payload.emails;

      let _emails = [] as any[];

      if (state.emails.length === 0) {
        _emails = newEmails;
      } else {
        _emails = state.emails
          .filter(
            (email: any) =>
              newEmails.findIndex((e: any) => e.id === email.id) === -1
          )
          .concat(newEmails);
      }

      return {
        ...state,
        emails: _emails
          .map((email: any) => parseSenderFromEmail(email))
          .sort((a: any, b: any) =>
            differenceInMilliseconds(
              new Date(b.createdAt),
              new Date(a.createdAt)
            )
          ),
        latestEmailsInitializedAt: new Date(),
        latestEmailsUpdateAt: new Date(),
      };

    // case EMAIL_FETCH_SINGLE_START:
    //   const ___emails = state.emails;
    //   const __index = ___emails.findIndex(
    //     (email: any) => email.id === payload.emailId
    //   );
    //   if (__index >= 0) {
    //     ___emails[__index].wasOpened = true;
    //   }
    //   return {
    //     ...state,
    //     emails: ___emails,
    //     latestEmailsUpdateAt: new Date(),
    //   };

    case EMAIL_FETCH_SINGLE_SUCCESS:
      const __emails = state.emails
        .map((email: any) =>
          email.id === payload.updatedEmail.id
            ? parseSenderFromEmail(payload.updatedEmail)
            : parseSenderFromEmail(email)
        )
        .sort((a: any, b: any) =>
          differenceInMilliseconds(new Date(b.createdAt), new Date(a.createdAt))
        );
      return {
        ...state,
        emails: __emails,
        latestEmailsUpdateAt: new Date(),
      };

    case EMAILS_DELETE_SUCCESS:
      const { emailIds } = payload;
      return {
        ...state,
        emails: state.emails.filter((email) => !emailIds.includes(email.id)),
        latestEmailsUpdateAt: new Date(),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
